import { IonCard, IonIcon } from "@ionic/react";
import { returnUpBack } from "ionicons/icons";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import MaloTank from "../component/maloTank";

const MaloTankPage : React.FC = () => {
  const history = useHistory();
  return (
    <div>
      <div className='pageTitle'><FormattedMessage id='maloTankTitle'/></div>
      <IonCard
        style={{
          color: "black",
          width: "40%",
          padding: "5%",
          fontFamily: "Lato",
          fontSize: "15pt",
          fontWeight: 600,
        }}
        onClick={() => {
          history.goBack();
        }}
      > <IonIcon icon={returnUpBack} /> <FormattedMessage id="goBack" /></IonCard>
      <MaloTank/>
    </div>
  );
};

export default MaloTankPage;
