import { language } from 'ionicons/icons';
import React from "react";
import { FormattedMessage } from "react-intl";

const LegalesNoticies: React.FC = () => {
  const lang = localStorage.getItem('language');

  return (
    <div>
      <div className='pageTitle' style={{ marginBottom: "20%" }}>
        <FormattedMessage id='legalesNoticies' />
      </div>
      <div style={{ textAlign: "left", margin: "10% 5%", fontFamily: 'Lato' }}>
        <div style={{ marginBottom: "20%" }}>
          <div style={{ fontSize: "28px", fontFamily: 'Abril Fatface', marginBottom: "5%" }}>
            <FormattedMessage id="editor" />
          </div>
          <div>
            <FormattedMessage id="websiteEditBy" /> : SOFRALAB
            <br />
            <FormattedMessage id="headOffice" /> : 79 av, A.A. Thévenet – CS 11031
            – 51530 MAGENTA – France
            <br />
            <FormattedMessage id="tel" /> : 33 (0)3 26 51 29 30
            <br />
            <FormattedMessage id="fax" /> : 33 (0)3 26 51 87 60
            <br />
            contact@sofralab.com
            <br />
            <FormattedMessage id="sharedCapital" /> : 1 315 600 €<br />
            <FormattedMessage id="siretNumber" /> 572 224 863 00041
            <br />
            {lang !== 'DE-DE' ? <>
              <FormattedMessage id="numero" /> RCS Reims 572 224 863
              <br />
              Ident. <FormattedMessage id="TVA" /> FR 29 572 224 863 <br />
            </> :
              <>
                HR Reims 572 224 863
                <br />
                USt-IdNr FR 29 572 224 863 <br />
              </>}
            <FormattedMessage id="directorOfPublication" /> : Didier FAGES
            <br />
          </div>
        </div>
        <div style={{ marginBottom: "20%" }}>
          <div style={{ fontSize: "28px", fontFamily: 'Abril Fatface', marginBottom: "5%" }}>
            <FormattedMessage id="host" />
          </div>
          <div>
            Log'in Line
            <br />
            <a href="https://www.loginline.com" target='_blank' rel='noreferrer'>www.loginline.com</a>
          </div>
        </div>
        <div style={{ marginBottom: "20%" }}>
          <div style={{ fontSize: "28px", fontFamily: 'Abril Fatface', marginBottom: "5%" }}>
            <FormattedMessage id="reproductiveRights" />
          </div>
          <div>
            <FormattedMessage id="reproductiveRightsContent" />
          </div>
        </div>
        <div style={{ marginBottom: "20%" }}>
          <div style={{ fontSize: "28px", fontFamily: 'Abril Fatface', marginBottom: "5%" }}>
            <FormattedMessage id="personnalData" />
          </div>
          <div>
            <FormattedMessage id="personnalDataContent" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegalesNoticies;
