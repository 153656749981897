import React from "react";
import { FormattedMessage } from "react-intl";
import translations from '../../../modules/translations';
import Charmat from "../../../types/Charmat";

interface Props {
  computedData: Charmat | undefined;
  aromaticProfile: string;
}

const roundTo1Digit = (x: number | undefined) => x && Math.round(x * 10) / 10;

const CharmatFirstStep: React.FC<Props> = ({
  computedData,
  aromaticProfile,
}) => {

  const language = localStorage.getItem('language') as string;
  

  return (
    <div>
      <div className="inputDiv">
        <FormattedMessage id="charmatFirstStepFirstMessage" />
      </div>
      <div className="inputDiv">
        <FormattedMessage id="dissolve" />
        <br />
        <span className="productValue">
          {roundTo1Digit(computedData?.rehydratation.startYWeight)}
        </span>
        <span className="productUnit">{translations[language]['kg']}</span>
        <br />
        <FormattedMessage id="of" />{" "}
        <span className="productName">START® Y SP (5 {language === 'RU-RU' ? 'г/гл' : 'g/hL'})</span>
        <br />
        <FormattedMessage id="in" />
        <br />
        <span className="productValue">
          {roundTo1Digit(computedData?.rehydratation.startYWaterVolume)}{" "}
        </span>
        <span className="productUnit" />
        <FormattedMessage id="liters" />
        <br />
        <FormattedMessage id="water35" />
      </div>
      <div
        style={{ color: "white", backgroundColor: "#1B1D26", width: "100%", padding: "10% 5%" }}
      >
        <FormattedMessage id="charmatFirstStepFirstBlackMessage" />
      </div>
      <div className="inputDiv">
        <FormattedMessage id="dissolve" />
        <br />
        <span className="productValue">
          {roundTo1Digit(computedData?.rehydratation.SP7Weight)}
        </span>
        <span className="productUnit">{translations[language]['kg']}</span>
        <br />
        <FormattedMessage id="of" />{" "}
        <span className="productName">
          {aromaticProfile === "mineralFraisFruitBlanc" && "SOEC® 1971"}
          {aromaticProfile === "tresFruite" && "SP® 49"}
          {aromaticProfile === "floralElegant" && "SP® 39"}
          {aromaticProfile === "cremeuxVarietal" &&
            "SP® 7"}
        </span> <br />
        <FormattedMessage id="in" />
        <br />
        <span className="productValue">
          {roundTo1Digit(computedData?.rehydratation.SP7WaterVolume)}{" "}
        </span>
        <span className="productUnit" />
        <FormattedMessage id="liters" />
        <br />
        {language === 'DE-DE' && <>
            Gäransatz <br/>
          </>}
        <FormattedMessage id="alreadyPrepared" />
      </div>
      <div
        style={{ color: "white", backgroundColor: "#1B1D26", width: "100%", padding: "10% 5%" }}
      >
        <FormattedMessage id="charmatFirstStepSecondBlackMessage" />
      </div>
      <div className="inputDiv">
        <FormattedMessage id="add" />
        <br />
        <span className="productValue">
          {roundTo1Digit(computedData?.rehydratation.sugarWeight)}
        </span>
        <span className="productUnit">{translations[language]['kg']}</span>
        <br />
        <FormattedMessage id="of" /> <FormattedMessage id="sugar" />
      </div>
    </div>
  );
};

export default CharmatFirstStep;
