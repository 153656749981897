import { IonButton, IonCard } from "@ionic/react";
import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const Protocoles: React.FC = () => {
  return (
    <div className="protocolsList">
      <div
        style={{
          fontWeight: "bold",
          fontFamily: "Abril Fatface",
          fontSize: "24px",
          marginTop: "10%",
          color: "white"
        }}
      >
        <FormattedMessage id="protocols" />
      </div>
      <br />
      <div className="traditionalProtocol">
        <div
          style={{
            textAlign: "left",
            marginLeft: "10%",
            fontFamily: "Abril Fatface",
            fontSize: "22px",
          }}
        >
          <FormattedMessage id="traditionalMethod" />
        </div>
        <br />
        <Link to="/protocoles/protocoleFML">
          <IonCard className="protocoleCard" color="white">
            <span>
              <FormattedMessage id="malolacticFermentation" />
            </span>
          </IonCard>
        </Link>
        <Link to="/protocoles/protocoleFMLBloquee">
          <IonCard className="protocoleCard" color="white">
            <span style={{ fontWeight: "bold" }}>
              <FormattedMessage id="malolacticFermentationBlocked" />
            </span>
          </IonCard>
        </Link>
        <Link to="/protocoles/protocoleMaceration">
          <IonCard className="protocoleCard" color="white">
            <span style={{ fontWeight: "bold" }}>
              {" "}
              <FormattedMessage id="maceration" />
            </span>
          </IonCard>
        </Link>
        <div style={{ width: "70%", marginLeft: "15%" }}>
          <FormattedMessage id="martinottiMethodDescription" />
        </div>
      </div>
      <div className="charmatProtocol">
        <div
          style={{
            textAlign: "left",
            marginLeft: "10%",
            fontFamily: "Abril Fatface",
            fontSize: "22px",
          }}
        >
          <FormattedMessage id="feedStockMethod" />
        </div>
        <br />
        <Link to="/protocoles/protocoleCharmat">
          <IonCard className="protocoleCard" color="white">
            <span style={{ fontWeight: "bold" }}>
              {" "}
              <FormattedMessage id="martinottiMethod" />
            </span>
          </IonCard>
        </Link>
      </div>
    </div>
  );
};

export default Protocoles;
