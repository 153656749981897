import React from "react";
import { FormattedMessage } from "react-intl";
import FML from "../../../types/FML";
import FMLBloquee from "../../../types/FMLBloquee";
import MultiChoicesDisplay from "../multiChoicesDisplay";
import SimpleChoice from "../simpleChoice";

interface Props {
  computedData: FML | FMLBloquee | undefined;
  FMLBloquee: boolean;
}

const FMLFourthStep: ({ computedData, FMLBloquee }: Props) => JSX.Element = ({
  computedData,
  FMLBloquee,
}) => {

  const language = localStorage.getItem('language');

  return (
    <div>
      <div className="actionDiv">
        <span className="actionTitle">
          <FormattedMessage id="yeasting" />
        </span>
      </div>
      <p>
        <FormattedMessage id="dissolve" />
      </p>
      <div className="actionDiv">
        <SimpleChoice
          action={{
            title: undefined,
            method: undefined,
            product: {
              name: "START® Y SP",
              value: computedData?.yeasting.startY.weight,
              unit: "g",
              description: `(5 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
            },
          }}
        />
        <p>
          <FormattedMessage id="in" />
        </p>
        <p>
          <span className="productValue">
            {computedData?.yeasting.startY.waterVolume}
          </span>{" "}
          <span className="productUnit">
            <FormattedMessage id="liters" />
          </span>
        </p>
        <p>
          <FormattedMessage id="FMLYeastingWater" />
        </p>
      </div>
      <div
        style={{
          backgroundColor: "#1B1D26",
          color: "white",
          height: "86px",
          width: "100%",
          display: 'inline-flex',
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FormattedMessage id="FMLYeastingMessage"/>
      </div>
      <div className="actionDiv">
        <p>
          <FormattedMessage id="dissolve" />
        </p>
        <MultiChoicesDisplay
          setSelectedProduct={undefined}
          choicesList={[
            {
              name: "SOEC® 1971",
              unit: "kg",
              value: computedData?.yeasting.soec.weight,
              description: FMLBloquee ? `(20 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})` : `(10 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
            },
            {
              name: "SP®7",
              unit: "kg",
              value: computedData?.yeasting.sp.weight,
              description: FMLBloquee ? `(20 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})` : `(10 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
            },
          ]}
        />
        <p>
          <FormattedMessage id="in" />
        </p>
        <p>
          <span className="productValue">
            {computedData?.yeasting.soec.waterVolume}
          </span>{" "}
          <span className="productUnit">
            <FormattedMessage id="liters" />
          </span>
        </p>
        <p>
          <FormattedMessage id="FMLYeastingWater" />
        </p>
      </div>
      <div
        style={{
          backgroundColor: "#1B1D26",
          color: "white",
          height: "20%",
          width: "100%",
          padding:"1% 0",
          marginBottom: "10%"
        }}
      >
        <p>
          <FormattedMessage id="FMLYeastingMessagePart1" />
          <br />
        </p>
        <p>
          <FormattedMessage id="FMLYeastingMessagePart2" />
          <br />
        </p>
        <p>
          <FormattedMessage id="FMLYeastingMessagePart3" />
          <br />
        </p>
      </div>
    </div>
  );
};

export default FMLFourthStep;
