import { IonIcon } from "@ionic/react";
import { close, informationCircle } from "ionicons/icons";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import FML from "../../../types/FML";
import FMLBloquee from "../../../types/FMLBloquee";
import MultiChoicesDisplay from "../multiChoicesDisplay";

interface Props {
  computedData: FML | FMLBloquee | undefined;
}

const FMLFifthStep: ({ computedData }: Props) => JSX.Element = ({
  computedData,
}) => {

  const language = localStorage.getItem('language');

  const [toggleNutricellMessage, setToggleNutricellMessage] =
    useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState("NUTRICELL®");
  return (
    <div>
      <div className="actionDiv">
        <span className="actionTitle">
          <FormattedMessage id="yeastNutrition" />
        </span>
      </div>
      <div className="actionDiv" style={{ position: "relative" }}>
        <MultiChoicesDisplay
          setSelectedProduct={setSelectedProduct}
          choicesList={[
            {
              name: "NUTRICELL®",
              unit: "kg",
              value: computedData?.yeastNutrition.nutricellWeight,
              description: `(20 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
            },
            {
              name: "PHOSPHATE COMPOSE",
              unit: "kg",
              value: computedData?.yeastNutrition.phosphateWeight,
              description: `(10 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
            },
          ]}
        />
        {selectedProduct === "NUTRICELL®" && (
          <IonIcon
            onClick={() => {
              setToggleNutricellMessage(!toggleNutricellMessage);
            }}
            size="large"
            color="warning"
            icon={informationCircle}
            style={{
              position: "absolute",
              left: "0%",
              top: "20%",
              zIndex: "1000",
            }}
          />
        )}
      </div>
      {toggleNutricellMessage && (
        <div
          style={{
            backgroundColor: "#1B1D26",
            color: "white",
            height: "86px",
            width: "100%",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative"
          }}
        >
          <IonIcon
                icon={close}
                style={{ position: "absolute", top: "5px", right: "5px" }}
                onClick={() => setToggleNutricellMessage(false)}
              />
          <FormattedMessage id="FMLYeastNutritionMessage" />
        </div>
      )}
    </div>
  );
};

export default FMLFifthStep;
