import React, { useEffect, useState } from "react";
import "./App.css";
import { IntlProvider } from "react-intl";
import translations from "./modules/translations";
import { IonApp } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import "./variables.css";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import MainLayout from "./views/layout/mainLayout";

import ReactGA from 'react-ga';

const initializeReactGA = () => {
  ReactGA.initialize('UA-109158200-11', {debug : false});
  ReactGA.pageview('/');
}

const App: React.FC = () => {
  useEffect(() => {
    if (!localStorage.getItem("language") || localStorage.getItem("language") === 'EN-US')
      localStorage.setItem("language", navigator.language.toUpperCase() === 'EN-US' ? 'EN-GB' : navigator.language.toUpperCase());
  }, []);
  const [language, setLanguage]  = useState(localStorage.getItem("language")? localStorage.getItem("language") as string : navigator.language.toUpperCase() === 'EN-US' ? 'EN-GB' : navigator.language.toUpperCase());

  
  initializeReactGA();

  return (
    <IntlProvider
      locale={language}
      defaultLocale="fr-FR"
      messages={translations[language || navigator.language.toUpperCase()]}
    >
      <IonReactRouter>
        <IonApp className="App">
          <MainLayout language={language} setLanguage={setLanguage}/>
        </IonApp>
      </IonReactRouter>
    </IntlProvider>
  );
};

export default App;
