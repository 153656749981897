import React from "react";
import { FormattedMessage } from "react-intl";
import translations from '../../../modules/translations';
import Charmat from "../../../types/Charmat";

const roundTo1Digit = (x: number | undefined) => x && Math.round(x * 10) / 10;

const roundTo2Digit = (x: number | undefined) => x && Math.round(x * 100) / 100;
interface Props {
  computedData: Charmat | undefined;
  aromaticProfile: string;
}

const CharmatKeyStep: React.FC<Props> = ({ computedData, aromaticProfile }) => {

  const language = localStorage.getItem('language');

  return (
    <div>
      <div className="inputDiv">
        <FormattedMessage id="decreaseTemperatureUnder15" />
      </div>
      <div
        style={{ border: "solid 1px #EDAE32", width: "30%", marginLeft: "35%" }}
      />
      <div className="inputDiv">
        <FormattedMessage id="addProductsUnderPressure" />
        {aromaticProfile === "mineralFraisFruitBlanc" ? (
          <p>
            <span className="productValue">
              {roundTo2Digit(computedData?.alcoholicFerment.activCharm)}
            </span>
            <span className="productUnit">{translations[language!]['kg']}</span>
            <br />
            <FormattedMessage id="of" />
            <span className="productName"> CHARM® ACTIV </span> (5 {language === 'RU-RU' ? 'г/гл' : 'g/hL'})
          </p>
        ) : aromaticProfile === "cremeuxVarietal" ? (
          <p>
            <span className="productValue">
              {roundTo2Digit(computedData?.alcoholicFerment.phosphate)}
            </span>
            <span className="productUnit">{translations[language!]['kg']}</span>
            <br />
            <FormattedMessage id="of" />
            <span className="productName"> PHOSPHATE COMPOSE </span> (5 {language === 'RU-RU' ? 'г/гл' : 'g/hL'})
          </p>
        ) : (
          <p>
            <p>
              <span className="productValue">
                {roundTo2Digit(computedData?.alcoholicFerment.startY)}
              </span>
              <span className="productUnit">{translations[language!]['kg']}</span>
              <br />
              <FormattedMessage id="of" />
              <span className="productName"> START® Y FRESH </span> (5 {language === 'RU-RU' ? 'г/гл' : 'g/hL'})
              <br />
            </p>
            +
            <p>
              <span className="productValue">
                {roundTo2Digit(computedData?.alcoholicFerment.dap)}
              </span>
              <span className="productUnit">{translations[language!]['kg']}</span>
              <br />
              <FormattedMessage id="of" />
              <span className="productName"> DAP </span> (5 {language === 'RU-RU' ? 'г/гл' : 'g/hL'})
              <br />
            </p>
          </p>
        )}
      </div>
      <p style={{ backgroundColor: "#1B1D26", color: "white", padding: "10% 5%"}}>
        <FormattedMessage id="fermentRecommandedTemperature" />
      </p>
      <div className="inputDiv">
        <p>
          <FormattedMessage id="reductionAromaCase" />
        </p>
        <p>
          <span className="productValue">
            {roundTo1Digit(computedData?.alcoholicFerment.redoxCharm)}
          </span>
          <span className="productUnit">{translations[language!]['kg']}</span>
          <br />
          <FormattedMessage id="of" />
          <span className="productName"> CHARM® REDOX T </span> (2 {language === 'RU-RU' ? 'г/гл' : 'g/hL'})
        </p>
      </div>
      <div style={{ backgroundColor: "#1B1D26", color: "white", padding: "10% 5%"}}>
        <FormattedMessage id="pressureReached" />
        <br /> <br/>
        <FormattedMessage id="necessaryTimeDependingEquipment" />
      </div>
      <div className="inputDiv">
        <span className="productName">
          <FormattedMessage id="setTemperatureTo0" />
        </span>
      </div>
    </div>
  );
};

export default CharmatKeyStep;
