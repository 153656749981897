import { IonButton, IonCard, IonInput } from "@ionic/react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import translations from "../../modules/translations";
import { InputNumberField } from "../component/inputFields";

const AddTankPage: React.FC = () => {
  const [tankName, setTankName] = useState<string>();
  const [volumeTank, setVolumeTank] = useState<number>(0);
  const [errorNameTank, setErrorNameTank] = useState<boolean>(false);
  const [errorVolumeTank, setErrorVolumeTank] = useState<boolean>(false);
  const history = useHistory();
  const language = localStorage.getItem("language") as string;
  const translation = translations[language];
  return (
    <div className="addTankPage">
      <span className="pageTitle">
        <FormattedMessage id="addingTank" />
      </span>
      <div className="addCard">
        <IonCard
          style={{
            color: "black",
            borderRadius: "20px",
            padding: "5%",
            fontFamily: "Abril Fatface",
          }}
        >
          <div style={{ margin: "5% 0", textAlign: "left" }}>
            <FormattedMessage id="nameTank" /> <br />
            <IonInput
              value={tankName}
              placeholder={`${translation["nameTheTank"]}`}
              onIonChange={(e) => {
                setTankName(e.detail.value!);
              }}
              style={{
                width: "100%",
                border: "1px solid black",
                borderRadius: "10px",
                margin: "1% 0",
              }}
            />
            {errorNameTank && (
              <span className="errorMessage">
                {" "}
                <FormattedMessage id="errorMessageInput" />
                <br />
                <br />
              </span>
            )}
          </div>
          <div style={{ margin: "10% 0", textAlign: "left" }}>
            <FormattedMessage id="volumeTank" /> <br />
            <span style={{ fontFamily: "Lato", fontSize: "12px" }}>
              <FormattedMessage id="inHectoliters" />
            </span> <br /><span className="onlyIntergerMessage"><FormattedMessage id="onlyInteger" /></span>
            <InputNumberField 
              value={volumeTank} 
              setValue={setVolumeTank} 
              parseValue={(val) => parseInt(val, 10)} 
              style={{
                width: "100%",
                border: "1px solid black",
                borderRadius: "10px",
                margin: "1% 0",
              }}
            />
            {errorVolumeTank && (
              <span className="errorMessage">
                {" "}
                <FormattedMessage id="errorNumberInput" />
                <br />
                <br />
              </span>
            )}
          </div>
          <IonButton
            style={{ color: "black", width: "90%" }}
            color="dark"
            onClick={() => {
              if (!tankName) {
                setErrorNameTank(true);
              } else if (volumeTank === 0) {
                setErrorVolumeTank(true);
              } else {
                const id =
                  Math.random().toString(36).substring(2, 15) +
                  Math.random().toString(36).substring(2, 15);
                localStorage.setItem(
                  "tank_" + id,
                  JSON.stringify({
                    name: tankName,
                    volume: volumeTank,
                    id: id,
                    protocoles: [],
                  })
                );
                history.push("/tankList");
              }
            }}
          >
            <FormattedMessage id="addTank" />
          </IonButton>
        </IonCard>
        <IonButton
          style={{
            color: "black",
            width: "70%",
            border: "1px black solid",
            borderRadius: "4px",
          }}
          color="light"
          onClick={() => history.goBack()}
        >
          <span style={{ fontWeight: "bold" }}>
            <FormattedMessage id="dontAddTank" />
          </span>
        </IonButton>
      </div>
    </div>
  );
};

export default AddTankPage;
