import {
  IonButton,
  IonCard,
  IonDatetime,
  IonInput,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import translations from "../../../modules/translations";
import Tank from "../../../types/Tank";
import { InputNumberField } from "../inputFields";

interface Props {
  tanks: Tank[];
  tankSelected: Tank | undefined;
  setTankSelected: (tank: Tank) => void;
  selectedDate: string;
  setSelectedDate: (date: string) => void;
  sampleName: string;
  setSampleName: (sampleName: string) => void;
  cepage: string;
  setCepage: (cepage: string) => void;
  compareWith: (o1: Tank, o2: Tank) => boolean;
  errorTank: boolean;
  errorDate: boolean;
  setErrorTank: (errorTank: boolean) => void;
  setErrorDate: (errorDate: boolean) => void;
}

const FMLZeroStep: ({
  tanks,
  tankSelected,
  setTankSelected,
  selectedDate,
  setSelectedDate,
  sampleName,
  setSampleName,
  cepage,
  setCepage,
  compareWith,
  errorTank,
  errorDate,
  setErrorTank,
  setErrorDate,
}: Props) => JSX.Element = ({
  tanks,
  tankSelected,
  setTankSelected,
  selectedDate,
  setSelectedDate,
  sampleName,
  setSampleName,
  cepage,
  setCepage,
  compareWith,
  errorTank,
  errorDate,
  setErrorTank,
  setErrorDate,
}) => {
  const [addNewTank, setAddNewTank] = useState<boolean>(false);
  const [newTankName, setNewTankName] = useState<string>();
  const [newTankVolume, setNewTankVolume] = useState<number>(0);
  const [errorNameTank, setErrorNameTank] = useState<boolean>(false);
  const [errorVolumeTank, setErrorVolumeTank] = useState<boolean>(false);

  const language = localStorage.getItem("language") as string;
  const translation = translations[language];
  return (
    <IonCard className="inputCard">
      <div style={{ padding: "5% 3%" }}>
        <div className="inputDiv">
          <div className="inputTitle">
            <FormattedMessage id="selectTank" />
          </div>
          <div className="descriptionText">
            <FormattedMessage id="askSelectedTank" />
          </div>
          <IonSelect
            className="inputField"
            compareWith={compareWith}
            value={tankSelected}
            placeholder={`${translation["selectATank"]}`}
            onIonChange={(e) => {
              if (e.detail.value === "new") {
                setNewTankVolume(0);
                setNewTankName("");
                setTankSelected({
                  id: "new",
                  name: "",
                  volume: 0,
                  protocoles: [],
                });
                setAddNewTank(true);
              } else {
                setErrorTank(false);
                setAddNewTank(false);
                setTankSelected(e.detail.value);
              }
            }}
          >
            {tanks.map((tank) => (
              <IonSelectOption value={tank} key={tank?.id}>
                {tank?.name}
              </IonSelectOption>
            ))}
            <IonSelectOption value={"new"}>
              <FormattedMessage id="addATank" />
            </IonSelectOption>
          </IonSelect>
          {errorTank && (
            <span className="errorMessage">
              <FormattedMessage id="errorSelectTank" />
              <br />
            </span>
          )}
          {addNewTank && (
            <>
              <IonCard className="addNewTankCardOnProtocols">
                <span style={{ fontWeight: "bold" }}>
                  <FormattedMessage id="nameTank" />{" "}
                </span>
                <br />
                <IonInput
                  value={newTankName}
                  placeholder={`${translation["nameTheTank"]}`}
                  onIonChange={(e) => {
                    setNewTankName(e.detail.value!);
                  }}
                />
                {errorNameTank && (
                  <span className="errorMessage">
                    {" "}
                    <FormattedMessage id="errorMessageInput" />
                    <br />
                    <br />
                  </span>
                )}
                <span style={{ fontWeight: "bold" }}>
                  <FormattedMessage id="volumeTank" />
                  <br />
                  <span style={{ fontFamily: "Lato", fontSize: "12px" }}>
                    <FormattedMessage id="inHectoliters" />
                  </span> <br/>
                  <span className="onlyIntergerMessage"><FormattedMessage id="onlyInteger"/></span>
                </span>
                <InputNumberField value={newTankVolume} setValue={setNewTankVolume} parseValue={(val) => parseInt(val, 10)}/>
                {errorVolumeTank && (
                  <span className="errorMessage">
                    {" "}
                    <FormattedMessage id="errorNumberInput" />
                    <br />
                    <br />
                  </span>
                )}
                <IonButton
                  style={{ color: "black" }}
                  color="dark"
                  onClick={() => {
                    if (!newTankName) {
                      setErrorNameTank(true);
                    } else if (newTankVolume === 0) {
                      setErrorVolumeTank(true);
                    } else {
                      const id =
                        Math.random().toString(36).substring(2, 15) +
                        Math.random().toString(36).substring(2, 15);
                      localStorage.setItem(
                        "tank_" + id,
                        JSON.stringify({
                          name: newTankName,
                          volume: newTankVolume,
                          id: id,
                          protocoles: [],
                        })
                      );
                      setTankSelected(
                        Object.keys(localStorage)
                          .filter((key) => key === "tank_" + id)
                          .map((key) => JSON.parse(localStorage[key]))[0]
                      );
                      setAddNewTank(false);
                    }
                  }}
                >
                  <FormattedMessage id="addTank" />
                </IonButton>
              </IonCard>
            </>
          )}
          <div
            style={{ fontFamily: "Lato", fontSize: "15pt", marginTop: "2%" }}
          >
            <FormattedMessage id="tankVolume" /> {tankSelected?.volume} {language === 'RU-RU' ? 'гл' : 'hL'}
          </div>
          <div style={{ fontFamily: "Lato", fontSize: "13pt" }}>
            <FormattedMessage id="tankVolumeInfo" />
          </div>
        </div>
        <div className="inputDiv">
          <div className="inputTitle">
            <FormattedMessage id="beginningDate" />
          </div>
          <div className="descriptionText">
            <FormattedMessage id="askBeginningDate" />
          </div>
          <IonDatetime
            className="inputField"
            displayFormat="DD/MM/YY"
            pickerOptions={{
              buttons: [
                {
                  text: translation["close"],
                },
                {
                  text: 'OK',
                  handler: (e) => {
                    const date = new Date();
                    date.setFullYear(
                      e.year.value,
                      e.month.value - 1,
                      e.day.value
                    );
                    setErrorDate(false);
                    setSelectedDate(date.toString());
                  },
                },
              ],
            }}
            placeholder={`${translation["selectADate"]}`}
            value={selectedDate}
            onIonChange={(e) => {
              setErrorDate(false);
              setSelectedDate(e.detail.value!);
            }}
          />
          {errorDate && (
            <span className="errorMessage">
              <FormattedMessage id="errorDate" />
              <br />
            </span>
          )}
        </div>
        <div className="inputDiv">
          <div className="inputTitle">
            <FormattedMessage id="sampleName" />
            <br />
            <span style={{ fontStyle: "italic", fontSize: "18px" }}>
              (<FormattedMessage id="optionnal" />)
            </span>
          </div>
          <div className="descriptionText">
            <FormattedMessage id="askSampleName" />
          </div>
          <IonInput
            className="inputField"
            value={sampleName}
            placeholder={`${translation["sampleNameOnly"]}`}
            onIonChange={(e) => {
              setSampleName(e.detail.value!);
            }}
          />
        </div>
        <div className="inputDiv">
          <div className="inputTitle">
            <FormattedMessage id="cepageType" />
          </div>
          <div className="descriptionText">
            <FormattedMessage id="askCepageType" />
          </div>
          <IonSelect
            className="inputField"
            value={cepage}
            placeholder={`${translation["selectACepage"]}`}
            onIonChange={(e) => {
              setCepage(e.detail.value!);
            }}
          >
            <IonSelectOption value="chardonnay">
              <FormattedMessage id="chardonnay" />
            </IonSelectOption>
            <IonSelectOption value="pinotNoirMeunier">
              <FormattedMessage id="pinotNoirMeunier" />{" "}
            </IonSelectOption>
          </IonSelect>
        </div>
      </div>
    </IonCard>
  );
};

export default FMLZeroStep;
