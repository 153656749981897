import { IonButton, IonIcon, IonImg, IonModal } from "@ionic/react";
import { addCircleOutline, downloadOutline } from "ionicons/icons";
import React from "react";
import { FormattedMessage } from "react-intl";

interface Props {
  openIphone: boolean;
  setOpenIphone: (openIphone: boolean) => void;
}

const IosModal: React.FC<Props> = ({ openIphone, setOpenIphone }) => {

  return (
    <IonModal
      isOpen={openIphone}
      cssClass="installationModal"
      onDidDismiss={() => setOpenIphone(false)}
    >
      <IonImg
        src="/assets/icons/soec.png"
        style={{
          marginLeft: "40%",
          marginTop: "10%",
          width: "80px",
          height: "80px",
        }}
      />
      <div
        style={{
          fontFamily: "Lato",
          fontSize: "22px",
          fontWeight: "bold",
        }}
      >
        <FormattedMessage id="addSOECToApp" />
      </div>
      <div style={{ fontFamily: "Lato", fontSize: "18px" }}>
        <FormattedMessage id="appSOECToAppInformationsIos" />
      </div>
      {/* <IonIcon
        style={{ margin: "0 45%" }}
        size="large"
        icon={downloadOutline}
      /> */}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <IonImg src='/assets/icons/iosShare.svg' style={{ width: '30px', height: '30px' }} />
      </div>
      <div style={{ fontFamily: "Lato", fontSize: "18px" }}>
        <FormattedMessage id="appSOECToAppInformationsIosFinalStep" />
      </div>
      <IonIcon
        style={{ margin: "0 45%" }}
        size="large"
        icon={addCircleOutline}
      />
      <IonButton
        color="white"
        onClick={() => setOpenIphone(false)}
        style={{
          border: "black solid 1px",
          color: "black",
          fontFamily: "Lato",
          fontSize: "15px",
          width: "80%",
          marginLeft: "10%",
          marginBottom: "10%",
        }}
      >
        {" "}
        <FormattedMessage id="stayOnBrowser" />
      </IonButton>
    </IonModal>
  );
};

export default IosModal;
