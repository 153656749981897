import Acclimatation from '../types/Acclimatation'
import Rehydratation from "../types/Rehydratation";

const roundTo1Digit = (x: number) => Math.round(x * 10) / 10;

const computeSulfiting = (harvestWeight: number) => {
  return {
    baktolMinVolume: roundTo1Digit((6 * harvestWeight) / 150),
    baktolMaxVolume: roundTo1Digit((8 * harvestWeight) / 150),
  };
};

const computeEnzyme = (harvestWeight: number) => {
  return { viazymWeight: roundTo1Digit((5 * harvestWeight) / 150) };
};

const computeTannin = (harvestWeight: number) => {
  return {
    clarVolume: roundTo1Digit((4 * harvestWeight) / 150),
    vitanilWeight: roundTo1Digit((10 * harvestWeight) / 150),
  };
};

const computeYeasting = (wineVolume: number) => {
  const weight = (5 * wineVolume) / 1000;
  return {
    flavourWeight: roundTo1Digit(weight),
    waterVolume: roundTo1Digit(10 * weight),
  };
};

const computeYeastingT48 = (wineVolume: number) => {
  const weight = (20 * wineVolume) / 1000;
  return {
    flavourWeight: roundTo1Digit(weight),
    waterVolume: roundTo1Digit(10 * weight),
  };
};

const computeClassicNutrition = (wineVolume: number) => {
  return {
    nutricellWeight: roundTo1Digit((20 * wineVolume) / 1000),
  };
};

const computePrefermNutrition = (wineVolume: number) => {
  return {
    nutricellWeight: roundTo1Digit((20 * wineVolume) / 1000),
    phosphateWeight: roundTo1Digit((20 * wineVolume) / 1000),
  };
};

const computeAnthocyanins = (wineVolume: number) => {
  return {
    neoWeight: roundTo1Digit((30 * wineVolume) / 1000),
  };
};

const computeMalolactic = (wineVolume: number) => {
  return {
    vitilacticWeight: roundTo1Digit(wineVolume),
    malovitWeight: roundTo1Digit((20 * wineVolume) / 1000),
  };
};

const computeCarboglace = (harvestWeight: number) => {
  return {
    carboglaceWeight: roundTo1Digit((0.5 * harvestWeight) / 100),
  };
};

const computeCuvee = (wineVolume: number, cepage: string) => {
  return {
    firstStep: {
      sulfivendangeVolume:
        cepage === "chardonnay" ? 5 * wineVolume : 6 * wineVolume,
      sulfossolVolume:
        cepage === "chardonnay" ? 3.5 * wineVolume : 4 * wineVolume,
    },
    secondStep: {
      catalMinWeight: 1 * wineVolume,
      catalMaxWeight: 2 * wineVolume,
    },
    thirdStep: {
      polygreen: {
        minWeight: (30 * wineVolume) / 1000,
        maxWeight: (50 * wineVolume) / 1000,
      },
      origin: {
        minWeight: (30 * wineVolume) / 1000,
        maxWeight: (50 * wineVolume) / 1000,
      },
    },
    fourthStep:
      cepage === "chardonnay"
        ? undefined
        : {
            activa: {
              minWeight: (20 * wineVolume) / 1000,
              maxWeight: (50 * wineVolume) / 1000,
            },
          },
  };
};

const computeCuveeBloquee = (wineVolume: number, cepage: string) => {
  return {
    firstStep:
      cepage === "chardonnay"
        ? {
            sulfossolOnly: {
              minVolume: 4.5 * wineVolume,
              maxVolume: 5.5 * wineVolume,
            },
            sulfivendangeAndSulfossol: {
              sulfivendangeVolume: 5 * wineVolume,
              sulfossolMinVolume: 1 * wineVolume,
              sulfossolMaxVolume: 2 * wineVolume,
              sulfossolVolume: undefined,
            },
            sulfossolOnlyVolume: undefined,
          }
        : {
            sulfossolOnlyVolume: 6 * wineVolume,
            sulfivendangeAndSulfossol: {
              sulfivendangeVolume: 6 * wineVolume,
              sulfossolVolume: 2 * wineVolume,
              sulfossolMinVolume: undefined,
              sulfossolMaxVolume: undefined,
            },
            sulfossolOnly: undefined,
          },
    secondStep: {
      catalMinWeight: 1 * wineVolume,
      catalMaxWeight: 2 * wineVolume,
    },
    thirdStep: {
      polygreen: {
        minWeight: (30 * wineVolume) / 1000,
        maxWeight: (50 * wineVolume) / 1000,
      },
      origin: {
        minWeight: (30 * wineVolume) / 1000,
        maxWeight: (50 * wineVolume) / 1000,
      },
    },
    fourthStep:
      cepage === "chardonnay"
        ? undefined
        : {
            activa: {
              minWeight: (20 * wineVolume) / 1000,
              maxWeight: (50 * wineVolume) / 1000,
            },
          },
  };
};

const computeTaille = (wineVolume: number, cepage: string) => {
  return {
    firstStep: {
      sulfivendangeVolume:
        cepage === "chardonnay" ? 7 * wineVolume : 8 * wineVolume,
      sulfossolVolume:
        cepage === "chardonnay" ? 5 * wineVolume : 5.5 * wineVolume,
    },
    secondStep: {
      catalWeight: 2 * wineVolume,
    },
    thirdStep: {
      polygreen: {
        minWeight: (50 * wineVolume) / 1000,
        maxWeight: (100 * wineVolume) / 1000,
      },
      origin: {
        minWeight: (50 * wineVolume) / 1000,
        maxWeight: (100 * wineVolume) / 1000,
      },
    },
    fourthStep:
      cepage === "chardonnay"
        ? undefined
        : {
            activa: {
              minWeight: (50 * wineVolume) / 1000,
              maxWeight: (100 * wineVolume) / 1000,
            },
          },
  };
};

const computeTailleBloquee = (wineVolume: number, cepage: string) => {
  return {
    firstStep:
      cepage === "chardonnay"
        ? {
            sulfossolOnly: {
              minVolume: 6 * wineVolume,
              maxVolume: 7 * wineVolume,
            },
            sulfivendangeAndSulfossol: {
              sulfivendangeVolume: 7 * wineVolume,
              sulfossolMinVolume: 1 * wineVolume,
              sulfossolMaxVolume: 2 * wineVolume,
              sulfossolVolume: undefined,
            },
            sulfossolOnlyVolume: undefined,
          }
        : {
            sulfossolOnlyVolume: 7 * wineVolume,
            sulfivendangeAndSulfossol: {
              sulfivendangeVolume: 7 * wineVolume,
              sulfossolVolume: 2 * wineVolume,
              sulfossolMinVolume: undefined,
              sulfossolMaxVolume: undefined,
            },
            sulfossolOnly: undefined,
          },
    secondStep: {
      catalWeight: 2 * wineVolume,
    },
    thirdStep: {
      polygreen: {
        minWeight: (50 * wineVolume) / 1000,
        maxWeight: (100 * wineVolume) / 1000,
      },
      origin: {
        minWeight: (50 * wineVolume) / 1000,
        maxWeight: (100 * wineVolume) / 1000,
      },
    },
    fourthStep:
      cepage === "chardonnay"
        ? undefined
        : {
            activa: {
              minWeight: (50 * wineVolume) / 1000,
              maxWeight: (100 * wineVolume) / 1000,
            },
          },
  };
};

const computeFMLYeasting = (wineVolume: number) => {
  const weight = (10 * wineVolume) / 1000;

  return {
    startY: {
      weight: 5 * wineVolume / 1000,
      waterVolume: 5 * wineVolume / 100,
    },
    soec: {
      weight,
      waterVolume: 10 * weight,
    },
    sp: {
      weight,
      waterVolume: 10 * weight,
    },
  };
};

const computeFMLYeastingBloquee = (wineVolume: number, cepage: string) => {
  const weight =
    cepage === "chardonnay"
      ? (10 * wineVolume) / 1000
      : (20 * wineVolume) / 1000;

  return {
    startY: {
      weight : 5 * wineVolume / 1000,
      waterVolume: 5*wineVolume/100
    },
    soec: {
      weight,
      waterVolume: 10 * weight,
    },
    sp: {
      weight,
      waterVolume: 10 * weight,
    },
  };
};

const computeYeastNutrition = (wineVolume: number) => {
  return {
    phosphateWeight: (10 * wineVolume) / 1000,
    nutricellWeight: (20 * wineVolume) / 1000,
  };
};

export const computePrefermMaceration = (
  harvestWeight: number,
  wineVolume: number
) => {
  return {
    sulfiting: computeSulfiting(harvestWeight),
    carboglace: computeCarboglace(harvestWeight),
    tannin: computeTannin(harvestWeight),
    enzyme: undefined,
    yeasting: computeYeasting(wineVolume),
    yeastingT48: undefined,
    yeastingT72: computeYeastingT48(wineVolume),
    enzymeT24: computeEnzyme(harvestWeight),
    nutrition: computePrefermNutrition(wineVolume),
    malolactic: computeMalolactic(wineVolume),
    anthocyanins: undefined,
  };
};

export const computeClassicMaceration = (
  harvestWeight: number,
  wineVolume: number
) => {
  return {
    sulfiting: computeSulfiting(harvestWeight),
    carboglace: undefined,
    tannin: computeTannin(harvestWeight),
    enzyme: computeEnzyme(harvestWeight),
    enzymeT24: undefined,
    yeasting: computeYeasting(wineVolume),
    yeastingT48: computeYeastingT48(wineVolume),
    yeastingT72: undefined,
    nutrition: computeClassicNutrition(wineVolume),
    malolactic: computeMalolactic(wineVolume),
    anthocyanins: computeAnthocyanins(wineVolume),
  };
};

export const computeFMLChardonnay = (wineVolume: number) => {
  return {
    cuvee: computeCuvee(wineVolume, "chardonnay"),
    taille: computeTaille(wineVolume, "chardonnay"),
    yeasting: computeFMLYeasting(wineVolume),
    yeastNutrition: computeYeastNutrition(wineVolume),
  };
};

export const computeFMLPinotNoir = (wineVolume: number) => {
  return {
    cuvee: computeCuvee(wineVolume, "pinot noir"),
    taille: computeTaille(wineVolume, "pinot noir"),
    yeasting: computeFMLYeasting(wineVolume),
    yeastNutrition: computeYeastNutrition(wineVolume),
  };
};

export const computeFMLBloqueeChardonnay = (wineVolume: number) => {
  return {
    cuvee: computeCuveeBloquee(wineVolume, "chardonnay"),
    taille: computeTailleBloquee(wineVolume, "chardonnay"),
    yeasting: computeFMLYeastingBloquee(wineVolume, "chardonnay"),
    yeastNutrition: computeYeastNutrition(wineVolume),
  };
};

export const computeFMLBloqueePinotNoir = (wineVolume: number) => {
  return {
    cuvee: computeCuveeBloquee(wineVolume, "pinot noir"),
    taille: computeTailleBloquee(wineVolume, "pinot noir"),
    yeasting: computeFMLYeastingBloquee(wineVolume, "pinot noir"),
    yeastNutrition: computeYeastNutrition(wineVolume),
  };
};

const computeRehydratation = (wineVolume: number) => {
  const startYWeight = wineVolume * 0.005;
  const startYWaterVolume = startYWeight * 10;
  const SP7Weight = startYWeight * 2;
  const SP7WaterVolume = startYWaterVolume * 2;
  const sugarWeight = (50 * SP7WaterVolume * 1.5) / 1000;

  return {
    startYWeight,
    startYWaterVolume,
    SP7Weight,
    SP7WaterVolume,
    sugarWeight,
  };
};

const computeAcclimatation = ({
  startYWaterVolume,
  SP7WaterVolume,
}: Rehydratation) => {
  const step1AlreadyPrepared = startYWaterVolume + SP7WaterVolume;
  const step1BasicWine = 0.2 * step1AlreadyPrepared;
  const step1Water = 0.3 * step1AlreadyPrepared;

  const step2AlreadyPrepared =
    step1AlreadyPrepared + step1BasicWine + step1Water;
  const step2BasicWine = 0.3 * step2AlreadyPrepared;
  const step2Water = 0.2 * step2AlreadyPrepared;

  const step3AlreadyPrepared =
    step2AlreadyPrepared + step2BasicWine + step2Water;
  const step3BasicWine = 0.4 * step3AlreadyPrepared;
  const step3Water = 0.1 * step3AlreadyPrepared;
  const step3Sugar =
    (20 * (step3AlreadyPrepared + step3BasicWine + step3Water)) / 1000;

  return {
    step1AlreadyPrepared,
    step1BasicWine,
    step1Water,
    step2AlreadyPrepared,
    step2BasicWine,
    step2Water,
    step3AlreadyPrepared,
    step3BasicWine,
    step3Water,
    step3Sugar,
  };
};

const computeFeedStock = (wineVolume: number, acclimatation: Acclimatation) => {
  const step1AlreadyPrepared =
    acclimatation.step3AlreadyPrepared +
    acclimatation.step3BasicWine +
    acclimatation.step3Water;
  const step1BasicWine = step1AlreadyPrepared / 2;
  const step1Water = step1AlreadyPrepared / 3;
  const step1Sugar =
    (80 * (step1AlreadyPrepared + step1BasicWine + step1Water)) / 1000;
  const step1StartY = (5 * wineVolume) / 1000;
  const step1Charm = (5 * wineVolume) / 1000;

  const step2AlreadyPrepared =
    step1AlreadyPrepared + step1BasicWine + step1Water;
  const step2BasicWine = step2AlreadyPrepared / 2;
  const step2Water = step2BasicWine;
  const step2Sugar =
    (40 * (step2AlreadyPrepared + step2BasicWine + step2Water)) / 1000;

  const step3AlreadyPrepared =
    step2AlreadyPrepared + step2BasicWine + step2Water;
  const step3BasicWine = step3AlreadyPrepared / 4;
  const step3Water = step3BasicWine / 1.5;
  const step3Sugar =
    (40 * (step3AlreadyPrepared + step3BasicWine + step3Water)) / 1000;
  const step3StartY = (5 * wineVolume) / 1000;

  return {
    step1AlreadyPrepared,
    step1BasicWine,
    step1Water,
    step1Sugar,
    step1StartY,
    step1Charm,
    step2AlreadyPrepared,
    step2BasicWine,
    step2Water,
    step2Sugar,
    step3AlreadyPrepared,
    step3BasicWine,
    step3Water,
    step3Sugar,
    step3StartY,
  };
};

const computeInterStep = (wineVolume: number, sugar: number) => {
  return {
    sugar: ((25 + sugar) * wineVolume * 100) / 1000,
    startY: 0.02 * wineVolume,
    dap: 0.01 * wineVolume,
    subliCharm: 0.0015 * wineVolume,
    elevageCharm: (5 * wineVolume) / 1000,
    ageCharm: (5 * wineVolume) / 1000,
    redoxCharm: 0.003 * wineVolume,
    activCharm: 0.02 * wineVolume,
    floralSubliCharm: 0.001 * wineVolume,
    floralElevageCharm: 0.004 * wineVolume,
    floralAgeCharm: 0.004 * wineVolume,
    creamyElevageCharm : 0.008 * wineVolume,
  };
};

const computeAlcoholicFerment = (wineVolume: number) => {
  return {
    activCharm: 0.005 * wineVolume,
    redoxCharm: 0.002 * wineVolume,
    startY: 0.005 * wineVolume,
    dap: 0.005 * wineVolume,
    phosphate: 0.005 * wineVolume,
  };
};

const computeElevage = (wineVolume: number) => {
  return {
    ageCharm: 0.005 * wineVolume,
    subliCharm: 0.001 * wineVolume,
    fruitedSubliCharm: 0.0015 * wineVolume,
    elevageCharm: 0.005 * wineVolume,
    floralElevageCharm: 0.003 * wineVolume,
    redoxCharm: 0.0015 * wineVolume,
    creamySubliCharm: 0.002 * wineVolume,
    creamyElevageCharm: 0.01 * wineVolume,
  };
};

export const computeCharmat = (wineVolume: number, sugar: number) => {
  const rehydratation = computeRehydratation(wineVolume);
  const acclimatation = computeAcclimatation(rehydratation);
  const feedStock = computeFeedStock(wineVolume, acclimatation);
  const interStep = computeInterStep(wineVolume, sugar);
  const alcoholicFerment = computeAlcoholicFerment(wineVolume);
  const elevage = computeElevage(wineVolume);
  return {
    rehydratation,
    acclimatation,
    feedStock,
    interStep,
    alcoholicFerment,
    elevage,
  };
};
