type Translation = {
  [key: string]: string;
};

const It: Translation = {
  "FR-FR": "Francese",
  "EN-GB": "Inglese",
  "ES-ES": "Spagnolo",
  "IT-IT": "Italiano",
  "DE-DE": "Tedesco",
  "RU-RU": "Russo",

  //Version ordinateur
  notAvailableForComputerTitle:
    "L'applicazione SOEC non è disponibile su un computer.",
  notAvailableForComputerContent:
    "Purtroppo questa app non è disponibile su computer. Per accedervi, scansiona il codice qui sotto o accedi al seguente URL: {soecUrl} sul tuo tablet o cellulare. Puoi poi aggiungere l'app al tuo dispositivo seguendo le indicazioni del pop-up",

  //installer la PWA
  addSOECToApp: "Aggiungi SOEC alle tue applicazioni!",
  appSOECToAppInformations:
    "Puoi aggiungere la nostra applicazione sul tuo cellulare/tablet. Per farlo, ti basta cliccare sul pulsante qui sotto.",
  appSOECToAppInformationsIos:
    "Per aggiungere l'applicazione al tuo iPhone, basta toccare l'icona di condivisione:",
  appSOECToAppInformationsIosFinalStep:
    "Poi clicca su \"Sulla schermata iniziale\":",
  addApplication: "Aggiungere l'applicazione",
  stayOnBrowser: "Restare sul browser",

  //Menu
  home: "Home",
  tanks: "Le mie vasche",
  protocoles: "Protocolli",
  calculators: "Calcolatori",
  contact: "Hai bisogno di aiuto?",

  //Page d'accueil
  legalesNoticies: "Avvisi legali",
  GTC: "CGU",
  calculatorsTools: "Strumenti di supporto decisionale",
  startA: "Iniziare un",
  protocole: "protocollo",
  allTools: "Tutti gli strumenti",
  useCalculator: "Utilizzare un calcolatore",
  handleTank: "Gestire le mie vasche",

  //Page d'accueil sans protocole
  noProtocoleFoundfirst: "Non è stato trovato nessun protocollo in corso.",
  noProtocoleFoundsecond:
    "Non abbiamo trovato protocolli sul tuo dispositivo.",
  noProtocoleFoundthird:
    "Inizia il tuo primo protocollo cliccando qui sotto!",

  //Liste des protocoles disponibles
  protocols: "Protocolli",
  maceration: "Macerazione",
  traditionalMethod: "Metodo tradizionale",
  feedStockMethod: "Metodo della vasca chiusa",
  martinottiMethod: "Metodo Martinotti",
  martinottiMethodDescription:
    "Questi protocolli del Metodo Tradizionale sono elaborati e adattati alle specificità dello Champenois. Non esitare a consultare i nostri consulenti enologi.",
  malolacticFermentation: "Vinificazione con fermentazione malolattica",
  malolacticFermentationBlocked: "Vinificazione senza fermentazione malolattica",

  //Liste des calculateurs disponible
  decisionSupportToolsCaps: "STRUMENTI DI SUPPORTO DECISIONALE",
  calculationCorrection: "Calcolo della correzione della",
  overpressureSparklingWine: "sovrapressione di un vino effervescente",
  calculationFoot: "Calcolo del pied de",
  maloTankLowPh: "cuve malolattico su pH basso",
  calculationOf: "Calcolo dello",
  sulfurAcetaldehyde: "Zolfo - Aldeide acetica",
  calculationOfThe: "Calcolo della",

  //Propre à tous les protocoles
  selectTank: "Selezionare/Aggiungere una vasca",
  askSelectedTank: "A quale vasca vuoi assegnare questo protocollo?",
  tankVolume: "Volume della vasca:",
  tankVolumeInfo:
    "(Per cambiare il volume desiderato, è possibile cambiare o aggiungere una vasca.)",
  beginningDate: "Data di attuazione",
  askBeginningDate: "Quando vuoi avviare il protocollo?",
  sampleName: "Numero del campione o del lotto",
  askSampleName: "Per quale campione vuoi realizzare questo protocollo?",
  beginProtocole: "COMINCIARE IL PROTOCOLLO",
  endProtocole: "Terminare il protocollo",
  sendResults: "Inviare i risultati",
  goBackToBoard: "Ritornare al dashboard",
  selectADate: "Seleziona una data",
  selectATank: "Selezionare una vasca",
  selectACepage: "Selezionare una varietà di uva",
  selectAMacerationType : "Selezionare un tipo di macerazione",
  selectAnAromaticProfile: "Selezionare un profilo di aroma",
  nameTheTank: "Numero della vasca",
  errorMessageInput: "Questo campo deve essere completato",
  errorNumberInput: "Il valore deve essere maggiore di 0",
  errorSelectTank: "Si prega di selezionare un serbatoio",
  errorDate: "Seleziona una data",
  optionnal: "Opzionale",

  //Protocole Macération
  //Titre et sous-titre pour chacune des étapes
  TitleMacerationStep0_classic: "Macerazione",
  TitleMacerationStep1_classic: "Macerazione classica",
  TitleMacerationStep2_classic: "Aggiunta in vasca",
  TitleMacerationStep3_classic: "Operazioni di pre-fermentazione",
  TitleMacerationStep4_classic: "Fermentazione alcolica",
  TitleMacerationStep5_classic: "Fermentazione alcolica",
  TitleMacerationStep6_classic: "Macerazione",
  TitleMacerationStep7_classic: "Zuccheraggio",
  TitleMacerationStep8_classic: "Svinatura, pressatura & fermentazione alcolica terminata",
  TitleMacerationStep9_classic: "Fermentazione malolattica",
  TitleMacerationStep10_classic: "Fine del protocollo",

  SubtitleMacerationStep0_classic: "METODO TRADIZIONALE",
  SubtitleMacerationStep1_classic: " ",
  SubtitleMacerationStep2_classic: "Macerazione classica",
  SubtitleMacerationStep3_classic: "Macerazione classica",
  SubtitleMacerationStep4_classic: "Macerazione classica",
  SubtitleMacerationStep5_classic: "Macerazione classica",
  SubtitleMacerationStep6_classic: "Macerazione classica",
  SubtitleMacerationStep7_classic: "Macerazione classica",
  SubtitleMacerationStep8_classic: "Macerazione classica",
  SubtitleMacerationStep9_classic: "Macerazione classica",
  SubtitleMacerationStep10_classic: "Macerazione classica",

  TitleMacerationStep0_coldPreferm: "Macerazione",
  TitleMacerationStep1_coldPreferm: "Macerazione prefermentativa a freddo (MPF)",
  TitleMacerationStep2_coldPreferm: "Aggiunta in vasca",
  TitleMacerationStep3_coldPreferm: "Enzimatura",
  TitleMacerationStep4_coldPreferm: "Macerazione prefermentativa a freddo",
  TitleMacerationStep5_coldPreferm: "Fermentazione alcolica",
  TitleMacerationStep6_coldPreferm: "Macerazione",
  TitleMacerationStep7_coldPreferm: "Zuccheraggio",
  TitleMacerationStep8_coldPreferm: "Svinatura, pressatura & fermentazione alcolica terminata",
  TitleMacerationStep9_coldPreferm: "Fermentazione malolattica",
  TitleMacerationStep10_coldPreferm: "Fine del protocollo",

  SubtitleMacerationStep0_coldPreferm: "METODO TRADIZIONNELLE",
  SubtitleMacerationStep1_coldPreferm: " ",
  SubtitleMacerationStep2_coldPreferm: "Macerazione prefermentativa a freddo",
  SubtitleMacerationStep3_coldPreferm: "Macerazione prefermentativa a freddo",
  SubtitleMacerationStep4_coldPreferm: "Macerazione prefermentativa a freddo",
  SubtitleMacerationStep5_coldPreferm: "Macerazione prefermentativa a freddo",
  SubtitleMacerationStep6_coldPreferm: "Macerazione prefermentativa a freddo",
  SubtitleMacerationStep7_coldPreferm: "Macerazione prefermentativa a freddo",
  SubtitleMacerationStep8_coldPreferm: "Macerazione prefermentativa a freddo",
  SubtitleMacerationStep9_coldPreferm: "Macerazione prefermentativa a freddo",
  SubtitleMacerationStep10_coldPreferm: "Macerazione prefermentativa a freddo",

  //Quantité de raisin
  harvestWeight: "Quantità d’uva utilizzata",
  askHarvestWeight: "Qual è la dimensione del tuo raccolto in chilogrammi?",
  //Type de macération
  macerationType: "Tipo di macerazione",
  askMacerationType: "Che tipo di macerazione vorresti fare?",
  coldPreferm: "Prefermentativa a freddo",
  classic: "Classica",

  //Etape 1
  harvestSorting: "Cernita della vendemmia",
  foulageEraflage: "Pigiatura - Diraspatura",

  //Etape 2 pour préf. à froid
  decreaseTemperature: "Abbassamento della temperatura",
  toDecreaseOf1Deg: "per abbassare la temperatura di 1°C",

  //Etape 3 pour préf. à froid
  atT24: "A T+24 ore",

  //Etape 4 pour pref. à froid
  prefermTitle: "MACERAZIONE PREFERMENTATIVA A FREDDO",
  pigeageWithoutOxygene: "Follatura senza aerazione",

  //Etape 6 pour les 2
  macerationCaps: "MACERAZIONE",
  remontagePigeage: "Rimontaggio e/o follatura con aerazione",
  decreaseVolume:
    "Ridurre i volumi rimontati man mano che la densità diminuisce.",

  //Etape 7 pour les 2
  chaptalisationMessage:
    "Per realizzare lo zuccheraggio, si consiglia una densità inferiore a 1040.",

  //Etape 8 pour les 2
  devatting: "SVINATURA",
  devattingContent: "Densità vicina a 1000.",
  pressing: "PRESSATURA",
  pressingContent:
    "Consulta il tuo enologo per una possibile miscelazione successiva del mosto vergine e del succo d’uva.",
  endOfAF: "Fine della FA",
  bottling: "Effettuare un travaso.",

  //Etape 9 pour les 2
  malolacticFermentationCaps: "FERMENTAZIONE MALOLATTICA",

  //FML
  TitleFMLStep0: "Vinificazione con fermentazione malolattica",
  TitleFMLStep1: "Operazioni prefermentative",
  TitleFMLStep2: "Operazioni prefermentative",
  TitleFMLStep3: "Operazioni prefermentative",
  TitleFMLStep4: "Fermentazione alcolica",
  TitleFMLStep5: "Fermentazione alcolica",
  TitleFMLStep6: "Fermentazione alcolica",
  TitleFMLStep7: "Fine del protocollo",

  SubtitleFMLStep0: "METODO TRADIZIONNELLE",
  SubtitleFMLStep1: "Vinificazione con fermentazione malolattica",
  SubtitleFMLStep2: "Vinificazione con fermentazione malolattica",
  SubtitleFMLStep3: "Vinificazione con fermentazione malolattica",
  SubtitleFMLStep4: "Vinificazione con fermentazione malolattica",
  SubtitleFMLStep5: "Vinificazione con fermentazione malolattica",
  SubtitleFMLStep6: "Vinificazione con fermentazione malolattica",
  SubtitleFMLStep7: "Vinificazione con fermentazione malolattica",

  //Type de cépage
  cepageType: "Vitigno del campione",
  askCepageType: "Qual è il vitigno del campione?",
  chardonnay: "Varietà bianche - Blanc de Noirs",
  pinotNoirMeunier: "Varietà rosse",

  //Etape 1 pour les 2 (Sulfitage est déjà défini)
  FMLFirstStepSulfitingMessage:
    "Dal deflusso dei mosti in poi, l’apporto va frazionato in 3 volte.",
  coldPrefermOperations: "Trattamento prefermentativo a freddo ",
  whenNeeded: "Se necessario",

  //Etape 2
  theCuvee: "LA CUVÉE",
  FMLToDelay:
    "Da diluire in 10 volte il suo peso di acqua fredda e incorporare al mosto dopo la disgregazione.",
  FMLCatalMessage:
    "Da incorporare prima di pompare la frazione nella vasca di sfecciatura.",
  FMLThirdStepMessage:
    "In base all'ossidazione del succo e all'estrazione del colore (chinone).",

  //Etape 3
  theTaille: "LA TAILLE",

  //Etape 4
  FMLYeastingWater: "di metà acqua / metà mosto a 37°C",
  FMLYeastingMessage:
    "È fondamentale rispettare la temperatura di reidratazione e non superare i 40°C oltre i quali si potrebbero uccidere le cellule.",
  FMLYeastingMessagePart1:
    "Lasciare riposare per 20 minuti, poi scuotere delicatamente per omogeneizzare.",
  FMLYeastingMessagePart2:
    "Incorporare il lievito in 10–20 volte il suo volume di mosto. ",
  FMLYeastingMessagePart3:
    "Lasciare fermentare per 6–12 ore, poi aggiungere nella vasca.",

  //Etape 5
  yeastNutrition: "NUTRIZIONE DEI LIEVITI",
  FMLYeastNutritionMessage:
    "Questo nutriente permette un buon controllo della fermentazione alcolica e ottimizza il profilo aromatico dei vini.",

  //FML Bloquée
  TitleFMLBloqueeStep0: "Vinificazione senza fermentazione malolattica",
  TitleFMLBloqueeStep1: "Operazioni prefermentative",
  TitleFMLBloqueeStep2: "Operazioni prefermentative",
  TitleFMLBloqueeStep3: "Operazioni prefermentative",
  TitleFMLBloqueeStep4: "Fermentazione alcolica",
  TitleFMLBloqueeStep5: "Fermentazione alcolica",
  TitleFMLBloqueeStep6: "Fine del protocollo",

  SubtitleFMLBloqueeStep0: "METODO TRADIZIONALE",
  SubtitleFMLBloqueeStep1: "Vinificazione senza fermentazione malolattica",
  SubtitleFMLBloqueeStep2: "Vinificazione senza fermentazione malolattica",
  SubtitleFMLBloqueeStep3: "Vinificazione senza fermentazione malolattica",
  SubtitleFMLBloqueeStep4: "Vinificazione senza fermentazione malolattica",
  SubtitleFMLBloqueeStep5: "Vinificazione senza fermentazione malolattica",
  SubtitleFMLBloqueeStep6: "Vinificazione senza fermentazione malolattica",

  //Charmat
  TitleCharmatStep0: "Metodo Martinotti",
  TitleCharmatStep1: "1.1 - Reidratazione",
  TitleCharmatStep2: "1.2 - Acclimatazione",
  TitleCharmatStep3: "1.2 - Acclimatazione",
  TitleCharmatStep4: "1.2 - Acclimatazione",
  TitleCharmatStep5: "1.3 - Pied de Cuve",
  TitleCharmatStep6: "1.3 - Pied de Cuve",
  TitleCharmatStep7: "1.3 - Pied de Cuve",
  TitleCharmatStep8: "1.3 - Pied de Cuve",
  TitleCharmatStep9: "Step intermedia",
  TitleCharmatStep10: "Step intermedia",
  TitleCharmatStep11: "1.4 - Step fondamentali della fermentazione alcolica",
  TitleCharmatStep12: "2.1 - Step fondamentali dell'affinamento",
  TitleCharmatStep13: "2.1 - Step fondamentali dell'affinamento",
  TitleCharmatStep14: "Fine del protocollo",

  SubtitleCharmatStep0: "Vasca chiusa",
  SubtitleCharmatStep1: "Metodo Martinotti - Starter",
  SubtitleCharmatStep2: "Metodo Martinotti - Starter",
  SubtitleCharmatStep3: "Metodo Martinotti - Starter",
  SubtitleCharmatStep4: "Metodo Martinotti - Starter",
  SubtitleCharmatStep5: "Metodo Martinotti - Starter",
  SubtitleCharmatStep6: "Metodo Martinotti - Starter",
  SubtitleCharmatStep7: "Metodo Martinotti - Starter",
  SubtitleCharmatStep8: "Metodo Martinotti - Starter",
  SubtitleCharmatStep9: "Metodo Martinotti - Starter",
  SubtitleCharmatStep10: "Metodo Martinotti - Starter",
  SubtitleCharmatStep11: "Metodo Martinotti - Starter",
  SubtitleCharmatStep12: "Metodo Martinotti - Starter",
  SubtitleCharmatStep13: "Metodo Martinotti - Starter",
  SubtitleCharmatStep14: "Metodo Martinotti - Starter",

  mineralFraisFruitBlanc: "Minerale, Fresco, Frutti bianchi",
  tresFruite: "Molto fruttato",
  floralElegant: "Floreale ed elegante",
  cremeuxVarietal: "Cremoso e varietale",

  //Etape 1
  charmatFirstStepFirstMessage:
    "Iniziare portando il vino di base a una temperatura di 12°C.",
  water35: "d'acqua a 35-36°C",
  water30: "d'acqua a 30°C",
  water25: "d'acqua a 25°C",
  water20: "d'acqua a 20°C",
  charmatFirstStepFirstBlackMessage:
    " È fondamentale rispettare la temperatura di reidratazione e non superare i 40°C, temperatura al di là della quale le cellule potrebbero essere distrutte.",
  charmatFirstStepSecondBlackMessage:
    "Agitare vigorosamente per evitare l’agglomerazione dei lieviti.",
  aromaticProfile: "Profilo aromatico desiderato",
  askAromaticProfile: "Quale profilo aromatico vuoi per il tuo vino?",

  //Etape 2
  sugar: "zucchero",
  stepCaps: "STEP ",
  step: "Step",
  charmatSecondStepMessageFirstStep:
    "Mescolare in un contenitore separato gli elementi sottostanti.",
  charmatSecondStepMessageSecondStep:
    "Aggiungere un po' di vino di base e acqua alla preparazione.",
  charmatSecondStepMessageThirdStep:
    "Aggiungere i seguenti prodotti per completare l'acclimatazione.",
  alreadyPrepared: "già pronti",
  acclimatationBasicWine12: "di vino di base (a 12°C)",
  acclimatationBasicWine14: "di vino di base (a 14°C)",
  feedStockBasicWine: "di vino di base (a 15-16°C)",
  feedStockBasicWine16: "di vino di base (a 16°C)",

  //Etape 3
  charmatThirdStepFirstMessage:
    "Durante i 3 giorni di preparazione del pied de cuve, assicurarsi che:",
  charmatThirdStepSecondMessage:
    "La densità non sia mai inferiore a 1005 (se diminuisce, aggiungere un po' di zucchero)",
  charmatThirdStepThirdMessage:
    "La differenza di temperatura tra l’ambiente di acclimatazione e il vino non superi i 5–7°C",
  charmatThirdStepFourthMessage:
    "La temperatura del vino di base aggiunto alla vasca sia sempre compresa tra 16 e 18°C",

  //Etape 4
  charmInfo: "Per garantire la nutrizione dei lieviti",
  charmatFourthStepMessageFirstStep:
    "Dopo un'ora il volume viene raddoppiato con il vino di base, la temperatura deve rimanere vicina ai 18–20°C.",
  charmatFourthStepMessageSecondStep:
    "Dopo altre 24 ore, l'operazione di cui sopra viene ripetuta e i volumi raddoppiati.",
  charmatFourthStepMessageThirdStep:
    "Dopo altre 24 ore, si aggiungono gli elementi nutritivi e si ripete l'operazione precedente.",
  charmatFourthStepLastMessage:
    "Quando l'attività fermentativa è ben avviata, aggiungere nell’autoclave l'intero volume preparato.",
  startYInfo: "Solo se la concentrazione di alcol è alta",

  //Etape 5
  sugarQuantity: "Quantità di zucchero residuo",
  sugarQuantityAsk:
    "Quanto zucchero residuo vuoi? (in g/L)",

  //Etape 6
  charmatSugarResultSugar: "di zucchero (25 g/L + zucchero residuo)",
  charmatSugarStartFreshMessage:
    "Si raccomanda di aggiungere START® Y FRESH (20 g/hL) per assicurare la nutrizione del lievito",
  mineralFraisFruitBlancSubliCharmMessage:
    "Per potenziare gli aromi fruttati, aggiungere:",
  cremeuxVarietalSubliCharmMessage: "Per aumentare il volume in bocca:",
  floralElegantSubliCharmMessage: "Per aumentare il volume in bocca:",
  charmElevageMessage:
    "Per aumentare il volume e il grasso e migliorare la qualità della spuma, aggiungere:",
  tresFruiteCharmElevageMessage:
    "Per aumentare il volume e la sensazione di fruttato e migliorare la qualità della spuma, aggiungere:",
  cremeuxVarietalCharmElevageMessage:
    "Per migliorare equilibrio REDOX e MP:",
  charmAgeMessage:
    "Per migliorare la durata di conservazione e preservare gli aromi sottili, aggiungere:",
  charmActivMessage: "Per garantire la nutrizione dei lieviti:",
  charmRedoxMessage: "Per evitare i fenomeni di riduzione:",

  //Etape 7
  decreaseTemperatureUnder15: "Abbassare la temperatura sotto i 15°C",
  addProductsUnderPressure:
    "Se è possibile aggiungere prodotti sotto pressione a 2,5 bar, aggiungere:",
  fermentRecommandedTemperature:
    "Temperatura di fermentazione consigliata: 14–16°C",
  reductionAromaCase: "In caso di comparsa di aromi di riduzione, aggiungere:",
  pressureReached:
    "Quando la pressione raggiunge 4,3–4,6 atm (calcolata a 20°C), abbassare la temperatura sotto i 2°C per interrompere la fermentazione.",
  necessaryTimeDependingEquipment:
    "Questo richiede circa 1 o 2 giorni, secondo l'attrezzatura.",
  setTemperatureTo0:
    "Regolare la temperatura a 0°C e filtrare il vino (filtrazione tangenziale o membrana da 1 μm).",

  //Etape 8
  duringElevage: "Durante l'affinamento, lo scuotimento:",
  firstOrder: "Deve durare 15 giorni (2x 15 minuti ogni giorno)",
  secondOrder: "Va effettuato ogni 3 giorni per i successivi 30 giorni (1x20 minuti)",
  thirdOrder: "Una volta alla settimana per gli ultimi 15 giorni (1x15 minuti)",

  //Etape 9
  elevageMessage:
    "Affinamento a una temperatura minima di 6–8°C per 2 mesi, con a scelta (secondo i risultati della degustazione):",

  //Calculateur SO2 Moléculaire
  so2MolecularTitle: "Calcolo del contenuto di SO{sub2} molecolare",
  so2MolecularSubTitle:
    "Si noti che la SO{sub2} molecolare ha una proprietà antisettica sopra una concentrazione di 1 mg/L.",
  so2Amount: "Contenuto di SO{sub2} molecolare",
  pH: "pH",
  good: "L'imbottigliamento è possibile",
  risquy: "Rischioso",
  lethal: "Letale",

  //Calculateur Soufre - Acétaldéhyde
  soufreAcetaldehydeTitle: "Zolfo - Aldeide acetica",
  soufreAcetaldehydeSubtitle: "Solitamente utilizzato per il metodo Martinotti",
  so2Molecular: "SO{sub2} molecolare",
  molecular: "molecolare",
  totalEthanal: "Etanale totale",
  freeSO2: "SO{sub2} libera",
  totalSO2: "SO{sub2} totale",
  residualSugar: "Zucchero residuo",
  wantedFreeSO2: "Valore di SO{sub2} libera target",
  freeEthanal: "Etanale libero",
  freeCombiningSO2: "SO{sub2} libera che si combina",
  SO2ToAdd: "SO{sub2} da aggiungere",
  finalTotalSO2: "Valore finale di SO{sub2} totale",

  //Calculateur Chaptalisation
  chaptalization: "Zuccheraggio",
  density: "Massa volumica",
  wantedDegree: "Gradazione desiderata",
  chaptalisationDescription:
    "L'esperienza del monitoraggio di molte vendemmie mostra che per ottenere una gradazione finale di 11% vol. è auspicabile puntare a 11% vol. per lo Chardonnay e 11,2% vol. per il Pinot Noir e il Pinot Meunier.",
  results: "Risultati",
  chaptalisationDensityError:
    "Inserisci una massa volumica maggiore o uguale a 1064.",
  chaptalisationResult:
    "Quantità di zucchero cristallizzato da aggiungere per ottenere la gradazione alcolica desiderata in % vol.:",
  chaptalisationWarningTitle:
    "Per realizzare lo zuccheraggio, si consiglia una densità inferiore a 1040.",
  chaptalisationWarningContent:
    "Informazioni regolamentari sul zuccheraggio.",

  //Calculateur Pied de Cuve Malo
  maloTankTitle: "Pied De Cuve Malolattica a basso pH",
  cuveMaloTitle: "Protocollo di preparazione di un pied de cuve malo su mosto",
  cuveMaloContent:
    "Secondo il protocollo stabilito dal servizio tecnico del CIVC e pubblicato in Le Vigneron Champenois n°6 - Giugno 2009",
  totalWineToSeed: "Volume totale di vino da inoculare",
  inHectoliter: "In ettolitri (hL)",
  globaldata: "Dati globali",
  rehydratation: "Reidratazione",
  reactivationEnvironment: "Mezzo di Riattivazione",
  feedStock: "Pied de Cuve",
  reactivationEnvironmentVolume: "Volume del mezzo di riattivazione:",
  feedStockVolume: "Volume del pied de cuve:",
  bacteries: "Batteri utilizzati: ",
  activator: "Attivante utilizzato: ",
  dryYeast: "Lieviti secchi utilizzati: ",
  globalDataFeedStockMessage:
    "I mosti utilizzati nella realizzazione del pied de cuve saranno mosti sfecciati, di preferenza della prima pressata, non arricchiti e solfitati al massimo con 4 g/hl (cioè 2,7 cl/hl di SULFOSSOL® 150).",
  maloTankMiddleMessage:
    "Il Pied de Cuve e il Mezzo di Riattivazione dovranno essere preparati simultaneamente.",
  delay: "Mescolare i",
  litersOfWater: "litro di acqua a 25°C",
  rehydratationMessage:
    "Agitare vigorosamente per evitare la formazione di un agglomerato. Lasciare riposare per 20-30 minuti quindi omogeneizzare la preparazione, agitando nuovamente, prima di elaborare il pied de cuve.",
  preparedBefore: "preventivamente preparati",
  rehydratedBacteries: "Batteri reidratati",
  settlingMust: "Mosto sfecciato e poco solfitato (1/2 Dose)",
  ofWaterAt25: "di acqua a 25°C",
  sprinklingDryYeast:
    "Lieviti secchi SOEC® 1971 da disperdere senza reidratazion",
  inTotal: "in Totale",
  reactivationEnvironmentMessage:
    "Attenzione a mantenere la temperatura a 25°C durante tutta la riattivazione quindi appena il mezzo di riattivazione è pronto, raddoppiare il suo volume con il mosto in fermentazione del pied de cuve per ottenere un volume di:",
  duration: "Durata",
  mustWaterMixing:
    "una miscela di mosto e acqua calda per ottenere una temperatura di 35°C.",
  feedStockFirstMessagePart1:
    "(rispettare con attenzione le temperature sopraindicate attenzione a non superare i 40°C, altrimenti si distruggono le cellule attive). Agitare vigorosamente per evitare la formazione di un agglomerato.",
  feedStockFirstMessagePart2:
    "Lasciare riposare per 20-30 minuti quindi omogeneizzare la preparazione, agitando nuovamente, prima di elaborare il pied de cuve.",
  rehydratedYeasts: "Lieviti reidratati",
  must: "Mosto",
  feedStockVolumeComplement:
    "Incorporazione del mezzo di riattivazione nel Pied de Cuve dopo 3 giorni",
  feedStockTotalVolume: "Volume totale del pied de cuve: ",
  feedStockSecondMessage:
    "Mantenere la temperatura del pied de cuve a 25°C durante la FA quindi a 20°C durante la FML. Il monitoraggio sarà eseguito dopo 6-7 giorni poi ogni secondo giorno.",
  feedStockFinalMessagePart1:
    "L'inoculo dell'insieme dei serbatoi si farà in ragione del 3%, incorporando dalla parte superiore dei serbatoi e senza mescolare una volta che i 2/3 dell\'acido malico presente nel pied de cuve sarà stato consumato.",
  feedStockFinalMessagePart2:
    "L'incorporazione può essere realizzata nei vini durante la FA o a fine FA, con o senza travaso preliminare dei serbatoi da inoculare.",
  feedStockFinalMessagePart3:
    "Per sfruttare l'inerzia termica dei serbatoi, è consigliato arrestare la regolazione della temperatura a 1005 - 1010.",
  feedStockFinalMessagePart4:
    "Mantenere poi la temperatura tra 18° e 20°C. Il primo controllo sarà realizzato dopo almeno 15 giorni dall'inoculo.",
  maloTankFinalMessage:
    "Questi valori sono comunicati a titolo informativo e non possono sostituirsi al parere del vostro enologo di riferimento.",

  //Calculateur Correction de la surpression d'un vin effervescent
  sparklinWineOverpressureTitle:
    "Correzione della sovrapressione della bottiglia",
  sparklingWineOverpressureMessage:
    "Calcolare la correzione della sovrapressione di un vino spumante ad una data temperatura",
  overpressure: "Sovrapressione",
  temperature: "Temperatura",
  onlyInteger: "(Solo numero intero)",

  //Gestion de Cuve
  managementTanks: "Gestione delle mie vasche",
  volume: "Volume",
  currentProtocol: "Protocolli in corso",
  startProtocol: "Cominciare un protocollo",
  continueProtocol: "Continuare con il protocollo",
  stopProtocol: "Fermare il protocollo",
  startNewProtocol: "Iniziare un nuovo protocollo",
  addATank: "Aggiungere una vasca",
  noTankFound: "Non è stata trovata nessuna vasca",
  textNoTankFound:
    "Non abbiamo trovato vasche sul tuo dispositivo. Aggiungine una cliccando qui sotto!",
  addingTank: "È stata aggiunta una vasca",
  nameTank: "Numero della tua vasca",
  volumeTank: "Volume della vasca",
  addTank: "Aggiungere una vasca",
  dontAddTank: "Annullare l’aggiunta",
  confirmationModalDeleteTank: "Sei sicuro di voler rimuovere il serbatoio?",
  subTitleConfirmationModalDeleteTank:
    "Cancellando il serbatoio, il protocollo associato sarà cancellato",
  confirmationModalDeleteProtocol:
    "Sei sicuro di voler fermare il protocollo?",
  confirmationModalNewProtocol:
    "Sei sicuro di voler iniziare un nuovo protocollo?",
  confirmationModalDeleteProtocolInProgress:
    "Sei sicuro di voler cancellare il protocollo attuale?",
  protocoleType: "Tipi di protocollo: ",
  sampleTitle: "Titolo del campione: ",
  date: "Data",
  tank: "Vasche",
  sampleNameOnly: "Numero del campione",
  FMLstep1: "Pressatura",
  FMLstep2: "La cuvée",
  FMLstep3: "La taille",
  FMLstep4: "Fermentazione Alcolica",
  FMLstep5: "Aggiunta lievito",
  FMLstep6: "Nutrizione dei lieviti",
  FMLstep7: "Calcolo del pied de cuve",
  Macerationstep1: "Cernita della vendemmia - Pigiatura - Diraspatura",
  Macerationstep2: "Aggiunta in vasca",
  Macerationstep3: "Enzimatura",
  Macerationstep4: "Macerazione prefermentativa a freddo	",
  Macerationstep5: "Fermentazione alcolica",
  Macerationstep6: "Macerazione",
  Macerationstep7: "Zuccheraggio",
  Macerationstep8: "Svinatura - Pressatura",
  Macerationstep9: "Fermentazione malolattica",
  Charmatstep1: "1.1 - Reidratazione",
  Charmatstep2: "1.2 - Acclimatazione/Step 1",
  Charmatstep3: "1.2 - Acclimatazione/Step 2",
  Charmatstep4: "1.2 - Acclimatazione/Step 3",
  Charmatstep5: "1.3 - Pied de Cuve",
  Charmatstep6: "1.3 - Pied de Cuve/Step 1",
  Charmatstep7: "1.3 - Pied de Cuve/Step 2",
  Charmatstep8: "1.3 - Pied de Cuve/Step 3",
  Charmatstep9: "Step intermedia",
  Charmatstep10: "Step intermedia",
  Charmatstep11: "Step intermedia",
  Charmatstep12: "1.4 - Step fondamentali della fermentazione alcolica",
  Charmatstep13: "2.1 - Step fondamentali dell'affinamento",
  Charmatstep14: "2.1 - Step fondamentali dell'affinamento",
  protocolCompleted: "Protocollo completato",
  continue: "Continuare",
  seeResults: "Vedere i risultati",
  startnewProtocol: "Iniziare un nuovo protocollo",
  runningProtocole: "Protocolli in corso",

  goToNextStep: "Step successivo",
  goBack: "Ritornare",
  none: "Nessun",
  by: "Di: ",
  of: "di",
  in: "in",
  to: "-",
  and: "e",
  at: "su",
  or: "o",
  yes: "Si",
  no: "No",
  between: "tra",
  add: "Aggiungere ",
  perDay: "al giorno",
  wait: "Aspettare",
  minutes: "minuti",
  hour: "ora",
  hours: "ore",
  days: "giorni",
  liters: "litri",
  inHectoliters: "in ettolitri (hL)",
  g: "g",
  kg: "kg",
  cL: "cL",
  L: "L",
  dissolve: "Sciogliere",
  yeasting: "AGGIUNTA LIEVITO",
  SULFITING: "SOLFITAZIONE",
  sulfiting: "Solfitazione",
  nutrition: "NUTRIZIONE DEI LIEVITI",
  tannin: "TANNIZZAZIONE",
  enzyme: "ENZIMATURA",
  anthocyaninsStabilization: "STABILIZZAZIONE DEGLI ANTOCIANI",
  addInTank: "Aggiungere direttamente nella vasca",
  mustClarification: "Chiarificazione del mosto",

  FMLInterStep:
    "Step intermedia da completare prima di continuare il protocollo",

  //Mentions Légales
  editor: "EDITORE",
  websiteEditBy: "Questa applicazione è pubblicata da",
  headOffice: "Sede centrale",
  tel: "Tel.",
  fax: "Fax.",
  sharedCapital: "Capitale sociale",
  siretNumber: "N° SIRET",
  numero: "N°",
  TVA: "TVA",
  directorOfPublication: "Direttore della pubblicazione",
  host: "HOST",
  reproductiveRights: "DIRITTI DI RIPRODUZIONE",
  reproductiveRightsContent:
    "Le informazioni presentate su questo sito sono pubbliche. La riproduzione delle informazioni di questo sito, ad eccezione dell'iconografia, è autorizzata a condizione di citare la fonte e di creare un link nella/e pagina/e di riferimento. Le informazioni su questo sito non possono essere utilizzate per scopi commerciali o pubblicitari.",
  personnalData: "DATI PERSONALI",
  personnalDataContent:
    "Quando i dati di questo sito sono di natura nominativa, gli utenti devono utilizzarli conformemente alla normativa in vigore e alle raccomandazioni della Commission Nationale de l'Informatique et des Libertés (CNIL). Le informazioni utilizzate devono essere solo per scopi personali, associativi o professionali, essendo esclusa qualsiasi distribuzione o uso per scopi commerciali o pubblicitari.",

  //Formulaire de contact
  titleContact: "Contatti",
  errorMessageContact: "Il tuo messaggio o indirizzo e-mail è vuoto.",
  errorSendMail:
    "Il tuo messaggio non può essere inviato, riprova più tardi.",
  send: "Inviare",
  chaptalisationWarning:
    "La quantità dell’apporto di zucchero cristallizzato è indicativa. Lo zuccheraggio è soggetto a regolamentazione; fare riferimento alla legislazione in vigore.",
  close: "Chiudere",
  inCaseOfProblem: "In caso di problemi: ",
  laboSecretary: "Segretaria di laboratorio",
  reimsAgency: "Sede di Reims",
  oenologist: "Enologo",
  oenologistf: "Enologo",
  technicalReferent: "Referente tecnico",
  oenologyReferent: "Consiglio enologico di riferimento",
  barSurSeineAgency: "Sede di Bar sur Seine",
  champagneAssistant: "Assistente  Champagne",
  mailSendWithSuccess:
    "Grazie per la vostra richiesta di informazioni. Sarà elaborato il più presto possibile dai nostri team.",
  lastName: "Nome",
  firstName: "Conome",
  phoneNumber: "Numero di telefono",
  mailAddress: "Indirizzo E-mail",
  postalAddress: "Indirizzo postale",
  zipCode: "Codice Postale",
  city: "Città",
  writeHere: "Scrivi la tua richiesta qui",
  scanMe: "Scannerizza il codice QR per scaricare l'applicazione sul tuo cellulare",
};

export default It;
