import {
  createAnimation,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonModal,
  IonRow,
} from "@ionic/react";
import React, { useState } from "react";
import { trash } from "ionicons/icons";
import { FormattedMessage } from "react-intl";
import Protocole from "../../types/Protocole";
import { Link } from "react-router-dom";
import Tank from "../../types/Tank";
interface Props {
  protocols: Protocole[];
  setProtocols: (protocols: Protocole[]) => void;
}
const animationEnterModal = (baseEl: any) => {
  const backdropAnimation = createAnimation()
    .addElement(baseEl.querySelector("ion-backdrop")!)
    .fromTo("opacity", "0.4", "var(--backdrop-opacity)");

  const wrapperAnimation = createAnimation()
    .addElement(baseEl.querySelector(".modal-wrapper")!)
    .keyframes([
      {
        borderRadius: "20px",
        offset: 0,
        opacity: "0",
        transform: "scale(0)",
        height: "272px",
        margin: "auto",
        width: "341px",
        textAlign: "center",
      },
      {
        borderRadius: "20px",
        offset: 1,
        opacity: "1",
        transform: "scale(1)",
        height: "272px",
        margin: "auto",
        width: "341px",
      },
    ]);

  return createAnimation()
    .addElement(baseEl)
    .easing("ease-out")
    .duration(250)
    .addAnimation([backdropAnimation, wrapperAnimation]);
};
const ProtocolsList: React.FC<Props> = ({ protocols, setProtocols }) => {
  const [showModalDeleteProtocol, setShowModalDeleteProtocol] =
    useState<string | null>(null);

  return (
    <div>
      {protocols.length ? (
        <div>
          {protocols.map((protocole) => (
            <IonCard
              style={{ position: "relative", borderRadius: "20pt" }}
              key={protocole.id}
            >
              <IonCardHeader>
                <IonCardTitle
                  style={{
                    textAlign: "left",
                    fontSize: "22px",
                    fontFamily: "Abril Fatface",
                    fontWeight: 400,
                    maxWidth: "80%",
                  }}
                >
                  <FormattedMessage id={`Title${protocole.type}Step0${protocole.type === "Maceration"? "_classic" : ""}`}/> -{" "}<br/>
                  {protocole.type === "FML" ||
                  protocole.type === "FMLBloquee" ? (
                    <FormattedMessage id={`${protocole.cepage}`} />
                  ) : protocole.type === "Charmat" ? (
                    <FormattedMessage id={`${protocole.aromaticProfile}`} />
                  ) : (
                    <FormattedMessage id={`${protocole.macerationType}`} />
                  )}
                </IonCardTitle>
                <IonIcon
                  style={{
                    position: "absolute",
                    top: "10%",
                    right: "5%",
                    color: "#C20E31",
                  }}
                  size="large"
                  icon={trash}
                  onClick={() => {
                    setShowModalDeleteProtocol(protocole.id);
                  }}
                />
                <IonModal
                  cssClass="modalConfirmation"
                  isOpen={showModalDeleteProtocol === protocole.id}
                  keyboard-close={true}
                  enterAnimation={animationEnterModal}
                  leaveAnimation={(baseEl) =>
                    animationEnterModal(baseEl).direction("reverse")
                  }
                  onDidDismiss={() => setShowModalDeleteProtocol(null)}
                >
                  <span className="modalTitle">
                    <FormattedMessage id="confirmationModalDeleteProtocolInProgress" />
                  </span>{" "}
                  <div>
                    <IonButton
                      className="buttonModal"
                      onClick={() => {
                        setShowModalDeleteProtocol(null);
                        const protocolTank: Tank = JSON.parse(
                          localStorage.getItem(
                            "tank_" + protocole.tankId
                          ) as string
                        );
                        localStorage.setItem(
                          "tank_" + protocolTank.id,
                          JSON.stringify({
                            ...protocolTank,
                            protocoles: protocolTank.protocoles.filter(
                              (id) => id !== protocole.id
                            ),
                          })
                        );
                        localStorage.removeItem("protocol_" + protocole.id);
                        setProtocols(
                          protocols.filter((value) => value.id !== protocole.id)
                        );
                      }}
                    >
                      <FormattedMessage id="yes" />
                    </IonButton>
                    <IonButton
                      className="buttonModal"
                      onClick={() => {
                        setShowModalDeleteProtocol(null);
                      }}
                    >
                      <FormattedMessage id="no" />
                    </IonButton>
                  </div>
                </IonModal>

                <IonCardSubtitle
                  style={{
                    textAlign: "left",
                    fontSize: "8pt",
                    fontFamily: "Lato",
                    color: "#EDAE32",
                  }}
                >
                  {protocole.type === "Charmat" ? (
                    <FormattedMessage id="martinottiMethod" />
                  ) : (
                    <FormattedMessage id="traditionalMethod" />
                  )}
                </IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <div style={{ textAlign: "left", fontSize: "10pt" }}>
                        <FormattedMessage id="step" />: <br />
                        <b>
                          {protocole.status === "En cours" ? (
                            <FormattedMessage
                              id={`Title${protocole.type}Step${protocole.step}${
                                protocole.type === "Maceration"
                                  ? `_${protocole.macerationType}`
                                  : ""
                              }`}
                            />
                          ) : (
                            <FormattedMessage id={"protocolCompleted"} />
                          )}
                        </b>
                        <br />
                        <br />
                        <FormattedMessage id="date" />: <br />
                        <b>
                          {new Date(protocole.modifiedAt).toLocaleDateString(
                            localStorage.getItem("language") as string
                          )}
                        </b>
                      </div>
                    </IonCol>

                    <IonCol>
                      <div style={{ textAlign: "left", fontSize: "10pt" }}>
                        <FormattedMessage id="tank" />: <br />
                        <b>
                          {
                            Object.keys(localStorage)
                              .filter(
                                (key) => key === "tank_" + protocole.tankId
                              )
                              .map((key) => JSON.parse(localStorage[key]))[0]
                              .name
                          }
                        </b>
                        <br />
                        <br />
                        {protocole.name !== "" && (
                          <>
                            <FormattedMessage id="sampleNameOnly" />:{" "}
                            <b>{protocole.name}</b>
                          </>
                        )}
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <Link
                  to={{
                    pathname: `/protocoles/protocole${protocole.type}`,
                    state: {
                      protocolId: protocole.id,
                    },
                  }}
                >
                  <IonButton
                    color="warning"
                    style={{
                      "--border-radius": "15pt",
                      marginRight: "100%",
                    }}
                  >
                    <FormattedMessage id="continue" />
                  </IonButton>
                </Link>
              </IonCardContent>
            </IonCard>
          ))}
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <IonCard
            style={{
              position: "relative",
              borderRadius: "20pt",
              padding: "20px",
            }}
          >
            <IonCardTitle
              style={{
                fontSize: "24pt",
                fontFamily: "Abril Fatface",
              }}
            >
              <FormattedMessage id="noProtocoleFoundfirst" />
            </IonCardTitle>
            <br />
            <IonCardContent
              style={{
                fontSize: "14pt",
                fontFamily: "Lato",
              }}
            >
              <FormattedMessage id="noProtocoleFoundsecond" />
              <br />
              <FormattedMessage id="noProtocoleFoundthird" />
              <br />
            </IonCardContent>
            <Link to="/protocoles">
              <IonButton
                color="dark"
                style={{
                  "--border-radius": "15pt",
                  width: "100%",
                  "--padding-end": "10%",
                  "--padding-start": "10%",
                  textTransform: "none",
                  fontFamily: "Lato",
                }}
              >
                <FormattedMessage id="startnewProtocol" />
              </IonButton>
            </Link>
          </IonCard>
        </div>
      )}
    </div>
  );
};

export default ProtocolsList;
