import { IonButton, IonImg, IonModal } from "@ionic/react";
import React from "react";
import { FormattedMessage } from "react-intl";

interface BeforeInstallPromptEvent extends Event {
  path: any[];
  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as,
   * for example, "web" or "play" which would allow the user to chose between a web version or
   * an Android version.
   */
  readonly platforms: Array<string>;

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;

  /**
   * Allows a developer to show the install prompt at a time of their own choosing.
   * This method returns a Promise.
   */
  prompt(): Promise<void>;
}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}
interface Props {
  openAndroid: boolean;
  setOpenAndroid: (openAndroid: boolean) => void;
  installEvent: BeforeInstallPromptEvent | null;
  setInstallEvent: (e: BeforeInstallPromptEvent | null) => void;
}

const AndroidModal: React.FC<Props> = ({
  openAndroid,
  setOpenAndroid,
  installEvent,
  setInstallEvent,
}) => {
  function addToHomeScreen() {
    if (installEvent) {
      setOpenAndroid(false);
      installEvent.prompt();
      installEvent.userChoice.then(() => setInstallEvent(null));
    }
  }

  return (
    <IonModal
      isOpen={openAndroid}
      cssClass="installationModal"
      onDidDismiss={() => setOpenAndroid(false)}
    >
      <IonImg
        src="/assets/icons/soec.png"
        style={{
          marginLeft: "40%",
          marginTop: "10%",
          width: "80px",
          height: "80px",
        }}
      />
      <div
        style={{
          fontFamily: "Lato",
          fontSize: "22px",
          fontWeight: "bold",
        }}
      >
        <FormattedMessage id="addSOECToApp" />
      </div>
      <div style={{ fontFamily: "Lato", fontSize: "18px" }}>
        <FormattedMessage id="appSOECToAppInformations" />
      </div>
      <IonButton
        color="dark"
        style={{
          color: "white",
          fontFamily: "Lato",
          fontSize: "15px",
          width: "80%",
          marginLeft: "10%",
        }}
        onClick={addToHomeScreen}
      >
        <FormattedMessage id="addApplication" />
      </IonButton>
      <IonButton
        color="white"
        onClick={() => setOpenAndroid(false)}
        style={{
          border: "black solid 1px",
          color: "black",
          fontFamily: "Lato",
          fontSize: "15px",
          width: "80%",
          marginLeft: "10%",
          marginBottom: "10%",
        }}
      >
        {" "}
        <FormattedMessage id="stayOnBrowser" />
      </IonButton>
    </IonModal>
  );
};

export default AndroidModal;
