import { IonCard } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import computeOverpressure from "../../methods/sparklingWineOverpressureCompute";
import { InputNumberField } from "./inputFields";

interface Overpressure {
  overpressureAt10: number;
  overpressureAt20: number;
}

const SparklingWineOverpressure: React.FC = () => {
  const [overpressure, setOverpressure] = useState<number>(0);
  const [temperature, setTemperature] = useState<number>(0);
  const [result, setResult] = useState<Overpressure | undefined>(undefined);

  useEffect(() => {
    if (overpressure > 0 && temperature >= 0 && temperature < 26) {
      setResult(computeOverpressure(overpressure, temperature));
    } else {
      setResult(undefined);
    }
  }, [overpressure, temperature]);

  return (
    <div>
      <IonCard style={{ textAlign: "left", padding: "5%", color: "black" }}>
        <p>
          <FormattedMessage id="sparklingWineOverpressureMessage" />
        </p>
        <span className="inputTitle">
          <FormattedMessage id="overpressure" />
        </span>
        <InputNumberField value={overpressure} setValue={setOverpressure} />
        <span className="inputTitle">
          <FormattedMessage id="temperature" />
          <br />
        </span>
        <FormattedMessage id="between" /> 0°C <FormattedMessage id="and" /> 25°C
        <InputNumberField value={temperature} setValue={setTemperature} parseValue={(val) => parseInt(val, 10)}/>
        <br />
      </IonCard>
      {result && (
        <div>
          <div
            className="inputTitle"
            style={{ textAlign: "left", marginLeft: "5%" }}
          >
            <FormattedMessage id="results" /> :{" "}
          </div>
          <div className="inputDiv">
            <div>
              <FormattedMessage id="overpressure" />{" "}
              <FormattedMessage id="at" /> 10°C
            </div>
            <div className="productValue">{result.overpressureAt10}</div>
          </div>
          <div className="inputDiv">
            <div>
              <FormattedMessage id="overpressure" />{" "}
              <FormattedMessage id="at" /> 20°C
            </div>
            <div className="productValue">{result.overpressureAt20}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SparklingWineOverpressure;
