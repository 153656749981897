import React, { useEffect } from "react";

import ReactGA from 'react-ga';

const withGATracker = (WrappedComponent) => {
  const trackPage = page => {
    ReactGA.set({
      page,
    });
    ReactGA.pageview(page);
  };

  return props => {
    useEffect(() => trackPage(props.location.pathname), [
      props.location.pathname
    ]);

    return <WrappedComponent {...props} />;
  };
};

export default withGATracker;
