import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

type Choice = {
  name: string;
  unit: string;
  values: (number | undefined)[];
  description: string;
};

const MultiChoicesDisplaySeveralValues = (choicesList: {
  choicesList: Choice[];
}) => {
  const [selected, setSelected] = useState(choicesList.choicesList[0]);
  const [extended, setExtended] = useState(false);
  return (
    <>
      {selected && (
        <>
          {!extended ? (
            <div
              style={{
                border: "#1B1D26 solid 1px",
                borderRadius: "20px",
                padding: "3%",
              }}
              onClick={() => setExtended(true)}
            >
              <span className="productValue">{selected.values[0]}</span>{" "}
              <span className="productUnit">
                <FormattedMessage id="to" />
              </span>{" "}
              <span className="productValue">{selected.values[1]}</span>
              <span className="productUnit">
                <FormattedMessage id={selected.unit} />{" "}
              </span>
              <br />
              <span className="transitionWord">
                <FormattedMessage id="of" />
              </span>{" "}
              <span className="productName">{selected.name}</span>
              <br />
              {selected.description}
            </div>
          ) : (
            <div
              style={{
                border: "#1B1D26 solid 1px",
                borderRadius: "20px",
                padding: "3%",
              }}
              onClick={() => setExtended(false)}
            >
              {choicesList.choicesList.map((choice) => (
                <div key={choice.name} onClick={() => setSelected(choice)}>
                  <span className="productValue">{choice.values[0]}</span>{" "}
                  <span className="productUnit">
                    <FormattedMessage id="to" />
                  </span>{" "}
                  <span className="productValue">{choice.values[1]}</span>
                  <span className="productUnit">
                    <FormattedMessage id={choice.unit} />{" "}
                  </span>
                  <br />
                  <span className="transitionWord">
                    <FormattedMessage id="of" />
                  </span>{" "}
                  <span className="productName">{choice.name}</span>
                  <br />
                  {choice.description}
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MultiChoicesDisplaySeveralValues;
