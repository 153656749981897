type Translation = {
  [key: string]: string;
};

const En: Translation = {
  "FR-FR": "French",
  "EN-GB": "English",
  "ES-ES": "Spanish",
  "IT-IT": "Italian",
  "DE-DE": "German",
  "RU-RU": "Russian",

  //Version ordinateur
  notAvailableForComputerTitle:
    "The SOEC application is not available on a computer.",
  notAvailableForComputerContent: "Unfortunately this application is not available on computer. To access it, scan the code below or access the following URL: {soecUrl} on your tablet or mobile. You can then add the application to your device by following the pop-up instructions.",

  //installer la PWA
  addSOECToApp: "Add SOEC to your applications!",
  appSOECToAppInformations:
    "You can add our application to your mobile / tablet. To do so, simply click on the button below.",
  appSOECToAppInformationsIos:
    "To add the application to your iPhone, just press the share icon:",
  appSOECToAppInformationsIosFinalStep:
    "Then click on \"On the home screen\":",
  addApplication: "Add the application",
  stayOnBrowser: "Stay on browser",

  //Menu
  home: "Home",
  tanks: "My tanks",
  protocoles: "Protocols",
  calculators: "Calculators",
  contact: "Need help?",

  //Page d'accueil
  legalesNoticies: "Legal information",
  GTC: "GTC",
  calculatorsTools: "Decision support tools",
  startA: "Start a",
  protocole: "protocol",
  allTools: "All tools",
  useCalculator: "Use a calculator",
  handleTank: "Manage my tanks",

  //Page d'accueil sans protocole
  noProtocoleFoundfirst: "No current protocols were found.",
  noProtocoleFoundsecond:
    "We have not found any protocols on your device.",
  noProtocoleFoundthird:
    "Start your first protocol by clicking below!",

  //Liste des protocoles disponibles
  protocols: "Protocols",
  maceration: "Maceration",
  traditionalMethod: "Traditional method",
  feedStockMethod: "Closed tank method",
  martinottiMethod: "Charmat method", //changement - correction
  martinottiMethodDescription:
    "These Traditional Method protocols are developed and adapted to the specificities of the Champagne region. Do not hesitate to consult our oenologists.",
  malolacticFermentation: "Vinification with malolactic fermentation",
  malolacticFermentationBlocked: "Vinification without malolactic fermentation",

  //Liste des calculateurs disponible
  decisionSupportToolsCaps: "DECISION SUPPORT TOOLS",
  calculationCorrection: "Correction of the",
  overpressureSparklingWine: "overpressure of a sparkling wine",
  calculationFoot: "Calculation of  the",
  maloTankLowPh: "Malo Starter",
  calculationOf: "Calculation of",
  sulfurAcetaldehyde: "Sulphur - Acetaldehyde",
  calculationOfThe: "Calculation of the",

  //Propre à tous les protocoles
  selectTank: "Select/Add a tank",
  askSelectedTank: "To which tank do you wish to add this protocol?",
  tankVolume: "Volume of the tank:",
  tankVolumeInfo:
    "(To change the desired volume, you can change or add a tank.)",
  beginningDate: "Start date",
  askBeginningDate: "When do you wish to start this protocol?",
  sampleName: "Sample or batch number",
  askSampleName: "For which sample do you want to perform this protocol?",
  beginProtocole: "START PROTOCOL",
  endProtocole: "Complete the protocol",
  sendResults: "Send results",
  goBackToBoard: "Back to the dashboard",
  selectADate: "Select a date",
  selectATank: "Select a tank",
  selectACepage: "Select a grape variety",
  selectAMacerationType: "Select a type of maceration",
  selectAnAromaticProfile: "Select an aromatic profile",
  nameTheTank: "Tank number",
  errorMessageInput: "This field must be completed",
  errorNumberInput: "Value must be greater than 0",
  errorSelectTank: "Please select a tank",
  errorDate: "Select a date",
  optionnal: "Optional",


  //Protocole Macération
  //Titre et sous-titre pour chacune des étapes
  TitleMacerationStep0_classic: "Maceration",
  TitleMacerationStep1_classic: "Classic maceration",
  TitleMacerationStep2_classic: "Vatting",
  TitleMacerationStep3_classic: "Pre-fermentative operations",
  TitleMacerationStep4_classic: "Alcoholic fermentation",
  TitleMacerationStep5_classic: "Alcoholic fermentation",
  TitleMacerationStep6_classic: "Maceration",
  TitleMacerationStep7_classic: "Chaptalisation",
  TitleMacerationStep8_classic: "Devatting, pressing & end of AF",
  TitleMacerationStep9_classic: "Malolactic fermentation",
  TitleMacerationStep10_classic: "End of the protocol",

  SubtitleMacerationStep0_classic: "TRADITIONAL METHOD",
  SubtitleMacerationStep1_classic: " ",
  SubtitleMacerationStep2_classic: "Classic maceration",
  SubtitleMacerationStep3_classic: "Classic maceration",
  SubtitleMacerationStep4_classic: "Classic maceration",
  SubtitleMacerationStep5_classic: "Classic maceration",
  SubtitleMacerationStep6_classic: "Classic maceration",
  SubtitleMacerationStep7_classic: "Classic maceration",
  SubtitleMacerationStep8_classic: "Classic maceration",
  SubtitleMacerationStep9_classic: "Classic maceration",
  SubtitleMacerationStep10_classic: "Classic maceration",

  TitleMacerationStep0_coldPreferm: "Maceration",
  TitleMacerationStep1_coldPreferm: "Cold pre-fermentative maceration",
  TitleMacerationStep2_coldPreferm: "Vatting",
  TitleMacerationStep3_coldPreferm: "Enzyme",
  TitleMacerationStep4_coldPreferm: "Cold pre-fermentative maceration",
  TitleMacerationStep5_coldPreferm: "Alcoholic fermentation",
  TitleMacerationStep6_coldPreferm: "Maceration",
  TitleMacerationStep7_coldPreferm: "Chaptalisation",
  TitleMacerationStep8_coldPreferm: "Devatting, pressing & end of AF",
  TitleMacerationStep9_coldPreferm: "Malolactic fermentation",
  TitleMacerationStep10_coldPreferm: "End of the protocol",

  SubtitleMacerationStep0_coldPreferm: "TRADITIONAL METHOD",
  SubtitleMacerationStep1_coldPreferm: " ",
  SubtitleMacerationStep2_coldPreferm: "Cold pre-fermentative maceration",
  SubtitleMacerationStep3_coldPreferm: "Cold pre-fermentative maceration",
  SubtitleMacerationStep4_coldPreferm: "Cold pre-fermentative maceration",
  SubtitleMacerationStep5_coldPreferm: "Cold pre-fermentative maceration",
  SubtitleMacerationStep6_coldPreferm: "Cold pre-fermentative maceration",
  SubtitleMacerationStep7_coldPreferm: "Cold pre-fermentative maceration",
  SubtitleMacerationStep8_coldPreferm: "Cold pre-fermentative maceration",
  SubtitleMacerationStep9_coldPreferm: "Cold pre-fermentative maceration",
  SubtitleMacerationStep10_coldPreferm: "Cold pre-fermentative maceration",

  //Quantité de raisin
  harvestWeight: "Quantity of grapes used",
  askHarvestWeight: "What is the size of your harvest in kilograms?",
  //Type de macération
  macerationType: "Type of maceration",
  askMacerationType: "What type of maceration would you like to do?",
  coldPreferm: "Cold pre-fermentative maceration",
  classic: "Classic maceration",

  //Etape 1
  harvestSorting: "Sorting of the harvest",
  foulageEraflage: "Foulage - Eraflage",

  //Etape 2 pour préf. à froid
  decreaseTemperature: "Decrease in temperature",
  toDecreaseOf1Deg: "to decrease by 1°C",

  //Etape 3 pour préf. à froid
  atT24: "At T+24h",

  //Etape 4 pour pref. à froid
  prefermTitle: "COLD PRE-FERMENTATIVE MACERATION",
  pigeageWithoutOxygene: "Pigeage without oxygen",

  //Etape 6 pour les 2
  macerationCaps: "MACERATION",
  remontagePigeage: "Remontage and/or pigeage with oxygenation",
  decreaseVolume: "Decrease the volumes gradually while the density decreases.",

  //Etape 7 pour les 2
  chaptalisationMessage:
    "It is recommended that the density be less than 1040 to achieve chaptalisation.",

  //Etape 8 pour les 2
  devatting: "DEVATTING",
  devattingContent: "Density close to 1000.",
  pressing: "PRESSING",
  pressingContent:
    "Consult your oenologist for a possible subsequent blending of free-run juice and press juice.",
  endOfAF: "End of AF",
  bottling: "Racking",

  //Etape 9 pour les 2
  malolacticFermentationCaps: "MALOLACTIC FERMENTATION",

  //FML
  TitleFMLStep0: "Vinification with malolactic fermentation",
  TitleFMLStep1: "Pre-fermentative operations",
  TitleFMLStep2: "Pre-fermentative operations",
  TitleFMLStep3: "Pre-fermentative operations",
  TitleFMLStep4: "Alcoholic fermentation",
  TitleFMLStep5: "Alcoholic fermentation",
  TitleFMLStep6: "Malolactic fermentation",
  TitleFMLStep7: "End of the protocol",

  SubtitleFMLStep0: "TRADITIONAL METHOD",
  SubtitleFMLStep1: "Vinification with malolactic fermentation",
  SubtitleFMLStep2: "Vinification with malolactic fermentation",
  SubtitleFMLStep3: "Vinification with malolactic fermentation",
  SubtitleFMLStep4: "Vinification with malolactic fermentation",
  SubtitleFMLStep5: "Vinification with malolactic fermentation",
  SubtitleFMLStep6: "Vinification with malolactic fermentation",
  SubtitleFMLStep7: "Vinification with malolactic fermentation",

  //Type de cépage
  cepageType: "Grape variety of the sample",
  askCepageType: "What is the grape variety of the sample?",
  chardonnay: "White grape varieties - Blanc de Noirs",
  pinotNoirMeunier: "Red grape varieties",

  //Etape 1 pour les 2 (Sulfitage est déjà défini)
  FMLFirstStepSulfitingMessage:
    "As soon as the juices run out, the addition is fractioned in 3 parts.",
  coldPrefermOperations: "Cold pre-fermentative treatment ",
  whenNeeded: "When needed",

  //Etape 2
  theCuvee: "LA CUVÉE",
  FMLToDelay:
    "To be diluted in 10x its weight of cold water and incorporated into the must once it has disintegrated.",
  FMLCatalMessage:
    "To be incorporated before pumping the fraction into the settling tank.",
  FMLThirdStepMessage:
    "Depends on the level of oxidation and colour extraction (quinones).",

  //Etape 3
  theTaille: "LA TAILLE",

  //Etape 4
  FMLYeastingWater: "of half water / half must at 37°C",
  FMLYeastingMessage:
    "It is crucial to respect the rehydration temperature and not to go above 40°C or you might kill active cells.", //changement - correction
  FMLYeastingMessagePart1:
    "Leave it to rest for 20 minutes, then stir gently to homogenize.",
  FMLYeastingMessagePart2:
    "Incorporate the leaven into 10 to 20 times its volume of must.",
  FMLYeastingMessagePart3:
    "Leave it to ferment for 6 to 12 hours, then add to the tank.",

  //Etape 5
  yeastNutrition: "YEAST NUTRITION",
  FMLYeastNutritionMessage:
    "This nutrient allows a good control of the alcoholic fermentation and optimizes the aromatic profile of the wines.",

  //FML Bloquée
  TitleFMLBloqueeStep0: "Vinification without malolactic fermentation",
  TitleFMLBloqueeStep1: "Pre-fermentative operations",
  TitleFMLBloqueeStep2: "Pre-fermentative operations",
  TitleFMLBloqueeStep3: "Pre-fermentative operations",
  TitleFMLBloqueeStep4: "Alcoholic fermentation",
  TitleFMLBloqueeStep5: "Alcoholic fermentation",
  TitleFMLBloqueeStep6: "End of the protocol",

  SubtitleFMLBloqueeStep0: "TRADITIONAL METHOD",
  SubtitleFMLBloqueeStep1: "Vinification without malolactic fermentation",
  SubtitleFMLBloqueeStep2: "Vinification without malolactic fermentation",
  SubtitleFMLBloqueeStep3: "Vinification without malolactic fermentation",
  SubtitleFMLBloqueeStep4: "Vinification without malolactic fermentation",
  SubtitleFMLBloqueeStep5: "Vinification without malolactic fermentation",
  SubtitleFMLBloqueeStep6: "Vinification without malolactic fermentation",

  //Charmat
  TitleCharmatStep0: "Charmat method", //changement - correction
  TitleCharmatStep1: "1.1 - Rehydration",
  TitleCharmatStep2: "1.2 - Acclimatation",
  TitleCharmatStep3: "1.2 - Acclimatation",
  TitleCharmatStep4: "1.2 - Acclimatation",
  TitleCharmatStep5: "1.3 - Malo Starter",
  TitleCharmatStep6: "1.3 - Malo Starter",
  TitleCharmatStep7: "1.3 - Malo Starter",
  TitleCharmatStep8: "1.3 - Malo Starter",
  TitleCharmatStep9: "Intermediate step",
  TitleCharmatStep10: "Intermediate step",
  TitleCharmatStep11: "1.4 - Key steps of the alcoholic fermentation",
  TitleCharmatStep12: "2.1 - Key steps of the ageing",
  TitleCharmatStep13: "2.1 - Key steps of the ageing",
  TitleCharmatStep14: "End of the protocol",

  SubtitleCharmatStep0: "Closed tank",
  SubtitleCharmatStep1: "Charmat method - Starter", //changement - correction
  SubtitleCharmatStep2: "Charmat method - Starter", //changement - correction
  SubtitleCharmatStep3: "Charmat method - Starter", //changement - correction
  SubtitleCharmatStep4: "Charmat  method - Starter", //changement - correction
  SubtitleCharmatStep5: "Charmat  method - Starter", //changement - correction
  SubtitleCharmatStep6: "Charmat method - Starter", //changement - correction
  SubtitleCharmatStep7: "Charmat method - Starter", //changement - correction
  SubtitleCharmatStep8: "Charmat method - Starter", //changement - correction
  SubtitleCharmatStep9: "Charmat method - Starter", //changement - correction
  SubtitleCharmatStep10: "Charmat method - Starter", //changement - correction
  SubtitleCharmatStep11: "Charmat method - Starter", //changement - correction
  SubtitleCharmatStep12: "Charmat method - Starter", //changement - correction
  SubtitleCharmatStep13: "Charmat method - Starter", //changement - correction
  SubtitleCharmatStep14: "Charmat method - Starter", //changement - correction

  mineralFraisFruitBlanc: "Mineral, fresh, white fruit",
  tresFruite: "Very fruity",
  floralElegant: "Floral and elegant",
  cremeuxVarietal: "Creamy and varietal",

  //Etape 1
  charmatFirstStepFirstMessage:
    "Start by increasing the base wine temperature to 12°C.",
  water35: "of water at 35°/36°C",
  water30: "of water at 30°C",
  water25: "of water at 25°C",
  water20: "of water at 20°C",
  charmatFirstStepFirstBlackMessage:
    "It is crucial to respect the rehydration temperature and to not go above 40°C or you might kill active cells.", //changement - correction
  charmatFirstStepSecondBlackMessage:
    "Stir vigorously to make sure that yeasts won't agglomerate together.",
  aromaticProfile: "Desired aromatic profile",
  askAromaticProfile: "Which aromatic profile do you wish for your wine?",

  //Etape 2
  sugar: "sugar",
  stepCaps: "STEP",
  step: "Step",
  charmatSecondStepMessageFirstStep:
    "Mix in a separate basin the preparations below.",
  charmatSecondStepMessageSecondStep:
    "Add some base wine and water to the basin.",
  charmatSecondStepMessageThirdStep:
    "Add the following products to complete the acclimatation.",
  alreadyPrepared: "already prepared",
  acclimatationBasicWine12: "of base wine (at 12°C)",
  acclimatationBasicWine14: "of base wine (at 14°C)",
  feedStockBasicWine: "of base wine (at 15-16°C)",
  feedStockBasicWine16: "of base wine (at 16°C)",

  //Etape 3
  charmatThirdStepFirstMessage:
    "During the 3-days preparation of malo starter, ensure that:",
  charmatThirdStepSecondMessage:
    "Density never falls down under 1005 (in case of decrease, add a small quantity of sugar).",
  charmatThirdStepThirdMessage:
    "The difference of T°C between the Acclimatation preparation and the wine is not higher than 5/7°C.",
  charmatThirdStepFourthMessage:
    "The base wine added to the tank always stays between 16 and 18°C.",

  //Etape 4
  charmInfo: "Recommended to improve longevity.",
  charmatFourthStepMessageFirstStep:
    "After 1 hour the volume is doubled with base wine, the temperature must remain close to 18/20°C.",
  charmatFourthStepMessageSecondStep:
    "After another 24 hours, the operation above is repeated and the volume doubles.",
  charmatFourthStepMessageThirdStep:
    "After a further 24 hours, nutrition is added and the operation above is repeated.",
  charmatFourthStepLastMessage:
    "When the fermentation activity is well started, add everything to the overall mass in Akratofor.",
  startYInfo: "Only if the alcohol concentration is high",

  //Etape 5
  sugarQuantity: "Quantity of residual sugar",
  sugarQuantityAsk:
    "How much residual sugar do you want? (in g/L)",

  //Etape 6
  charmatSugarResultSugar: "of sugar (25 g/L + residual sugar)",
  charmatSugarStartFreshMessage:
    "It is recommended to add START Y® FRESH (20 g/hL) to ensure yeast nutrition.",
  mineralFraisFruitBlancSubliCharmMessage:
    "To increase the fresh fruit aromas, add:",
  cremeuxVarietalSubliCharmMessage: "To increase the volume in the mouth, add:",
  floralElegantSubliCharmMessage: "To increase the volume in the mouth, add:",
  charmElevageMessage:
    "To increase volume and fatness and improve the quality of the foam, add:",
  tresFruiteCharmElevageMessage:
    "To increase the volume and fruity sensation and improve the quality of the foam, add:",
  cremeuxVarietalCharmElevageMessage:
    "To increase the MP and RedOx equilibrium, add:",
  charmAgeMessage:
    "For a better shelf life and to preserve the fine aromas, add:",
  charmActivMessage: "To ensure the yeast nutrition, add:",
  charmRedoxMessage: "To prevent from reduction phenomena, add:",

  //Etape 7
  decreaseTemperatureUnder15: "Decrease the temperature down to 15°C.",
  addProductsUnderPressure:
    "If you can add some products under pressure at 2.5 bars, add:",
  fermentRecommandedTemperature:
    "Recommended fermentation temperature: 14°C to 16°C",
  reductionAromaCase: "In case some reduction aromas appear, add:",
  pressureReached:
    "When pressure reaches 4.3 to 4.6 ATM (calculated at 20°C), decrease the temperature down to 2°C to stop the fermentation.",
  necessaryTimeDependingEquipment:
    "This takes around 1 to 2 days according to the equipments.",
  setTemperatureTo0:
    "Bring the temperature to 0°C and filter the wine (cross-flow or 1 μm cartridges).",

  //Etape 8
  duringElevage: "During the ageing, the stirring must:", //changement - correction
  firstOrder: "Last 15 days (2x 15 minutes per day)",
  secondOrder: "Be every 3 days for the next 30 days (1x15 minutes)",
  thirdOrder: "Be once a week for the last 15 days (1x30 minutes)",

  //Etape 9
  elevageMessage:
    "Ageing at 6-8°C minimum during 2 months with propositions below (to be decided according to the tasting):",

  //Calculateur SO2 Moléculaire
  so2MolecularTitle: "Molecular SO{sub2} calculation",
  so2MolecularSubTitle:
    "Note that molecular SO{sub2} has an antiseptic property above a concentration of 1 mg/L.",
  so2Amount: "Molecular SO{sub2} concentration",
  pH: "pH",
  good: "Racking possible",
  risquy: "At risk",
  lethal: "Lethal",

  //Calculateur Soufre - Acétaldéhyde
  soufreAcetaldehydeTitle: "Sulphur - Acetaldehyde",
  soufreAcetaldehydeSubtitle: "Commonly used for the Charmat method.", //changement - correction
  so2Molecular: "Molecular SO{sub2}",
  molecular: "molecular",
  totalEthanal: "Total ethanal",
  freeSO2: "Free SO{sub2}",
  totalSO2: "Total SO{sub2}",
  residualSugar: "Residual sugar",
  wantedFreeSO2: "Target free SO{sub2} value",
  freeEthanal: "Free ethanal",
  freeCombiningSO2: "Free SO{sub2} that combines",
  SO2ToAdd: "SO{sub2} to add",
  finalTotalSO2: "Final value of total SO{sub2}",

  //Calculateur Chaptalisation
  chaptalization: "Chaptalisation",
  density: "Volumic mass",
  wantedDegree: "Desired degree",
  chaptalisationDescription:
    "The experience of monitoring numerous harvests shows that to obtain a final degree of 11% vol. it is desirable to aim for 11% vol. for Chardonnay and 11.2% vol. for Pinot Noir and Pinot Meunier.",
  results: "Results",
  chaptalisationDensityError:
    "Please enter a density greater than or equal to 1064.",
  chaptalisationResult:
    "Quantity of crystal sugar to be added to obtain the desired alcohol content in %vol.",
  chaptalisationWarningTitle:
    "It is recommended that the density be less than 1040 to achieve chaptalization.",
  chaptalisationWarningContent:
    "Regulatory information on chaptalisation.",

  //Calculateur Pied de Cuve Malo
  maloTankTitle: "Malo starter",
  cuveMaloTitle: "Preparation protocol of a malolactic yeast starter on must",
  cuveMaloContent:
    "According to the protocol established by the technical department of the CIVC and published in Vigneron Champenois n° 6 - June 2009.",
  totalWineToSeed: "Total volume of wine to be inoculated",
  inHectoliter: "In hectoliters (hL)",
  globaldata: "Aggregate data",
  rehydratation: "Rehydration",
  reactivationEnvironment: "Reactivation medium",
  feedStock: "Malo starter",
  reactivationEnvironmentVolume: "Volume of the reactivation medium:",
  feedStockVolume: "Volume of the malo starter:",
  bacteries: "Quantity of bacteria:",
  activator: "Quantity of activator:",
  dryYeast: "Quantity of dry yeast:",
  globalDataFeedStockMessage:
    "The musts used to prepare the yeast starter will be settled musts, preferably from the second pressing juice (taille), not chaptalized and sulfited to a maximum of 4 g/hl (i.e. 2.7 cl/hl of SULFOSSOL® 150).",
  maloTankMiddleMessage:
    "The yeast starter and the reactivation medium must be prepared simultaneously.",
  delay: "Dilute the",
  litersOfWater: "liters of water at 25°C",
  rehydratationMessage:
    "Stir vigorously to prevent agglomeration. Allow to stand for 15 to 20 minutes, then homogenise the mixture by stirring again, before preparing the Reactivation Medium.",
  preparedBefore: "previously prepared",
  rehydratedBacteries: "Rehydrated bacteria",
  settlingMust: "Settled & slightly sulfited must (half a dose)",
  ofWaterAt25: "of water at 25°C",
  sprinklingDryYeast:
    "SOEC® 1971 dried yeast in powder form without rehydration",
  inTotal: "in total",
  reactivationEnvironmentMessage:
    "Be sure to maintain a temperature of 25 °C throughout the reactivation process; then, as soon as the reactivation medium is ready (malic acid < 0,5 g/L), double its volume with the fermenting must from the yeast starter to obtain a volume of:",
  duration: "Duration",
  mustWaterMixing:
    "of a must/hot water mixture to obtain a temperature of 35°C.",
  feedStockFirstMessagePart1:
    "(be sure to observe the temperatures indicated above, and in particular to not exceed 40 °C, otherwise the active cells might be destroyed). Stir vigorously to prevent agglomeration.",
  feedStockFirstMessagePart2:
    " Allow to stand for 20 to 30 minutes, then homogenise the mixture by stirring again, before preparing the yeast starter.",
  rehydratedYeasts: "Rehydrated yeast",
  must: "Must",
  feedStockVolumeComplement:
    "Incorporation of the Reactivation Medium into the yeast starter after 3 days",
  feedStockTotalVolume: "Yeast starter total volume",
  feedStockSecondMessage:
    "Keep the yeast starter temperature at 25 °C during alcoholic fermentation, and then at 20 °C during malolactic fermentation. Follow-up will be performed after 6 or 7 days, then every 2 days.",
  feedStockFinalMessagePart1:
    "The tanks will be inoculated at a rate of 3% by incorporating from the top of the tanks, with no mixing once 2/3 of the malic acid present in the yeast starter is consumed.",
  feedStockFinalMessagePart2:
    "Incorporation into the wines can be done during or at the end of alcoholic fermentation, with or without prior racking of the tanks to be inoculated.",
  feedStockFinalMessagePart3:
    "To benefit from the thermal inertia of the tanks, it is advisable to turn off the temperature control at 1005-1010.",
  feedStockFinalMessagePart4:
    "Then maintain the temperature between 18 °C and 20 °C. The first check will be performed no less than 15 days after inoculation.",
  maloTankFinalMessage:
    "The amounts indicated are for informational purposes only. Under no circumstances does this information replace the advice of your consultant oenologist.",

  //Calculateur Correction de la surpression d'un vin effervescent
  sparklinWineOverpressureTitle:
    "Correction of overpressure of a sparkling wine",
  sparklingWineOverpressureMessage:
    "Calculate the correction of overpressure of a sparkling wine at a given temperature",
  overpressure: "Overpressure",
  temperature: "Temperature",

  //Gestion de Cuve
  managementTanks: "Manage my tanks",
  volume: "Volume",
  currentProtocol: "Protocol in progress",
  startProtocol: "Start a protocol",
  continueProtocol: "Continue protocol",
  stopProtocol: "Stop protocol",
  startNewProtocol: "Start a new protocol",
  addATank: "Add a tank",
  noTankFound: "No tank was found",
  textNoTankFound:
    "We have not found a tank on your device. Add one by clicking below!",
  addingTank: "Add a tank",
  nameTank: "Tank number",
  volumeTank: "Volume of the tank",
  addTank: "Add tank",
  dontAddTank: "Cancel the addition",
  confirmationModalDeleteTank: "Are you sure you want to remove the tank?",
  subTitleConfirmationModalDeleteTank:
    "By deleting the tank, the associated protocol will be deleted",
  confirmationModalDeleteProtocol:
    "Are you sure you want to stop the protocol?",
  confirmationModalNewProtocol:
    "Are you sure you want to start a new protocol?",
  confirmationModalDeleteProtocolInProgress:
    "Are you sure you want to delete the current protocol?",
  protocoleType: "Type of protocol:",
  sampleTitle: "Name of the sample:",
  date: "Date",
  tank: "Tank",
  sampleNameOnly: "Number of the sample",
  FMLstep1: "Pressing",
  FMLstep2: "La cuvée",
  FMLstep3: "La taille",
  FMLstep4: "Alcoholic fermentation",
  FMLstep5: "Yeasting",
  FMLstep6: "Yeast nutrition",
  FMLstep7: "Malo starter",
  Macerationstep1: "Sorting of the harvest - Foulage - Eraflage",
  Macerationstep2: "Vatting",
  Macerationstep3: "Enzyme",
  Macerationstep4: "Cold pre-fermentative maceration",
  Macerationstep5: "Alcoholic fermentation",
  Macerationstep6: "Maceration",
  Macerationstep7: "Chaptalisation",
  Macerationstep8: "Devatting - Pressing",
  Macerationstep9: "Malolactic fermentation",
  Charmatstep1: "1.1 - Rehydration",
  Charmatstep2: "1.2 - Acclimatation/Step 1",
  Charmatstep3: "1.2 - Acclimatation/Step 2",
  Charmatstep4: "1.2 - Acclimatation/Step 3",
  Charmatstep5: "1.3 - Malo starter",
  Charmatstep6: "1.3 - Malo starter/Step 1",
  Charmatstep7: "1.3 - Malo starter/Step 2",
  Charmatstep8: "1.3 - Malo starter/Step 3",
  Charmatstep9: "Intermediate step",
  Charmatstep10: "Intermediate step",
  Charmatstep11: "Intermediate step",
  Charmatstep12: "1.4 - Key steps of the alcoholic fermentation",
  Charmatstep13: "2.1 - Key steps of the ageing",
  Charmatstep14: "2.1 - Key steps of the ageing",
  protocolCompleted: "Protocol completed",
  continue: "Continue protocol",
  seeResults: "See results",
  startnewProtocol: "Start a new protocol",
  runningProtocole: "Ongoing  protocols",

  goToNextStep: "Next step",
  goBack: "Go back",
  none: "None",
  by: "By:",
  of: "of",
  in: "in",
  to: "to",
  and: "and",
  at: "at",
  or: "or",
  yes: "Yes",
  no: "No",
  between: "between",
  add: "Add",
  perDay: "per day",
  wait: "Wait",
  minutes: "minutes",
  hour: "hour",
  hours: "hours",
  days: "days",
  liters: "liters",
  inHectoliters: "in hectoliters (hL)",
  g: "g",
  kg: "kg",
  cL: "cL",
  L: "L",
  dissolve: "Dissolve",
  yeasting: "YEASTING",
  SULFITING: "SULFITING",
  sulfiting: "Sulfiting",
  nutrition: "NUTRITION",
  tannin: "TANNIN",
  enzyme: "ENZYME",
  anthocyaninsStabilization: "ANTHOCYANINS STABILIZATION",
  addInTank: "Add directly to the tank", //changement - correction
  mustClarification: "Must clarification",

  FMLInterStep:
    "Intermediate step to be completed before continuing the protocol.",



  //Mentions Légales
  editor: "EDITOR",
  websiteEditBy: "This application was edited by",
  headOffice: "Head Office",
  tel: "Tel.",
  fax: "Fax.",
  sharedCapital: "Shared capital",
  siretNumber: "SIRET number",
  numero: "N°",
  TVA: "VAT",
  directorOfPublication: "Director of publication",
  host: "HOST",
  reproductiveRights: "REPRODUCTIVE RIGHTS",
  reproductiveRightsContent:
    "The information presented on this site is public. Reproduction of the information on this site, with the exception of the iconography, is authorised on condition that the source is mentioned and a link is created on the reference page(s). The information on this site may not be used for commercial or advertising purposes.",
  personnalData: "PERSONAL DATA",
  personnalDataContent:
    "When data present on this site is of a nominative nature, users must make use of it in accordance with the regulations in force and the recommendations of the Commission Nationale de l'Informatique et des Libertés (CNIL). The information used must only be for personal, associative or professional purposes, any distribution or use for commercial or advertising purposes being excluded.",


  //Formulaire de contact
  titleContact: "Contact",
  errorMessageContact: "Your message or email address is empty.",
  errorSendMail:
    "Your message could not be sent, please try again later.",
  send: "Send",
  chaptalisationWarning:
    "The quantity of crystallised sugar to be added is given as an indication. Chaptalisation is subject to regulations; please refer to the legislation in force.",
  close: "Close",
  inCaseOfProblem: "In case of a problem:",
  laboSecretary: "Laboratory Secretary",
  reimsAgency: "Reims branch",
  oenologist: "Oenologist",
  oenologistf: "Oenologist",
  technicalReferent: "Technical advisor",
  oenologyReferent: "Referent Oenology Consultant",
  barSurSeineAgency: "Bar sur Seine branch",
  champagneAssistant: "Champagne Assistant ",
  mailSendWithSuccess:
    "Thank you for your request for information. It will be processed as soon as possible by our teams.",

  scanMe: "Scan the QR code to download the application on your mobile",
  lastName: "Lastname",
  firstName: "Firstname",
  phoneNumber: "Phone number",
  mailAddress: "email",
  postalAddress: "Postal address",
  zipCode: "Zip",
  city: "City",
  writeHere: "Your message",
};

export default En;
