import translations from "../modules/translations";
import Maceration from "../types/Maceration";

interface Props {
  data: Maceration;
  sampleName: string;
  protocoleType: string;
  selectedDate: string;
  macerationType: string;
}

const MacerationTemplate: (props: Props) => Blob = ({
  data,
  sampleName,
  protocoleType,
  selectedDate,
  macerationType,
}) => {
  const language = localStorage.getItem("language") as string;
  const traductions = translations[language];
  const content = new Blob(
    [
      traductions["protocoleType"],
      protocoleType,
      "\n",
      traductions["sampleTitle"],
      sampleName,
      "\n",
      traductions["beginningDate"],
      " : ",
      new Date(selectedDate).toLocaleDateString(
        localStorage.getItem("language") as string
      ),
      "\n \n", //SortingHarvest and Foulage
      traductions["harvestSorting"],
      "\n",
      traductions["foulageEraflage"],
      "\n \n \n", //SecondStep
      traductions["SULFITING"],
      " - ",
      traductions["add"],
      " : \n",
      `${data.sulfiting?.baktolMinVolume}`,
      " ",
      traductions["to"],
      " ",
      `${data.sulfiting?.baktolMaxVolume}`,
      " ",
      traductions["of"],
      ` BAKTOL® 150 (6 ${traductions['to']} 8 ${language === 'RU-RU' ? 'сл' : 'cL'}/150kg)`,
      "\n",
      macerationType === "classic"
        ? `${traductions["enzyme"]} - ${traductions["add"]} :\n${data.enzyme?.viazymWeight} ${language === 'RU-RU' ? 'сл' : 'cL'} ${traductions["of"]} VIAZYM® EXTRACT PREMIUM (5 ${traductions['g']}/150 ${traductions['kg']})` //TODO
        : `${traductions["decreaseTemperature"]}\n${data?.carboglace?.carboglaceWeight} ${traductions['kg']} ${traductions["of"]} Carboglace (0,5kg/100kg to decrease of 1°C)`, //TODO
      traductions["tannin"],
      " - ",
      traductions["add"],
      " : \n",
      `${data?.tannin?.clarVolume}`,
      `${language === 'RU-RU' ? 'сл' : 'cL'}`,
      traductions["of"],
      ` CLAR T DEGORGEMENT (4 ${language === 'RU-RU' ? 'сл' : 'cL'}/150 ${traductions['kg']})`,
      " ",
      traductions["or"],
      " ",
      `${data?.tannin?.vitanilWeight}`,
      traductions['g'],
      traductions["of"],
      ` VITANIL® VR (10 ${traductions['g']}/150 ${traductions['kg']})`,
      "\n",
      traductions["yeasting"],
      "\n",
      traductions["FMLYeastingInformation"],
      "\n",
      traductions["dissolve"],
      " : \n",
      `${data.yeasting?.flavourWeight}`,
      traductions['kg'],
      traductions["of"],
      ` SO® FLAVOUR (5 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'}) `,
      traductions["in"],
      " ",
      `${data.yeasting?.waterVolume}`,
      " ",
      traductions["liters"],
      "\n",
      traductions["wait"],
      " ",
      macerationType === "classic" ? "48" : "24",
      " ",
      traductions["hours"],
      "\n \n \n",
      macerationType === "coldPreferm"
        ? `${traductions["enzyme"]} - ${traductions["add"]}\n${data.enzymeT24?.viazymWeight} ${traductions['g']} ${traductions["of"]} VIAZYM EXTRACT PREMIUM (5g/150kg)\n \n \n`
        : "",
      macerationType === "coldPreferm"
        ? `${traductions["prefermTitle"]}\n${traductions["pigeageWithoutOxygene"]} 1 ${traductions["to"]} 2 ${traductions["perDay"]}\n${traductions["wait"]} 3 ${traductions["days"]} \n \n \n`
        : "",
      traductions["yeasting"],
      "\n",
      traductions["dissolve"],
      " :\n",
      macerationType === "coldPreferm"
        ? `${data.yeastingT72?.flavourWeight}`
        : `${data.yeastingT48?.flavourWeight}`,
      traductions['kg'],
      traductions["of"],
      ` SO® FLAVOUR (20 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'}) `,
      traductions["in"],
      " ",
      macerationType === "coldPreferm"
        ? `${data.yeastingT72?.waterVolume}`
        : `${data.yeastingT48?.waterVolume}`,
      " ",
      traductions["liters"],
      "\n",
      traductions["nutrition"],
      " - ",
      traductions["add"],
      " :\n",
      macerationType === "classic"
        ? `${data.nutrition?.nutricellWeight} ${traductions['kg']} ${traductions["of"]} NUTRICELL® AA (20 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`
        : `${data.nutrition?.nutricellWeight} ${traductions['kg']} ${traductions["of"]} PHOSPHATE DIAMMONIQUE (20 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'}) ${traductions["or"]} ${data.nutrition?.nutricellWeight} ${traductions['kg']} ${traductions["of"]} NUTRICELL® AA (20 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
      "\n \n \n",
      traductions["maceration"],
      " :\n",
      traductions["remontagePigeage"],
      " : 1 ",
      traductions["to"],
      " 2 ",
      traductions["perDay"],
      "\n",
      macerationType === "classic"
        ? `${traductions["anthocyaninsStabilization"]} - ${traductions["add"]} :\n${data.anthocyanins?.neoWeight} ${traductions['kg']} ${traductions["of"]} NEO® SWEET (30 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`
        : traductions["decreaseVolume"],
      "\n \n \n",
      traductions["devatting"],
      " : ",
      traductions["devattingContent"],
      "\n",
      traductions["pressing"],
      " : ",
      traductions["pressingContent"],
      "\n",
      traductions["endOfAF"],
      " : ",
      traductions["bottling"],
      "\n \n \n",
      traductions["malolacticFermentationCaps"],
      " - ",
      traductions["addInTank"],
      "\n",
      `${data.malolactic?.vitilacticWeight}`,
      traductions['g'],
      traductions["of"],
      ` VITILACTIC F (1 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'}) `,
      traductions["or"],
      " ",
      `${data.malolactic?.malovitWeight}`,
      traductions['kg'],
      traductions["of"],
      ` MALOVIT (20 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'}) `,
    ],
    {
      type: "text/plain; charset=utf-8",
    }
  );
  return content;
};

export default MacerationTemplate;
