import { IonButton, IonInput, IonItem, IonTextarea } from "@ionic/react";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import translations from "../../modules/translations";

const ContactPage: React.FC = () => {
  const [lastNameSender, setLastNameSender] = useState<string>("");
  const [firstNameSender, setFirstNameSender] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [mobilePhone, setMobilePhone] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [errorFailedSendMail, setErrorFailedSendMail] =
    useState<boolean>(false);
  const history = useHistory();

  const language = localStorage.getItem("language") as string;
  const translation = translations[language];
  const defaultTranslation = translations["EN-GB"];

  return (
    <div className="contactPage">
      <span className="titleProtocols">
        <FormattedMessage id="titleContact" />
      </span>
      <div className="contactForm">
        <IonItem className="inputContactForm">
          <IonInput
            value={lastNameSender}
            placeholder={`${translation["lastName"] || defaultTranslation["lastName"] }`}
            onIonChange={(e) => setLastNameSender(e.detail.value!)}
            clearInput
            type="text"
          ></IonInput>
        </IonItem>

        <IonItem className="inputContactForm">
          <IonInput
            value={firstNameSender}
            placeholder={`${translation["firstName"] || defaultTranslation["firstName"] }`}
            onIonChange={(e) => setFirstNameSender(e.detail.value!)}
            clearInput
          ></IonInput>
        </IonItem>

        <IonItem className="inputContactForm">
          <IonInput
            value={mobilePhone}
            placeholder={`${translation["phoneNumber"] || defaultTranslation["phoneNumber"] }`}
            onIonChange={(e) => setMobilePhone(e.detail.value!)}
            clearInput
            type="tel"
          ></IonInput>
        </IonItem>

        <IonItem className="inputContactForm">
          <IonInput
            value={emailAddress}
            placeholder={`${translation["mailAddress"] || defaultTranslation["mailAddress"] }`}
            onIonChange={(e) => setEmailAddress(e.detail.value!)}
            clearInput
            type="email"
          ></IonInput>
        </IonItem>

        <IonItem className="inputContactForm">
          <IonInput
            value={address}
            placeholder={`${translation["postalAddress"] || defaultTranslation["postalAddress"] }`}
            onIonChange={(e) => setAddress(e.detail.value!)}
            clearInput
          ></IonInput>
        </IonItem>

        <IonItem className="inputContactForm">
          <IonInput
            value={zipCode}
            placeholder={`${translation["zipCode"] || defaultTranslation["zipCode"] }`}
            onIonChange={(e) => setZipCode(e.detail.value!)}
            clearInput
            autocomplete="postal-code"
          ></IonInput>
        </IonItem>

        <IonItem className="inputContactForm">
          <IonInput
            value={city}
            placeholder={`${translation["city"] || defaultTranslation["city"] }`}
            onIonChange={(e) => setCity(e.detail.value!)}
            clearInput
          ></IonInput>
        </IonItem>

        <IonItem className="inputContactForm">
          <IonTextarea
            placeholder={`${translation["writeHere"] || defaultTranslation["writeHere"] }`}
            value={message}
            onIonChange={(e) => setMessage(e.detail.value!)}
          />
        </IonItem>
        {errorMessage && (
          <span className="errorMessage">
            <FormattedMessage id="errorMessageContact" />
            <br />
          </span>
        )}
        {errorFailedSendMail && (
          <span className="errorMessage">
            <FormattedMessage id="errorSendMail" />
            <br />
          </span>
        )}
        <IonButton
          onClick={() => {
            if (emailAddress && message) {
              setErrorMessage(false);
              const targetUrl = window.location.origin + "/api/sendMail";
              fetch(targetUrl, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify({
                  lastName: lastNameSender,
                  firstName: firstNameSender,
                  address,
                  city,
                  zipCode,
                  emailAddress,
                  mobilePhone,
                  message,
                }),
              })
                .then((res) => {
                  if (res.status === 200) {
                    history.push({
                      pathname : "/home",
                      state: { success : true}
                    });
                  } else {
                    setErrorFailedSendMail(true);
                  }
                })
                .catch(() => {
                  setErrorFailedSendMail(true);
                });
            } else {
              setErrorMessage(true);
            }
          }}
        >
          <FormattedMessage id="send" />
        </IonButton>
      </div>
      <div style={{ textAlign: "left", margin: "40px 20px", fontFamily: "Lato" }}>
        <h1><FormattedMessage id="inCaseOfProblem" /></h1>
        <p>
          <b>MARNE :</b>
          <ul>
            <li>
              <FormattedMessage id="reimsAgency" /> : 03.26.85.81.40
            </li>
          </ul>
        </p>
        <br/>
        <p>
          <b>AUBE :</b>
          <ul>
            <li>
              <FormattedMessage id="barSurSeineAgency" /> : 03.25.29.98.45
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default ContactPage;
