import { IonCheckbox } from "@ionic/react";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import translations from '../../../modules/translations';
import Charmat from "../../../types/Charmat";

interface Props {
  computedData: Charmat | undefined;
  aromaticProfile: string;
}

const roundTo1Digit = (x: number | undefined) => x && Math.round(x * 10) / 10;

const roundTo2Digit = (x: number | undefined) => x && Math.round(x * 100) / 100;

const CharmatSugarResult: React.FC<Props> = ({
  computedData,
  aromaticProfile,
}) => {

  const language = localStorage.getItem('language');

  const [checked, setChecked] = useState(false);
  return (
    <div>
      <div className="inputDiv">
        <span className="productValue">
          {roundTo1Digit(computedData?.interStep.sugar)}
        </span>
        <span className="productUnit">{translations[language!]['kg']}</span>
        <br />
        <FormattedMessage id="charmatSugarResultSugar" />
      </div>
      {["floralElegant", "cremeuxVarietal"].includes(aromaticProfile) && (
        <div className="inputDiv">
          <p>
            <FormattedMessage id={"charmActivMessage"} />
          </p>
          <span className="productValue">
            {roundTo1Digit(computedData?.interStep.activCharm)}
          </span>
          <span className="productUnit">{translations[language!]['kg']}</span>
          <br />
          <FormattedMessage id="of" />
          <span className="productName"> CHARM® ACTIV</span> (20 {language === 'RU-RU' ? 'г/гл' : 'g/hL'})
        </div>
      )}
      {["mineralFraisFruitBlanc", "tresFruite"].includes(aromaticProfile) && (
        <>
          <div
            style={{
              backgroundColor: "#1B1D26",
              color: "white",
              padding: "10% 5%",
            }}
          >
            <FormattedMessage id="charmatSugarStartFreshMessage" />
          </div>
          {/* <div className="inputDiv"> */}
          <div
            style={{
              border: "1px solid #1B1D26",
              borderRadius: "20pt",
              margin: "5% 10%",
              position: "relative",
              opacity: `${checked ? "100%" : "50%"}`,
            }}
          >
            <div>
            <span className="productValue">
              {roundTo1Digit(computedData?.interStep.startY)}
            </span>
            <span className="productUnit">{translations[language!]['kg']}</span>
            <br />
            <FormattedMessage id="of" />
            <span className="productName"> START® Y FRESH</span> (20g/hL)
          </div>
          +
          <div className="inputDiv">
            <span className="productValue">
              {roundTo1Digit(computedData?.interStep.dap)}
            </span>
            <span className="productUnit">{translations[language!]['kg']}</span>
            <br />
            <FormattedMessage id="of" />
            <span className="productName"> DAP</span> (10g/hL)
          </div>
          <IonCheckbox
            color="dark"
            onClick={() => setChecked(!checked)}
            style={{ position: "absolute", top: "5%", right: "10%" }}
          />
          </div>
        </>
      )}
      {["mineralFraisFruitBlanc", "cremeuxVarietal"].includes(
        aromaticProfile
      ) && (
        <div className="inputDiv">
          <p>
            <FormattedMessage id={`${aromaticProfile}SubliCharmMessage`} />
          </p>
          <span className="productValue">
            {roundTo1Digit(computedData?.interStep.subliCharm)}
          </span>
          <span className="productUnit">{translations[language!]['kg']}</span>
          <br />
          <FormattedMessage id="of" />
          <span className="productName"> SUBLI CHARM®</span> (1.5g/hL)
        </div>
      )}
      {["floralElegant"].includes(aromaticProfile) && (
        <div className="inputDiv">
          <p>
            <FormattedMessage id={`${aromaticProfile}SubliCharmMessage`} />
          </p>
          <span className="productValue">
            {roundTo2Digit(computedData?.interStep.floralSubliCharm)}
          </span>
          <span className="productUnit">{translations[language!]['kg']}</span>
          <br />
          <FormattedMessage id="of" />
          <span className="productName"> SUBLI CHARM®</span> (1g/hL)
        </div>
      )}
      <div className="inputDiv">
        <p>
          <FormattedMessage
            id={`${
              aromaticProfile === "cremeuxVarietal"
                ? "cremeuxVarietalC"
                : aromaticProfile === "tresFruite"
                ? "tresFruiteC"
                : "c"
            }harmElevageMessage`}
          />
        </p>
        <span className="productValue">
          {roundTo2Digit(
            aromaticProfile === "floralElegant"
              ? computedData?.interStep.floralElevageCharm
              : aromaticProfile === "cremeuxVarietal"
              ? computedData?.interStep.creamyElevageCharm
              : computedData?.interStep.elevageCharm
          )}
        </span>
        <span className="productUnit">{translations[language!]['kg']}</span>
        <br />
        <FormattedMessage id="of" />
        <span className="productName"> CHARM® ELEVAGE</span> (
        {aromaticProfile === "floralElegant"
          ? 4
          : aromaticProfile === "cremeuxVarietal"
          ? 8
          : 5}
        {language === 'RU-RU' ? 'г/гл' : 'g/hL'})
      </div>
      {["mineralFraisFruitBlanc", "floralElegant"].includes(
        aromaticProfile
      ) && (
        <div className="inputDiv">
          <p>
            <FormattedMessage id={"charmAgeMessage"} />
          </p>
          <span className="productValue">
            {roundTo2Digit(
              aromaticProfile === "floralElegant"
                ? computedData?.interStep.floralAgeCharm
                : computedData?.interStep.ageCharm
            )}
          </span>
          <span className="productUnit">{translations[language!]['kg']}</span>
          <br />
          <FormattedMessage id="of" />
          <span className="productName"> CHARM® AGE</span> (
          {aromaticProfile === "floralElegant" ? 4 : 5}
          {language === 'RU-RU' ? 'г/гл' : 'g/hL'})
        </div>
      )}
      {aromaticProfile === "tresFruite" && (
        <div className="inputDiv">
          <p>
            <FormattedMessage id={"charmRedoxMessage"} />
          </p>
          <span className="productValue">
            {roundTo1Digit(computedData?.interStep.redoxCharm)}
          </span>
          <span className="productUnit">{translations[language!]['kg']}</span>
          <br />
          <FormattedMessage id="of" />
          <span className="productName"> CHARM® REDOX T</span> (3 {language === 'RU-RU' ? 'г/гл' : 'g/hL'})
        </div>
      )}
    </div>
  );
};

export default CharmatSugarResult;
