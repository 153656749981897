import { IonCard } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import translations from '../../modules/translations';
import { InputNumberField } from "./inputFields";

interface Result {
  freeEthanol: number | undefined;
  freeCombiningSO2: number | undefined;
  SO2ToAdd: number | undefined;
  finalTotalSO2: number | undefined;
}

const computeResult: (
  ethanol: number,
  freeSO2: number,
  totalSO2: number,
  sugar: number,
  wantedFreeSO2: number
) => Result = (ethanol, freeSO2, totalSO2, sugar, wantedFreeSO2) => {
  const freeEthanol = ethanol - totalSO2 / 1.7 - sugar * 1;
  const freeCombiningSO2 = freeEthanol * 1.5;
  const SO2ToAdd = wantedFreeSO2 + freeCombiningSO2 - freeSO2;
  const finalTotalSO2 = SO2ToAdd + totalSO2;
  return {
    freeEthanol: Math.round(freeEthanol * 10) / 10,
    freeCombiningSO2: Math.round(freeCombiningSO2 * 10) / 10,
    SO2ToAdd: Math.round(SO2ToAdd * 10) / 10,
    finalTotalSO2: Math.round(finalTotalSO2 * 10) / 10,
  };
};

const SoufreAcetaldehyde: React.FC = () => {

  const language = localStorage.getItem('language') as string;

  const [ethanol, setEthanol] = useState<number>(0);
  const [freeSO2, setFreeSO2] = useState<number>(0);
  const [totalSO2, setTotalSO2] = useState<number>(0);
  const [sugar, setSugar] = useState<number>(0);
  const [wantedFreeSO2, setWantedFreeSO2] = useState<number>(0);
  const [result, setResults] = useState<Result | undefined>(undefined);
  useEffect(() => {
    if (
      ethanol > 0 &&
      freeSO2 > 0 &&
      totalSO2 > 0 &&
      sugar > 0 &&
      wantedFreeSO2 > 0
    ) {
      setResults(
        computeResult(ethanol, freeSO2, totalSO2, sugar, wantedFreeSO2)
      );
    } else {
      setResults(undefined);
    }
  }, [ethanol, freeSO2, totalSO2, sugar, wantedFreeSO2]);

  return (
    <div>
      <IonCard style={{ textAlign: "left", padding: "5%" }}>
        <div style={{ marginBottom: "5%" }}>
          <div style={{ marginBottom: "2%" }}>
            <span className="inputTitle">
              <FormattedMessage id="totalEthanal" />
            </span>
            <br />
            <span>({language === 'RU-RU' ? 'мг' : 'mg'}/{translations[language!]["L"]})</span>
          </div>
          <InputNumberField value={ethanol} setValue={setEthanol} />
        </div>
        <div style={{ marginBottom: "5%" }}>
          <div style={{ marginBottom: "2%" }}>
            <span className="inputTitle">
              <FormattedMessage id="freeSO2" values={{ sub2: <sub>2</sub> }} />
            </span>
            <br />
            <span>({language === 'RU-RU' ? 'мг' : 'mg'}/{translations[language!]["L"]})</span>
          </div>
          <InputNumberField value={freeSO2} setValue={setFreeSO2} />
        </div>
        <div style={{ marginBottom: "5%" }}>
          <div style={{ marginBottom: "2%" }}>
            <span className="inputTitle">
              <FormattedMessage id="totalSO2" values={{ sub2: <sub>2</sub> }} />
            </span>
            <br />
            <span>({language === 'RU-RU' ? 'мг' : 'mg'}/{translations[language!]["L"]})</span>
          </div>
          <InputNumberField value={totalSO2} setValue={setTotalSO2} />
        </div>
        <div style={{ marginBottom: "5%" }}>
          <div style={{ marginBottom: "2%" }}>
            <span className="inputTitle">
              <FormattedMessage id="residualSugar" />
            </span>
            <br />
            <span>{translations[language]['g']}/{translations[language!]["L"]})</span>
          </div>
          <InputNumberField value={sugar} setValue={setSugar} />
        </div>
        <div style={{ marginBottom: "5%" }}>
          <div style={{ marginBottom: "2%" }}>
            <span className="inputTitle">
              <FormattedMessage
                id="wantedFreeSO2"
                values={{ sub2: <sub>2</sub> }}
              />
            </span>
            <br />
            <span>({language === 'RU-RU' ? 'мг' : 'mg'}/{translations[language!]["L"]})</span>
          </div>
          <InputNumberField value={wantedFreeSO2} setValue={setWantedFreeSO2} />
        </div>
      </IonCard>
      {result && (
        <div>
          <div
            className="inputTitle"
            style={{ textAlign: "left", marginLeft: "5%" }}
          >
            <FormattedMessage id="results" /> :
          </div>
          <div className="inputDiv">
            <FormattedMessage id="freeEthanal" />
            <br />
            <span className="productValue">{result.freeEthanol}</span>{" "}
            <span className="productUnit">{language === 'RU-RU' ? 'мг' : 'mg'}/{translations[language!]["L"]}</span>
            <br />
          </div>
          <div className="inputDiv">
            <FormattedMessage
              id="freeCombiningSO2"
              values={{ sub2: <sub>2</sub> }}
            />
            <br />
            <span className="productValue">{result.freeCombiningSO2}</span>{" "}
            <span className="productUnit">{language === 'RU-RU' ? 'мг' : 'mg'}/{translations[language!]["L"]}</span>
            <br />
          </div>
          <div className="inputDiv">
            <FormattedMessage id="SO2ToAdd" values={{ sub2: <sub>2</sub> }} />
            <br />
            <span className="productValue">{result.SO2ToAdd}</span>{" "}
            <span className="productUnit">{language === 'RU-RU' ? 'мг' : 'mg'}/{translations[language!]["L"]}</span>
            <br />
          </div>
          <div className="inputDiv">
            <FormattedMessage
              id="finalTotalSO2"
              values={{ sub2: <sub>2</sub> }}
            />
            <br />
            <span className="productValue">{result.finalTotalSO2}</span>{" "}
            <span className="productUnit">{language === 'RU-RU' ? 'мг' : 'mg'}/{translations[language!]["L"]}</span>
            <br />
          </div>
        </div>
      )}
    </div>
  );
};

export default SoufreAcetaldehyde;
