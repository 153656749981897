import { IonIcon } from "@ionic/react";
import { close, informationCircle } from "ionicons/icons";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import translations from '../../../modules/translations';
import FML from "../../../types/FML";
import MultiChoicesDisplay from "../multiChoicesDisplay";
import MultiChoicesDisplaySeveralValues from "../multiChoicesDisplaySeveralValues";
import SimpleChoiceSeveralValues from "../simpleChoiceSeveralValues";

interface Props {
  computedData: FML | undefined;
  cepage: string;
}

const FMLSecondStep: ({ computedData, cepage }: Props) => JSX.Element = ({
  computedData,
  cepage,
}) => {

  const language = localStorage.getItem('language');

  const traductions = translations[language as string];

  const [toggleCatalMessage, setToggleCatalMessage] = useState<boolean>(false);
  const [toggleNoirActivaMessage, setToggleNoirActivaMessage] =
    useState<boolean>(false);
  return (
    <div>
      <p>
        <span className="actionTitle">
          <FormattedMessage id="theCuvee" />
        </span>{" "}
        -{" "}
        <span className="actionMethod">
          <FormattedMessage id="add" />
        </span>
      </p>
      <div className="actionDiv">
        <MultiChoicesDisplay
          setSelectedProduct={undefined}
          choicesList={[
            {
              name: "SULFIVENDANGE",
              unit: "cL",
              value: computedData?.cuvee.firstStep.sulfivendangeVolume,
              description: cepage === "chardonnay" ? ` (5 ${language === 'RU-RU' ? 'сл/гл' : 'cL/hL'})` : `(6 ${language === 'RU-RU' ? 'сл/гл' : 'cL/hL'})`,
            },
            {
              name: "SULFOSSOL® 150",
              unit: "cL",
              value: computedData?.cuvee.firstStep.sulfossolVolume,
              description:
                cepage === "chardonnay" ? ` (3,5 ${language === 'RU-RU' ? 'сл/гл' : 'cL/hL'})` : `(4 ${language === 'RU-RU' ? 'сл/гл' : 'cL/hL'})`,
            },
          ]}
        />
      </div>
      <div className="actionDiv" style={{ position: "relative" }}>
        <IonIcon
          onClick={() => {
            setToggleCatalMessage(!toggleCatalMessage);
          }}
          size="large"
          color="warning"
          icon={informationCircle}
          style={{
            position: "absolute",
            left: "0%",
            top: "20%",
            zIndex: "1000",
          }}
        />
        <SimpleChoiceSeveralValues
          action={{
            title: undefined,
            method: undefined,
            product: {
              name: "E CATAL® CLARIF",
              values: [
                computedData?.cuvee.secondStep.catalMinWeight,
                computedData?.cuvee.secondStep.catalMaxWeight,
              ],
              description: ` (1 ${traductions["to"]} 2 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
              unit: "g",
            },
          }}
        />
      </div>
      {toggleCatalMessage && (
        <div
          style={{
            backgroundColor: "#1B1D26",
            color: "white",
            height: "86px",
            width: "100%",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 5%",
            position: "relative",
          }}
        >
          <IonIcon
            icon={close}
            style={{ position: "absolute", top: "5px", right: "5px" }}
            onClick={() => setToggleCatalMessage(false)}
          />
          <FormattedMessage id="FMLCatalMessage" />
        </div>
      )}
      {cepage === "pinotNoirMeunier" && (
        <>
          <div className="actionDiv" style={{ position: "relative" }}>
            <IonIcon
              onClick={() => {
                setToggleNoirActivaMessage(!toggleNoirActivaMessage);
              }}
              size="large"
              color="warning"
              icon={informationCircle}
              style={{
                position: "absolute",
                left: "0%",
                top: "20%",
                zIndex: "1000",
              }}
            />
            <SimpleChoiceSeveralValues
              action={{
                title: undefined,
                method: undefined,
                product: {
                  name: `NOIR ACTIVA + ${language === 'DE-DE' ? "Granulat" : "Granulé"}`,
                  values: [
                    computedData?.cuvee.fourthStep?.activa.minWeight,
                    computedData?.cuvee.fourthStep?.activa.maxWeight,
                  ],
                  unit: "kg",
                  description: `(20 ${traductions["to"]} 50 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
                },
              }}
            />
          </div>
          {toggleNoirActivaMessage && (
            <div
              style={{
                backgroundColor: "#1B1D26",
                color: "white",
                height: "86px",
                width: "100%",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0 5%",
                position: "relative",
              }}
            >
              <IonIcon
                icon={close}
                style={{ position: "absolute", top: "5px", right: "5px" }}
                onClick={() => setToggleNoirActivaMessage(false)}
              />
              <FormattedMessage id="FMLToDelay" />
            </div>
          )}
        </>
      )}
      <div className="actionDiv">
        <MultiChoicesDisplaySeveralValues
          choicesList={[
            {
              name: "POLYGREEN®",
              unit: "kg",
              values: [
                computedData?.cuvee.thirdStep.polygreen.minWeight,
                computedData?.cuvee.thirdStep.polygreen.maxWeight,
              ],
              description: `(30 ${traductions["to"]} 50 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
            },
            {
              name: "ORIGIN® F-MAX",
              unit: "kg",
              values: [
                computedData?.cuvee.thirdStep.origin.minWeight,
                computedData?.cuvee.thirdStep.origin.maxWeight,
              ],
              description: `(30 ${traductions["to"]} 50 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
            },
          ]}
        />
        <p>
          <FormattedMessage id="FMLThirdStepMessage" />
        </p>
      </div>
    </div>
  );
};

export default FMLSecondStep;
