import { IonCard, IonIcon } from "@ionic/react";
import { returnUpBack } from "ionicons/icons";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import SoufreAcetaldehyde from "../component/soufreAcetaldehyde";

const SoufreAcetaldehydePage : React.FC = () => {
  const history = useHistory();
  return (
    <div>
      <div style={{fontFamily: 'Abril Fatface', fontSize: "24px", margin: "5% 5% 1% 5%", color:"white"}}><FormattedMessage id='soufreAcetaldehydeTitle'/></div>
      <div style={{fontFamily: 'Lato', fontSize: '18px', fontWeight: 100, width: "70%", marginLeft: "15%", marginBottom: "10%", color: "white"}}><FormattedMessage id='soufreAcetaldehydeSubtitle'/></div>
      <IonCard
        style={{
          color: "black",
          width: "40%",
          padding: "5%",
          fontFamily: "Lato",
          fontSize: "15pt",
          fontWeight: 600,
        }}
        onClick={() => {
          history.goBack();
        }}
      > <IonIcon icon={returnUpBack} /> <FormattedMessage id="goBack" /></IonCard>
      <SoufreAcetaldehyde/>
    </div>
  );
};

export default SoufreAcetaldehydePage;
