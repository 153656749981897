type Translation = {
  [key: string]: string;
};

const Ru: Translation = {
  "fr-FR": "Французский",
  "en-GB": "Английский",
  "es-ES": "Испанский",
  "it-IT": "Итальянский",

  //Version ordinateur
  notAvailableForComputerTitle:
    "Компьютерная версия приложения SOEC не доступна",
  notAvailableForComputerContent:
    "К сожалению, данное приложение не доступно для компьютера. Для получения доступа сканируйте код ниже или пройдите по следующей ссылке URL: {soecUrl} на планшете или мобильном телефоне.. Вы сможете добавить данное приложение на ваше устройство, следуя инструкциям pop-up",

  //installer la PWA
  addSOECToApp: "Добавить SOEC в Ваши приложения !",
  appSOECToAppInformations:
    "Вы можете добавить наше приложение на Ваше мобильное устройство. Для этого нажмите на кнопку ниже",
  appSOECToAppInformationsIos:
    "Чтобы добавить приложение на Ваш iPhone, достаточно нажать на иконку :",
  appSOECToAppInformationsIosFinalStep:
    "Затем, нажать на «На главный экран» :",
  addApplication: "Добавить приложение",
  stayOnBrowser: "Оставаться в браузере",

  //Menu
  home: "Главная страница",
  tanks: "Мои емкости",
  protocoles: "Протоколы",
  calculators: "Калькуляторы",
  contact: "Нужна помощь ?",

  //Page d'accueil
  legalesNoticies: "Правовая информация",
  GTC: "CGV",
  calculatorsTools: "Вспомогательные инструменты",
  startA: "Начать",
  protocole: "протокол",
  allTools: "Все инструменты",
  useCalculator: "Использовать калькулятор",
  handleTank: "Управлять емкостями",

  //Page d'accueil sans protocole
  noProtocoleFoundfirst: "Действующих протоколов не найдено",
  noProtocoleFoundsecond:
    "Мы не нашли протокол на Вашем устройстве.",
  noProtocoleFoundthird:
    "Нажать, чтобы начать Ваш первый протокол!",

  //Liste des protocoles disponibles
  protocols: "Протоколы",
  maceration: "Мацерация",
  traditionalMethod: "Классический метод",
  feedStockMethod: "Ускоренный резервуарный метод",
  martinottiMethod: "Метод Мартинотти",
  martinottiMethodDescription:
    "Данные протоколы по классическому методу разработаны в Шампани и адаптированы к местным особенностям. По всем вопросам обращайтесь за помощью к нашим энологам - консультантам",
  malolacticFermentation: "Винификация с яблочно-молочным брожением",
  malolacticFermentationBlocked: "Винификация без яблочно-молочного брожения",

  //Liste des calculateurs disponible
  decisionSupportToolsCaps: "ВСПОМОГАТЕЛЬНЫЕ ИНСТРУМЕНТЫ",
  calculationCorrection: "Подсчет коррекции",
  overpressureSparklingWine: "избыточного давления в игристом вине",
  calculationFoot: "Расчет",
  maloTankLowPh: "предварительной закваски при низком pH",
  calculationOf: "Расчет",
  sulfurAcetaldehyde: "Сера - Ацетальдегид",
  calculationOfThe: "Расчет",

  //Propre A  tous les protocoles
  selectTank: "Выбрать / добавить емкость",
  askSelectedTank: "«К какой емкости Вы желаете прикрепить данный протокол?",
  tankVolume: "Объем емкости :",
  tankVolumeInfo:
    "(Чтобы изменить объем, Вы можете изменить или добавить емкость.)",
  beginningDate: "Дата введения в работу",
  askBeginningDate: "Когда Вы желаете начать данный протокол ?",
  sampleName: "Номер образца и партии",
  askSampleName: "Для какого образца Вы желаете использовать данный протокол ?",
  beginProtocole: "НАЧАТЬ ПРИМЕНЯТЬ ПРОТОКОЛ",
  endProtocole: "Завершить протокол",
  sendResults: "Прислать результаты",
  goBackToBoard: "Вернуться к итоговой таблице",
  selectADate: "Выбрать дату",
  selectATank: "Выбрать емкость",
  selectACepage: "Выбрать сорт",
  selectAMacerationType : "Выбрать тип мацерации",
  selectAnAromaticProfile: "Выбрать профиль аромата",
  nameTheTank: "Номер емкости",
  errorMessageInput: "Обязательное поле",
  errorNumberInput: "Значение должно быть больше 0",
  errorSelectTank: "Пожалуйста, выберите емкость",
  errorDate: "Выберите дату",
  optionnal: "На выбор",

  //Protocole MacA©ration
  //Titre et sous-titre pour chacune des A©tapes
  TitleMacerationStep0_classic: "Мацерация",
  TitleMacerationStep1_classic: "Классическая мацерация",
  TitleMacerationStep2_classic: "Наполнение емкости",
  TitleMacerationStep3_classic: "Операции перед брожением",
  TitleMacerationStep4_classic: "Спиртовое брожение",
  TitleMacerationStep5_classic: "Спиртовое брожение",
  TitleMacerationStep6_classic: "Мацерация",
  TitleMacerationStep7_classic: "Шаптализация",
  TitleMacerationStep8_classic: "Слив, отжим и завершение СБ",
  TitleMacerationStep9_classic: "Яблочно-молочное брожение",
  TitleMacerationStep10_classic: "Завершение протокола",

  SubtitleMacerationStep0_classic: "КЛАССИЧЕСКИЙ МЕТОД",
  SubtitleMacerationStep1_classic: " ",
  SubtitleMacerationStep2_classic: "Классическая мацерация",
  SubtitleMacerationStep3_classic: "Классическая мацерация",
  SubtitleMacerationStep4_classic: "Классическая мацерация",
  SubtitleMacerationStep5_classic: "Классическая мацерация",
  SubtitleMacerationStep6_classic: "Классическая мацерация",
  SubtitleMacerationStep7_classic: "Классическая мацерация",
  SubtitleMacerationStep8_classic: "Классическая мацерация",
  SubtitleMacerationStep9_classic: "Классическая мацерация",
  SubtitleMacerationStep10_classic: "Классическая мацерация",

  TitleMacerationStep0_coldPreferm: "Мацерация",
  TitleMacerationStep1_coldPreferm: "Холодная мацерация, предшествующая брожению (МПБ)",
  TitleMacerationStep2_coldPreferm: "Наполнение емкости",
  TitleMacerationStep3_coldPreferm: "Заполнение емкости",
  TitleMacerationStep4_coldPreferm: "Холодная мацерация, предшествующая брожению",
  TitleMacerationStep5_coldPreferm: "Спиртовое брожение",
  TitleMacerationStep6_coldPreferm: "Мацерация",
  TitleMacerationStep7_coldPreferm: "Шаптализация",
  TitleMacerationStep8_coldPreferm: "Слив, отжим и завершение СБ",
  TitleMacerationStep9_coldPreferm: "Яблочно-молочное брожение",
  TitleMacerationStep10_coldPreferm: "Завершение протокола",

  SubtitleMacerationStep0_coldPreferm: "КЛАССИЧЕСКИЙ МЕТОД",
  SubtitleMacerationStep1_coldPreferm: " ",
  SubtitleMacerationStep2_coldPreferm: "Холодная мацерация, предшествующая брожению",
  SubtitleMacerationStep3_coldPreferm: "Холодная мацерация, предшествующая брожению",
  SubtitleMacerationStep4_coldPreferm: "Холодная мацерация, предшествующая брожению",
  SubtitleMacerationStep5_coldPreferm: "Холодная мацерация, предшествующая брожению",
  SubtitleMacerationStep6_coldPreferm: "Холодная мацерация, предшествующая брожению",
  SubtitleMacerationStep7_coldPreferm: "Холодная мацерация, предшествующая брожению",
  SubtitleMacerationStep8_coldPreferm: "Холодная мацерация, предшествующая брожению",
  SubtitleMacerationStep9_coldPreferm: "Холодная мацерация, предшествующая брожению",
  SubtitleMacerationStep10_coldPreferm: "Холодная мацерация, предшествующая брожению",

  //QuantitA© de raisin
  harvestWeight: "Задействованное количество винограда",
  askHarvestWeight: "Какой объем собранного винограда в кг?",
  //Type de macA©ration
  macerationType: "Тип мацерации",
  askMacerationType: "Какой тип мацерации Вы желаете применить ?",
  coldPreferm: "Перед брожением. холодная",
  classic: "Классическая",

  //Etape 1
  harvestSorting: "Сортировка собранного винограда",
  foulageEraflage: "Дробление - Удаление гребней",

  //Etape 2 pour prA©f. A  froid
  decreaseTemperature: "Понизить температуру",
  toDecreaseOf1Deg: "понизить на 1C",

  //Etape 3 pour prA©f. A  froid
  atT24: "A T+24ч",

  //Etape 4 pour pref. A  froid
  prefermTitle: "ХОЛОДНАЯ МАЦЕРАЦИЯ, ПРЕДШЕСТВУЮЩАЯ БРОЖЕНИЮ",
  pigeageWithoutOxygene: "Погружение мезги без аэрации",

  //Etape 6 pour les 2
  macerationCaps: "МАЦЕРАЦИЯ",
  remontagePigeage: "Полив мезги суслом и / или погружение мезги в сусло с аэрацией",
  decreaseVolume:
    "Уменьшать объемы, поднимаемые на поверхность, по мере уменьшения плотности.",

  //Etape 7 pour les 2
  chaptalisationMessage:
    "Для проведения шаптализации плотность должна быть меньше 1040.",

  //Etape 8 pour les 2
  devatting: "ОПУСТОШЕНИЕ ЕМКОСТИ",
  devattingContent: "Когда плотность близко к значению 1000",
  pressing: "ОТЖИМ",
  pressingContent:
    "Посоветуйтесь с Вашим энологом по поводу возможного ассамбляжа самотека с суслом из-под пресса.",
  endOfAF: "Завершение СБ",
  bottling: "Осуществить слив с осадка",

  //Etape 9 pour les 2
  malolacticFermentationCaps: "ЯБЛОЧНО-МОЛОЧНОЕ БРОЖЕНИЕ",

  //FML
  TitleFMLStep0: "Винификация с яблочно-молочным брожением",
  TitleFMLStep1: "Операции перед брожением",
  TitleFMLStep2: "Операции перед брожением",
  TitleFMLStep3: "Операции перед брожением",
  TitleFMLStep4: "Спиртовое брожение",
  TitleFMLStep5: "Спиртовое брожение",
  TitleFMLStep6: "Яблочно-молочное брожение",
  TitleFMLStep7: "Завершение протокола",

  SubtitleFMLStep0: "КЛАССИЧЕСКИЙ МЕТОД",
  SubtitleFMLStep1: "Винификация с яблочно-молочным брожением",
  SubtitleFMLStep2: "Винификация с яблочно-молочным брожением",
  SubtitleFMLStep3: "Винификация с яблочно-молочным брожением",
  SubtitleFMLStep4: "Винификация с яблочно-молочным брожением",
  SubtitleFMLStep5: "Винификация с яблочно-молочным брожением",
  SubtitleFMLStep6: "Винификация с яблочно-молочным брожением",
  SubtitleFMLStep7: "Винификация с яблочно-молочным брожением",

  //Type de cA©page
  cepageType: "Сорт образца",
  askCepageType: "Какой сорт образца ?",
  chardonnay: "Белые сорта - Блан де Нуар",
  pinotNoirMeunier: "Красные сорта",

  //Etape 1 pour les 2 (Sulfitage est dA©jA  dA©fini)
  FMLFirstStepSulfitingMessage:
    "Сразу после стекания сока, вносить порционно, 3 раза",
  coldPrefermOperations: "Обработка холодом, предшествующая брожению",
  whenNeeded: "По необходимости",

  //Etape 2
  theCuvee: "ПЕРВАЯ ФРАКЦИЯ",
  FMLToDelay:
    "Разбавить в холодной воде в пропорции 1 к 10 в внести в сусло сразу после расслоения в воде .",
  FMLCatalMessage:
    "Внести перед закачкой сусла в емкость для отстаивания.",
  FMLThirdStepMessage:
    "В зависимости от окисления сусла и экстракции цвета (хинон).",

  //Etape 3
  theTaille: "ВТОРАЯ ФРАКЦИЯ",

  //Etape 4
  FMLYeastingWater: "наполовину сусло / наполовину вода при  37°C",
  FMLYeastingMessage:
    "Необходимо поддерживать правильную температуру регидрации, не превышая 40°C . Иначе, это может вызвать гибель клеток.",
  FMLYeastingMessagePart1:
    "Подождать 20 минут а затем медленно перемешать, чтобы привести разводку в однородное состояние..",
  FMLYeastingMessagePart2:
    "Влить дрожжевую разводку в сусло в пропорции 1 к 10 или 20..",
  FMLYeastingMessagePart3:
    "Оставить бродить 6 - 12 часов. Затем влить в емкость..",

  //Etape 5
  yeastNutrition: "ДРОЖЖЕВАЯ ПОДКОРМКА",
  FMLYeastNutritionMessage:
    "Эта подкормка помогает лучшим образом контролировать спиртовое брожение и улучшить ароматический профиль вин.",

  //FML BloquA©e
  TitleFMLBloqueeStep0: "Винификация без яблочно-молочного брожения",
  TitleFMLBloqueeStep1: "Операции перед брожением",
  TitleFMLBloqueeStep2: "Операции перед брожением",
  TitleFMLBloqueeStep3: "Операции перед брожением",
  TitleFMLBloqueeStep4: "Спиртовое брожение",
  TitleFMLBloqueeStep5: "Спиртовое брожение",
  TitleFMLBloqueeStep6: "Завершение протокола",

  SubtitleFMLBloqueeStep0: "КЛАССИЧЕСКИЙ МЕТОД",
  SubtitleFMLBloqueeStep1: "Винификация без яблочно-молочного брожения",
  SubtitleFMLBloqueeStep2: "Винификация без яблочно-молочного брожения",
  SubtitleFMLBloqueeStep3: "Винификация без яблочно-молочного брожения",
  SubtitleFMLBloqueeStep4: "Винификация без яблочно-молочного брожения",
  SubtitleFMLBloqueeStep5: "Винификация без яблочно-молочного брожения",
  SubtitleFMLBloqueeStep6: "Винификация без яблочно-молочного брожения",

  //Charmat
  TitleCharmatStep0: "Метод Мартинотти",
  TitleCharmatStep1: "1.1 - Регидратация",
  TitleCharmatStep2: "1.2 - Адаптация",
  TitleCharmatStep3: "1.2 - Адаптация",
  TitleCharmatStep4: "1.2 - Адаптация",
  TitleCharmatStep5: "1.3 - Дрожжевая разводка",
  TitleCharmatStep6: "1.3 - Дрожжевая разводка",
  TitleCharmatStep7: "1.3 - Дрожжевая разводка",
  TitleCharmatStep8: "1.3 - Дрожжевая разводка",
  TitleCharmatStep9: "Промежуточный этап",
  TitleCharmatStep10: "Промежуточный этап",
  TitleCharmatStep11: "1.4 - Ключевые этапы спиртового брожения",
  TitleCharmatStep12: "2.1 - Ключевые этапы выдержки",
  TitleCharmatStep13: "2.1 - Ключевые этапы выдержки",
  TitleCharmatStep14: "Завершение протокола",

  SubtitleCharmatStep0: "Резервуарный метод",
  SubtitleCharmatStep1: "Метод Мартинотти - Старт",
  SubtitleCharmatStep2: "Метод Мартинотти - Старт",
  SubtitleCharmatStep3: "Метод Мартинотти - Старт",
  SubtitleCharmatStep4: "Метод Мартинотти - Старт",
  SubtitleCharmatStep5: "Метод Мартинотти - Старт",
  SubtitleCharmatStep6: "Метод Мартинотти - Старт",
  SubtitleCharmatStep7: "Метод Мартинотти - Старт",
  SubtitleCharmatStep8: "Метод Мартинотти - Старт",
  SubtitleCharmatStep9: "Метод Мартинотти - Старт",
  SubtitleCharmatStep10: "Метод Мартинотти - Старт",
  SubtitleCharmatStep11: "Метод Мартинотти - Старт",
  SubtitleCharmatStep12: "Метод Мартинотти - Старт",
  SubtitleCharmatStep13: "Метод Мартинотти - Старт",
  SubtitleCharmatStep14: "Метод Мартинотти - Старт",

  mineralFraisFruitBlanc: "Минеральные ноты, свежесть, белые фрукты ",
  tresFruite: "Очень много ягодных нот",
  floralElegant: "Цветочные и элегантные ноты",
  cremeuxVarietal: "Жирные и сортовые ноты",

  //Etape 1
  charmatFirstStepFirstMessage:
    "Для начала, доведите виноматериал до температуры 12°C.",
  water35: "воды при  35-36°C", //changement - correction
  water30: "воды при  30°C", //changement - correction
  water25: "воды при  25°C", //changement - correction
  water20: "воды при  20°C", //changement - correction
  charmatFirstStepFirstBlackMessage:
    "Необходимо поддерживать правильную температуру регидрации, не превышая 40°C . Иначе, это может вызвать гибель клеток.",
  charmatFirstStepSecondBlackMessage:
    "Интенсивно перемешать во избежании слипания дрожжей.",
  aromaticProfile: "Желаемый ароматический профиль",
  askAromaticProfile: "Какой ароматический профил Вы желаете для этого вина ?",

  //Etape 2
  sugar: "сахарa", //changement - correction
  stepCaps: "ЭТАП ",
  step: "Этап ",
  charmatSecondStepMessageFirstStep:
    "Смешать в отдельном контейнере элементы, указанные ниже",
  charmatSecondStepMessageSecondStep:
    "Налить немного виноматериала и воды в препарат.",
  charmatSecondStepMessageThirdStep:
    "Добавить следующие препараты для адаптации.",
  alreadyPrepared: "уже готовые",
  acclimatationBasicWine12: "виноматериала (при 12°C)",
  acclimatationBasicWine14: "виноматериала (при 14°C)",
  feedStockBasicWine: "виноматериала (при 15-16°C)",
  feedStockBasicWine16: "виноматериала (при16°C)",

  //Etape 3
  charmatThirdStepFirstMessage:
    "На протяжении 3 дней приготовления разводки контролировать следующее :",
  charmatThirdStepSecondMessage:
    "Плотность не должна опускаться ниже 1005 (в случае уменьшения, добавить немного сахара)",
  charmatThirdStepThirdMessage:
    "Разница температур между акклиматизационной средой и вином не должна превышать 5-7°C",
  charmatThirdStepFourthMessage:
    "Температура добавляемого в емкость  виноматериала должна быть в диапазоне 16 -18°C включительно°C",

  //Etape 4
  charmInfo: "Рекомендовано для улучшения потенциала к выдержке",
  charmatFourthStepMessageFirstStep:
    "По прошествии часа объем виноматериала удвоится, но температура должна оставаться около 18-20°C.",
  charmatFourthStepMessageSecondStep:
    "После 24 часов предыдущая операция возобновляется, объемы удваиваются.",
  charmatFourthStepMessageThirdStep:
    "После 24 дополнительных часов добавляется подкормка, и предыдущая операция возобновляется.",
  charmatFourthStepLastMessage:
    "После старта брожения добавить весь подготовленный объем в емкость",
  startYInfo: "Только при повышающейся концентрации алкоголя",

  //Etape 5
  sugarQuantity: "Количество остаточного сахара",
  sugarQuantityAsk:
    "Какое количество остаточного сахара Вам необходимо ? (в г/л)",

  //Etape 6
  charmatSugarResultSugar: "сахара (25 г/л + остаточный сахар)",
  charmatSugarStartFreshMessage:
    "Рекомендуется добавить START® Y FRESH (20 г/гл) чтобы обеспечить питание дрожжей",
  mineralFraisFruitBlancSubliCharmMessage:
    "Для усиления ягодных ароматов добавьте :",
  cremeuxVarietalSubliCharmMessage: "Чтобы сделать вкус более объемным :",
  floralElegantSubliCharmMessage: "Чтобы сделать вкус более объемным :",
  charmElevageMessage:
    "Чтобы сделать вкус более объемным, жирным и улучшить качество пены, добавьте :",
  tresFruiteCharmElevageMessage:
    "Чтобы сделать вкус более объемным, ягодным и улучшить качество пены, добавьте :",
  cremeuxVarietalCharmElevageMessage:
    "Чтобы улучшит  баланс REDOX и MP :",
  charmAgeMessage:
    "Чтобы продлить срок хранения и сохранить тонкие ароматы, добавьте :",
  charmActivMessage: "Чтобы гарантировать правильное питание дрожжей :",
  charmRedoxMessage: "Для профилактики редукции :",

  //Etape 7
  decreaseTemperatureUnder15: "Понизить температуру менее 15°C",
  addProductsUnderPressure:
    "Если у Вас есть возможность добавлять препараты под давлением 2,5 бар, добавьте :",
  fermentRecommandedTemperature:
    "Рекомендуемая температура брожения : 14-16°C",
  reductionAromaCase: "При появлении запаха редукции, добавьте :",
  pressureReached:
    "Когда давление достигает 4,3 - 4,6 атм. (в расчете при 20°C), снизить температуру менее 2°C для остановки брожения.",
  necessaryTimeDependingEquipment:
    "Это занимает около 1 -  2 дней, в зависимости от оборудования.",
  setTemperatureTo0:
    "Настроить температуру на 0°C и фильтровать вино (тангенциальная фильтрация или мембрана  1 ?m).",

  //Etape 8
  duringElevage: "Во время выдержки ремюаж должен :",
  firstOrder: "Длиться15 дней (2x 15 минут каждый день)",
  secondOrder: "Каждые 3 дня на протяжении 30 следующих дней (1x20 минут)",
  thirdOrder: "Раз в неделю на протяжении двух последних недель (1x15 минут)",

  //Etape 9
  elevageMessage:
    "Выдержка при минимальной температуре 6-8°C на протяжении 2 месяцев,применяя на выбор (в зависимости от результатов дегустации) :",


  //Calculateur SO2 MolA©culaire
  so2MolecularTitle: "Подсчет содержания SO{sub2} молекул.",
  so2MolecularSubTitle:
    "Внимание! SO{sub2} молекул. обладает антисептическим свойством при концентрации выше1 мг/л.",
  so2Amount: "Содержание SO{sub2} молекул.",
  pH: "pH",
  good: "Розлив возможен",
  risquy: "Риск",
  lethal: "Опасно",

  //Calculateur Soufre - AcA©taldA©hyde
  soufreAcetaldehydeTitle: "Сера - Ацетальдегид",
  soufreAcetaldehydeSubtitle: "Широко применяется в методе Мартинотти",
  so2Molecular: "SO{sub2} молекул.",
  molecular: "молекул.",
  totalEthanal: "Этаналь общий",
  freeSO2: "SO{sub2} свободн.",
  totalSO2: "SO{sub2} общ.",
  residualSugar: "Остаточный сахар",
  wantedFreeSO2: "Заданное значение SO{sub2} свободного",
  freeEthanal: "Этаналь свободный",
  freeCombiningSO2: "SO{sub2} свободно. соединяющ.",
  SO2ToAdd: "SO{sub2} внестиr",
  finalTotalSO2: "Итоговое значение общего SO{sub2}",

  //Calculateur Chaptalisation
  chaptalization: "Шаптализация",
  density: "Плотность",
  wantedDegree: "Желаемый градус",
  chaptalisationDescription:
    "Многолетний опыт показывает, что для достижения итогового градуса 11% vol. желательно поставить цель 11% vol. для Шардонне и 11,2% vol. для Пино Нуар и Пино Менье.",
  results: "Результаты",
  chaptalisationDensityError:
    "Введите, пожалуйста, объем, превышающий или равный1064.",
  chaptalisationResult:
    "Количество сахарного песка для достижения желаемого градуса алкоголя % vol. :",
  chaptalisationWarningTitle:
    "Для проведения шаптализации плотность должна быть меньше 1040.",
  chaptalisationWarningContent:
    "Шаптализация: нормативные уведомления.",

  //Calculateur Pied de Cuve Malo
  maloTankTitle: "Предварительная закваска ЯМБ при низком pH",
  cuveMaloTitle: "Протокол приготовления предварительной закваски ЯМБ с суслом",
  cuveMaloContent:
    "Согласно протоколу, разработанному техническим отделом CIVC, опубликованному в Vigneron Champenois N°6 - Июнь 2009 года",
  totalWineToSeed: "Общий объем вина для посева",
  inHectoliter: "В гектолитрах (ГЛ)",
  globaldata: "Общие данные",
  rehydratation: "Регидратация",
  reactivationEnvironment: "Среда для реактивирования",
  feedStock: "Закваска",
  reactivationEnvironmentVolume: "Объем среды для реактивирования:",
  feedStockVolume: "Объем закваски:",
  bacteries: "Применяемые бактерии: ",
  activator: "Применяемый активатор : ",
  dryYeast: "Применяемые сухие дрожжи : ",
  globalDataFeedStockMessage:
    "Сусло, используемое для реактивации закваски, должно быть освобождено от твердых частиц, предпочтительно второго отжима (тай), без шаптализации и с сульфитацией максимум 4 г/гл (или 2,7 сл/гл SULFOSSOL® 150).",
  maloTankMiddleMessage:
    "Закваска и среда для реактивации должны готовиться одновременно",
  delay: "Насыпать",
  litersOfWater: "л. воды   25°C",
  rehydratationMessage:
    "Интенсивно перемешать во избежании формирования сгустка. Дать настояться 15 -  20 минут, затем снова перемешать перед приготовлением среды для реактивации.",
  preparedBefore: "предварительно приготовлены",
  rehydratedBacteries: "Регидратированные бактерии",
  settlingMust: "Сусло без твердых частиц, слабо сульфитированное (половина дозы)",
  ofWaterAt25: "воды при  температуре 25°C",
  sprinklingDryYeast:
    "Насыпать сухие дрожжи SOEC® 1971 без регидратации", //changement - correction
  inTotal: "итого",
  reactivationEnvironmentMessage:
    "Пожалуйста, поддерживайте температуру на уровне 25°C во время реактивации. Затем, как только реактивационная среда будет готова (яблочная кислота < 0,5 г/л), удвойте ее объем, добавив бродящее сусло закваски, для получения объема, равного :",
  duration: "Продолжительность",
  mustWaterMixing:
    "смесь сусла и горячей воды, чтобы получить температуру 35°C.",
  feedStockFirstMessagePart1:
    "(пожалуйста, соблюдайте указанные выше температуры. В частности, не превышать 40°C. Иначе, можно уничтожить активные клетки).. Интенсивно перемешать во избежании формирования сгустка.",
  feedStockFirstMessagePart2:
    "Дать настояться 20 -  30 минут, затем снова перемешать перед приготовлением закваски.",
  rehydratedYeasts: "Сухие дрожжи",
  must: "Сусло",
  feedStockVolumeComplement:
    "Внесение реактивационной среды в закваску после 3-х дней",
  feedStockTotalVolume: "Общий объем закваски:",
  feedStockSecondMessage:
    "Поддерживать температуру закваски 25°C во время С. Б. и затем 20°C во время ЯМБ. Определить количество яблочной кислоты. Контроль осуществляется через 6-7 дней, затем каждые 2 дня",
  feedStockFinalMessagePart1:
    "Посев емкостей осуществляется из расчета 3% через верх емкостей,;без перемешивания, как только 2/3 яблочной кислоты в закваске переработано..",
  feedStockFinalMessagePart2:
    "Добавление в вино может осуществляться в течение и по окончании С. Б. с или без предварительного снятия с осадка..",
  feedStockFinalMessagePart3:
    "Чтобы использовать тепловую инерцию емкости, рекомендуется выключить регулирование температуры при 1005 - 1010..",
  feedStockFinalMessagePart4:
    "Затем, поддерживать температуру в пределах 18°C и 20°C. Первый контроль делается, минимум, через две недели после посева.",
  maloTankFinalMessage:
    "Данные значения даются для информации и ни в коем случае не отменяют решение Вашего энолога - консультанта..",

  //Calculateur Correction de la surpression d'un vin effervescent
  sparklinWineOverpressureTitle:
    "Коррекция избыточного давления в бутылке",
  sparklingWineOverpressureMessage:
    "Подсчитайте коррекцию избыточного давления в бутылке при заданной температуре",
  overpressure: "Избыточное давление",
  temperature: "Температура",

  //Gestion de Cuve
  managementTanks: "Управление моими емкостями",
  volume: "Объем",
  currentProtocol: "Действующий протокол",
  startProtocol: "Начать протокол",
  continueProtocol: "Продолжить протокол",
  stopProtocol: "Завершить протокол",
  startNewProtocol: "Начать новый протокол",
  addATank: "Добавить емкость",
  noTankFound: "Не найдено ни одной емкости",
  textNoTankFound:
    "Мы не нашли емкость на Вашем устройстве. Добавьте одну емкость одним нажатием !",
  addingTank: "Добавление емкости",
  nameTank: "Номер Вашей емкости",
  volumeTank: "Объем емкости :",
  addTank: "Добавить емкость",
  dontAddTank: "Отменить добавление",
  confirmationModalDeleteTank: "Вы уверены, что хотите удалить емкость ?",
  subTitleConfirmationModalDeleteTank:
    "При удалении емкости прикрепленный к ней протокол будет удален",
  confirmationModalDeleteProtocol:
    "Вы уверены, что хотите завершить протокол ?",
  confirmationModalNewProtocol:
    "Вы уверены, что хотите начать новый протокол ?",
  confirmationModalDeleteProtocolInProgress:
    "Вы уверены, что хотите удалить текущий протокол ?",
  protocoleType: "Тип протокола : ",
  sampleTitle: "Название образца",
  date: "Дата",
  tank: "Емкость",
  sampleNameOnly: "Номер образца",
  FMLstep1: "Прессование",
  FMLstep2: "Первая фракция",
  FMLstep3: "Вторая фракция",
  FMLstep4: "Спиртовое брожение",
  FMLstep5: "Внесение дрожжей",
  FMLstep6: "Подкормка дрожжей",
  FMLstep7: "Расчет, закваска",
  Macerationstep1: "Сортировка винограда - Дробление - Отделение гребней",
  Macerationstep2: "Брожение на мезге",
  Macerationstep3: "Добавление фермента",
  Macerationstep4: "Холодная мацерация, предшествующая брожению",
  Macerationstep5: "Спиртовое брожение",
  Macerationstep6: "Мацерация",
  Macerationstep7: "Шаптализация",
  Macerationstep8: "Опустошение чана - Прессование",
  Macerationstep9: "Яблочно-молочное брожение",
  Charmatstep1: "1.1 - Регидратация",
  Charmatstep2: "1.2 - Адаптация/Этап 1",
  Charmatstep3: "2.2 - Адаптация/Этап 1",
  Charmatstep4: "3.2 - Адаптация/Этап 1",
  Charmatstep5: "1.3 - Дрожжевая разводка",
  Charmatstep6: "1.3 - Закваска/Этап 1",
  Charmatstep7: "2.3 - Закваска/Этап 1",
  Charmatstep8: "3.3 - Закваска/Этап 1",
  Charmatstep9: "Промежуточный этап",
  Charmatstep10: "Промежуточный этап",
  Charmatstep11: "Промежуточный этап",
  Charmatstep12: "1.4 - Ключевые этапы спиртового брожения",
  Charmatstep13: "2.1 - Ключевые этапы выдержки",
  Charmatstep14: "2.1 - Ключевые этапы выдержки",
  protocolCompleted: "Протокол завершен",
  continue: "Продолжить",
  seeResults: "Посмотреть результаты",
  startnewProtocol: "Начать новый протокол",
  runningProtocole: "Действующий протокол",

  goToNextStep: "Следующий этап",
  goBack: "Назад",
  none: "Нет",
  by: "по",
  of: " ", //changement - correction 
  in: "в",
  to: "на",
  and: "и",
  at: "на сайте",
  or: "или",
  yes: "Да",
  no: "Нет",
  between: "между",
  add: "Добавить",
  perDay: "в день",
  wait: "Подождать",
  minutes: "минут",
  hour: "час",
  hours: "час.",
  days: "дней (дня)",
  liters: "литров",
  inHectoliters: "в гектолитрах (ГЛ)",
  g: "г",
  kg: "кг",
  cL: "сл",
  L: "Л",
  dissolve: "Растворить",
  yeasting: "Внесение дрожжей",
  SULFITING: "СУЛЬФИТАЦИЯ",
  sulfiting: "Сульфитация",
  nutrition: "ПОДКОРМКА",
  tannin: "ОБРАБОТКА ТАНИНАМИ",
  enzyme: "ВНЕСЕНИЕ ФЕРМЕНТОВ",
  anthocyaninsStabilization: "СТАБИЛИЗАЦИЯ АНТОЦИАНОВ",
  addInTank: "Добавить напрямую в емкость",
  mustClarification: "Осветление сусла",

  FMLInterStep:
    "Осуществить промежуточный этап перед продолжением протокола",

  //Mentions LA©gales
  editor: "ИЗДАТЕЛЬ",
  websiteEditBy: "Это приложение издано",
  headOffice: "Юридический адрес",
  tel: "Тел.",
  fax: "Факс.",
  sharedCapital: "Уставной капитал",
  siretNumber: "N° SIRET",
  numero: "N°",
  TVA: "НДС",
  directorOfPublication: "Директор публикации",
  host: "ХОСТЕР",
  reproductiveRights: "ПРАВА НА ВОСПРОИЗВЕДЕНИЕ",
  reproductiveRightsContent:
    "Информация, опубликованная на данном сайта, является публичной. Воспроизведение информации данного сайта, кроме прямого копирования, не запрещается, при условии указания источника, ссылок на страницу или страницы. Информация с данного сайта не может быть использована в рекламных или коммерческих целях.",
  personnalData: "ПЕРСОНАЛЬНЫЕ ДАННЫЕ",
  personnalDataContent:
    "Информация должна быть использована в соответствии с действующим законодательством и рекомендациями Национальной комиссии по информатике и свободам (CNIL). Информация может быть использована в личных или профессиональных целях. Распространение и коммерческое использование не допускается",

  //Formulaire de contact
  titleContact: "Контакт",
  errorMessageContact: "Поле сообщения или адреса - пустое.",
  errorSendMail:
    "Ваше сообщение не было отправлено. Попробуйте позже.",
  send: "Отправить",
  chaptalisationWarning:
    "Количество вносимого сахарного песка - условно. Что касается шапатлизации, необходимо действовать в рамках действующего законодательства.",
  close: "Закрыть",
  inCaseOfProblem: "В случае возникновения проблем : ",
  laboSecretary: "Секреталрь лаборатории",
  reimsAgency: "Филиал в Реймсе",
  oenologist: "Энолог",
  oenologistf: "Энолог",
  technicalReferent: "Технический референт",
  oenologyReferent: "Референт энолог - консультант",
  barSurSeineAgency: "Филиал Бар-сюр-Сен",
  champagneAssistant: "Ассистент Шампань",
  mailSendWithSuccess:
    "Благодарим за Ваш запрос. Наши сотрудники обработают его в кратчайшие сроки.",
  scanMe: "Сканируйте QR-код, чтобы загрузить приложение на свой мобильный телефон",
  lastName: "Фамилия",
  firstName: "Имя", 
  phoneNumber: "номер телефона",
  mailAddress: "Эл. почта",
  postalAddress: "адрес",
  zipCode: "Codice Postale",
  city: "город",
  writeHere: "Твое сообщение",
}

;

export default Ru;
