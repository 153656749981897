import { IonCheckbox, IonIcon } from "@ionic/react";
import { close, informationCircle } from "ionicons/icons";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Charmat from "../../../types/Charmat";

interface Props {
  computedData: Charmat | undefined;
  step: number;
}

const roundTo1Digit = (x: number | undefined) => x && Math.round(x * 10) / 10;

//step between 6 and 8 included
const CharmatFourthStep: React.FC<Props> = ({ computedData, step }) => {
  const language = localStorage.getItem('language');
 
  const firstStepChoices = [
    {
      value: roundTo1Digit(computedData?.feedStock.step1StartY),
      name: "START® Y SP",
      unit: "kg",
    },
    {
      value: roundTo1Digit(computedData?.feedStock.step1Charm),
      name: "CHARM® AGE",
      unit: "kg",
    },
  ];

  const alreadyPrepared = (step: number) => {
    switch (step) {
      case 6:
        return roundTo1Digit(computedData?.feedStock.step1AlreadyPrepared);
      case 7:
        return roundTo1Digit(computedData?.feedStock.step2AlreadyPrepared);
      case 8:
        return roundTo1Digit(computedData?.feedStock.step3AlreadyPrepared);
    }
  };

  const basicWine = (step: number) => {
    switch (step) {
      case 6:
        return roundTo1Digit(computedData?.feedStock.step1BasicWine);
      case 7:
        return roundTo1Digit(computedData?.feedStock.step2BasicWine);
      case 8:
        return roundTo1Digit(computedData?.feedStock.step3BasicWine);
    }
  };

  const water = (step: number) => {
    switch (step) {
      case 6:
        return roundTo1Digit(computedData?.feedStock.step1Water);
      case 7:
        return roundTo1Digit(computedData?.feedStock.step2Water);
      case 8:
        return roundTo1Digit(computedData?.feedStock.step3Water);
    }
  };

  const sugar = (step: number) => {
    switch (step) {
      case 6:
        return roundTo1Digit(computedData?.feedStock.step1Sugar);
      case 7:
        return roundTo1Digit(computedData?.feedStock.step2Sugar);
      case 8:
        return roundTo1Digit(computedData?.feedStock.step3Sugar);
    }
  };

  const [firstStepSelected, setFirstStepSelected] = useState(
    firstStepChoices[0]
  );
  const [firstStepExtended, setFirstStepExtended] = useState(false);
  const [toggleCharmInfo, setToggleCharmInfo] = useState(false);
  const [toggleSTARTYInfo, setToggleSTARTYInfo] = useState(false);
  const [checked, setChecked] = useState(false);

  return (
    <div>
      <div className="inputDiv">
        <span className="actionTitle">
          <FormattedMessage id="stepCaps" /> {step - 5}{" "}
        </span>
      </div>
      <div
        className="inputDiv"
        style={{ marginLeft: "10%", marginRight: "10%" }}
      >
        {step === 6 && (
          <FormattedMessage id="charmatFourthStepMessageFirstStep" />
        )}
        {step === 7 && (
          <FormattedMessage id="charmatFourthStepMessageSecondStep" />
        )}
        {step === 8 && (
          <FormattedMessage id="charmatFourthStepMessageThirdStep" />
        )}
        <p>
          <span className="productValue">{alreadyPrepared(step)}</span>
          <span className="productUnit">
            <FormattedMessage id="liters" />
          </span>
          <br />
          {language === 'DE-DE' && <>
            Gäransatz <br/>
          </>}
          <FormattedMessage id="alreadyPrepared" />
        </p>
      </div>
      <div className="inputDiv">
        <span className="productValue">{basicWine(step)}</span>
        <span className="productUnit">
          <FormattedMessage id="liters" />
        </span>
        <br />
        <FormattedMessage
          id={`feedStockBasicWine${step - 5 === 3 ? "16" : ""}`}
        />
        <p>
          <span className="productValue">{sugar(step)}</span>
          <span className="productUnit">
            <FormattedMessage id="kg" />
          </span>
          <br />
          <FormattedMessage id="of" /> <FormattedMessage id="sugar" />
        </p>
      </div>
      {step === 6 && (
        <>
          <div className="inputDiv">
            {!firstStepExtended ? (
              <div
                style={{
                  position: "relative",
                  border: "#1B1D26 solid 1px",
                  borderRadius: "20pt",
                  marginLeft: "10%",
                  marginRight: "10%",
                }}
                onClick={() => setFirstStepExtended(true)}
              >
                {firstStepSelected.name === "CHARM® AGE" && (
                  <IonIcon
                    onClick={() => {
                      setToggleCharmInfo(!toggleCharmInfo);
                    }}
                    size="large"
                    color="warning"
                    icon={informationCircle}
                    style={{
                      position: "absolute",
                      left: "5%",
                      top: "20%",
                      zIndex: "1000",
                    }}
                  />
                )}
                <span className="productValue">{firstStepSelected.value}</span>{" "}
                <span className="productUnit">
                  <FormattedMessage id={firstStepSelected.unit} />{" "}
                </span>
                <br />
                <span className="transitionWord">
                  <FormattedMessage id="of" />
                </span>{" "}
                <span className="productName">{firstStepSelected.name}</span>
              </div>
            ) : (
              <div
                style={{
                  border: "#1B1D26 solid 1px",
                  borderRadius: "20pt",
                  marginLeft: "10%",
                  marginRight: "10%",
                }}
                onClick={() => setFirstStepExtended(false)}
              >
                {firstStepChoices.map((choice) => (
                  <div
                    key={choice.name}
                    style={{ position: "relative" }}
                    onClick={() => setFirstStepSelected(choice)}
                  >
                    {choice.name === "CHARM® AGE" && (
                      <IonIcon
                        onClick={() => {
                          setToggleCharmInfo(!toggleCharmInfo);
                        }}
                        size="large"
                        color="warning"
                        icon={informationCircle}
                        style={{
                          position: "absolute",
                          left: "5%",
                          top: "20%",
                          zIndex: "1000",
                        }}
                      />
                    )}
                    <span className="productValue">{choice.value}</span>{" "}
                    <span className="productUnit">
                      <FormattedMessage id={choice.unit} />{" "}
                    </span>
                    <br />
                    <span className="transitionWord">
                      <FormattedMessage id="of" />
                    </span>{" "}
                    <span className="productName">{choice.name}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
          {toggleCharmInfo && (
            <div
              style={{
                color: "white",
                backgroundColor: "#1B1D26",
                width: "100%",
                padding: "10% 5%",
                position: "relative",
              }}
            >
              <IonIcon
                icon={close}
                color="white"
                size="large"
                style={{ position: "absolute", top: "8%", right: "2%" }}
                onClick={() => setToggleCharmInfo(false)}
              />
              <FormattedMessage id="charmInfo" />
            </div>
          )}
        </>
      )}
      <div className="inputDiv">
        <span className="productValue">{water(step)}</span>
        <span className="productUnit">
          <FormattedMessage id="liters" />
        </span>
        <br />
        <FormattedMessage id="water20" />
      </div>
      {step === 8 && (
        <>
          <div className="inputDiv">
            <div
              style={{
                border: "1px solid #1B1D26",
                borderRadius: "20pt",
                margin: "0 10%",
                position: "relative",
                opacity: `${checked ? "100%" : "50%"}`,
              }}
            >
              <IonIcon
                onClick={() => {
                  setToggleSTARTYInfo(!toggleSTARTYInfo);
                }}
                size="large"
                color="warning"
                icon={informationCircle}
                style={{
                  position: "absolute",
                  left: "10%",
                  top: "30%",
                  zIndex: "1000",
                }}
              />
              <span className="productValue">
                {roundTo1Digit(computedData?.feedStock.step3StartY)}
              </span>
              <span className="productUnit">
                <FormattedMessage id="kg" />
              </span>
              <br />
              <span className="transitionWord">
                <FormattedMessage id="of" />
              </span>{" "}
              <span className="productName">START® Y SP</span>
              <IonCheckbox
                color="dark"
                onClick={() => setChecked(!checked)}
                style={{ position: "absolute", top: "35%", right: "10%" }}
              />
            </div>
          </div>
          {toggleSTARTYInfo && (
            <div
              style={{
                color: "white",
                backgroundColor: "#1B1D26",
                width: "100%",
                padding: "10% 5%",
                position: "relative",
              }}
            >
              <IonIcon
                icon={close}
                color="white"
                size="large"
                style={{ position: "absolute", top: "8%", right: "2%" }}
                onClick={() => setToggleSTARTYInfo(false)}
              />
              <FormattedMessage id="startYInfo" />
            </div>
          )}
        </>
      )}
      {step !== 8 && (
        <div className="inputDiv">
          <span className="productName">
            <FormattedMessage id="wait" /> 24 <FormattedMessage id="hours" />
          </span>
        </div>
      )}
      {step === 8 && (
        <div className="inputDiv">
          <FormattedMessage id="charmatFourthStepLastMessage" />
        </div>
      )}
    </div>
  );
};

export default CharmatFourthStep;
