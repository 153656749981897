import React from 'react';
import { FormattedMessage } from 'react-intl';

const CharmatElevageFirstStep : React.FC = () => {
  return (
    <div style={{textAlign: "left"}}>
      <div className="inputDiv">
        <span className='productName'><FormattedMessage id='duringElevage'/></span>
      </div>
      <div className="inputDiv">
        <FormattedMessage id='firstOrder'/>
      </div>
      <div style={{border: '1px solid #EDAE32', width: '20%', marginLeft: '10%'}}/>
      <div className="inputDiv">
        <FormattedMessage id='secondOrder'/>
      </div>
      <div style={{border: '1px solid #EDAE32', width: '20%', marginLeft: '10%'}}/>
      <div className="inputDiv">
        <FormattedMessage id='thirdOrder'/>
      </div>
    </div>
  );
};

export default CharmatElevageFirstStep;