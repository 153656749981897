import { IonCard, IonIcon } from "@ionic/react";
import { arrowForwardCircle, returnUpBack } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import { computeCharmat } from "../../methods/compute";
import Charmat from "../../types/Charmat";
import Tank from "../../types/Tank";
import CharmatFirstStep from "../component/Charmat/charmatFirstStep";
import CharmatSecondStep from "../component/Charmat/charmatSecondStep";
import CharmatThirdStep from "../component/Charmat/charmatThirdStep";
import CharmatZeroStep from "../component/Charmat/charmatZeroStep";
import CharmatFourthStep from "../component/Charmat/charmatFourthStep";
import CharmatSugarAsk from "../component/Charmat/charmatSugarAsk";
import CharmatSugarResult from "../component/Charmat/charmatSugarResult";
import CharmatKeyStep from "../component/Charmat/charmatKeySteps";
import CharmatElevageSecondStep from "../component/Charmat/charmatElevageSecondStep";
import CharmatElevageFirstStep from "../component/Charmat/charmatElevageFirstStep";
import EndOfProtocole from "../component/endOfProtocole";
import Protocole from "../../types/Protocole";
import translations from "../../modules/translations";

const compareWith = (o1: Tank, o2: Tank) => {
  return o1 && o2 ? o1.id === o2.id : o1 === o2;
};

interface Props {
  location: Location;
}

interface Location {
  state: State;
}

interface State {
  protocolId: string | undefined;
}
const ProtocoleCharmat: React.FC<Props> = (props) => {
  const [protocolId, setProtocolId] = useState<string | undefined>(
    props.location?.state?.protocolId
  );
  const protocoleSelected: Protocole | undefined = protocolId
    ? Object.keys(localStorage)
      .filter((key) => key === "protocol_" + protocolId)
      .map((key) => JSON.parse(localStorage[key]))[0]
    : undefined;
  const [step, setStep] = useState<number>(
    protocoleSelected ? protocoleSelected.step : 0
  );
  const savedStep: number = step;
  const tanks: Tank[] = Object.keys(localStorage)
    .filter((key) => key.startsWith("tank_"))
    .map((key) => JSON.parse(localStorage[key]));
  const tankSelectedInProtocol =
    protocoleSelected && protocoleSelected.tankId
      ? tanks.filter((tank) => tank.id === protocoleSelected?.tankId)[0]
      : undefined;
  const [tankSelected, setTankSelected] = useState<Tank | undefined>(
    tankSelectedInProtocol
  );
  const [sugar, setSugar] = useState<number>(
    (protocoleSelected && protocoleSelected.sugar) || 0
  );
  const [selectedDate, setSelectedDate] = useState<string>(
    protocoleSelected ? protocoleSelected.implementationDate : ""
  );
  const [sampleName, setSampleName] = useState<string>(
    protocoleSelected ? protocoleSelected.name : ""
  );
  const [aromaticProfile, setAromaticProfile] = useState<string>(
    (protocoleSelected && protocoleSelected.aromaticProfile) ||
    "mineralFraisFruitBlanc"
  );
  const [computedData, setComputedData] = useState<Charmat | undefined>(
    protocoleSelected && tankSelected
      ? computeCharmat(tankSelected.volume, sugar)
      : undefined
  );
  const [errorTank, setErrorTank] = useState<boolean>(false);
  const [errorDate, setErrorDate] = useState<boolean>(false);
  const history = useHistory();

  const mainRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (mainRef && mainRef.current) {
      mainRef.current.scrollIntoView();
    }
  }, [step]);
  return (
    <div ref={mainRef}>
      <p>
        <span className="titleProtocols">
          <FormattedMessage id={`TitleCharmatStep${step}`} /> <br />
        </span>
        <span className="subTitleProtocols">
          <FormattedMessage id={`SubtitleCharmatStep${step}`} />
        </span>
      </p>
      <IonCard
        style={{
          color: "black",
          width: "40%",
          padding: "5%",
          fontFamily: "Lato",
          fontSize: "15pt",
          fontWeight: 600,
        }}
        onClick={() => {
          if (step === 0) {
            history.goBack();
          } else {
            setStep(step - 1);
          }
        }}
      >
        <IonIcon icon={returnUpBack} />{" "}
        <span style={{ paddingLeft: "5%" }}>
          <FormattedMessage id="goBack" />
        </span>
      </IonCard>
      <div>
        {step === 0 && (
          <CharmatZeroStep
            tanks={tanks}
            tankSelected={tankSelected}
            setTankSelected={setTankSelected}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            sampleName={sampleName}
            setSampleName={setSampleName}
            aromaticProfile={aromaticProfile}
            setAromaticProfile={setAromaticProfile}
            compareWith={compareWith}
            errorTank={errorTank}
            setErrorTank={setErrorTank}
            errorDate={errorDate}
            setErrorDate={setErrorDate}
          />
        )}
        {step === 1 && (
          <CharmatFirstStep
            computedData={computedData}
            aromaticProfile={aromaticProfile}
          />
        )}
        {step >= 2 && step <= 4 && (
          <CharmatSecondStep computedData={computedData} step={step} />
        )}
        {step === 5 && <CharmatThirdStep />}
        {step >= 6 && step <= 8 && (
          <CharmatFourthStep computedData={computedData} step={step} />
        )}
        {step === 9 && <CharmatSugarAsk sugar={sugar} setSugar={setSugar} />}
        {step === 10 && (
          <CharmatSugarResult
            computedData={computedData}
            aromaticProfile={aromaticProfile}
          />
        )}
        {step === 11 && (
          <CharmatKeyStep
            computedData={computedData}
            aromaticProfile={aromaticProfile}
          />
        )}
        {step === 12 && <CharmatElevageFirstStep />}
        {step === 13 && (
          <CharmatElevageSecondStep
            computedData={computedData}
            aromaticProfile={aromaticProfile}
          />
        )}
        {step === 14 && <EndOfProtocole computedData={computedData} sampleName={sampleName} protocoleType={"Charmat"} selectedDate={selectedDate} cepage={undefined} macerationType={undefined} aromaticProfile={aromaticProfile} sugar={sugar} />}
        {step !== 14 && (
          <div>
            {step === 0 ? (
              <IonCard
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#1B1D26",
                  fontFamily: "Lato",
                  fontSize: "11pt",
                  fontWeight: 100,
                  padding: "5% 0",
                }}
                onClick={() => {
                  if (!tankSelected) {
                    setErrorTank(true);
                  } else if (!selectedDate) {
                    setErrorDate(true);
                  } else {
                    if (tankSelected && aromaticProfile) {
                      setComputedData(
                        computeCharmat(tankSelected?.volume, sugar)
                      );
                      const saveStep = step + 1;
                      setStep(step + 1);
                      let id = protocolId;
                      if (!id) {
                        id =
                          Math.random().toString(36).substring(2, 15) +
                          Math.random().toString(36).substring(2, 15);
                      }
                      setProtocolId(id);
                      if (savedStep === 0) {
                        localStorage.setItem(
                          "protocol_" + id,
                          JSON.stringify({
                            id: id,
                            modifiedAt: new Date(),
                            tankId: tankSelected.id,
                            name: sampleName,
                            type: "Charmat",
                            step: saveStep,
                            status: "En cours",
                            implementationDate: selectedDate,
                            aromaticProfile: aromaticProfile,
                          })
                        );
                        const protocoles = tankSelected.protocoles.concat(id);
                        localStorage.setItem(
                          "tank_" + tankSelected.id,
                          JSON.stringify({
                            ...tankSelected,
                            protocoles: protocoles,
                          })
                        );
                      } else {
                        if (
                          protocoleSelected?.tankId !== tankSelected.id ||
                          protocoleSelected?.aromaticProfile !==
                          aromaticProfile ||
                          protocoleSelected?.implementationDate !==
                          selectedDate ||
                          protocoleSelected?.name !== sampleName ||
                          protocoleSelected?.sugar !== sugar
                        ) {
                          //supp protocolId in tank
                          if (tankSelectedInProtocol) {
                            localStorage.setItem(
                              "tank_" + tankSelectedInProtocol.id,
                              JSON.stringify({
                                ...tankSelectedInProtocol,
                                protocoles: tankSelectedInProtocol.protocoles.filter(
                                  (protocolId) => protocolId !== id
                                ),
                              })
                            );
                          }

                          //add protocolId in tankSelected
                          const protocoles = tankSelected.protocoles.concat(id);
                          localStorage.setItem(
                            "tank_" + tankSelected.id,
                            JSON.stringify({
                              ...tankSelected,
                              protocoles: protocoles,
                            })
                          );

                          localStorage.setItem(
                            "protocol_" + id,
                            JSON.stringify({
                              id: id,
                              modifiedAt: new Date(),
                              tankId: tankSelected.id,
                              name: sampleName,
                              type: "Charmat",
                              step: saveStep,
                              status: "En cours",
                              implementationDate: selectedDate,
                              aromaticProfile: aromaticProfile,
                            })
                          );
                        }
                      }
                    }
                  }
                }}
              >
                <FormattedMessage id="beginProtocole" />
              </IonCard>
            ) : (
              <IonCard
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#1B1D26",
                  fontFamily: "Lato",
                  fontSize: "15pt",
                  fontWeight: 600,
                  padding: "5% 0",
                }}
                onClick={() => {
                  const saveStep = step + 1;
                  setStep(step + 1);
                  if (saveStep > savedStep) {
                    localStorage.setItem(
                      "protocol_" + protocolId,
                      JSON.stringify({
                        ...protocoleSelected,
                        modifiedAt: new Date(),
                        status: saveStep >= 14 ? "Terminé" : "En cours",
                        step: saveStep,
                      })
                    );
                  }
                }}
              >
                {step === 13 ? (
                  <FormattedMessage id="endProtocole" />
                ) : (
                  <FormattedMessage id="goToNextStep" />
                )}
                <span style={{ paddingLeft: "25%" }}>
                  <IonIcon
                    style={{ fontSize: "30pt" }}
                    icon={arrowForwardCircle}
                    color="warning"
                  />
                </span>
              </IonCard>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProtocoleCharmat;
