import translations from "../modules/translations";
import FMLBloquee from "../types/FMLBloquee";

interface Props {
  data: FMLBloquee;
  sampleName: string;
  protocoleType: string;
  selectedDate: string;
  cep: string;
}

const FMLBTemplate: (props: Props) => Blob = ({
  data,
  sampleName,
  protocoleType,
  selectedDate,
  cep,
}) => {
  const language = localStorage.getItem("language") as string;
  const traductions = translations[language];
  const content = new Blob(
    [
      traductions["protocoleType"],
      protocoleType,
      "\n",
      traductions["sampleTitle"],
      sampleName,
      "\n",
      traductions["beginningDate"],
      " : ",
      new Date(selectedDate).toLocaleDateString(
        localStorage.getItem("language") as string
      ),
      "\n \n",
      traductions["TitleFMLBloqueeStep1"],
      "\n",
      traductions["pressing"],
      "\n",
      traductions["sulfiting"],
      " ",
      traductions["FMLFirstStepSulfitingMessage"],
      "\n",
      traductions["coldPrefermOperations"],
      traductions["whenNeeded"],
      "\n \n", //Cuvee
      traductions["theCuvee"],
      " - ",
      traductions["add"],
      "\n",
      cep === "chardonnay"
        ? `${data.cuvee.firstStep.sulfossolOnly?.minVolume} ${traductions["to"]} ${data.cuvee.firstStep.sulfossolOnly?.maxVolume} ${language === 'RU-RU' ? 'сл' : 'cL'} ${traductions["of"]} SULFOSSOL®150 (4,5 ${traductions["to"]}  5,5 ${language === 'RU-RU' ? 'сл/гл' : 'cL/hL'}) ${traductions["or"]}  ${data.cuvee.firstStep.sulfivendangeAndSulfossol.sulfossolMinVolume} ${traductions["to"]} ${data.cuvee.firstStep.sulfivendangeAndSulfossol?.sulfossolMaxVolume} ${language === 'RU-RU' ? 'сл' : 'cL'} ${traductions["of"]} SULFOSSOL®150 (1 to 2 ${language === 'RU-RU' ? 'сл/гл' : 'cL/hL'}) ${traductions["and"]} ${data.cuvee.firstStep.sulfivendangeAndSulfossol.sulfivendangeVolume} ${language === 'RU-RU' ? 'сл' : 'cL'} ${traductions["of"]} SULFIVENDANGE (5 ${language === 'RU-RU' ? 'сл/гл' : 'cL/hL'})`
        : `${data.cuvee.firstStep.sulfossolOnlyVolume} SULFOSSOL®150 (7 ${language === 'RU-RU' ? 'сл/гл' : 'cL/hL'}) ${traductions["or"]}  ${data.taille.firstStep.sulfivendangeAndSulfossol.sulfossolVolume} ${language === 'RU-RU' ? 'сл' : 'cL'} ${traductions["of"]} SULFOSSOL®150 (2 ${language === 'RU-RU' ? 'сл/гл' : 'cL/hL'}) ${traductions["and"]} ${data.taille.firstStep.sulfivendangeAndSulfossol.sulfivendangeVolume} ${language === 'RU-RU' ? 'сл' : 'cL'} ${traductions["of"]} SULFIVENDANGE (7 ${language === 'RU-RU' ? 'сл/гл' : 'cL/hL'})`,
      "\n",
      `${data?.cuvee.secondStep.catalMinWeight}`,
      " ",
      traductions["to"],
      " ",
      `${data?.cuvee.secondStep.catalMaxWeight}`,
      traductions['g'],
      traductions["of"],
      " E CATAL® CLARIF ",
      `(1 ${traductions["to"]} 2 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
      "\n",
      `${
        cep === "pinotNoirMeunier"
          ? `${data?.cuvee.fourthStep?.activa.minWeight} ${traductions["to"]} ${data?.cuvee.fourthStep?.activa.maxWeight} ${traductions['kg']} ${traductions["of"]}  NOIR ACTIVA + ${language === 'DE-DE' ? "Granulat" : "Granulé"} (20 ${traductions["to"]} 50`
          : ""
      }`,
      "\n",
      `${data?.cuvee.thirdStep.polygreen.minWeight}`,
      " ",
      traductions["to"],
      " ",
      `${data?.cuvee.thirdStep.polygreen.maxWeight}`,
      traductions['kg'],
      traductions["of"],
      " POLYGREEN® ",
      `(30 to 50 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
      " ",
      traductions["or"],
      " ",
      `${data?.cuvee.thirdStep.origin.minWeight}`,
      " ",
      traductions["to"],
      " ",
      `${data?.cuvee.thirdStep.origin.maxWeight}`,
      traductions['kg'],
      traductions["of"],
      " ORIGIN® F-MAX ",
      `(30 ${traductions["to"]} 50 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
      "\n",
      traductions["FMLThirdStepMessage"],
      "\n \n \n", //Taille
      traductions["theTaille"],
      " - ",
      traductions["add"],
      "\n",
      cep === "chardonnay"
        ? `${data.taille.firstStep.sulfossolOnly?.minVolume} ${traductions["to"]} ${data.cuvee.firstStep.sulfossolOnly?.maxVolume} ${language === 'RU-RU' ? 'сл' : 'cL'} ${traductions["of"]} SULFOSSOL®150 (6 ${traductions["to"]} 7 ${language === 'RU-RU' ? 'сл/гл' : 'cL/hL'}) ${traductions["or"]}  ${data.taille.firstStep.sulfivendangeAndSulfossol.sulfossolMinVolume} ${traductions["to"]} ${data.taille.firstStep.sulfivendangeAndSulfossol?.sulfossolMaxVolume} cL ${traductions["of"]} SULFOSSOL®150 (1 ${traductions["to"]} 2 ${language === 'RU-RU' ? 'сл/гл' : 'cL/hL'}) ${traductions["and"]} ${data.taille.firstStep.sulfivendangeAndSulfossol.sulfivendangeVolume} cL ${traductions["of"]} SULFIVENDANGE (7 ${language === 'RU-RU' ? 'сл/гл' : 'cL/hL'})`
        : `${data.taille.firstStep.sulfossolOnlyVolume} SULFOSSOL®150 (6 ${language === 'RU-RU' ? 'сл/гл' : 'cL/hL'}) ${traductions["or"]}  ${data.cuvee.firstStep.sulfivendangeAndSulfossol.sulfossolVolume} cL ${traductions["of"]} SULFOSSOL®150 (2 ${language === 'RU-RU' ? 'сл/гл' : 'g/hL'}) ${traductions["and"]} ${data.cuvee.firstStep.sulfivendangeAndSulfossol.sulfivendangeVolume} ${language === 'RU-RU' ? 'сл' : 'cl'} ${traductions["of"]} SULFIVENDANGE (6 ${language === 'RU-RU' ? 'сл/гл' : 'g/hL'})`,
      "\n",
      `${data?.taille.secondStep.catalWeight}`,
      traductions['g'],
      traductions["of"],
      " E CATAL® CLARIF ",
      cep === "chardonnay" ? `(2 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})` : `(1 to 2 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
      "\n",
      `${
        cep === "pinotNoirMeunier"
          ? `${data?.taille.fourthStep?.activa.minWeight} ${traductions["to"]} ${data?.taille.fourthStep?.activa.maxWeight} ${traductions['kg']} ${traductions["of"]}  NOIR ACTIVA + ${language === 'DE-DE' ? "Granulat" : "Granulé"} (50 ${traductions["to"]} 100 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`
          : ""
      }`,
      "\n",
      `${data?.taille.thirdStep.polygreen.minWeight}`,
      " ",
      traductions["to"],
      " ",
      `${data?.taille.thirdStep.polygreen.maxWeight}`,
      traductions['kg'],
      traductions["of"],
      " POLYGREEN® ",
      `(50 ${traductions["to"]} 100 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
      " ",
      traductions["or"],
      " ",
      `${data?.taille.thirdStep.origin.minWeight}`,
      " ",
      traductions["to"],
      " ",
      `${data?.taille.thirdStep.origin.maxWeight}`,
      traductions['kg'],
      traductions["of"],
      " ORIGIN® F-MAX ",
      `(50 ${traductions["to"]} 100 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
      "\n",
      traductions["FMLThirdStepMessage"],
      "\n \n \n", //Yeasting
      traductions["yeasting"],
      "\n",
      traductions["FMLYeastingInformation"],
      "\n",
      traductions["dissolve"],
      "\n",
      `${data.yeasting.startY.weight}`,
      traductions['g'],
      traductions["of"],
      ` START® Y SP (5 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'}) `,
      traductions["in"],
      " ",
      `${data.yeasting.startY.waterVolume}`,
      " ",
      traductions["liters"],
      " ",
      traductions["FMLYeastingWater"],
      "\n",
      traductions["dissolve"],
      "\n",
      `${data?.yeasting.soec.weight}`,
      traductions['kg'],
      traductions["of"],
      ` SOEC® 1971 (20 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
      " ",
      traductions["or"],
      " ",
      `${data?.yeasting.sp.weight}`,
      traductions['kg'],
      traductions["of"],
      ` SP®7 (20 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
      " ",
      traductions["in"],
      " ",
      `${data.yeasting.soec.waterVolume}`,
      " ",
      traductions["liters"],
      " ",
      traductions["FMLYeastingWater"],
      "\n",
      traductions["FMLYeastingMessagePart1"],
      "\n",
      traductions["FMLYeastingMessagePart2"],
      "\n",
      traductions["FMLYeastingMessagePart3"],
      "\n \n \n", // YEAST NUTRITION
      traductions["yeastNutrition"],
      "\n",
      traductions["FMLYeastingInformation"],
      "\n",
      `${data?.yeastNutrition.phosphateWeight}`,
      traductions['kg'],
      traductions["of"],
      ` PHOSPHATE COMPOSE (10 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
      " ",
      traductions["or"],
      " ",
      `${data?.yeastNutrition.nutricellWeight}`,
      traductions['kg'],
      traductions["of"],
      ` NUTRICELL® (20 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
      "\n \n \n",
    ],
    {
      type: "text/plain; charset=utf-8",
    }
  );
  return content;
};

export default FMLBTemplate;
