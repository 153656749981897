import De from "../locales/de/de";
import En from "../locales/en/en";
import Fr from "../locales/fr/fr";
import It from "../locales/it/it";
import Ru from "../locales/ru/ru";
import Sp from "../locales/sp/Sp";

type Translation = {
  [key: string] : string;
}

type Translations = {
  [key : string] : Translation
}

const translations : Translations = {
  "FR-FR" : Fr,
  "EN-GB" : En, 
  "ES-ES" : Sp, 
  "IT-IT" : It, 
  "DE-DE" : De,
  "RU-RU" : Ru,
};

export default translations;