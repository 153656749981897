import { IonCard, IonIcon } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { beaker, calculator, checkmarkCircle } from "ionicons/icons";
import { Link, useLocation } from "react-router-dom";
import Protocole from "../../types/Protocole";
import ProtocolsList from "../component/protocolsList";
import { FormattedMessage } from "react-intl";
import AndroidModal from "../component/androidModal";
import IosModal from "../component/iosModal";

interface BeforeInstallPromptEvent extends Event {
  path: any[];
  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as,
   * for example, "web" or "play" which would allow the user to chose between a web version or
   * an Android version.
   */
  readonly platforms: Array<string>;

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;

  /**
   * Allows a developer to show the install prompt at a time of their own choosing.
   * This method returns a Promise.
   */
  prompt(): Promise<void>;
}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}

const sortByDate = (p1: Protocole, p2: Protocole) =>
  new Date(p2.modifiedAt).getTime() - new Date(p1.modifiedAt).getTime();

let deferredPrompt: BeforeInstallPromptEvent;
window.addEventListener(
  "beforeinstallprompt",
  (e: BeforeInstallPromptEvent) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
  }
);

interface LocationState {
  success: boolean;
}

const HomePage: React.FC = () => {
  const protocols: Protocole[] = Object.keys(localStorage)
    .filter((key) => key.startsWith("protocol_"))
    .map((key) => JSON.parse(localStorage[key]))
    .sort(sortByDate);
  const [listProtocols, setListProtocols] = useState(
    protocols.filter((protocol) => protocol.status === "En cours").slice(0, 3)
  );

  const [openAndroid, setOpenAndroid] = useState<boolean>(false);
  const [openIphone, setOpenIphone] = useState<boolean>(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor; //|| window.opera;
    if (!window.matchMedia("(display-mode: standalone)").matches) {
      if (/android/i.test(userAgent)) {
        setOpenAndroid(true);
      }
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        setOpenIphone(true);
      }
    }
  }, []);

  const [installEvent, setInstallEvent] =
    useState<BeforeInstallPromptEvent | null>(null);
  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      setInstallEvent(e);
    });
  }, []);

  const location = useLocation();
  const [mailSuccess, setMailSuccess] = useState<boolean>(false);
  useEffect(() => {
    if (location.state) {
      const state = location.state as LocationState;
      if (state.success) {
        setMailSuccess(true);
        setTimeout(() => {
          setMailSuccess(false);
        }, 2000);
      }
    }
  }, [location.state]);

  return (
    <div className="homePage">
      {((openAndroid && installEvent !== null) || openIphone) && (
        <div
          style={{
            position: "absolute",
            zIndex: 100,
            width: "100%",
            height: "100%",
            backgroundColor: "black",
            opacity: "30%",
          }}
        >
          <AndroidModal
            openAndroid={openAndroid && installEvent !== null}
            setOpenAndroid={setOpenAndroid}
            installEvent={installEvent}
            setInstallEvent={setInstallEvent}
          />
          <IosModal openIphone={openIphone} setOpenIphone={setOpenIphone} />
        </div>
      )}
      <div className="tools">
        <div
          style={{
            textAlign: "left",
            marginLeft: "5%",
            fontFamily: "Abril Fatface",
            fontSize: "24px",
            fontWeight: 500,
            color: "white"
          }}
        >
          <FormattedMessage id="allTools" />
        </div>
        <div>
          <Link to="/protocoles">
            <IonCard
              style={{
                color: "black",
                marginLeft: "20%",
                marginRight: "20%",
                padding: "5% 0",
                fontFamily: "Lato",
                fontSize: "20px",
                backgroundColor: "white"
              }}
              color="white"
            >
              <div>
                <div>
                  <IonIcon
                    color="primary"
                    icon={checkmarkCircle}
                    size="large"
                  ></IonIcon>
                </div>
                <FormattedMessage id="startA" />
                <br />
                <span style={{ fontWeight: "bold" }}>
                  <FormattedMessage id="protocole" />
                </span>
              </div>
            </IonCard>
          </Link>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link to="/calculators">
            <IonCard
              style={{
                color: "black",
                marginLeft: "10%",
                marginRight: "10%",
                padding: "5% 0",
                fontFamily: "Lato",
                fontSize: "20px",
                backgroundColor: "white"
              }}
              color="white"
            >
              <div>
                <IonIcon
                  color="primary"
                  icon={calculator}
                  size="large"
                ></IonIcon>
              </div>
              <div>
                <FormattedMessage id="useCalculator" />
              </div>
            </IonCard>
          </Link>
          <Link to="/tankList">
            <IonCard
              style={{
                color: "black",
                marginLeft: "10%",
                marginRight: "10%",
                padding: "5% 0",
                fontFamily: "Lato",
                fontSize: "20px",
                backgroundColor: "white"
              }}
              color="white"
            >
              <div>
                <IonIcon color="primary" icon={beaker} size="large"></IonIcon>
              </div>
              <div>
                <FormattedMessage id="handleTank" />
              </div>
            </IonCard>
          </Link>
        </div>
      </div>
      <div className="protocolesList">
        <span className="pageTitle">
          <FormattedMessage id="runningProtocole" />
        </span>
        <ProtocolsList
          protocols={listProtocols}
          setProtocols={setListProtocols}
        />
      </div>
      {mailSuccess &&<div  style={{position: "sticky", bottom: "0", height: "100x", backgroundColor: "lightGreen", color: "white", fontFamily: "Lato"}} >
        <FormattedMessage id="mailSendWithSuccess" />
      </div>} 
    </div>
  );
};

export default HomePage;
