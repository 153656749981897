import {
  IonContent,
  IonHeader,
  IonImg,
  IonMenuButton,
  IonRouterOutlet,
} from "@ionic/react";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Redirect, Route } from "react-router";
import { Link } from "react-router-dom";
import withGATracker from "../../helpers/withGATracker";
import Menu from "../component/menu";
import AddTankPage from "../page/addTankPage";
import CalculatorsPage from "../page/calculatorsPage";
import ChaptalisationPage from "../page/chaptalisationPage";
import ComputerPage from "../page/computerPage";
import ContactPage from "../page/contactPage";
import HomePage from "../page/homePage";
import LegalesNoticies from "../page/legalesNoticies";
import MaloTankPage from "../page/maloTankPage";
import ProtocoleCharmat from "../page/protocoleCharmat";
import ProtocoleFML from "../page/protocoleFML";
import ProtocoleFMLBloquee from "../page/protocoleFMLBloquee";
import ProtocoleMaceration from "../page/protocoleMaceration";
import Protocoles from "../page/protocoles";
import SO2MolecularPage from "../page/so2MolecularPage";
import SoufreAcetaldehydePage from "../page/soufreAcetaldehydePage";
import SparklingWineOverpressurePage from "../page/sparklinWineOverpressurePage";
import TankListPage from "../page/tankListPage";

interface Props {
  language: string;
  setLanguage: (value: string) => void;
}

const pdfLink: { [key: string]: string } = {
  "FR-FR": "https://www.oenotechnic.com/PDF/CGV_FR.pdf",
  "EN-GB": "https://www.oenotechnic.com/PDF/CGV_EN.pdf",
  "ES-ES": "https://www.oenotechnic.com/PDF/CGV_ES.pdf",
  "IT-IT": "https://www.oenotechnic.com/PDF/CGV_IT.pdf",
  "DE-DE": "https://www.oenotechnic.com/PDF/CGV_EN.pdf",
  "RU-RU": "https://www.oenotechnic.com/PDF/CGV_EN.pdf",
};

const MainLayout: React.FC<Props> = ({ language, setLanguage }) => {
  const isOnMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const linkToPdf = pdfLink[language];

  return (
    <>
      {isOnMobile && (
        <Menu
          locale={language}
          setLocale={setLanguage}
        />
      )}
      <IonRouterOutlet id="main">
        <IonContent id="content">
          {isOnMobile && (
            <>
              <div className="background" />
              <IonHeader style={{ position: "relative", height: "90px" }}>
                <span></span>
                <IonImg
                  src="/assets/icons/soec.png"
                  style={{
                    position: "absolute",
                    left: "50%",
                    marginLeft: "-30px",
                    top: "10px",
                    width: "70px",
                    height: "70px",
                  }}
                />
                <IonMenuButton
                  style={{ position: "absolute", right: "5%", top: "20%" }}
                />
              </IonHeader>
            </>
          )}
          <Route
            exact
            path="/home"
            component={withGATracker(isOnMobile ? HomePage : ComputerPage)}
          />
          <Route exact path="/" render={() => <Redirect to="/home" />} />
          <Route
            exact
            path="/legalesNoticies"
            component={withGATracker(LegalesNoticies)}
          />
          {isOnMobile && (
            <>
              <Route
                exact
                path="/protocoles"
                component={withGATracker(Protocoles)}
              />
              <Route
                exact
                path="/protocoles/protocoleMaceration"
                component={withGATracker(ProtocoleMaceration)}
              />
              <Route
                exact
                path="/protocoles/protocoleFML"
                component={withGATracker(ProtocoleFML)}
              />
              <Route
                exact
                path="/protocoles/protocoleFMLBloquee"
                component={withGATracker(ProtocoleFMLBloquee)}
              />
              <Route
                exact
                path="/protocoles/protocoleCharmat"
                component={withGATracker(ProtocoleCharmat)}
              />
              <Route
                exact
                path="/calculators"
                component={withGATracker(CalculatorsPage)}
              />
              <Route
                exact
                path="/calculator/soufreAcetaldehyde"
                component={withGATracker(SoufreAcetaldehydePage)}
              />
              <Route
                exact
                path="/calculator/maloTank"
                component={withGATracker(MaloTankPage)}
              />
              <Route
                exact
                path="/calculator/sparklingWineOverpressure"
                component={withGATracker(SparklingWineOverpressurePage)}
              />
              <Route
                exact
                path="/calculator/so2Molecular"
                component={withGATracker(SO2MolecularPage)}
              />
              <Route
                exact
                path="/calculator/chaptalisation"
                component={withGATracker(ChaptalisationPage)}
              />
              <Route
                exact
                path="/contact"
                component={withGATracker(ContactPage)}
              />

              <Route
                exact
                path="/tank/add"
                component={withGATracker(AddTankPage)}
              />
              <Route
                exact
                path="/tankList"
                component={withGATracker(TankListPage)}
              />
              <div
                style={{
                  bottom: "0",
                  position: "static",
                  marginTop: "10%",
                }}
              >
                <div
                  style={{
                    padding: "5px",
                    fontFamily: "Lato",
                    fontWeight: 100,
                    fontSize: "15pt",
                  }}
                >
                  <Link to="/legalesNoticies">
                    <FormattedMessage id="legalesNoticies" />
                  </Link>{" "}
                  -{" "}
                  <a
                    href={linkToPdf}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FormattedMessage id="GTC" />
                  </a>
                </div>
                <div
                  style={{
                    borderTop: "1px solid #707070",
                    width: "20%",
                    margin: "5px 0 5px 0",
                    marginLeft: "40%",
                  }}
                ></div>
                <div style={{ padding: "5px" }}>
                  <FormattedMessage id="by" />
                </div>
                <IonImg
                  style={{
                    heigth: "50%",
                    width: "50%",
                    marginLeft: "25%",
                    padding: "5px",
                  }}
                  src="/assets/icons/sofralab.png"
                />
              </div>
            </>
          )}
        </IonContent>
      </IonRouterOutlet>
    </>
  );
};

export default MainLayout;
