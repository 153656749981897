type Translation = {
  [key: string]: string;
};

const Fr: Translation = {
  "FR-FR": "Français",
  "EN-GB": "Anglais",
  "ES-ES": "Espagnol",
  "IT-IT": "Italien",
  "DE-DE": "Allemand",
  "RU-RU": "Russe",

  //Version ordinateur
  notAvailableForComputerTitle:
    "L'application SOEC n'est pas disponible sur ordinateur.",
  notAvailableForComputerContent:
    "Malheureusement cette application n’est pas disponible sur ordinateur. Pour y accéder, scannez le code ci-dessous ou accédez à l’URL suivante : {soecUrl} sur votre tablette ou votre mobile. Vous pourrez alors ajouter l’application à votre appareil en suivant les indications de la pop-up.",

  //installer la PWA
  addSOECToApp: "Ajouter SOEC à vos applications !",
  appSOECToAppInformations:
    "Vous pouvez ajouter notre application aux applications de votre mobile. Pour ce faire il vous suffit de cliquer sur le bouton ci-dessous.",
  appSOECToAppInformationsIos:
    "Pour ajouter l’application sur votre iPhone, il vous suffit d’appuyer sur l’icône partager :",
  appSOECToAppInformationsIosFinalStep:
    "Puis de cliquer sur « Sur l’écran d’accueil » :",
  addApplication: "Ajouter l'application",
  stayOnBrowser: "Rester sur le navigateur",

  //Menu
  home: "Accueil",
  tanks: "Mes cuves",
  protocoles: "Protocoles",
  calculators: "Calculateurs",
  contact: "Besoin d'aide ?",

  //Page d'accueil
  legalesNoticies: "Mentions légales",
  GTC: "CGV",
  calculatorsTools: "Outils d'aide à la décision",
  startA: "Débuter un",
  protocole: "protocole",
  allTools: "Tous les outils",
  useCalculator: "Utiliser un calculateur",
  handleTank: "Gérer mes cuves",

  //Page d'accueil sans protocole
  noProtocoleFoundfirst: "Aucun protocole en cours n’a été trouvé",
  noProtocoleFoundsecond:
    "Nous n’avons pas trouvé de protocole sur votre appareil.",
  noProtocoleFoundthird:
    "Débutez votre premier protocole en cliquant ci-dessous !",

  //Liste des protocoles disponibles
  protocols: "Protocoles",
  maceration: "Macération",
  traditionalMethod: "Méthode traditionnnelle",
  feedStockMethod: "Méthode cuve close",
  martinottiMethod: "Méthode Charmat",
  martinottiMethodDescription:
    "Ces protocoles Méthode Traditionnelle sont élaborés et adaptés aux spécificités champenoises. N’hésitez pas à consulter nos oenologues conseil.",
  malolacticFermentation: "Vinification avec fermentation malolactique",
  malolacticFermentationBlocked: "Vinification sans fermentation malolactique",

  //Liste des calculateurs disponible
  decisionSupportToolsCaps: "OUTILS D'AIDE A LA DECISION",
  calculationCorrection: "Calcul de la correction de la",
  overpressureSparklingWine: "surpression d’un vin effervescent",
  calculationFoot: "Calcul du pied de",
  maloTankLowPh: "Cuve Malo sur pH bas",
  calculationOf: "Calcul du",
  sulfurAcetaldehyde: "Soufre - Acétaldéhyde",
  calculationOfThe: "Calcul de la",

  //Propre à tous les protocoles
  selectTank: "Sélectionner / Ajouter une cuve",
  askSelectedTank: "À quelle cuve souhaitez-vous attitrer ce protocole ?",
  tankVolume: "Volume de la cuve :",
  tankVolumeInfo:
    "(Pour changer le volume souhaité, vous pouvez changer ou ajouter une cuve.)",
  beginningDate: "Date de mise en application",
  askBeginningDate: "Quand souhaitez-vous démarrer ce protocole ?",
  sampleName: "Numéro de l'échantillon ou de lot",
  askSampleName: "Pour quel échantillon souhaitez-vous réaliser ce protocole ?",
  beginProtocole: "COMMENCER LE PROTOCOLE",
  endProtocole: "Finir le protocole",
  sendResults: "Envoyer les résultats",
  goBackToBoard: "Revenir au tableau de bord",
  selectADate: "Sélectionnez une date",
  selectATank: "Sélectionnez une cuve",
  selectACepage: "Sélectionnez un cépage",
  selectAMacerationType : "Sélectionnez un type de macération",
  selectAnAromaticProfile: "Sélectionnez un profil aromatique",
  nameTheTank: "Numéro de cuve",
  errorMessageInput: "Ce champ est obligatoire",
  errorNumberInput: "La valeur doit être supérieure à 0",
  errorSelectTank: "Veuillez sélectionner une cuve",
  errorDate: "Sélectionner une date",
  optionnal: "Optionnel",

  //Protocole Macération
  //Titre et sous-titre pour chacune des étapes
  TitleMacerationStep0_classic: "Macération",
  TitleMacerationStep1_classic: "Macération classique",
  TitleMacerationStep2_classic: "Encuvage",
  TitleMacerationStep3_classic: "Opérations pré-fermentaires",
  TitleMacerationStep4_classic: "Fermentation alcoolique",
  TitleMacerationStep5_classic: "Fermentation alcoolique",
  TitleMacerationStep6_classic: "Macération",
  TitleMacerationStep7_classic: "Chaptalisation",
  TitleMacerationStep8_classic: "Décuvage, pressurage & fin de FA",
  TitleMacerationStep9_classic: "Fermentation malolactique",
  TitleMacerationStep10_classic: "Fin du protocole",

  SubtitleMacerationStep0_classic: "MÉTHODE TRADITIONNELLE",
  SubtitleMacerationStep1_classic: " ",
  SubtitleMacerationStep2_classic: "Macération classique",
  SubtitleMacerationStep3_classic: "Macération classique",
  SubtitleMacerationStep4_classic: "Macération classique",
  SubtitleMacerationStep5_classic: "Macération classique",
  SubtitleMacerationStep6_classic: "Macération classique",
  SubtitleMacerationStep7_classic: "Macération classique",
  SubtitleMacerationStep8_classic: "Macération classique",
  SubtitleMacerationStep9_classic: "Macération classique",
  SubtitleMacerationStep10_classic: "Macération classique",

  TitleMacerationStep0_coldPreferm: "Macération",
  TitleMacerationStep1_coldPreferm: "Macération pré-fermentaire à froid (MPF)",
  TitleMacerationStep2_coldPreferm: "Encuvage",
  TitleMacerationStep3_coldPreferm: "Enzymage",
  TitleMacerationStep4_coldPreferm: "Macération pré-fermentaire à froid",
  TitleMacerationStep5_coldPreferm: "Fermentation alcoolique",
  TitleMacerationStep6_coldPreferm: "Macération",
  TitleMacerationStep7_coldPreferm: "Chaptalisation",
  TitleMacerationStep8_coldPreferm: "Décuvage, pressurage & fin de FA",
  TitleMacerationStep9_coldPreferm: "Fermentation malolactique",
  TitleMacerationStep10_coldPreferm: "Fin du protocole",

  SubtitleMacerationStep0_coldPreferm: "MÉTHODE TRADITIONNELLE",
  SubtitleMacerationStep1_coldPreferm: " ",
  SubtitleMacerationStep2_coldPreferm: "Macération pré-fermentaire à froid",
  SubtitleMacerationStep3_coldPreferm: "Macération pré-fermentaire à froid",
  SubtitleMacerationStep4_coldPreferm: "Macération pré-fermentaire à froid",
  SubtitleMacerationStep5_coldPreferm: "Macération pré-fermentaire à froid",
  SubtitleMacerationStep6_coldPreferm: "Macération pré-fermentaire à froid",
  SubtitleMacerationStep7_coldPreferm: "Macération pré-fermentaire à froid",
  SubtitleMacerationStep8_coldPreferm: "Macération pré-fermentaire à froid",
  SubtitleMacerationStep9_coldPreferm: "Macération pré-fermentaire à froid",
  SubtitleMacerationStep10_coldPreferm: "Macération pré-fermentaire à froid",

  //Quantité de raisin
  harvestWeight: "Quantité de raisin mise en œuvre",
  askHarvestWeight: "Quelle est la taille de votre récolte en kilogrammes ?",
  //Type de macération
  macerationType: "Type de macération",
  askMacerationType: "Quel type de macération souhaitez-vous réaliser ?",
  coldPreferm: "Préferm. à froid",
  classic: "Classique",

  //Etape 1
  harvestSorting: "Tri de la vendange",
  foulageEraflage: "Foulage - Eraflage",

  //Etape 2 pour préf. à froid
  decreaseTemperature: "Diminuer la température",
  toDecreaseOf1Deg: "pour diminuer d'1°C",

  //Etape 3 pour préf. à froid
  atT24: "A T+24h",

  //Etape 4 pour pref. à froid
  prefermTitle: "MACÉRATION PRÉ-FERMENTAIRE À FROID",
  pigeageWithoutOxygene: "Pigeage sans aération",

  //Etape 6 pour les 2
  macerationCaps: "MACÉRATION",
  remontagePigeage: "Remontage et/ou pigeage avec aération",
  decreaseVolume:
    "Diminuer les volumes remontés au fur et à mesure que la densité diminue.",

  //Etape 7 pour les 2
  chaptalisationMessage:
    "Il est conseillé que la densité soit inférieure à 1040 pour réaliser la chaptalisation.",

  //Etape 8 pour les 2
  devatting: "DECUVAGE",
  devattingContent: "Lorsque la densité est proche de 1000",
  pressing: "PRESSURAGE",
  pressingContent:
    "Consulter votre œnologue pour un éventuel assemblage ultérieur des jus de goutte et jus de presse.",
  endOfAF: "Fin de FA",
  bottling: "Effectuer un soutirage",

  //Etape 9 pour les 2
  malolacticFermentationCaps: "FERMENTATION MALOLACTIQUE",

  //FML
  TitleFMLStep0: "Vinification avec fermentation malolactique",
  TitleFMLStep1: "Opérations pré-fermentaires",
  TitleFMLStep2: "Opérations pré-fermentaires",
  TitleFMLStep3: "Opérations pré-fermentaires",
  TitleFMLStep4: "Fermentation alcoolique",
  TitleFMLStep5: "Fermentation alcoolique",
  TitleFMLStep6: "Fermentation malolactique",
  TitleFMLStep7: "Fin du protocole",

  SubtitleFMLStep0: "MÉTHODE TRADITIONNELLE",
  SubtitleFMLStep1: "Vinification avec fermentation malolactique",
  SubtitleFMLStep2: "Vinification avec fermentation malolactique",
  SubtitleFMLStep3: "Vinification avec fermentation malolactique",
  SubtitleFMLStep4: "Vinification avec fermentation malolactique",
  SubtitleFMLStep5: "Vinification avec fermentation malolactique",
  SubtitleFMLStep6: "Vinification avec fermentation malolactique",
  SubtitleFMLStep7: "Vinification avec fermentation malolactique",

  //Type de cépage
  cepageType: "Cépage de l'échantillon",
  askCepageType: "Quel est le cépage de l'échantillon ?",
  chardonnay: "Variétés blanches - Blanc de Noirs",
  pinotNoirMeunier: "Variétés rouges",

  //Etape 1 pour les 2 (Sulfitage est déjà défini)
  FMLFirstStepSulfitingMessage:
    "Dès l'écoulement des jus, apport fractionné en 3 fois",
  coldPrefermOperations: "Traitement pré-fermentaire à froid ",
  whenNeeded: "Au besoin",

  //Etape 2
  theCuvee: "LA CUVÉE",
  FMLToDelay:
    "À délayer dans 10x son poids d’eau froide et incorporer au moût une fois délité.",
  FMLCatalMessage:
    "À incorporer avant pompage de la fraction en cuve de débourbage.",
  FMLThirdStepMessage:
    "En fonction de l’oxydation des jus et de l’extraction de couleur (quinone).",

  //Etape 3
  theTaille: "LA TAILLE",

  //Etape 4
  FMLYeastingWater: "de moitié eau / moitié moût à 37°C",
  FMLYeastingMessage:
    "Il est primordial de respecter la température de réhydratation et de ne pas dépasser les 40°C au-dessus desquels vous pourriez tuer les cellules.",
  FMLYeastingMessagePart1:
    "Laisser reposer pendant 20 minutes, puis agiter doucement pour homogénéïser.",
  FMLYeastingMessagePart2:
    "Incorporer le levain dans 10 à 20 fois son volume de moût.",
  FMLYeastingMessagePart3:
    "Laisser fermenter pendant 6 à 12 heures, puis ajouter à la cuve.",

  //Etape 5
  yeastNutrition: "NUTRITION DE LA LEVURE",
  FMLYeastNutritionMessage:
    "Ce nutriment permet une bonne maîtrise de la fermentation alcoolique et optimise le profil aromatique des vins.",

  //FML Bloquée
  TitleFMLBloqueeStep0: "Vinification sans fermentation malolactique",
  TitleFMLBloqueeStep1: "Opérations pré-fermentaires",
  TitleFMLBloqueeStep2: "Opérations pré-fermentaires",
  TitleFMLBloqueeStep3: "Opérations pré-fermentaires",
  TitleFMLBloqueeStep4: "Fermentation alcoolique",
  TitleFMLBloqueeStep5: "Fermentation alcoolique",
  TitleFMLBloqueeStep6: "Fin du protocole",

  SubtitleFMLBloqueeStep0: "MÉTHODE TRADITIONNELLE",
  SubtitleFMLBloqueeStep1: "Vinification sans fermentation malolactique",
  SubtitleFMLBloqueeStep2: "Vinification sans fermentation malolactique",
  SubtitleFMLBloqueeStep3: "Vinification sans fermentation malolactique",
  SubtitleFMLBloqueeStep4: "Vinification sans fermentation malolactique",
  SubtitleFMLBloqueeStep5: "Vinification sans fermentation malolactique",
  SubtitleFMLBloqueeStep6: "Vinification sans fermentation malolactique",

  //Charmat
  TitleCharmatStep0: "Méthode Charmat",
  TitleCharmatStep1: "1.1 - Réhydratation",
  TitleCharmatStep2: "1.2 - Acclimatation",
  TitleCharmatStep3: "1.2 - Acclimatation",
  TitleCharmatStep4: "1.2 - Acclimatation",
  TitleCharmatStep5: "1.3 - Pied de Cuve",
  TitleCharmatStep6: "1.3 - Pied de Cuve",
  TitleCharmatStep7: "1.3 - Pied de Cuve",
  TitleCharmatStep8: "1.3 - Pied de Cuve",
  TitleCharmatStep9: "Étape intermédiaire",
  TitleCharmatStep10: "Étape intermédiaire",
  TitleCharmatStep11: "1.4 - Étapes clés de la fermentation alcoolique",
  TitleCharmatStep12: "2.1 - Étapes clés de l’élevage",
  TitleCharmatStep13: "2.1 - Étapes clés de l’élevage",
  TitleCharmatStep14: "Fin du protocole",

  SubtitleCharmatStep0: "Cuve Close",
  SubtitleCharmatStep1: "Méthode Charmat - Starter",
  SubtitleCharmatStep2: "Méthode Charmat - Starter",
  SubtitleCharmatStep3: "Méthode Charmat - Starter",
  SubtitleCharmatStep4: "Méthode Charmat - Starter",
  SubtitleCharmatStep5: "Méthode Charmat - Starter",
  SubtitleCharmatStep6: "Méthode Charmat - Starter",
  SubtitleCharmatStep7: "Méthode Charmat - Starter",
  SubtitleCharmatStep8: "Méthode Charmat - Starter",
  SubtitleCharmatStep9: "Méthode Charmat - Starter",
  SubtitleCharmatStep10: "Méthode Charmat - Starter",
  SubtitleCharmatStep11: "Méthode Charmat - Starter",
  SubtitleCharmatStep12: "Méthode Charmat - Starter",
  SubtitleCharmatStep13: "Méthode Charmat - Starter",
  SubtitleCharmatStep14: "Méthode Charmat - Starter",

  mineralFraisFruitBlanc: "Minéral, Frais, Fruit blanc",
  tresFruite: "Très fruité",
  floralElegant: "Floral et Elégant",
  cremeuxVarietal: "Crémeux et Variétal",

  //Etape 1
  charmatFirstStepFirstMessage:
    "Commencez par amener le vin de base à une température de 12°C.",
  water35: "d'eau à 35-36°C",
  water30: "d'eau à 30°C",
  water25: "d'eau à 25°C",
  water20: "d'eau à 20°C",
  charmatFirstStepFirstBlackMessage:
    "Il est primordial de respecter la température de réhydratation et de ne pas dépasser les 40°C au-delà desquels les cellules peuvent être détruites.",
  charmatFirstStepSecondBlackMessage:
    "Agiter vigoureusement pour s’assurer que les levures ne s’agglomèrent pas.",
  aromaticProfile: "Profil aromatique souhaité",
  askAromaticProfile: "Quel profil aromatique souhaitez-vous pour votre vin ?",

  //Etape 2
  sugar: "sucre",
  stepCaps: "ÉTAPE ",
  step: "Étape",
  charmatSecondStepMessageFirstStep:
    "Mélanger dans un contenant séparé les éléments ci-dessous.",
  charmatSecondStepMessageSecondStep:
    "Ajouter un peu de vin de base et d’eau à la préparation.",
  charmatSecondStepMessageThirdStep:
    "Ajouter les produits suivants pour compléter l’acclimatation.",
  alreadyPrepared: "déja préparés",
  acclimatationBasicWine12: "de vin de base (à 12°C)",
  acclimatationBasicWine14: "de vin de base (à 14°C)",
  feedStockBasicWine: "de vin de base (à 15-16°C)",
  feedStockBasicWine16: "de vin de base (à 16°C)",

  //Etape 3
  charmatThirdStepFirstMessage:
    "Au cours des 3 jours de préparation du pied de cuve, s’assurer que :",
  charmatThirdStepSecondMessage:
    "La densité ne soit jamais inférieure à 1005 (en cas de diminution, ajouter un peu de sucre)",
  charmatThirdStepThirdMessage:
    "La différence de température entre le milieu d’acclimatation et le vin ne soit pas supérieure à 5-7°C",
  charmatThirdStepFourthMessage:
    "La température du vin de base ajouté à la cuve soit toujours comprise entre 16 et 18°C",

  //Etape 4
  charmInfo: "Recommandé pour améliorer la longévité",
  charmatFourthStepMessageFirstStep:
    "Au bout d’une heure le volume est doublé de vin de base, la température doit rester proche de 18-20°C.",
  charmatFourthStepMessageSecondStep:
    "Après 24h, l’opération précédente est renouvelée et les volumes doublés.",
  charmatFourthStepMessageThirdStep:
    "Après 24h supplémentaires, les éléments nutritifs sont ajoutés et l’opération précédente répétée.",
  charmatFourthStepLastMessage:
    "Quand l’activité fermentaire a bien démarré, ajouter tout le volume préparé dans la cuve close.",
  startYInfo: "Seulement si la concentration en alcool est élevée",

  //Etape 5
  sugarQuantity: "Quantité de sucre résiduel",
  sugarQuantityAsk:
    "Quelle quantité de sucre résiduel souhaitez-vous ? (en g/L)",

  //Etape 6
  charmatSugarResultSugar: "de sucre (25 g/L + sucre résiduel)",
  charmatSugarStartFreshMessage:
    "Il est recommandé d’ajouter du START® Y FRESH (20 g/hL) pour assurer la nutrition des levures",
  mineralFraisFruitBlancSubliCharmMessage:
    "Pour booster les arômes fruités ajoutez :",
  cremeuxVarietalSubliCharmMessage: "Pour augmenter le volume en bouche :",
  floralElegantSubliCharmMessage: "Pour augmenter le volume en bouche :",
  charmElevageMessage:
    "Pour augmenter le volume et le gras et améliorer la qualité de la mousse, ajoutez :",
  tresFruiteCharmElevageMessage:
    "Pour augmenter le volume et la sensation de fruité et améliorer la qualité de la mousse, ajoutez :",
  cremeuxVarietalCharmElevageMessage:
    "Pour améliorer l'équilibre REDOX et le MP :",
  charmAgeMessage:
    "Pour améliorer la durée de conservation et préserver les arômes fins, ajoutez :",
  charmActivMessage: "Pour assurer la nutrition des levures :",
  charmRedoxMessage: "Pour prévenir les phénomènes de réduction :",

  //Etape 7
  decreaseTemperatureUnder15: "Abaisser la température sous les 15°C",
  addProductsUnderPressure:
    "S’il vous est possible d’ajouter des produits sous pression à 2,5 bars, ajoutez :",
  fermentRecommandedTemperature:
    "Température de fermentation recommandée : 14-16°C",
  reductionAromaCase: "En cas d'apparition d'arômes de réduction, ajoutez :",
  pressureReached:
    "Quand la pression atteint 4,3 à 4,6 atm (calculés à 20°C), abaisser la température sous les 2°C pour stopper la fermentation.",
  necessaryTimeDependingEquipment:
    "Cela prend environ 1 à 2 jours en fonction des équipements.",
  setTemperatureTo0:
    "Régler la température à 0°C et filtrer le vin (filtration tangentielle ou membrane de 1 μm).",

  //Etape 8
  duringElevage: "Durant l’élevage, le remuage doit :",
  firstOrder: "Durer 15 jours (2x 15 minutes chaque jour)",
  secondOrder: "Tous les 3 jours pour les 30 prochains jours (1x20 minutes)",
  thirdOrder: "Une fois par semaine pour les derniers 15 jours (1x15 minutes)",

  //Etape 9
  elevageMessage:
    "Elevage à une température minimum de 6-8°C pendant 2 mois avec au choix (en fonction des résultats à la dégustation) :",

  //Calculateur SO2 Moléculaire
  so2MolecularTitle: "Calcul de la teneur en SO{sub2} moléculaire",
  so2MolecularSubTitle:
    "Attention, le SO{sub2} moléculaire a une propriété antiseptique au de-delà d'une concentration de 1 mg/L.",
  so2Amount: "Teneur en SO{sub2} moléculaire",
  pH: "pH",
  good: "Tirage possible",
  risquy: "À risque",
  lethal: "Léthal",

  //Calculateur Soufre - Acétaldéhyde
  soufreAcetaldehydeTitle: "Soufre - Acétaldéhyde",
  soufreAcetaldehydeSubtitle: "Couramment utilisé pour la méthode Charmat",
  so2Molecular: "SO{sub2} moléculaire",
  molecular: "moléculaire",
  totalEthanal: "Ethanal total",
  freeSO2: "SO{sub2} libre",
  totalSO2: "SO{sub2} total",
  residualSugar: "Sucre résiduel",
  wantedFreeSO2: "Valeur de SO{sub2} libre cible",
  freeEthanal: "Ethanal libre",
  freeCombiningSO2: "SO{sub2} libre qui se combine",
  SO2ToAdd: "SO{sub2} à ajouter",
  finalTotalSO2: "Valeur finale de SO{sub2} total",

  //Calculateur Chaptalisation
  chaptalization: "Chaptalisation",
  density: "Masse volumique",
  wantedDegree: "Degré souhaité",
  chaptalisationDescription:
    "L’expérience de suivi de nombreuses vendanges montre que pour obtenir un degré final de 11% vol. , il est souhaitable de viser 11% vol. pour le Chardonnay et 11,2% vol. pour le Pinot Noir et le Pinot Meunier.",
  results: "Résultats",
  chaptalisationDensityError:
    "Veuillez inscrire une masse volumique supérieure ou égale à 1064.",
  chaptalisationResult:
    "Quantité de sucre cristallisé à apporter pour obtenir le degré alcoolique désiré en % vol. :",
  chaptalisationWarningTitle:
    "Il est conseillé que la densité soit inférieure à 1040 pour réaliser la Chaptalisation.",
  chaptalisationWarningContent:
    "Mentions réglementaires sur la chaptalisation.",

  //Calculateur Pied de Cuve Malo
  maloTankTitle: "Pied de Cuve Malo sur pH bas",
  cuveMaloTitle: "Protocole de préparation d’un pied de Cuve Malo sur moût",
  cuveMaloContent:
    "Selon le protocole mis en place par le service technique du CIVC et paru dans le Vigneron Champenois N°6 - Juin 2009.",
  totalWineToSeed: "Volume Total de vin à ensemencer",
  inHectoliter: "En hectolitre (HL)",
  globaldata: "Données globales",
  rehydratation: "Réhydratation",
  reactivationEnvironment: "Milieu de Réactivation",
  feedStock: "Pied de Cuve",
  reactivationEnvironmentVolume: "Volume du milieu de réactivation:",
  feedStockVolume: "Volume de pied de cuve:",
  bacteries: "Bactéries mises en oeuvre: ",
  activator: "Activateur mis en oeuvre : ",
  dryYeast: "Levures sèches mises en oeuvre : ",
  globalDataFeedStockMessage:
    "Les moûts utilisés dans la réalisation du pied de cuve seront des moûts débourbés, préférentiellement de la taille, non chaptalisés et sulfités au maximum a 4 g/hl (soit 2,7 cL/hl de SULFOSSOL® 150).",
  maloTankMiddleMessage:
    "Le Pied de Cuve et le Milieu de Réactivation devront être préparés simultanément",
  delay: "Délayer les",
  litersOfWater: "litres d'eau à 25°C",
  rehydratationMessage:
    "Agiter énergiquement afin d’éviter la formation d’un agglomérat. Laisser reposer durant 15 à 20 minutes puis homogénéiser la préparation, en agitant de nouveau, avant d’élaborer le Milieu de Réactivation.",
  preparedBefore: "préalablement préparés",
  rehydratedBacteries: "Bactéries réhydratées",
  settlingMust: "Moût débourbé & peu sulfité (Mi Dose)",
  ofWaterAt25: "d'eau à une température de 25°C",
  sprinklingDryYeast:
    "Levures Sèches SOEC® 1971 en saupoudrage sans réhydratation",
  inTotal: "au Total",
  reactivationEnvironmentMessage:
    "Veillez à maintenir une température de 25°C pendant toute la réactivation puis dès que le milieu de réactivation est prêt (acide malique < 0,5 g/L), doubler son volume avec le moût en fermentation du pied de cuve pour obtenir un volume de :",
  duration: "Durée",
  mustWaterMixing:
    "d’un mélange Moût / Eau chaude pour obtenir une température de 35°C.",
  feedStockFirstMessagePart1:
    "(veillez à bien respecter les températures indiquées ci-dessus, notamment à ne pas dépasser les 40°C, sous peine de détruire les cellules actives). Agiter énergiquement afin d’éviter la formation d’un agglomérat.",
  feedStockFirstMessagePart2:
    " Laisser reposer durant 20 à 30 minutes puis homogénéiser la préparation, en agitant de nouveau, avant d’élaborer le pied de cuve.",
  rehydratedYeasts: "Levures réhydratées",
  must: "Moût",
  feedStockVolumeComplement:
    "Incorporation du Milieu de Réactivation au Pied de cuve au bout de 3 jours",
  feedStockTotalVolume: "Volume total du pied de cuve : ",
  feedStockSecondMessage:
    "Maintenir la température du Pied de Cuve à 25°C pendant la F.A. puis 20°C pendant la F.M.L. Faire une détermination de la quantité de l’acide malique présent à l’ensemencement. Le suivi sera ensuite réalisé après 6 ou 7 jours puis tous les 2 jours.",
  feedStockFinalMessagePart1:
    "L’ensemencement de la cuverie se fera à raison de 3%, en incorporant par le haut des cuves et sans brassage une fois que les 2/3 de l’acide malique présent dans le Pied de Cuve est consommé.",
  feedStockFinalMessagePart2:
    "L’incorporation peut se faire dans les vins en cours de F.A. ou en fin de F.A., avec ou sans soutirage préalable des cuves à ensemencer.",
  feedStockFinalMessagePart3:
    "Pour bénéficier de l’inertie thermique des cuves, il est conseillé de couper la régulation de température à 1005 - 1010.",
  feedStockFinalMessagePart4:
    "Maintenir ensuite la température entre 18°C et 20°C. Le premier contrôle se fera minimum après 15 jours d’ensemencement.",
  maloTankFinalMessage:
    "Ces valeurs vous sont transmises à titre indicatif et ne remplaceront en aucun cas l’avis de votre œnologue conseil.",

  //Calculateur Correction de la surpression d'un vin effervescent
  sparklinWineOverpressureTitle:
    "Correction de la surpression d'un vin effervescent",
  sparklingWineOverpressureMessage:
    "Calculez la correction de la surpression d’un vin effervescent à une température donnée",
  overpressure: "Surpression",
  temperature: "Température",
  onlyInteger: "(Valeur entière seulement)",

  //Gestion de Cuve
  managementTanks: "Gestion de mes cuves",
  volume: "Volume",
  currentProtocol: "Protocole en cours",
  startProtocol: "Débuter un protocole",
  continueProtocol: "Poursuivre le protocole",
  stopProtocol: "Arrêter le protocole",
  startNewProtocol: "Débuter un nouveau protocole",
  addATank: "Ajouter une cuve",
  noTankFound: "Aucune cuve n'a été trouvé",
  textNoTankFound:
    "Nous n'avons pas trouvé de cuve sur votre appareil. Ajoutez-en une en cliquant ci-dessous !",
  addingTank: "Ajout d’une cuve",
  nameTank: "Numéro de votre cuve",
  volumeTank: "Volume de la cuve",
  addTank: "Ajouter la cuve",
  dontAddTank: "Annuler l'ajout",
  confirmationModalDeleteTank: "Êtes-vous sûr de vouloir supprimer la cuve ?",
  subTitleConfirmationModalDeleteTank:
    "En supprimant la cuve, le protocole associé sera supprimé",
  confirmationModalDeleteProtocol:
    "Êtes-vous sûr de vouloir arrêter le protocole ?",
  confirmationModalNewProtocol:
    "Êtes-vous sûr de vouloir débuter un nouveau protocole ?",
  confirmationModalDeleteProtocolInProgress:
    "Êtes-vous sûr de vouloir supprimer le protocole en cours ?",
  protocoleType: "Type de protocole : ",
  sampleTitle: "Titre de l'échantillon : ",
  date: "Date",
  tank: "Cuve",
  sampleNameOnly: "Numéro de l'échantillon",
  FMLstep1: "Pressurage",
  FMLstep2: "La cuvée",
  FMLstep3: "La taille",
  FMLstep4: "Fermentation Alcoolique",
  FMLstep5: "Levurage",
  FMLstep6: "Nutrition des levures",
  FMLstep7: "Calcul du pied de cuve",
  Macerationstep1: "Tri de la récolte - Foulage - Eraflage",
  Macerationstep2: "Cuvaison",
  Macerationstep3: "Ajout de l'enzyme",
  Macerationstep4: "Macération pré-fermentaire à froid",
  Macerationstep5: "Fermentation alcoolique",
  Macerationstep6: "Macération",
  Macerationstep7: "Chaptalisation",
  Macerationstep8: "Décuvage - Pressurage",
  Macerationstep9: "Fermentation malolactique",
  Charmatstep1: "1.1 - Réhydratation",
  Charmatstep2: "1.2 - Acclimatation/Etape 1",
  Charmatstep3: "1.2 - Acclimatation/Etape 2",
  Charmatstep4: "1.2 - Acclimatation/Etape 3",
  Charmatstep5: "1.3 - Pied de Cuve",
  Charmatstep6: "1.3 - Pied de Cuve/Etape 1",
  Charmatstep7: "1.3 - Pied de Cuve/Etape 2",
  Charmatstep8: "1.3 - Pied de Cuve/Etape 3",
  Charmatstep9: "Étape intermédiaire",
  Charmatstep10: "Étape intermédiaire",
  Charmatstep11: "Étape intermédiaire",
  Charmatstep12: "1.4 - Étapes clés de la fermentation alcoolique",
  Charmatstep13: "2.1 - Étapes clés de l’élevage",
  Charmatstep14: "2.1 - Étapes clés de l’élevage",
  protocolCompleted: "Protocole terminé",
  continue: "Poursuivre",
  seeResults: "Voir les résultats",
  startnewProtocol: "Démarrer un nouveau protocole",
  runningProtocole: "Protocoles en cours",

  goToNextStep: "Prochaine étape",
  goBack: "Revenir",
  none: "Aucun",
  by: "Par : ",
  of: "de",
  in: "dans",
  to: "à",
  and: "et",
  at: "à",
  or: "ou",
  yes: "Oui",
  no: "Non",
  between: "entre",
  add: "Ajouter ",
  perDay: "par jour",
  wait: "Patienter",
  minutes: "minutes",
  hour: "heure",
  hours: "heures",
  days: "jours",
  liters: "litres",
  inHectoliters: "en hectolitres (hL)",
  g: "g",
  kg: "kg",
  cL: "cL",
  L: "L",
  dissolve: "Dissoudre",
  yeasting: "LEVURAGE",
  SULFITING: "SULFITAGE",
  sulfiting: "Sulfitage",
  nutrition: "NUTRITION",
  tannin: "TANNISAGE",
  enzyme: "ENZYMAGE",
  anthocyaninsStabilization: "STABILIZATION DES ANTHOCYANES",
  addInTank: "Ajouter directement dans la cuve",
  mustClarification: "Clarification du moût",

  FMLInterStep:
    "Étape intermédiaire à réaliser avant de poursuivre le protocole",

  //Mentions Légales
  editor: "EDITEUR",
  websiteEditBy: "Cette application est édité par",
  headOffice: "Siège",
  tel: "Tél.",
  fax: "Fax.",
  sharedCapital: "Capital social",
  siretNumber: "N° SIRET",
  numero: "N°",
  TVA: "TVA",
  directorOfPublication: "Directeur de la publication",
  host: "HEBERGEUR",
  reproductiveRights: "DROITS DE REPRODUCTION",
  reproductiveRightsContent:
    "Les informations présentées sur ce site sont publiques. La reproduction des informations de ce site, à l’exception de l’iconographie, est autorisée à la condition d’en mentionner la source et de créer un lien sur la ou les pages de référence. Les informations de ce site ne peuvent en aucun cas être utilisées à des fins commerciales ou publicitaires.",
  personnalData: "DONNÉES PERSONNELLES",
  personnalDataContent:
    "Lorsque des données présentes sur ce site ont un caractère nominatif, les utilisateurs doivent en faire un usage conforme aux réglementations en vigueur et aux recommandations de la Commission nationale de l’informatique et des libertés (CNIL). Les informations utilisées ne doivent l’être qu’à des fins personnelles, associatives ou professionnelles, toute diffusion ou utilisation à des fins commerciales ou publicitaires étant exclues.",

  //Formulaire de contact
  titleContact: "Contact",
  errorMessageContact: "Votre message ou votre adresse mail est vide.",
  errorSendMail:
    "Votre message n'a pas pu être envoyé, veuillez réessayer plus tard.",
  send: "Envoyer",
  chaptalisationWarning:
    "La quantité de sucre cristallisé à apporter est donnée à titre indicatif. La chaptalisation est soumise à réglementation ; se référer à la législation en vigueur.",
  close: "Fermer",
  inCaseOfProblem: "En cas de problème : ",
  laboSecretary: "Secrétaire laboratoire",
  reimsAgency: "Agence de Reims",
  oenologist: "Œnologue",
  oenologistf: "Œnologue",
  technicalReferent: "Référent technique",
  oenologyReferent: "Référent Œnologie Conseil",
  barSurSeineAgency: "Agence de Bar sur Seine",
  champagneAssistant: "Assistante Champagne",
  mailSendWithSuccess:
    "Nous vous remercions pour votre demande d’informations. Celle-ci sera traitée dans les meilleurs délais par nos équipes.",
  lastName: "Nom",
  firstName: "Prénom",
  phoneNumber: "Numéro de téléphone",
  mailAddress: "Adresse Email",
  postalAddress: "Adresse Postale",
  zipCode: "Code Postal",
  city: "Ville",
  writeHere: "Écrivez votre requête ici",
  scanMe: "Scannez-moi pour télécharger l’application mobile",
};

export default Fr;
