import { IonCard } from "@ionic/react";
import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const CalculatorsPage: React.FC = () => {
  return (
    <div className="calculatorsPage">
      <div style={{color: "white", marginTop: "5%"}}>
      <span style={{ fontWeight: "bold" }}>
        <FormattedMessage id="calculators" />
      </span>
      <br /> <FormattedMessage id="decisionSupportToolsCaps" />
      </div>
      <div className="calculatorsList" style={{marginTop: '30%'}}>
        <Link to="/calculator/so2Molecular">
          <IonCard className="calculatorCard" color="white">
            <div>
              <FormattedMessage id="calculationOf" />
              <br />
              <span style={{ fontWeight: "bold" }}>
              <FormattedMessage id="so2Molecular" values={{ sub2: <sub>2</sub> }}/>
              </span>
            </div>
          </IonCard>
        </Link>
        <br />
        <Link to="/calculator/soufreAcetaldehyde">
          <IonCard className="calculatorCard" color="white">
            <div>
              <FormattedMessage id="calculationOf" />
              <br />
              <span style={{ fontWeight: "bold" }}>
                {" "}
                <FormattedMessage id="sulfurAcetaldehyde" />
              </span>
            </div>
          </IonCard>
        </Link>
        <br />
        <Link to="/calculator/chaptalisation">
          <IonCard className="calculatorCard" color="white">
            <div>
              <FormattedMessage id="calculationOfThe" />
              <br />
              <span style={{ fontWeight: "bold" }}>
                {" "}
                <FormattedMessage id="chaptalization" />
              </span>
            </div>
          </IonCard>
        </Link>
        <br />
        <Link to="/calculator/maloTank">
          <IonCard className="calculatorCard" color="white">
            <div>
              <FormattedMessage id="calculationFoot" />
              <br />
              <span style={{ fontWeight: "bold" }}>
                {" "}
                <FormattedMessage id="maloTankLowPh" />
              </span>
            </div>
          </IonCard>
        </Link>
        <br />
        <Link to="/calculator/sparklingWineOverpressure">
          <IonCard className="calculatorCard" color="white">
            <div>
              <FormattedMessage id="calculationCorrection" />
              <br />
              <span style={{ fontWeight: "bold" }}>
                <FormattedMessage id="overpressureSparklingWine" />
              </span>
            </div>
          </IonCard>
        </Link>
      </div>
    </div>
  );
};

export default CalculatorsPage;
