import {
  createAnimation,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonIcon,
  IonModal,
} from "@ionic/react";
import React, { useState } from "react";
import { trash } from "ionicons/icons";
import { FormattedMessage } from "react-intl";
import Protocole from "../../types/Protocole";
import { Link, useHistory } from "react-router-dom";
import Tank from "../../types/Tank";

const animationEnterModal = (baseEl: any) => {
  const backdropAnimation = createAnimation()
    .addElement(baseEl.querySelector("ion-backdrop")!)
    .fromTo("opacity", "0.4", "var(--backdrop-opacity)");

  const wrapperAnimation = createAnimation()
    .addElement(baseEl.querySelector(".modal-wrapper")!)
    .keyframes([
      {
        borderRadius: "20px",
        offset: 0,
        opacity: "0",
        transform: "scale(0)",
        height: "272px",
        margin: "auto",
        width: "341px",
        textAlign: "center",
      },
      {
        borderRadius: "20px",
        offset: 1,
        opacity: "1",
        transform: "scale(1)",
        height: "272px",
        margin: "auto",
        width: "341px",
      },
    ]);

  return createAnimation()
    .addElement(baseEl)
    .easing("ease-out")
    .duration(250)
    .addAnimation([backdropAnimation, wrapperAnimation]);
};

interface DeleteProps {
  tank: Tank;
  showModalDeleteTank: string | null;
  setShowModalDeleteTank: (b: string | null) => void;
  setTanksList: (list: Tank[]) => void;
}

const DeleteTankModal: React.FC<DeleteProps> = ({
  tank,
  showModalDeleteTank,
  setShowModalDeleteTank,
  setTanksList,
}) => {
  return (
    <IonModal
      cssClass="modalConfirmation"
      isOpen={showModalDeleteTank === tank.id}
      keyboard-close={true}
      enterAnimation={animationEnterModal}
      leaveAnimation={(baseEl) =>
        animationEnterModal(baseEl).direction("reverse")
      }
      onDidDismiss={() => setShowModalDeleteTank(null)}
    >
      <span className="modalTitle">
        <FormattedMessage id="confirmationModalDeleteTank" />
      </span>{" "}
      <span className="modalSubtitle">
        <FormattedMessage id="subTitleConfirmationModalDeleteTank" />
      </span>
      <div>
        <IonButton
          className="buttonModal"
          onClick={() => {
            setShowModalDeleteTank(null);
            tank.protocoles.forEach((id) => {
              localStorage.removeItem("protocol_" + id);
            });
            localStorage.removeItem("tank_" + tank.id);
            setTanksList(
              Object.keys(localStorage)
                .filter((key: string) => key.startsWith("tank_"))
                .map((key: string) => JSON.parse(localStorage[key]))
                .filter(
                  (tank: Tank) =>
                    tank.protocoles !== undefined && tank.protocoles !== null
                )
            );
          }}
        >
          <FormattedMessage id="yes" />
        </IonButton>
        <IonButton
          className="buttonModal"
          onClick={() => {
            setShowModalDeleteTank(null);
          }}
        >
          <FormattedMessage id="no" />
        </IonButton>
      </div>
    </IonModal>
  );
};

const TankListPage: React.FC = () => {
  interface Tanks {
    name: string;
    id: string;
    protocoles: Array<string>;
    volume: number;
  }

  const language = localStorage.getItem('language');

  const [tanksList, setTanksList] = useState<Array<Tanks>>(
    Object.keys(localStorage)
      .filter((key: string) => key.startsWith("tank_"))
      .map((key: string) => JSON.parse(localStorage[key]))
      .filter(
        (tank: Tank) =>
          tank.protocoles !== undefined && tank.protocoles !== null
      )
  );
  const [showModalDeleteTank, setShowModalDeleteTank] =
    useState<string | null>(null);
  const [showModalDeleteProtocol, setShowModalDeleteProtocol] =
    useState<string | null>(null);
  const [showModalStartNewProtocol, setShowModalStartNewProtocol] =
    useState<boolean>(false);
  const history = useHistory();

  return (
    <div className="tankListPage">
      <span className="pageTitle">
        <FormattedMessage id="managementTanks" />
      </span>
      <div className="tanksList">
        {tanksList.length === 0 ? (
          <IonCard className="cardTank">
            <IonCardTitle>
              <FormattedMessage id="noTankFound" />
            </IonCardTitle>
            <IonCardContent>
              <FormattedMessage id="textNoTankFound" />
            </IonCardContent>
            <Link to="/tank/add">
              <IonButton color="dark">
                <FormattedMessage id="addATank" />
              </IonButton>
            </Link>
          </IonCard>
        ) : (
          <div>
            {tanksList.map((tank) => {
              return (
                <div key={tank.id}>
                  {tank.protocoles.length === 0 ? (
                    <IonCard className="cardTank" key={tank.id}>
                      <IonCardTitle>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "22pt",
                            textAlign: "left",
                          }}
                        >
                          {tank.name}
                        </span>
                        <IonIcon
                          icon={trash}
                          className="iconTrash"
                          onClick={() => {
                            setShowModalDeleteTank(tank.id);
                          }}
                        />
                        <DeleteTankModal
                          tank={tank}
                          showModalDeleteTank={showModalDeleteTank}
                          setShowModalDeleteTank={setShowModalDeleteTank}
                          setTanksList={setTanksList}
                        />
                      </IonCardTitle>
                      <IonCardContent className="cardTankContent">
                        <FormattedMessage id="volume" />:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {tank.volume} {language === 'RU-RU' ? 'гл' : 'HL'}
                        </span>
                        <br />
                        <FormattedMessage id="currentProtocol" />:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          <FormattedMessage id="none" />
                        </span>
                      </IonCardContent>
                      <Link to="/protocoles">
                        <IonButton style={{ color: "black" }} color="warning">
                          <FormattedMessage id="startProtocol" />
                        </IonButton>
                      </Link>
                    </IonCard>
                  ) : (
                    tank.protocoles.map((protocolId: string) => {
                      const protocol: Protocole = Object.keys(localStorage)
                        .filter(
                          (key: string) => key === "protocol_" + protocolId
                        )
                        .map((key: string) => JSON.parse(localStorage[key]))[0];
                      return (
                        <>
                          <IonCard className="cardTank" key={protocolId}>
                            <IonCardTitle>
                              <span style={{ fontWeight: "bold" }}>
                                {tank.name}
                              </span>
                              <IonIcon
                                icon={trash}
                                className="iconTrash"
                                onClick={() => {
                                  setShowModalDeleteTank(tank.id);
                                }}
                              />
                              <DeleteTankModal
                                tank={tank}
                                showModalDeleteTank={showModalDeleteTank}
                                setShowModalDeleteTank={setShowModalDeleteTank}
                                setTanksList={setTanksList}
                              />
                            </IonCardTitle>
                            <IonCardContent>
                              <FormattedMessage id="volume" />:{" "}
                              <span style={{ fontWeight: "bold" }}>
                                {tank.volume} {language === 'RU-RU' ? 'гл' : 'hL'}
                              </span>
                              <br />
                              <FormattedMessage id="currentProtocol" />:{" "}
                              <span style={{ fontWeight: "bold" }}>
                                <FormattedMessage
                                  id={`Title${protocol.type}Step0${
                                    protocol.type === "Maceration"
                                      ? "_classic"
                                      : ""
                                  }`}
                                />{" "}
                                -{" "}
                                {protocol.type === "Charmat" ? (
                                  <FormattedMessage id="martinottiMethod" />
                                ) : (
                                  <FormattedMessage id="traditionalMethod" />
                                )}
                              </span>
                            </IonCardContent>
                            {protocol.status === "En cours" ? (
                              <>
                                <Link
                                  to={{
                                    pathname: `/protocoles/protocole${protocol.type}`,
                                    state: {
                                      protocolId: protocol.id,
                                    },
                                  }}
                                >
                                  <IonButton
                                    style={{ color: "black" }}
                                    color="warning"
                                  >
                                    <FormattedMessage id="continueProtocol" />
                                  </IonButton>
                                </Link>
                                <IonButton
                                  style={{ color: "black" }}
                                  color="warning"
                                  onClick={() => {
                                    setShowModalDeleteProtocol(protocol.id);
                                  }}
                                >
                                  <FormattedMessage id="stopProtocol" />
                                </IonButton>
                                <IonModal
                                  enterAnimation={animationEnterModal}
                                  leaveAnimation={(baseEl) =>
                                    animationEnterModal(baseEl).direction(
                                      "reverse"
                                    )
                                  }
                                  isOpen={
                                    showModalDeleteProtocol === protocol.id
                                  }
                                  cssClass="modalConfirmation"
                                  keyboard-close={true}
                                  onDidDismiss={() => {
                                    setShowModalDeleteProtocol(null);
                                  }}
                                >
                                  <span className="modalTitle">
                                    <FormattedMessage id="confirmationModalDeleteProtocol" />
                                  </span>
                                  <div>
                                    <IonButton
                                      className="buttonModal"
                                      onClick={() => {
                                        setShowModalDeleteTank(null);
                                        const protocoles =
                                          tank.protocoles.filter(
                                            (id) => id !== protocolId
                                          );
                                        localStorage.setItem(
                                          "tank_" + tank.id,
                                          JSON.stringify({
                                            ...tank,
                                            protocoles,
                                          })
                                        );
                                        localStorage.removeItem(
                                          "protocol_" + protocolId
                                        );
                                        setTanksList(
                                          Object.keys(localStorage)
                                            .filter((key: string) =>
                                              key.startsWith("tank_")
                                            )
                                            .map((key: string) =>
                                              JSON.parse(localStorage[key])
                                            )
                                        );
                                      }}
                                    >
                                      <FormattedMessage id="yes" />
                                    </IonButton>
                                    <IonButton
                                      className="buttonModal"
                                      onClick={() => {
                                        setShowModalDeleteProtocol(null);
                                      }}
                                    >
                                      <FormattedMessage id="no" />
                                    </IonButton>
                                  </div>
                                </IonModal>
                              </>
                            ) : (
                              <>
                                <Link
                                  to={{
                                    pathname: `/protocoles/protocole${protocol.type}`,
                                    state: {
                                      protocolId: protocol.id,
                                    },
                                  }}
                                >
                                  <IonButton
                                    style={{ color: "black" }}
                                    color="warning"
                                  >
                                    <FormattedMessage id="seeResults" />
                                  </IonButton>
                                </Link>
                                <IonButton
                                  style={{ color: "black" }}
                                  color="warning"
                                  onClick={() => {
                                    setShowModalStartNewProtocol(true);
                                  }}
                                >
                                  <FormattedMessage id="startNewProtocol" />
                                </IonButton>
                                <IonModal
                                  enterAnimation={animationEnterModal}
                                  leaveAnimation={(baseEl) =>
                                    animationEnterModal(baseEl).direction(
                                      "reverse"
                                    )
                                  }
                                  isOpen={showModalStartNewProtocol}
                                  cssClass="modalConfirmation"
                                  keyboard-close={true}
                                  onDidDismiss={() => {
                                    setShowModalStartNewProtocol(false);
                                  }}
                                >
                                  <span className="modalTitle">
                                    <FormattedMessage id="confirmationModalNewProtocol" />
                                  </span>
                                  <div>
                                    <IonButton
                                      className="buttonModal"
                                      onClick={() => {
                                        setShowModalStartNewProtocol(false);
                                        localStorage.removeItem(
                                          "protocol_" + protocol.id
                                        );
                                        history.push("/protocoles");
                                      }}
                                    >
                                      <FormattedMessage id="yes" />
                                    </IonButton>
                                    <IonButton
                                      className="buttonModal"
                                      onClick={() => {
                                        setShowModalStartNewProtocol(false);
                                      }}
                                    >
                                      <FormattedMessage id="no" />
                                    </IonButton>
                                  </div>
                                </IonModal>
                              </>
                            )}
                          </IonCard>
                        </>
                      );
                    })
                  )}
                </div>
              );
            })}
            <Link to="/tank/add">
              <IonButton color="dark">
                <FormattedMessage id="addATank" />
              </IonButton>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default TankListPage;
