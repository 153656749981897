/* eslint-disable react-hooks/rules-of-hooks */
import {
  IonButton,
  IonCard,
  IonDatetime,
  IonIcon,
  IonInput,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { arrowForwardCircle, returnUpBack } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import {
  computeClassicMaceration,
  computePrefermMaceration,
} from "../../methods/compute";
import EndOfProtocole from "../component/endOfProtocole";
import MultiChoicesDisplay from "../component/multiChoicesDisplay";
import SimpleChoice from "../component/simpleChoice";
import SimpleChoiceSeveralValues from "../component/simpleChoiceSeveralValues";
import Protocole from "../../types/Protocole";
import Tank from "../../types/Tank";
import Maceration from "../../types/Maceration";
import translations from "../../modules/translations";
import { InputNumberField } from "../component/inputFields";

const compareWith = (o1: Tank, o2: Tank) => {
  return o1 && o2 ? o1.id === o2.id : o1 === o2;
};

interface Props {
  location: Location;
}

interface Location {
  state: State;
}

interface State {
  protocolId: string | undefined;
}

const ProtocoleMaceration: React.FC<Props> = (props) => {
  const [protocolId, setProtocolId] = useState<string | undefined>(
    props.location?.state?.protocolId
  );
  const protocoleSelected: Protocole | undefined = protocolId
    ? Object.keys(localStorage)
      .filter((key) => key === "protocol_" + protocolId)
      .map((key) => JSON.parse(localStorage[key]))[0]
    : undefined;
  const history = useHistory();
  const tanks: Tank[] = Object.keys(localStorage)
    .filter((key) => key.startsWith("tank_"))
    .map((key) => JSON.parse(localStorage[key]));
  const tankSelectedInProtocol =
    protocoleSelected && protocoleSelected.tankId
      ? tanks.filter((tank) => tank.id === protocoleSelected?.tankId)[0]
      : undefined;
  const [tankSelected, setTankSelected] = useState<Tank | undefined>(
    tankSelectedInProtocol
  );
  const [harvestWeight, setHarvestWeight] = useState<number>(
    (protocoleSelected && protocoleSelected.harvestWeight) || 0
  );
  const [selectedDate, setSelectedDate] = useState<string>(
    protocoleSelected ? protocoleSelected.implementationDate : ""
  );
  const [sampleName, setSampleName] = useState<string>(
    protocoleSelected ? protocoleSelected.name : ""
  );
  const [macerationType, setMacerationType] = useState<string>(
    (protocoleSelected && protocoleSelected.macerationType) || "coldPreferm"
  );
  const [step, setStep] = useState<number>(
    protocoleSelected ? protocoleSelected.step : 0
  );
  const savedStep: number = step;
  const [computedData, setComputedData] = useState<Maceration | undefined>(
    protocoleSelected && tankSelected && macerationType
      ? macerationType === "classic"
        ? computeClassicMaceration(harvestWeight, tankSelected?.volume)
        : computePrefermMaceration(harvestWeight, tankSelected?.volume)
      : undefined
  );
  const [addNewTank, setAddNewTank] = useState<boolean>(false);
  const [newTankName, setNewTankName] = useState<string>();
  const [newTankVolume, setNewTankVolume] = useState<number>(0);
  const [errorNameTank, setErrorNameTank] = useState<boolean>(false);
  const [errorVolumeTank, setErrorVolumeTank] = useState<boolean>(false);
  const [errorTank, setErrorTank] = useState<boolean>(false);
  const [errorDate, setErrorDate] = useState<boolean>(false);
  const [errorHarvest, setErrorHarvest] = useState<boolean>(false);

  const language = localStorage.getItem("language") as string;
  const translation = translations[language];

  const mainRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (mainRef && mainRef.current) {
      mainRef.current.scrollIntoView();
    }
  }, [step]);

  const [selectedProduct, setSelectedProduct] =
    useState<string>("NUTRICELL® AA");

  return (
    <div ref={mainRef}>
      <div>
        <span className="titleProtocols">
          <FormattedMessage
            id={`TitleMacerationStep${step}_${macerationType}`}
          />
          <br />
        </span>
        <span className="subTitleProtocols">
          <FormattedMessage
            id={`SubtitleMacerationStep${step}_${macerationType}`}
          />
        </span>
      </div>
      <IonCard
        style={{
          color: "black",
          width: "40%",
          padding: "5%",
          fontFamily: "Lato",
          fontSize: "15pt",
          fontWeight: 600,
        }}
        onClick={() =>
          step === 0
            ? history.goBack()
            : macerationType === "classic" && step === 5
              ? setStep(2)
              : setStep(step - 1)
        }
      >
        <IonIcon icon={returnUpBack} />{" "}
        <span style={{ paddingLeft: "5%" }}>
          <FormattedMessage id="goBack" />
        </span>
      </IonCard>
      {step === 0 && (
        <IonCard className="inputCard">
          <div style={{ padding: "5% 3%" }}>
            <div className="inputDiv">
              <div className="inputTitle">
                <FormattedMessage id="selectTank" />
              </div>
              <div className="descriptionText">
                <FormattedMessage id="askSelectedTank" />
              </div>
              <IonSelect
                className="inputField"
                compareWith={compareWith}
                value={tankSelected}
                placeholder={`${translation["selectATank"]}`}
                onIonChange={(e) => {
                  if (e.detail.value === "new") {
                    setNewTankVolume(0);
                    setNewTankName("");
                    setTankSelected({
                      id: "new",
                      name: "",
                      volume: 0,
                      protocoles: [],
                    });
                    setAddNewTank(true);
                  } else {
                    setErrorTank(false);
                    setAddNewTank(false);
                    setTankSelected(e.detail.value);
                  }
                }}
              >
                {tanks.map((tank) => (
                  <IonSelectOption value={tank} key={tank?.id}>
                    {tank?.name}
                  </IonSelectOption>
                ))}
                <IonSelectOption value={"new"}>
                  <FormattedMessage id="addATank" />
                </IonSelectOption>
              </IonSelect>
              {errorTank && (
                <span className="errorMessage">
                  <FormattedMessage id="errorSelectTank" />
                  <br />
                </span>
              )}
              {addNewTank && (
                <>
                  <IonCard className="addNewTankCardOnProtocols">
                    <span style={{ fontWeight: "bold" }}>
                      <FormattedMessage id="nameTank" />{" "}
                    </span>
                    <br />
                    <IonInput
                      value={newTankName}
                      placeholder={`${translation["nameTheTank"]}`}
                      onIonChange={(e) => {
                        setNewTankName(e.detail.value!);
                      }}
                    />
                    {errorNameTank && (
                      <span className="errorMessage">
                        {" "}
                        <FormattedMessage id="errorMessageInput" />
                        <br />
                        <br />
                      </span>
                    )}
                    <span style={{ fontWeight: "bold" }}>
                      <FormattedMessage id="volumeTank" />
                      <br />
                      <span style={{ fontFamily: "Lato", fontSize: "12px" }}>
                        <FormattedMessage id="inHectoliters" />
                      </span>{" "}<br />
                      <span className="onlyIntergerMessage">
                        <FormattedMessage id="onlyInteger" />
                      </span>
                    </span>
                    <InputNumberField value={newTankVolume} setValue={setNewTankVolume} parseValue={(val) => parseInt(val, 10)}/>
                    {errorVolumeTank && (
                      <span className="errorMessage">
                        {" "}
                        <FormattedMessage id="errorNumberInput" />
                        <br />
                        <br />
                      </span>
                    )}
                    <IonButton
                      style={{ color: "black" }}
                      color="dark"
                      onClick={() => {
                        if (!newTankName) {
                          setErrorNameTank(true);
                        } else if (newTankVolume === 0) {
                          setErrorVolumeTank(true);
                        } else {
                          const id =
                            Math.random().toString(36).substring(2, 15) +
                            Math.random().toString(36).substring(2, 15);
                          localStorage.setItem(
                            "tank_" + id,
                            JSON.stringify({
                              name: newTankName,
                              volume: newTankVolume,
                              id: id,
                              protocoles: [],
                            })
                          );
                          setTankSelected(
                            Object.keys(localStorage)
                              .filter((key) => key === "tank_" + id)
                              .map((key) => JSON.parse(localStorage[key]))[0]
                          );
                          setAddNewTank(false);
                        }
                      }}
                    >
                      <FormattedMessage id="addTank" />
                    </IonButton>
                  </IonCard>
                </>
              )}
              <div
                style={{
                  fontFamily: "Lato",
                  fontSize: "15pt",
                  marginTop: "2%",
                }}
              >
                <FormattedMessage id="tankVolume" /> {tankSelected?.volume} {language === 'RU-RU' ? 'гл' : 'hL'}
              </div>
              <div style={{ fontFamily: "Lato", fontSize: "13pt" }}>
                <FormattedMessage id="tankVolumeInfo" />
              </div>
            </div>
            <div className="inputDiv">
              <div className="inputTitle">
                <FormattedMessage id="harvestWeight" />
              </div>
              <div className="descriptionText">
                <FormattedMessage id="askHarvestWeight" />
              </div>{" "}
              <span className="onlyIntergerMessage">
                <FormattedMessage id="onlyInteger" />
              </span>
              <InputNumberField value={harvestWeight} setValue={setHarvestWeight} parseValue={(val) => parseInt(val, 10)} />
              {errorHarvest && (
                <span className="errorMessage">
                  <FormattedMessage id="errorNumberInput" />
                  <br />
                </span>
              )}
            </div>
            <div className="inputDiv">
              <div className="inputTitle">
                <FormattedMessage id="beginningDate" />
              </div>
              <div className="descriptionText">
                <FormattedMessage id="askBeginningDate" />
              </div>
              <IonDatetime
                className="inputField"
                displayFormat="DD/MM/YY"
                pickerOptions={{
                  buttons: [
                    {
                      text: translation["close"],
                    },
                    {
                      text: 'OK',
                      handler: (e) => {
                        const date = new Date();
                        date.setFullYear(
                          e.year.value,
                          e.month.value - 1,
                          e.day.value
                        );
                        setErrorDate(false);
                        setSelectedDate(date.toString());
                      },
                    },
                  ],
                }}
                placeholder={`${translation["selectADate"]}`}
                value={selectedDate}
                onIonChange={(e) => {
                  setErrorDate(false);
                  setSelectedDate(e.detail.value!);
                }}
              />
              {errorDate && (
                <span className="errorMessage">
                  <FormattedMessage id="errorDate" />
                  <br />
                </span>
              )}
            </div>
            <div className="inputDiv">
              <div className="inputTitle">
                <FormattedMessage id="sampleName" />
                <br />
                <span style={{ fontStyle: "italic", fontSize: "18px" }}>
                  (<FormattedMessage id="optionnal" />)
                </span>
              </div>
              <div className="descriptionText">
                <FormattedMessage id="askSampleName" />
              </div>
              <IonInput
                className="inputField"
                value={sampleName}
                placeholder={`${translation["sampleNameOnly"]}`}
                onIonChange={(e) => {
                  setSampleName(e.detail.value!);
                }}
              />
            </div>
            <div className="inputDiv">
              <div className="inputTitle">
                <FormattedMessage id="macerationType" />
              </div>
              <div className="descriptionText">
                <FormattedMessage id="askMacerationType" />
              </div>
              <IonSelect
                className="inputField"
                value={macerationType}
                placeholder={`${translation["selectAMacerationType"]}`}
                onIonChange={(e) => {
                  setMacerationType(e.detail.value!);
                }}
              >
                <IonSelectOption value="coldPreferm">
                  <FormattedMessage id="coldPreferm" />
                </IonSelectOption>
                <IonSelectOption value="classic">
                  <FormattedMessage id="classic" />{" "}
                </IonSelectOption>
              </IonSelect>
            </div>
          </div>
        </IonCard>
      )}
      {step === 1 && (
        <div>
          <div
            style={{
              padding: "20% 0 ",
              fontFamily: "Lato",
              fontSize: "18pt",
              fontWeight: 800,
            }}
          >
            <FormattedMessage id="harvestSorting" />
          </div>
          <div
            style={{
              width: "30%",
              marginLeft: "35%",
              borderBottom: "2px solid #EDAE32",
            }}
          />
          <div
            style={{
              padding: "20% 0 ",
              fontFamily: "Lato",
              fontSize: "18pt",
              fontWeight: 800,
            }}
          >
            <FormattedMessage id="foulageEraflage" />
          </div>
        </div>
      )}
      {step === 2 && (
        <div>
          <div className="actionDiv">
            <SimpleChoiceSeveralValues
              action={{
                title: "SULFITING",
                method: "add",
                product: {
                  name: "BAKTOL® 150",
                  unit: "cL",
                  values: [
                    computedData?.sulfiting?.baktolMinVolume,
                    computedData?.sulfiting?.baktolMaxVolume,
                  ],
                  description: `(6 ${translations[language]['to']} 8 ${language === 'RU-RU' ? 'сл' : 'cL'}/150 ${translations[language!]['kg']})`,
                },
              }}
            />
          </div>
          {macerationType === "classic" && (
            <div className="actionDiv">
              <SimpleChoice
                action={{
                  title: "enzyme",
                  method: "add",
                  product: {
                    name: "VIAZYM® EXTRACT PREMIUM",
                    unit: "g",
                    value: computedData?.enzyme?.viazymWeight,
                    description: `(5 ${translations[language!]['g']}/150 ${translations[language!]['kg']})`,
                  },
                }}
              />
            </div>
          )}
          {macerationType === "coldPreferm" && (
            <div>
              <div
                style={{
                  backgroundColor: "#1B1D26",
                  color: "white",
                  height: "86px",
                  width: "100%",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FormattedMessage id="decreaseTemperature" />
              </div>
              <div className="actionDiv">
                <span className="productValue">
                  {computedData?.carboglace?.carboglaceWeight}
                </span>
                <span className="productUnit">{translations[language!]['kg']}</span>
                <br />
                <FormattedMessage id="of" />
                <span className="productName"> {localStorage.getItem("language") === "EN-GB" ? "Dry Ice" : "Carboglace"} </span>
                <br />
                (0,5kg/100kg <FormattedMessage id="toDecreaseOf1Deg" />)
              </div>
            </div>
          )}
          <div className="actionDiv">
            <span className="actionTitle">
              <FormattedMessage id="tannin" />
            </span>{" "}
            <span className="actionMethod">
              - <FormattedMessage id="add" /> :
            </span>
            <br />
            <MultiChoicesDisplay
              setSelectedProduct={undefined}
              choicesList={[
                {
                  name: "VITANIL® VR",
                  unit: "g",
                  value: computedData?.tannin?.vitanilWeight,
                  description: `(10 ${translations[language!]['g']}/150 ${translations[language!]['kg']})`,
                },
                {
                  name: "CLAR T DEGORGEMENT",
                  unit: "cL",
                  value: computedData?.tannin?.clarVolume,
                  description: `(4 ${language === 'RU-RU' ? 'сл' : 'cL'}/150 ${translations[language!]['kg']})`,
                },
              ]}
            />
          </div>
          <div className="actionDiv">
            <SimpleChoice
              action={{
                title: "yeasting",
                method: "dissolve",
                product: {
                  name: "SO® FLAVOUR",
                  value: computedData?.yeasting?.flavourWeight,
                  unit: "kg",
                  description: `(5 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
                },
              }}
            />
            <span className="transitionWords">
              <FormattedMessage id="in" />
            </span>
            <br />
            <span className="productValue">
              {computedData?.yeasting?.waterVolume}
            </span>{" "}
            <span className="productUnit">
              <FormattedMessage id="liters" />
            </span>
          </div>
          <span style={{ fontWeight: "bold", fontSize: "18pt" }}>
            <FormattedMessage id="wait" />{" "}
            {macerationType === "classic" ? 48 : 24}{" "}
            <FormattedMessage id="hours" />
          </span>
        </div>
      )}
      {step === 3 && (
        <div>
          {macerationType === "coldPreferm" && (
            <div className="actionDiv">
              <span style={{ fontWeight: "bold", fontSize: "18pt" }}>
                <FormattedMessage id="atT24" />
              </span>
              <SimpleChoice
                action={{
                  title: "enzyme",
                  method: "add",
                  product: {
                    name: "VIAZYM® EXTRACT PREMIUM",
                    unit: "g",
                    value: computedData?.enzymeT24?.viazymWeight,
                    description: "(5g/150kg)",
                  },
                }}
              />
            </div>
          )}
        </div>
      )}
      {step === 4 && (
        <>
          {macerationType === "coldPreferm" && (
            <div>
              <div className="actionDiv">
                <div className="actionTitle">
                  <FormattedMessage id="prefermTitle" />
                </div>
              </div>
              <div className="actionDiv">
                <FormattedMessage id="pigeageWithoutOxygene" />:<br />
                <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                  1 <FormattedMessage id="to" /> 2{" "}
                  <FormattedMessage id="perDay" />
                </span>
              </div>
              <br />
              <div className="actionDiv">
                <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                  <FormattedMessage id="wait" /> 3{" "}
                  <FormattedMessage id="days" />
                </span>
                <br />
              </div>
            </div>
          )}
        </>
      )}
      {step === 5 && (
        <div>
          <div className="actionDiv">
            <span className="actionTitle">
              <FormattedMessage id="nutrition" />
            </span>{" "}
            - <FormattedMessage id="add" /> :<br />
            {macerationType === "coldPreferm" && (
              <MultiChoicesDisplay
                setSelectedProduct={setSelectedProduct}
                choicesList={[
                  {
                    name: "NUTRICELL® AA",
                    unit: "kg",
                    value: computedData?.nutrition?.nutricellWeight,
                    description: `(20 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
                  },
                  {
                    name: "PHOSPHATE DIAMMONIQUE",
                    unit: "kg",
                    value: computedData?.nutrition?.nutricellWeight,
                    description: `(20 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
                  },
                ]}
              />
            )}
            {macerationType === "classic" && (
              <div>
                <span className="productValue">
                  {computedData?.nutrition?.nutricellWeight}
                </span>{" "}
                <span className="productUnit">
                  <FormattedMessage id="kg" />
                </span>
                <br />
                <span className="transitionWords">
                  <FormattedMessage id="of" />
                </span>{" "}
                <span className="productName">NUTRICELL® AA</span> (20 {language === 'RU-RU' ? 'г/гл' : 'g/hL'})
                <br />
              </div>
            )}
          </div>
          {selectedProduct === "NUTRICELL® AA" && (
            <div className="actionDiv">
              <SimpleChoice
                action={{
                  title: "yeasting",
                  method: "dissolve",
                  product: {
                    name: "SO® FLAVOUR",
                    unit: "kg",
                    value:
                      macerationType === "coldPreferm"
                        ? computedData?.yeastingT72?.flavourWeight
                        : computedData?.yeastingT48?.flavourWeight,
                    description: `(20 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
                  },
                }}
              />
              <span className="transitionWords">
                <FormattedMessage id="in" />
              </span>
              <br />
              <span className="productValue">
                {macerationType === "coldPreferm"
                  ? computedData?.yeastingT72?.waterVolume
                  : computedData?.yeastingT48?.waterVolume}
              </span>{" "}
              <span className="productUnit">
                <FormattedMessage id="liters" />
              </span>
            </div>
          )}
        </div>
      )}
      {step === 6 && (
        <div>
          <div className="actionDiv">
            <div className="actionTitle">
              <FormattedMessage id="macerationCaps" />
            </div>
          </div>
          <div className="actionDiv">
            <FormattedMessage id="remontagePigeage" /> :<br />
            <span style={{ fontWeight: "bold", fontSize: "18px" }}>
              1 <FormattedMessage id="to" /> 2 <FormattedMessage id="perDay" />
            </span>
          </div>
          <div className="actionDiv">
            {macerationType === "coldPreferm" && (
              <>
                <FormattedMessage id="decreaseVolume" />
              </>
            )}
            {macerationType === "classic" && (
              <SimpleChoice
                action={{
                  title: "anthocyaninsStabilization",
                  method: "add",
                  product: {
                    name: "NEO® SWEET",
                    value: computedData?.anthocyanins?.neoWeight,
                    description: `(30 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
                    unit: "kg",
                  },
                }}
              />
            )}
          </div>
        </div>
      )}
      {/* {step === 7 && <Chaptalisation />} */}
      {step === 7 && (
        <div>
          <div
            className="actionDiv"
            style={{ marginTop: "30%", marginBottom: "50%" }}
          >
            <b>
              <FormattedMessage id="chaptalisationMessage" />
            </b>
          </div>
        </div>
      )}
      {step === 8 && (
        <div>
          <div className="actionDiv">
            <div className="actionTitle">
              <FormattedMessage id="devatting" />
            </div>
            <FormattedMessage id="devattingContent" />
            <br />
          </div>
          <div
            style={{
              width: "30%",
              marginLeft: "35%",
              border: "1px solid #EDAE32",
            }}
          />
          <div className="actionDiv">
            <div className="actionTitle">
              <FormattedMessage id="pressing" />
            </div>
            <FormattedMessage id="pressingContent" />
            <br />
          </div>
          <div
            style={{
              width: "30%",
              marginLeft: "35%",
              border: "1px solid #EDAE32",
            }}
          />
          <div className="actionDiv">
            <div className="actionTitle">
              <FormattedMessage id="endOfAF" />
            </div>
            <FormattedMessage id="bottling" />
          </div>
        </div>
      )}
      {step === 9 && (
        <div>
          <div className="actionDiv">
            <div style={{ marginBottom: "10%" }}>
              <span className="actionTitle">
                <FormattedMessage id="malolacticFermentationCaps" />
              </span>{" "}
              <span className="actionMethod">
                {" "}
                - <FormattedMessage id="addInTank" /> :
              </span>
            </div>
            <div style={{ marginBottom: "10%" }}>
              <span className="productValue">
                {computedData?.malolactic?.malovitWeight}
              </span>{" "}
              <span className="productUnit">
                <FormattedMessage id="kg" />
              </span>
              <br />
              <span className="transitionWords">
                <FormattedMessage id="of" />
              </span>{" "}
              <span className="productName">MALOVIT® </span> (20 {language === 'RU-RU' ? 'г/гл' : 'g/hL'})
            </div>
            <div style={{ marginBottom: "10%" }}>+ </div>
            <div style={{ marginBottom: "10%" }}>
              <span className="productValue">
                {computedData?.malolactic?.vitilacticWeight}
              </span>{" "}
              <span className="productUnit">
                <FormattedMessage id="g" />
              </span>
              <br />
              <span className="transitionWords">
                <FormattedMessage id="of" />
              </span>{" "}
              <span className="productName">VITILACTIC® F </span> (1 {language === 'RU-RU' ? 'г/гл' : 'g/hL'})
            </div>
          </div>
        </div>
      )}
      {step === 10 && (
        <EndOfProtocole
          computedData={computedData}
          sampleName={sampleName}
          protocoleType={"Maceration"}
          selectedDate={selectedDate}
          cepage={undefined}
          macerationType={macerationType}
          aromaticProfile={undefined}
          sugar={undefined}
        />
      )}
      {step !== 10 && (
        <div>
          {step === 0 ? (
            <IonCard
              onClick={() => {
                if (!tankSelected) {
                  setErrorTank(true);
                } else if (harvestWeight === 0) {
                  setErrorHarvest(true);
                } else if (!selectedDate) {
                  setErrorDate(true);
                } else {
                  if (harvestWeight > 0 && tankSelected && macerationType) {
                    macerationType === "classic"
                      ? setComputedData(
                        computeClassicMaceration(
                          harvestWeight,
                          tankSelected?.volume
                        )
                      )
                      : setComputedData(
                        computePrefermMaceration(
                          harvestWeight,
                          tankSelected?.volume
                        )
                      );
                    const saveStep = step + 1;
                    setStep(step + 1);
                    let id = protocolId;
                    if (!id) {
                      id =
                        Math.random().toString(36).substring(2, 15) +
                        Math.random().toString(36).substring(2, 15);
                    }
                    setProtocolId(id);
                    if (savedStep === 0) {
                      localStorage.setItem(
                        "protocol_" + id,
                        JSON.stringify({
                          id: id,
                          modifiedAt: new Date(),
                          tankId: tankSelected.id,
                          name: sampleName,
                          type: "Maceration",
                          step: saveStep,
                          status: "En cours",
                          implementationDate: selectedDate,
                          harvestWeight: harvestWeight,
                          macerationType: macerationType,
                        })
                      );
                      const protocoles = tankSelected.protocoles.concat(id);
                      localStorage.setItem(
                        "tank_" + tankSelected.id,
                        JSON.stringify({
                          ...tankSelected,
                          protocoles: protocoles,
                        })
                      );
                    } else {
                      if (
                        protocoleSelected?.tankId !== tankSelected.id ||
                        protocoleSelected?.harvestWeight !== harvestWeight ||
                        protocoleSelected?.implementationDate !==
                        selectedDate ||
                        protocoleSelected?.name !== sampleName ||
                        protocoleSelected?.macerationType !== macerationType
                      ) {
                        //supp protocolId in tank
                        if (tankSelectedInProtocol) {
                          localStorage.setItem(
                            "tank_" + tankSelectedInProtocol.id,
                            JSON.stringify({
                              ...tankSelectedInProtocol,
                              protocoles:
                                tankSelectedInProtocol.protocoles.filter(
                                  (protocolId) => protocolId !== id
                                ),
                            })
                          );
                        }

                        //add protocolId in tankSelected
                        const protocoles = tankSelected.protocoles.concat(id);
                        localStorage.setItem(
                          "tank_" + tankSelected.id,
                          JSON.stringify({
                            ...tankSelected,
                            protocoles: protocoles,
                          })
                        );

                        localStorage.setItem(
                          "protocol_" + id,
                          JSON.stringify({
                            id: id,
                            modifiedAt: new Date(),
                            tankId: tankSelected.id,
                            name: sampleName,
                            type: "Maceration",
                            step: saveStep,
                            status: "En cours",
                            implementationDate: selectedDate,
                            harvestWeight: harvestWeight,
                            macerationType: macerationType,
                          })
                        );
                      }
                    }
                  }
                }
              }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                backgroundColor: "#1B1D26",
                fontFamily: "Lato",
                fontSize: "11pt",
                fontWeight: 100,
                padding: "5% 0",
              }}
            >
              <FormattedMessage id="beginProtocole" />
            </IonCard>
          ) : (
            <IonCard
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#1B1D26",
                fontFamily: "Lato",
                fontSize: "15pt",
                fontWeight: 600,
                padding: "5% 0",
              }}
              onClick={() => {
                const saveStep =
                  macerationType === "classic" && step === 2 ? 5 : step + 1;
                setStep(saveStep);
                if (saveStep > savedStep) {
                  localStorage.setItem(
                    "protocol_" + protocolId,
                    JSON.stringify({
                      ...protocoleSelected,
                      modifiedAt: new Date(),
                      status: saveStep >= 10 ? "Terminé" : "En cours",
                      step: saveStep,
                    })
                  );
                }
              }}
            >
              {step === 9 ? (
                <FormattedMessage id="endProtocole" />
              ) : (
                <FormattedMessage id="goToNextStep" />
              )}
              <span style={{ paddingLeft: "25%" }}>
                <IonIcon
                  style={{ fontSize: "30pt" }}
                  icon={arrowForwardCircle}
                  color="warning"
                />
              </span>
            </IonCard>
          )}
        </div>
      )}
    </div>
  );
};

export default ProtocoleMaceration;
