import { IonCard } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import computeSO2 from "../../methods/so2MolecularCompute";
import translations from '../../modules/translations';
import { InputNumberField } from "./inputFields";

const SO2Molecular: React.FC = () => {

  const language = localStorage.getItem('language');

  const [pH, setPH] = useState<number>(0);
  const [freeSO2, setFreeSO2] = useState<number>(0);
  const [result, setResult] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (pH >= 2.5 && pH <= 4.0 && freeSO2 >= 6 && freeSO2 <= 100) {
      setResult(Math.round(computeSO2(pH, freeSO2) * 10) / 10);
    } else {
      setResult(undefined);
    }
  }, [pH, freeSO2]);

  return (
    <div>
      <IonCard style={{ textAlign: "left", padding: "5%", color: "black" }}>
        <div style={{ textAlign: "center", marginBottom: "10%" }}>
          <div style={{ fontFamily: "Abril Fatface", fontSize: "20px" }}>
            <FormattedMessage
              id="so2MolecularTitle"
              values={{ sub2: <sub>2</sub> }}
            />
          </div>
          <div>
            <FormattedMessage
              id="so2MolecularSubTitle"
              values={{ sub2: <sub>2</sub> }}
            />
          </div>
        </div>
        <span className="inputTitle">
          <FormattedMessage id="pH" />
        </span>
        <br />
        <FormattedMessage id="between" /> 2,5 <FormattedMessage id="and" /> 4
        <InputNumberField value={pH} setValue={setPH} />
        <span className="inputTitle">
          <FormattedMessage id="freeSO2" values={{ sub2: <sub>2</sub> }} />
          <br />
        </span>
        <FormattedMessage id="between" /> 6 <FormattedMessage id="and" /> 100
        {language === 'RU-RU' ? 'мг' : 'mg'}/{translations[language!]["L"]}
        <InputNumberField value={freeSO2} setValue={setFreeSO2} />
        <br />
      </IonCard>
      {result && (
        <div>
          <div
            className="inputTitle"
            style={{ textAlign: "left", marginLeft: "5%" }}
          >
            <FormattedMessage id="results" /> :{" "}
          </div>
          <div className="inputDiv">
            <div
              style={{
                fontFamily: "Lato",
                fontSize: "20px",
                marginBottom: "5%",
              }}
            >
              <FormattedMessage
                id="so2Amount"
                values={{ sub2: <sub>2</sub> }}
              />
            </div>
            <div
              style={{
                color: `${result < 0.9
                  ? "#208D35"
                  : result === 0.9
                    ? "#EDAE32"
                    : "#C20E31"
                  }`,
              }}
            >
              <span className="productValue">
                {result.toString().replace(".", ",")}{" "}
              </span>{" "}
              <span className="productUnit">mg/{translations[language!]["L"]}</span>
              <br />
              <span
                style={{
                  fontFamily: "Lato",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                <FormattedMessage
                  id={`${result < 0.9 ? "good" : result === 0.9 ? "risquy" : "lethal"
                    }`}
                />
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SO2Molecular;
