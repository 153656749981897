import React from "react";
import { FormattedMessage } from "react-intl";

interface Product {
  name: string;
  unit: string;
  values: (number | undefined)[];
  description: string;
}

interface Action {
  title: string | undefined;
  method: string | undefined;
  product: Product;
}

interface Props {
  action: Action;
}

const SimpleChoiceSeveralValues = ({ action }: Props) => {
  return (
    <div>
      {action.title && (
        <h1>
          <span className="actionTitle">
            <FormattedMessage id={action.title} />
          </span>
          <span className="actionMethod">
            {" "}
            - <FormattedMessage id={action.method} /> :{" "}
          </span>
        </h1>
      )}
      <div>
        <span className="productValue">{action?.product?.values[0]}</span>{" "}
        <span className="productUnit">
          <FormattedMessage id="to" />
        </span>{" "}
        <span className="productValue">{action.product?.values[1]}</span>
        <span className="productUnit">
          <FormattedMessage id={action.product.unit} />
        </span>
        <br />
        <span className="transitionWords">
          <FormattedMessage id="of" />{" "}
        </span>
        <span className="productName">{action.product.name}</span>
        <br />
        {action.product.description}
        <br />
      </div>
    </div>
  );
};

export default SimpleChoiceSeveralValues;
