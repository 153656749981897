interface iTempCorrection {
  [key: number]: number;
}

interface iDensitySugarDegreeLink {
  [key: number]: {
    sugar: number;
    degree: number;
  };
}

const tempCorrection: iTempCorrection = {
  8: -2.92,
  9: -2.72,
  10: -2.52,
  11: -2.29,
  12: -2.08,
  13: -1.85,
  14: -1.64,
  15: -1.37,
  16: -1.12,
  17: -0.86,
  18: -0.59,
  19: -0.31,
  20: 0,
  21: 0.31,
  22: 0.61,
  23: 0.95,
  24: 1.25,
  25: 1.59,
  26: 1.89,
};

const densitySugarDegreeLink: iDensitySugarDegreeLink = {
  1048: {
    sugar: 105.5,
    degree: 105.5 / 16.83,
  },
  1049: {
    sugar: 108.0,
    degree: 108.0 / 16.83,
  },
  1050: {
    sugar: 110.6,
    degree: 110.6 / 16.83,
  },
  1051: {
    sugar: 113.2,
    degree: 113.2 / 16.83,
  },
  1052: {
    sugar: 115.7,
    degree: 115.7 / 16.83,
  },
  1053: {
    sugar: 118.3,
    degree: 118.3 / 16.83,
  },
  1054: {
    sugar: 120.9,
    degree: 120.9 / 16.83,
  },
  1055: {
    sugar: 123.4,
    degree: 123.4 / 16.83,
  },
  1056: {
    sugar: 126.0,
    degree: 126.0 / 16.83,
  },
  1057: {
    sugar: 128.5,
    degree: 128.5 / 16.83,
  },
  1058: {
    sugar: 131.1,
    degree: 131.1 / 16.83,
  },
  1059: {
    sugar: 133.7,
    degree: 133.7 / 16.83,
  },
  1060: {
    sugar: 136.2,
    degree: 136.2 / 16.83,
  },
  1061: {
    sugar: 138.8,
    degree: 138.8 / 16.83,
  },
  1062: {
    sugar: 141.4,
    degree: 141.4 / 16.83,
  },
  1063: {
    sugar: 143.9,
    degree: 143.9 / 16.83,
  },
  1064: {
    sugar: 146.5,
    degree: 146.5 / 16.83,
  },
  1065: {
    sugar: 149.1,
    degree: 149.1 / 16.83,
  },
  1066: {
    sugar: 151.6,
    degree: 151.6 / 16.83,
  },
  1067: {
    sugar: 154.2,
    degree: 154.2 / 16.83,
  },
  1068: {
    sugar: 156.8,
    degree: 156.8 / 16.83,
  },
  1069: {
    sugar: 159.3,
    degree: 159.3 / 16.83,
  },
  1070: {
    sugar: 161.9,
    degree: 161.9 / 16.83,
  },
  1071: {
    sugar: 164.4,
    degree: 164.4 / 16.83,
  },
  1072: {
    sugar: 167.0,
    degree: 167.0 / 16.83,
  },
  1073: {
    sugar: 169.6,
    degree: 169.6 / 16.83,
  },
  1074: {
    sugar: 172.1,
    degree: 172.1 / 16.83,
  },
  1075: {
    sugar: 174.7,
    degree: 174.7 / 16.83,
  },
  1076: {
    sugar: 177.3,
    degree: 177.3 / 16.83,
  },
  1077: {
    sugar: 179.8,
    degree: 179.8 / 16.83,
  },
  1078: {
    sugar: 182.4,
    degree: 182.4 / 16.83,
  },
  1079: {
    sugar: 185.0,
    degree: 185.0 / 16.83,
  },
  1080: {
    sugar: 187.5,
    degree: 187.5 / 16.83,
  },
  1081: {
    sugar: 190.1,
    degree: 190.1 / 16.83,
  },
  1082: {
    sugar: 192.6,
    degree: 192.6 / 16.83,
  },
  1083: {
    sugar: 195.2,
    degree: 195.2 / 16.83,
  },
  1084: {
    sugar: 197.8,
    degree: 197.8 / 16.83,
  },
  1085: {
    sugar: 200.3,
    degree: 200.3 / 16.83,
  },
  1086: {
    sugar: 202.9,
    degree: 202.9 / 16.83,
  },
  1087: {
    sugar: 205.5,
    degree: 205.5 / 16.83,
  },
  1088: {
    sugar: 208.0,
    degree: 208.0 / 16.83,
  },
  1089: {
    sugar: 210.6,
    degree: 210.6 / 16.83,
  },
  1090: {
    sugar: 213.2,
    degree: 213.2 / 16.83,
  },
  1091: {
    sugar: 215.7,
    degree: 215.7 / 16.83,
  },
  1092: {
    sugar: 218.3,
    degree: 218.3 / 16.83,
  },
  1093: {
    sugar: 220.9,
    degree: 220.9 / 16.83,
  },
  1094: {
    sugar: 223.4,
    degree: 223.4 / 16.83,
  },
  1095: {
    sugar: 226.0,
    degree: 226.0 / 16.83,
  },
  1096: {
    sugar: 228.5,
    degree: 228.5 / 16.83,
  },
  1097: {
    sugar: 231.1,
    degree: 231.1 / 16.83,
  },
  1098: {
    sugar: 233.7,
    degree: 233.7 / 16.83,
  },
  1099: {
    sugar: 236.2,
    degree: 236.2 / 16.83,
  },
};

//compute the chaptalisation (cf exel file in drive)
const chaptalisationCompute: (
  density: number,
  temperature: number,
  wantedDegree: number
) => number = (density, temperature, wantedDegree) => {
  const correctedTemp = tempCorrection[temperature];
  const sugarFromDensityCeiled =
    densitySugarDegreeLink[Math.ceil(density + correctedTemp)];
  const sugarFromDensityFloored =
    densitySugarDegreeLink[Math.floor(density + correctedTemp)];
  let result = 0;

  const compute = (correctedTemp !== undefined && sugarFromDensityCeiled !== undefined && sugarFromDensityFloored !== undefined) ? 
    (wantedDegree * 16.83 -
      ((density + correctedTemp - Math.trunc(density + correctedTemp)) *
        (sugarFromDensityCeiled.sugar - sugarFromDensityFloored.sugar) +
        sugarFromDensityFloored.sugar)) /
    10 : 0;

  if (compute > 3.36 || compute < 0) {
    result = 0;
  } else {
    result = compute;
  }

  return Math.round(result * 100) / 100;
};

export default chaptalisationCompute;
