import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Charmat from "../../../types/Charmat";

interface Props {
  computedData: Charmat | undefined;
  aromaticProfile: string;
}

interface Product {
  name: string;
  value: number | undefined;
  unit: string;
  description: string;
}

interface Option {
  [key: string]: Product[];
}


const roundTo2Digit = (x: number | undefined) => x && Math.round(x * 100) / 100;
const roundTo3Digit = (x: number | undefined) => x && Math.round(x * 1000) / 1000;

const CharmatElevageSecondStep: React.FC<Props> = ({
  computedData,
  aromaticProfile,
}) => {
  const language = localStorage.getItem('language')

  const options: Option = {
    mineralFraisFruitBlanc: [
      {
        name: "CHARM® AGE",
        value: roundTo2Digit(computedData?.elevage.ageCharm),
        unit: "kg",
        description: `(5 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
      },
      {
        name: "SUBLICHARM®",
        value: roundTo2Digit(computedData?.elevage.subliCharm),
        unit: "kg",
        description: `(1 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
      },
    ],
    tresFruite: [
      {
        name: "CHARM® AGE",
        value: roundTo2Digit(computedData?.elevage.ageCharm),
        unit: "kg",
        description: `(5 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
      },
      {
        name: "SUBLICHARM®",
        value: roundTo3Digit(computedData?.elevage.fruitedSubliCharm),
        unit: "kg",
        description: `(1,5 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
      },
      {
        name: "CHARM® ELEVAGE",
        value: roundTo2Digit(computedData?.elevage.elevageCharm),
        unit: "kg",
        description: `(5 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
      },
    ],
    floralElegant: [
      {
        name: "CHARM® AGE",
        value: roundTo2Digit(computedData?.elevage.ageCharm),
        unit: "kg",
        description: `(5 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
      },
      {
        name: "CHARM® ELEVAGE",
        value: roundTo2Digit(computedData?.elevage.floralElevageCharm),
        unit: "kg",
        description: `(3 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
      },
      {
        name: "CHARM® REDOX T",
        value: roundTo3Digit(computedData?.elevage.redoxCharm),
        unit: "kg",
        description: `(1,5 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
      },
    ],
    cremeuxVarietal: [
      {
        name: "SUBLICHARM®",
        value: roundTo2Digit(computedData?.elevage.creamySubliCharm),
        unit: "kg",
        description: `(2 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
      },
      {
        name: "CHARM® ELEVAGE",
        value: roundTo2Digit(computedData?.elevage.creamyElevageCharm),
        unit: "kg",
        description: `(10 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
      },
    ],
  };

  const [selected, setSelected] = useState<Product>(
    options[aromaticProfile][0]
  );

  const [extended, setExtended] = useState<boolean>(false);

  return <div>
    <div className="inputDiv">
      <FormattedMessage id='elevageMessage'/>
    </div>
    <div className="inputDiv">
          {!extended ? (
            <div
              style={{ border: "#1B1D26 solid 1px", padding: "3%", borderRadius: '20px' }}
              onClick={() => setExtended(true)}
            >
              <span className="productValue">{selected.value}</span>{" "}
              <span className="productUnit">
                <FormattedMessage id={selected.unit} />{" "}
              </span>
              <br />
              <span className="transitionWord">
                <FormattedMessage id="of" />
              </span>{" "}
              <span className="productName">{selected.name}</span> {' '}
              {selected.description}
            </div>
          ) : (
            <div
              style={{ border: "#1B1D26 solid 1px", padding: "3%", borderRadius: '20px' }}
              onClick={() => setExtended(false)}
            >
              {options[aromaticProfile].map((choice) => (
                <div
                  key={choice.name}
                  onClick={() => setSelected(choice)}
                >
                  <span className="productValue">{choice.value}</span>{" "}
                  <span className="productUnit">
                    <FormattedMessage id={choice.unit} />{" "}
                  </span>
                  <br />
                  <span className="transitionWord">
                    <FormattedMessage id="of" />
                  </span>{" "}
                  <span className="productName">{choice.name}</span> {' '}
                  {choice.description}
                </div>
              ))}
            </div>
          )}
        </div>
  </div>;
};

export default CharmatElevageSecondStep;
