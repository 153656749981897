interface Overpressure {
  overpressureAt10: number;
  overpressureAt20: number;
}

interface Paph {
  paph20: number;
  paph10: number;
}

interface PaphObject {
  [key:number] : Paph
}


const temperaturePaphLink : PaphObject = {
  0 : {
    paph20: 1.85,
    paph10: 1.36,
  },
  1 : {
    paph20: 1.80,
    paph10: 1.32,
  },
  2 : {
    paph20: 1.74,
    paph10: 1.28,
  },
  3 : {
    paph20: 1.68,
    paph10: 1.24,
  },
  4 : {
    paph20: 1.64,
    paph10: 1.21,
  },
  5 : {
    paph20: 1.59,
    paph10: 1.17,
  },
  6 : {
    paph20: 1.54,
    paph10: 1.13,
  },
  7 : {
    paph20: 1.50,
    paph10: 1.10,
  },
  8 : {
    paph20: 1.45,
    paph10: 1.07,
  },
  9 : {
    paph20: 1.40,
    paph10: 1.03,
  },
  10 : {
    paph20: 1.36,
    paph10: 1.00,
  },
  11 : {
    paph20: 1.32,
    paph10: 0.97,
  },
  12 : {
    paph20: 1.28,
    paph10: 0.94,
  },
  13 : {
    paph20: 1.24,
    paph10: 0.90,
  },
  14 : {
    paph20: 1.20,
    paph10: 0.88,
  },
  15 : {
    paph20: 1.16,
    paph10: 0.85,
  },
  16 : {
    paph20: 1.13,
    paph10: 0.83,
  },
  17 : {
    paph20: 1.09,
    paph10: 0.80,
  },
  18 : {
    paph20: 1.06,
    paph10: 0.78,
  },
  19 : {
    paph20: 1.03,
    paph10: 0.76,
  },
  20 : {
    paph20: 1.00,
    paph10: 0.74,
  },
  21 : {
    paph20: 0.97,
    paph10: 0.71,
  },
  22 : {
    paph20: 0.95,
    paph10: 0.70,
  },
  23 : {
    paph20: 0.93,
    paph10: 0.68,
  },
  24 : {
    paph20: 0.91,
    paph10: 0.67,
  },
  25 : {
    paph20: 0.88,
    paph10: 0.65,
  },
}

const computeOverpressure: (
  overpressure: number,
  temperature: number
) => Overpressure = (overpressure, temperature) => {
  const paph : Paph = temperaturePaphLink[temperature];
  return {
    overpressureAt10: Math.round(overpressure * paph.paph10 *10) / 10,
    overpressureAt20: Math.round(overpressure * paph.paph20 * 10) / 10,
  }
};


export default computeOverpressure;