import { IonInput } from "@ionic/react";

interface InputNumberProps {
  value: number;
  setValue: (value: number) => void;
  parseValue?: (value: string) => number;
  style?: Object;
}

export const InputNumberField: React.FC<InputNumberProps> = ({ value, setValue, parseValue = parseFloat, style }) => (
  <IonInput
    className="inputField"
    inputmode="decimal"
    value={value}
    style={style}
    onIonChange={(e) => {
      const target = e.target as HTMLInputElement;
      if (e.detail.value !== undefined && e.detail.value !== null) {
        const value = e.detail.value.replace(',', '.');
        if (parseValue(value) > 0) {
          setValue(parseValue(value));
        } else {
          target.value = '0';
          setValue(0);
        }
      } else {
        target.value = '0';
        setValue(0);
      }
    }}
/>);