import {
  IonContent,
  IonIcon,
  IonImg,
  IonItem,
  IonList,
  IonMenu,
  IonMenuToggle,
} from "@ionic/react";
import { close } from "ionicons/icons";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

const languages = ["FR-FR", "EN-GB", "ES-ES", "IT-IT", "DE-DE", "RU-RU"];

interface Props {
  locale: string;
  setLocale: (locale: string) => void;
}

const Menu: React.FC<Props> = ({ locale, setLocale }) => {
  const [extended, setExtended] = useState<boolean>(false);
  const history = useHistory();
  return (
    <>
      <IonMenu side="end" swipeGesture type="overlay" contentId="main">
        <IonContent>
          <IonMenuToggle autoHide={false}>
            <IonIcon
              icon={close}
              size="large"
              style={{ position: "absolute", right: "5%", top: "5%" }}
            />
          </IonMenuToggle>
          <IonImg
            src="/assets/icons/soec.png"
            style={{
              marginLeft: "40%",
              marginTop: "10%",
              width: "80px",
              height: "80px",
              marginBottom: "10%",
            }}
          />
          <IonList
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <IonMenuToggle autoHide={false}>
              <IonItem
                onClick={() => history.push("/home")}
                style={{ marginBottom: "20%", fontWeight: 600 }}
              >
                <FormattedMessage id="home" />
              </IonItem>
            </IonMenuToggle>
            <IonMenuToggle autoHide={false}>
              <IonItem
                onClick={() => history.push("/protocoles")}
                style={{ marginBottom: "20%", fontWeight: 600 }}
              >
                <FormattedMessage id="protocoles" />
              </IonItem>
            </IonMenuToggle>
            <IonMenuToggle autoHide={false}>
              <IonItem
                onClick={() => history.push("/tankList")}
                style={{ marginBottom: "20%", fontWeight: 600 }}
              >
                <FormattedMessage id="tanks" />
              </IonItem>
            </IonMenuToggle>
            <IonMenuToggle autoHide={false}>
              <IonItem
                onClick={() => history.push("/calculators")}
                style={{ marginBottom: "20%", textAlign: "center" }}
              >
                <div>
                  <b>
                    <FormattedMessage id="calculators" />
                  </b>
                  <br />
                  <FormattedMessage id="calculatorsTools" />
                </div>
              </IonItem>
            </IonMenuToggle>
            <IonMenuToggle autoHide={false}>
              <IonItem
                onClick={() => history.push("/contact")}
                style={{ marginBottom: "20%", fontWeight: 600 }}
              >
                <FormattedMessage id="contact" />
              </IonItem>
            </IonMenuToggle>
            {/* <IonMenuToggle autoHide={true}> */}
            {!extended ? (
              <IonItem
                style={{
                  border: "1px solid #1B1D26",
                  borderRadius: "10pt",
                  marginBottom: "20%",
                }}
                onClick={() => setExtended(true)}
              >
                <IonImg
                  src={`/assets/flags/${locale}.jpg`}
                  alt="missing"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                  }}
                />{" "}
                <FormattedMessage id={locale} />
              </IonItem>
            ) : (
              <div
                style={{
                  border: "1px solid #1B1D26",
                  borderRadius: "10pt",
                  marginBottom: "20%",
                }}
              >
                {languages.map((lang) => (
                  <IonItem
                    key={lang}
                    onClick={() => {
                      localStorage.setItem("language", lang);
                      setLocale(lang);
                      setExtended(false);
                    }}
                  >
                    <IonImg
                      src={`/assets/flags/${lang}.jpg`}
                      alt="missing"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "10px",
                      }}
                    />{" "}
                    <FormattedMessage id={lang} />
                  </IonItem>
                ))}
              </div>
            )}
            <IonItem>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <FormattedMessage id="by" />
                <br />
                <IonImg
                  style={{
                    heigth: "50%",
                    width: "50%",
                    marginTop: "10%",
                  }}
                  src="/assets/icons/sofralab.png"
                />
              </div>
            </IonItem>
          </IonList>
        </IonContent>
      </IonMenu>
    </>
  );
};

export default Menu;
