import { IonCard, IonImg } from "@ionic/react";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const ComputerPage: React.FC = () => {
  return (
    <div className="computerPageDiv">
      <IonImg
        src="/assets/icons/soec.png"
        style={{
          width: "150px",
          height: "150px",
          marginLeft: "calc(50% - 75px)",
          marginTop: "10px",
          marginBottom:"5%"
        }}
      />
      <IonCard style={{ color: "black", width: "60%", marginLeft: "20%" }}>
        <div style={{ margin: "5% 10%", fontFamily: "Abril Fatface", fontSize: "32px" }}>
          <FormattedMessage id="notAvailableForComputerTitle" />
        </div>
        <div style={{ margin: "5% 15% 2% 15%", fontFamily: "Lato", fontSize: "16px" }}>
          <FormattedMessage id="notAvailableForComputerContent" values={{ soecUrl: <b>https://app.oenotechnic.com/</b> }}/>
        </div>
        <div>
          <FormattedMessage id="scanMe"/>
        </div>
        <IonImg src="/assets/soecProdQRCode.png" style={{width: "100px", height: "100px", marginLeft: "calc(50% - 50px)",}}/>
      </IonCard>
      <div
        style={{
          position: "absolute",
          bottom: "2%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color:"white"
        }}
      >
        <Link to="/legalesNoticies">
          <FormattedMessage id="legalesNoticies" />
        </Link>
        {" "}-{" "}
        <a
          href="https://www.oenotechnic.com/PDF/CGV_FR.pdf"
          target="_blank"
          rel="noreferrer"
          style={{ paddingRight: "5%" }}
        >
          <FormattedMessage id="GTC" />
        </a>
        |
        <span style={{ padding: " 0 5%" }}>
          <FormattedMessage id="by" />
        </span>
        <IonImg
          style={{
            heigth: "20%",
            width: "20%",
          }}
          src="assets/icons/sofralab.png"
        />
      </div>
    </div>
  );
};

export default ComputerPage;
