type Translation = {
  [key: string]: string;
};

const Sp: Translation = {
  "FR-FR": "Francés",
  "EN-GB": "Inglés",
  "ES-ES": "Español",
  "IT-IT": "Italiano",
  "DE-DE": "Alemán",
  "RU-RU": "Ruso",

  //Version ordinateur
  notAvailableForComputerTitle:
    "La aplicación SOEC no está disponible en un ordenador.",
  notAvailableForComputerContent:
    "Lamentablemente, esta aplicación no está disponible en el ordenador. Para acceder a ella, escanee el código que aparece a continuación o acceda a la siguiente URL: {soecUrl} en su tableta o móvil. A continuación, puedes añadir la aplicación a tu dispositivo siguiendo las instrucciones emergentes.",

  //installer la PWA
  addSOECToApp: "¡Añadir SOEC a sus aplicaciones!",
  appSOECToAppInformations:
    "Puede añadir nuestra aplicación a su móvil o tableta. Solo tiene que clicar en el botón de abajo.",
  appSOECToAppInformationsIos:
    "Para añadir la aplicación a tu iPhone, sólo tienes que tocar el icono de compartir:",
  appSOECToAppInformationsIosFinalStep:
    "A continuación, haz clic en \"En la pantalla de inicio\":",
  addApplication: "Añadir la aplicación",
  stayOnBrowser: "Permanecer en el navegador",

  //Menu
  home: "Inicio",
  tanks: "Mis depósitos",
  protocoles: "Protocolos",
  calculators: "Calculadoras",
  contact: "¿Necesitas ayuda?",

  //Page d'accueil
  legalesNoticies: "Menciones legales",
  GTC: "CGU",
  calculatorsTools: "Ayudas para la toma de decisiones",
  startA: "Iniciar un",
  protocole: "protocolo",
  allTools: "Todas las herramientas",
  useCalculator: "Usar una calculadora",
  handleTank: "Gestionar mis depósitos",

  //Page d'accueil sans protocole
  noProtocoleFoundfirst: "No se ha encontrado ningún protocolo en curso.",
  noProtocoleFoundsecond:
    "No se ha podido encontrar ningún protocolo en su dispositivo.",
  noProtocoleFoundthird:
    "Inicie su primer protocolo haciendo clic a continuación.",

  //Liste des protocoles disponibles
  protocols: "Protocolos",
  maceration: "Maceración",
  traditionalMethod: "Método tradicional",
  feedStockMethod: "Método cuve close",
  martinottiMethod: "Método Charmat", //changement - correction
  martinottiMethodDescription:
    "Estos protocolos del Método Tradicional están desarrollados y adaptados a las especificidades de Champagne. No dude en consultar a nuestros asesores enológicos.",
  malolacticFermentation: "Vinificación con fermentación maloláctica",
  malolacticFermentationBlocked: "Vinificación sin fermentación maloláctica",

  //Liste des calculateurs disponible
  decisionSupportToolsCaps: "AYUDAS PARA LA TOMA DE DECISIONES",
  calculationCorrection: "Cálculo de la corrección de la",
  overpressureSparklingWine: "sobrepresión de un vino espumoso", //changement - correction
  calculationFoot: "Cálculos para el pie de",
  maloTankLowPh: "Cuba maloláctico a pH bajo",
  calculationOf: "Cálculos para el",
  sulfurAcetaldehyde: "Sulfuroso - Acetaldehído",
  calculationOfThe: "Cálculos para la",

  //Propre à tous les protocoles
  selectTank: "Seleccionar/Añadir un depósito",
  askSelectedTank: "¿Qué depósito quiere designar?",
  tankVolume: "Volumen del depósito:",
  tankVolumeInfo:
    "(Para cambiar el volumen deseado, puede cambiar o añadir un depósito.)",
  beginningDate: "Fecha de implementación",
  askBeginningDate: "¿Cuándo desea iniciar este protocolo?",
  sampleName: "Número de muestra o lote",
  askSampleName: "¿Para qué muestra desea iniciar este protocolo?",
  beginProtocole: "EMPEZAR EL PROTOCOLO",
  endProtocole: "Completar el protocolo",
  sendResults: "Enviar los resultados",
  goBackToBoard: "Volver al cuadro de mandos",
  selectADate: "Seleccione una fecha",
  selectATank: "Seleccione un depósito",
  selectACepage: "Seleccione una variedad de uva", //changement - ajout d'une clé
  selectAMacerationType: "Seleccione un tipo de maceración",
  selectAnAromaticProfile: "Seleccione un perfil aromático",
  nameTheTank: "Número de depósito",
  errorMessageInput: "Este campo debe completarse",
  errorNumberInput: "El valor debe ser mayor que 0",
  errorSelectTank: "Seleccione un depósito",
  errorDate: "Seleccione una fecha",
  optionnal: "Opcional",

  //Protocole Macération
  //Titre et sous-titre pour chacune des étapes
  TitleMacerationStep0_classic: "Maceración",
  TitleMacerationStep1_classic: "Maceración Clásica",
  TitleMacerationStep2_classic: "Encubado",
  TitleMacerationStep3_classic: "Operaciones de pre-fermentación",
  TitleMacerationStep4_classic: "Fermentación alcohólica",
  TitleMacerationStep5_classic: "Fermentación alcohólica",
  TitleMacerationStep6_classic: "Maceración",
  TitleMacerationStep7_classic: "Chaptalización",
  TitleMacerationStep8_classic: "Descube, Prensado & Fermentación alcohólica acabada",
  TitleMacerationStep9_classic: "Fermentación maloláctica",
  TitleMacerationStep10_classic: "Protocolo completado",

  SubtitleMacerationStep0_classic: "METODO TRADICIONAL",
  SubtitleMacerationStep1_classic: " ",
  SubtitleMacerationStep2_classic: "Maceración Clásica",
  SubtitleMacerationStep3_classic: "Maceración Clásica",
  SubtitleMacerationStep4_classic: "Maceración Clásica",
  SubtitleMacerationStep5_classic: "Maceración Clásica",
  SubtitleMacerationStep6_classic: "Maceración Clásica",
  SubtitleMacerationStep7_classic: "Maceración Clásica",
  SubtitleMacerationStep8_classic: "Maceración Clásica",
  SubtitleMacerationStep9_classic: "Maceración Clásica",
  SubtitleMacerationStep10_classic: "Maceración Clásica",

  TitleMacerationStep0_coldPreferm: "Maceración",
  TitleMacerationStep1_coldPreferm: "Maceración prefermentativa en frío (MPF)",
  TitleMacerationStep2_coldPreferm: "Encubado",
  TitleMacerationStep3_coldPreferm: "Enzimas",
  TitleMacerationStep4_coldPreferm: "Maceración prefermentativa en frío",
  TitleMacerationStep5_coldPreferm: "Fermentación alcohólica",
  TitleMacerationStep6_coldPreferm: "Maceración",
  TitleMacerationStep7_coldPreferm: "Chaptalización",
  TitleMacerationStep8_coldPreferm: "Descube, Prensado & Fermentación alcohólica acabada",
  TitleMacerationStep9_coldPreferm: "Fermentación maloláctica",
  TitleMacerationStep10_coldPreferm: "Protocolo completado",

  SubtitleMacerationStep0_coldPreferm: "METODO TRADICIONAL",
  SubtitleMacerationStep1_coldPreferm: " ",
  SubtitleMacerationStep2_coldPreferm: "Maceración prefermentativa en frío",
  SubtitleMacerationStep3_coldPreferm: "Maceración prefermentativa en frío",
  SubtitleMacerationStep4_coldPreferm: "Maceración prefermentativa en frío",
  SubtitleMacerationStep5_coldPreferm: "Maceración prefermentativa en frío",
  SubtitleMacerationStep6_coldPreferm: "Maceración prefermentativa en frío",
  SubtitleMacerationStep7_coldPreferm: "Maceración prefermentativa en frío",
  SubtitleMacerationStep8_coldPreferm: "Maceración prefermentativa en frío",
  SubtitleMacerationStep9_coldPreferm: "Maceración prefermentativa en frío",
  SubtitleMacerationStep10_coldPreferm: "Maceración prefermentativa en frío",

  //Quantité de raisin
  harvestWeight: "Cantidad de uva utilizada",
  askHarvestWeight: "¿Cuál es la cantidad de uva en kilogramos?",
  //Type de macération
  macerationType: "Tipo de maceración",
  askMacerationType: "¿Qué tipo de maceración le gustaría hacer?",
  coldPreferm: "Maceración prefermentativa en frío",
  classic: "Maceración clásica",

  //Etape 1
  harvestSorting: "Selección de las uvas",
  foulageEraflage: "Despalillado - Estrujado",

  //Etape 2 pour préf. à froid
  decreaseTemperature: "Descenso de la temperatura",
  toDecreaseOf1Deg: "para reducir 1°C",

  //Etape 3 pour préf. à froid
  atT24: "A T+24h",

  //Etape 4 pour pref. à froid
  prefermTitle: "MACERACIÓN PREFERMENTATIVA EN FRÍO",
  pigeageWithoutOxygene: "Bazuqueos sin aireación",

  //Etape 6 pour les 2
  macerationCaps: "MACERACIÓN",
  remontagePigeage: "Remontados y/o bazuqueos con aireación",
  decreaseVolume:
    "Disminuir la cantidad de vino remontado a medida que la densidad disminuye.",

  //Etape 7 pour les 2
  chaptalisationMessage:
    "Se recomienda que la densidad sea menor de 1040 para realizar la chaptalización.",

  //Etape 8 pour les 2
  devatting: "DESCUBE",
  devattingContent: "Densidad cercana a 1000",
  pressing: "PRENSADO",
  pressingContent:
    "Consulte a su enólogo para una eventual mezcla posterior del mosto de escurrido y el mosto de prensa.",
  endOfAF: "FA ACABADA",
  bottling: "Realizar un trasiego",

  //Etape 9 pour les 2
  malolacticFermentationCaps: "FERMENTACIÓN MALOLÁCTICA",

  //FML
  TitleFMLStep0: "Vinificación con fermentación maloláctica",
  TitleFMLStep1: "Operaciones prefermentativas",
  TitleFMLStep2: "Operaciones prefermentativas",
  TitleFMLStep3: "Operaciones prefermentativas",
  TitleFMLStep4: "Fermentación alcohólica",
  TitleFMLStep5: "Fermentación alcohólica",
  TitleFMLStep6: "Fermentación maloláctica",
  TitleFMLStep7: "Protocolo completado",

  SubtitleFMLStep0: "METODO TRADICIONAL",
  SubtitleFMLStep1: "Vinificación con fermentación maloláctica",
  SubtitleFMLStep2: "Vinificación con fermentación maloláctica",
  SubtitleFMLStep3: "Vinificación con fermentación maloláctica",
  SubtitleFMLStep4: "Vinificación con fermentación maloláctica",
  SubtitleFMLStep5: "Vinificación con fermentación maloláctica",
  SubtitleFMLStep6: "Vinificación con fermentación maloláctica",
  SubtitleFMLStep7: "Vinificación con fermentación maloláctica",

  //Type de cépage
  cepageType: "Variedad de uva de la muestra",
  askCepageType: "¿Cuál es la variedad de uva de la muestra?",
  chardonnay: "Variedades blancas - Blanc de Noirs",
  pinotNoirMeunier: "Variedades tintas",

  //Etape 1 pour les 2 (Sulfitage est déjà défini)
  FMLFirstStepSulfitingMessage:
    "En cuanto empiece a fluir el mosto. Aporte fraccionado en 3 veces",
  coldPrefermOperations: "Operaciones prefermentativas en frío",
  whenNeeded: "Cuando sea necesario",

  //Etape 2
  theCuvee: "MOSTO FLOR",
  FMLToDelay:
    "Desleír en 10 veces su peso de agua fría y añadir al mosto una vez deshecho.",
  FMLCatalMessage:
    "A añadir antes de trasegar la fracción del depósito de desfangado.",
  FMLThirdStepMessage:
    "En función de la oxidación del mosto y la extracción de color (quinona).",

  //Etape 3
  theTaille: "MOSTO DE PRENSA",

  //Etape 4
  FMLYeastingWater: "mitad de agua y mitad de mosto a 37 °C",
  FMLYeastingMessage:
    "Es fundamental respetar la temperatura de rehidratación y no superar los 40 °C ya que por encima de este valor las levaduras podrían morir.",
  FMLYeastingMessagePart1:
    "Dejar reposar durante 20 minutos y luego agitar cuidadosamente para homogeneizar.",
  FMLYeastingMessagePart2:
    "Incorporar las levaduras en 10 a 20 veces su volumen de mosto.",
  FMLYeastingMessagePart3:
    "Dejar fermentar de 6 a 12 horas y luego añadir al depósito.",

  //Etape 5
  yeastNutrition: "NUTRICIÓN DE LAS LEVADURAS",
  FMLYeastNutritionMessage:
    "Este nutriente permite una buena gestión de la fermentación alcohólica y optimiza el perfil aromático de los vinos.",

  //FML Bloquée
  TitleFMLBloqueeStep0: "Vinificación sin fermentación maloláctica",
  TitleFMLBloqueeStep1: "Operaciones prefermentativas",
  TitleFMLBloqueeStep2: "Operaciones prefermentativas",
  TitleFMLBloqueeStep3: "Operaciones prefermentativas",
  TitleFMLBloqueeStep4: "Fermentación alcohólica",
  TitleFMLBloqueeStep5: "Fermentación alcohólica",
  TitleFMLBloqueeStep6: "Protocolo completado",

  SubtitleFMLBloqueeStep0: "METODO TRADICIONAL",
  SubtitleFMLBloqueeStep1: "Vinificación sin fermentación maloláctica",
  SubtitleFMLBloqueeStep2: "Vinificación sin fermentación maloláctica",
  SubtitleFMLBloqueeStep3: "Vinificación sin fermentación maloláctica",
  SubtitleFMLBloqueeStep4: "Vinificación sin fermentación maloláctica",
  SubtitleFMLBloqueeStep5: "Vinificación sin fermentación maloláctica",
  SubtitleFMLBloqueeStep6: "Vinificación sin fermentación maloláctica",

  //Charmat
  TitleCharmatStep0: "Método Charmat", //changement - correction
  TitleCharmatStep1: "1.1 - Rehidratación",
  TitleCharmatStep2: "1.2 - Aclimatación",
  TitleCharmatStep3: "1.2 - Aclimatación",
  TitleCharmatStep4: "1.2 - Aclimatación",
  TitleCharmatStep5: "1.3 - Pie de Cuba",
  TitleCharmatStep6: "1.3 - Pie de Cuba",
  TitleCharmatStep7: "1.3 - Pie de Cuba",
  TitleCharmatStep8: "1.3 - Pie de Cuba",
  TitleCharmatStep9: "Etapa intermedia",
  TitleCharmatStep10: "Etapa intermedia",
  TitleCharmatStep11: "1.4 - Etapas clave de la fermentación alcohólica",
  TitleCharmatStep12: "2.1 - Etapas clave de la crianza",
  TitleCharmatStep13: "2.1 - Etapas clave de la crianza",
  TitleCharmatStep14: "Protocolo completado",

  SubtitleCharmatStep0: "Cuve Close",
  SubtitleCharmatStep1: "Metodo Charmat - Starter", //changement - correction
  SubtitleCharmatStep2: "Metodo Charmat - Starter", //changement - correction
  SubtitleCharmatStep3: "Metodo Charmat - Starter", //changement - correction
  SubtitleCharmatStep4: "Metodo Charmat - Starter", //changement - correction
  SubtitleCharmatStep5: "Metodo Charmat - Starter", //changement - correction
  SubtitleCharmatStep6: "Metodo Charmat - Starter", //changement - correction
  SubtitleCharmatStep7: "Metodo Charmat - Starter", //changement - correction
  SubtitleCharmatStep8: "Metodo Charmat - Starter", //changement - correction
  SubtitleCharmatStep9: "Metodo Charmat - Starter", //changement - correction
  SubtitleCharmatStep10: "Metodo Charmat - Starter", //changement - correction
  SubtitleCharmatStep11: "Metodo Charmat - Starter", //changement - correction
  SubtitleCharmatStep12: "Metodo Charmat - Starter", //changement - correction
  SubtitleCharmatStep13: "Metodo Charmat - Starter", //changement - correction
  SubtitleCharmatStep14: "Metodo Charmat - Starter", //changement - correction

  mineralFraisFruitBlanc: "Mineral, Fresco, Fruta blanca",
  tresFruite: "Muy afrutado",
  floralElegant: "Floral y Elegante",
  cremeuxVarietal: "Cremoso y Varietal",

  //Etape 1
  charmatFirstStepFirstMessage:
    "Empezar ajustando la temperatura del vino base a 12°C.",
  water35: "de agua a 35-36°C",
  water30: "de agua a 30°C",
  water25: "de agua a 25°C",
  water20: "de agua a 20°C",
  charmatFirstStepFirstBlackMessage:
    "Es fundamental respetar la temperatura de rehidratación y no superar los 40 °C ya que por encima de este valor las células podrían morir.",
  charmatFirstStepSecondBlackMessage:
    "Agitar vigorosamente para asegurarse de que las levaduras no formen grumos.",
  aromaticProfile: "Perfil aromático deseado",
  askAromaticProfile: "¿Qué perfil aromático quiere para su vino?",

  //Etape 2
  sugar: "azúcar",
  stepCaps: "ETAPA ",
  step: "Etapa",
  charmatSecondStepMessageFirstStep:
    "Mezclar en un recipiente aparte los siguientes elementos.", //changement - correction
  charmatSecondStepMessageSecondStep:
    "Añadir un poco de vino base y agua a la preparación.",
  charmatSecondStepMessageThirdStep:
    "Añadir los siguientes productos para completar la aclimatación.",
  alreadyPrepared: "ya preparados",
  acclimatationBasicWine12: "vino base (a 12°C)",
  acclimatationBasicWine14: "vino base (a 14°C)",
  feedStockBasicWine: "vino base (15 a 16°C)",
  feedStockBasicWine16: "vino base (a 16°C)",

  //Etape 3
  charmatThirdStepFirstMessage:
    "Durante los 3 días de preparación del pie de cuba, asegurarse de que:",
  charmatThirdStepSecondMessage:
    "La densidad no sea nunca inferior a 1005 (en caso de disminución, añadir un poco de azúcar)",
  charmatThirdStepThirdMessage:
    "La diferencia de temperaturas entre el medio de aclimatación y el vino no sea superior a 5-7°C",
  charmatThirdStepFourthMessage:
    "La temperatura del vino base añadido al depósito esté siempre entre 16 y 18°C",

  //Etape 4
  charmInfo: "Recomendado para mejorar la longevidad",
  charmatFourthStepMessageFirstStep:
    "Al cabo de una hora duplicar el volumen de vino base. La temperatura debe permanecer cercana a los 18-20°C.",
  charmatFourthStepMessageSecondStep:
    "Después de 24 horas más, repetir la operación anterior y duplicar el volumen.",
  charmatFourthStepMessageThirdStep:
    "Después de 24 horas más, añadir los nutrientes y repetir la operación anterior.",
  charmatFourthStepLastMessage:
    "Cuando la actividad fermentativa ha bien empezado, añadir todo el volumen preparado al depósito isobárico (cuve close).",
  startYInfo: "Sólo si la concentración de alcohol es alta",

  //Etape 5
  sugarQuantity: "Cantidad de azúcares residuales",
  sugarQuantityAsk:
    "¿Cuánto azúcares residuales quieres? (en g/L)",

  //Etape 6
  charmatSugarResultSugar: "de azúcar (25 g/L + azúcares residuales)",
  charmatSugarStartFreshMessage:
    "Se recomienda añadir START Y® FRESH (20 g/hL) para asegurar la nutrición de las levaduras.",
  mineralFraisFruitBlancSubliCharmMessage:
    "Para incrementar los aromas afrutados, añada:",
  cremeuxVarietalSubliCharmMessage: "Para aumentar el volumen en boca, añada:",
  floralElegantSubliCharmMessage: "Para aumentar el volumen en boca, añada:",
  charmElevageMessage:
    "Para aumentar el volumen en boca y la untuosidad, y mejorar la calidad de la espuma, añada:",
  tresFruiteCharmElevageMessage:
    "Para aumentar el volumen y la sensación afrutada y mejorar la calidad de la espuma, añada :",
  cremeuxVarietalCharmElevageMessage:
    "Para mejorar el equilibrio REDOX y el MP, añada:",
  charmAgeMessage:
    "Para mejorar el tiempo de conservación y preservar los aromas delicados, añada:",
  charmActivMessage: "Para asegurar la nutrición de las levaduras, añada:",
  charmRedoxMessage: "Para prevenir los fenómenos de reducción, añada:",

  //Etape 7
  decreaseTemperatureUnder15: "Regular la temperatura por debajo de 15°C",
  addProductsUnderPressure:
    "Si le es posible añadir productos bajo presión a 2.5 bar, añada:",
  fermentRecommandedTemperature:
    "Temperatura recomendada de fermentación: 14 a 16°C",
  reductionAromaCase: "En caso de aparecer aromas de reducción, añada:",
  pressureReached:
    "Cuando la presión alcance de 4.3 a 4.6 atm (calculada a 20°C), ajustar la temperatura por debajo de 2°C para detener la fermentación.",
  necessaryTimeDependingEquipment:
    "Este proceso tarda entre 1 y 2 días, dependiendo del equipo utilizado.",
  setTemperatureTo0:
    "Ajustar la temperatura a 0°C y filtrar el vino (filtración tangencial o membrana de 1 μm).",

  //Etape 8
  duringElevage: "Durante la crianza, el removido debe realizarse:",
  firstOrder: "Durante los primeros 15 días (2 x 15 minutos cada día)",
  secondOrder: "Cada 3 días durante los próximos 30 días (1 x 20 minutos)",
  thirdOrder: "Una vez a la semana durante los últimos 15 días (1 x 15 minutos)",

  //Etape 9
  elevageMessage:
    "Crianza a una temperatura mínima de 6 a 8 °C durante 2 meses, y elegir (en función de los resultados de la degustación):",

  //Calculateur SO2 Moléculaire
  so2MolecularTitle: "Cálculo del contenido de SO{sub2} molecular",
  so2MolecularSubTitle:
    "Tenga en cuenta que el SO{sub2} molecular tiene una propiedad antiséptica por encima de una concentración de 1 mg/L.",
  so2Amount: "Contenido de SO{sub2} molecular",
  pH: "pH",
  good: "Tiraje posible", //changement - correction
  risquy: "En riesgo",
  lethal: "Letal",

  //Calculateur Soufre - Acétaldéhyde
  soufreAcetaldehydeTitle: "Sulfuroso - Acetaldehído",
  soufreAcetaldehydeSubtitle: "Comúnmente utilizado en el método Charmat", //changement - correction
  so2Molecular: "SO{sub2} molecular",
  molecular: "molecular",
  totalEthanal: "Etanal total",
  freeSO2: "SO{sub2} libre",
  totalSO2: "SO{sub2} total",
  residualSugar: "Azúcares residuales",
  wantedFreeSO2: "Valor deseado para el SO{sub2} libre",
  freeEthanal: "Etanal libre",
  freeCombiningSO2: "SO{sub2} libre que se combina",
  SO2ToAdd: "SO{sub2} a añadir",
  finalTotalSO2: "Valor final del SO{sub2} total",

  //Calculateur Chaptalisation
  chaptalization: "Chaptalización",
  density: "Masa volúmica",
  wantedDegree: "Grado alcohólico deseado",
  chaptalisationDescription:
    "Nuestra experiencia sobre el seguimiento de numerosas cosechas muestra que para obtener un grado final del 11% vol. se recomienda buscar un 11% vol. en el Chardonnay y un 11.2% vol. en el Pinot Noir y el Pinot Meunier.",
  results: "Resultados",
  chaptalisationDensityError:
    "Introduzca una masa volúmica mayor o igual a 1064.",
  chaptalisationResult:
    "Cantidad de azúcar cristalizado a añadir para obtener el grado alcohólico deseado en % vol.:",
  chaptalisationWarningTitle:
    "Se recomienda que la densidad sea menor de 1040 para realizar la chaptalización.",
  chaptalisationWarningContent:
    "Información reglamentaria sobre la chaptalización.",

  //Calculateur Pied de Cuve Malo
  maloTankTitle: "Pie de cuba maloláctico a pH bajo",
  cuveMaloTitle: "Protocolo de preparación de un pie de cuba maloláctico con mosto", //changement - correction
  cuveMaloContent:
    "Según el protocolo establecido por el servicio técnico del CIVC y publicado en la revista Vigneron Champenois n°6 – Junio 2009.",
  totalWineToSeed: "Volumen total de vino a inocular",
  inHectoliter: "En hectolitros (hL)",
  globaldata: "Datos generales",
  rehydratation: "Rehidratación",
  reactivationEnvironment: "Medio de reactivación",
  feedStock: "Pie de cuba",
  reactivationEnvironmentVolume: "Volumen del medio de reactivación:", //changement - correction
  feedStockVolume: "Volumen de pie de cuba: ",
  bacteries: "Bacterias utilizadas: ",
  activator: "Activador utilizado: ",
  dryYeast: "Levaduras secas utilizadas: ",
  globalDataFeedStockMessage:
    "El mosto utilizado en la preparación del pie de cuba debe estar desfangado, ser preferiblemente de escurrido, sin chaptalizar y sulfitado con un máximo de 4 g/hl (equivalentes a 2.7 cl/hl de SULFOSSOL® 150).",
  maloTankMiddleMessage:
    "El pie de cuba y el medio de reactivación deben prepararse al mismo tiempo.",
  delay: "Disolver los", //changement - correction
  litersOfWater: "litros de agua a 25°C",
  rehydratationMessage:
    "Agitar enérgicamente para evitar la formación de grumos. Dejar reposar durante 15 a 20 minutos y homogeneizar la preparación, agitando nuevamente, antes de preparar el Medio de Reactivación.",
  preparedBefore: "previamente preparados",
  rehydratedBacteries: "Bacterias rehidratadas",
  settlingMust: "Mosto desfangado & poco sulfitado (media dosis)",
  ofWaterAt25: "de agua a una temperatura de 25°C",
  sprinklingDryYeast:
    "Levaduras Secas SOEC® 1971 espolvoreadas, sin rehidratación",
  inTotal: "en Total",
  reactivationEnvironmentMessage:
    "Procurar mantener una temperatura de 25 °C durante todo el proceso de reactivación. Cuando el medio de reactivación esté a punto, doblar su volumen con el mosto en fermentación del pie de cuba para obtener un volumen de:",
  duration: "Duración:",
  mustWaterMixing:
    "de una mezcla de mosto / agua caliente para obtener una temperatura de 35°C.", //changement - correction
  feedStockFirstMessagePart1:
    "(procurar respetar las temperaturas indicadas anteriormente, y sobretodo no sobrepasar los 40 °C ya que las células activas podrían morir). Agitar enérgicamente para evitar la formación de grumos.",
  feedStockFirstMessagePart2:
    "Dejar reposar durante 20 a 30 minutos y homogeneizar la preparación, agitando nuevamente, antes de preparar el pie de cuba.",
  rehydratedYeasts: "Levaduras rehidratadas",
  must: "Mosto",
  feedStockVolumeComplement:
    "Incorporación del Medio de Reactivación en el pie de cuba después de 3 días.",
  feedStockTotalVolume: "Volumen total de pie de cuba: ",
  feedStockSecondMessage:
    "Mantener la temperatura del Pie de Cuba a 25 °C durante la FA, y después a 20 °C durante la FML. El seguimiento se realizará a los 6 o 7 días, y después cada 2 días.",
  feedStockFinalMessagePart1:
    "La inoculación de los depósitos se realizará a razón de un 3 %, por la parte superior de los depósitos, sin remover, una vez consumidos los 2/3 del ácido málico presente en el Pie de Cuba.",
  feedStockFinalMessagePart2:
    "La adición puede realizarse en el vino durante la FA o cuando ésta finalice, con o sin un trasiego previo de los depósitos a inocular.",
  feedStockFinalMessagePart3:
    "Para aprovechar la inercia térmica de los depósitos, se recomienda parar la regulación de temperatura a 1005 – 1010.",
  feedStockFinalMessagePart4:
    "Mantener a continuación la temperatura entre 18 °C y 20 °C. El primer control se llevará a cabo, como mínimo, a los 15 días de la inoculación.",
  maloTankFinalMessage:
    "Estos valores se facilitan a título indicativo. En ningún caso sustituyen las recomendaciones de su asesor enológico.",

  //Calculateur Correction de la surpression d'un vin effervescent
  sparklinWineOverpressureTitle:
    "Corrección de la sobrepresión de un vino espumoso",
  sparklingWineOverpressureMessage:
    "Calcular la corrección de la sobrepresión de un vino espumoso a una temperatura determinada",
  overpressure: "Sobrepresión",
  temperature: "Temperatura",

  //Gestion de Cuve
  managementTanks: "Gestionar mis depósitos",
  volume: "Volumen",
  currentProtocol: "Protocolos en curso",
  startProtocol: "Empezar un protocolo",
  continueProtocol: "Continuar el protocolo",
  stopProtocol: "Detener el protocolo",
  startNewProtocol: "Iniciar un nuevo protocolo",
  addATank: "Añadir un depósito",
  noTankFound: "No se ha encontrado ningún depósito",
  textNoTankFound:
    "No se ha encontrado ningún depósito en su dispositivo. Añada uno haciendo clic a continuación!",
  addingTank: "Adición de un depósito",
  nameTank: "Número de su depósito",
  volumeTank: "Volumen del depósito",
  addTank: "Añadir el depósito",
  dontAddTank: "Cancelar la adición",
  confirmationModalDeleteTank: "¿Está seguro de querer eliminar el depósito?",
  subTitleConfirmationModalDeleteTank:
    "Al eliminar el depósito, se eliminará el protocolo asociado",
  confirmationModalDeleteProtocol:
    "¿Estás seguro de que quieres parar el protocolo?",
  confirmationModalNewProtocol:
    "¿Estás seguro de que quieres empezar un nuevo protocolo?",
  confirmationModalDeleteProtocolInProgress:
    "¿Está seguro de que quiere eliminar el protocolo actual?",
  protocoleType: "Tipo de protocolo: ",
  sampleTitle: "Número de muestra: ",
  date: "Fecha",
  tank: "Depósito",
  sampleNameOnly: "Número de muestra",
  FMLstep1: "Prensado",
  FMLstep2: "Mosto flor",
  FMLstep3: "Mosto de prensa",
  FMLstep4: "Fermentación alcohólica",
  FMLstep5: "Adición de las levaduras",
  FMLstep6: "Nutrición de las levaduras",
  FMLstep7: "Cálculos para el pie de cuba maloláctico",
  Macerationstep1: "Selección de las uvas - Despalillado - Estrujado",
  Macerationstep2: "Encubado",
  Macerationstep3: "Adición de enzimas",
  Macerationstep4: "Maceración prefermentativa en frío",
  Macerationstep5: "Fermentación alcohólica",
  Macerationstep6: "Maceración",
  Macerationstep7: "Chaptalización",
  Macerationstep8: "Descube - Prensado",
  Macerationstep9: "Fermentación maloláctica",
  Charmatstep1: "1.1 - Rehidratación",
  Charmatstep2: "1.2 - Aclimatación/Etapa 1",
  Charmatstep3: "1.2 - Aclimatación/Etapa 2",
  Charmatstep4: "1.2 - Aclimatación/Etapa 3",
  Charmatstep5: "1.3 - Pie de Cuba",
  Charmatstep6: "1.3 - Pie de Cuba/Etapa 1",
  Charmatstep7: "1.3 - Pie de Cuba/Etapa 2",
  Charmatstep8: "1.3 - Pie de Cuba/Etapa 3",
  Charmatstep9: "Etapa intermedia",
  Charmatstep10: "Etapa intermedia",
  Charmatstep11: "Etapa intermedia",
  Charmatstep12: "1.4 - Etapas clave de la Fermentación Alcohólica",
  Charmatstep13: "2.1 - Etapas clave de la Crianza",
  Charmatstep14: "2.1 - Etapas clave de la Crianza",
  protocolCompleted: "Protocolo completado",
  continue: "Continuar",
  seeResults: "Ver los resultados",
  startnewProtocol: "Iniciar un nuevo protocolo",
  runningProtocole: "Protocolos en curso",

  goToNextStep: "Siguiente etapa",
  goBack: "Volver",
  none: "Ningun",
  by: "Por : ",
  of: "de",
  in: "en",
  to: "a",
  and: "y",
  at: "a",
  or: "o",
  yes: "Si",
  no: "No",
  between: "entre",
  add: "Añadir",
  perDay: "por día",
  wait: "Espere",
  minutes: "minutos",
  hour: "hora",
  hours: "horas",
  days: "días",
  liters: "litros",
  inHectoliters: "en hectolitros (hL)",
  g: "g",
  kg: "kg",
  cL: "cL",
  L: "L",
  dissolve: "Disolver",
  yeasting: "ADICIÓN DE LAS LEVADURAS",
  SULFITING: "SULFITADO",
  sulfiting: "Sulfitado",
  nutrition: "NUTRICIÓN",
  tannin: "TANINOS",
  enzyme: "ENZYMAS",
  anthocyaninsStabilization: "ESTABILIZACIÓN DE LOS ANTOCIANOS",
  addInTank: "Puntualización: siembra directa",
  mustClarification: "Clarificación del mosto",

  FMLInterStep:
    "Paso intermedio que debe completarse antes de continuar el protocolo",

  //Mentions Légales
  editor: "EDITOR",
  websiteEditBy: "Esta aplicación es editada por",
  headOffice: "Oficina central",
  tel: "Tel.",
  fax: "Fax.",
  sharedCapital: "Capital social",
  siretNumber: "N° SIRET",
  numero: "N°",
  TVA: "TVA",
  directorOfPublication: "Director de la publicación",
  host: "HOST",
  reproductiveRights: "Derechos de reproducción",
  reproductiveRightsContent:
    "La información presentada en este sitio es pública. Se autoriza la reproducción de la información de este sitio, a excepción de la iconografía, a condición de que se mencione la fuente y se cree un enlace en la(s) página(s) de referencia. La información de este sitio no puede utilizarse con fines comerciales o publicitarios.",
  personnalData: "DATOS PERSONALES",
  personnalDataContent:
    "Cuando los datos de este sitio son de carácter nominativo, los usuarios deben utilizarlos de acuerdo con la normativa vigente y las recomendaciones de la Comisión Nacional de la Informática y las Libertades (CNIL). La información utilizada debe ser únicamente para fines personales, asociativos o profesionales, quedando excluida cualquier distribución o uso con fines comerciales o publicitarios.",

  //Formulaire de contact
  titleContact: "Contacto",
  errorMessageContact: "Su mensaje o dirección de correo electrónico están vacíos.",
  errorSendMail:
    "Su mensaje no pudo ser enviado, por favor inténtelo más tarde.",
  send: "Envía",
  chaptalisationWarning:
    "La cantidad de azúcar cristalizado a añadir se da a título indicativo. La chaptalización está sujeta a regulación. Consulte la legislación vigente.",
  close: "Cerrar",
  inCaseOfProblem: "En caso de problema : ",
  laboSecretary: "Secretario de laboratorio",
  reimsAgency: "Sucursal de Reims",
  oenologist: "Enólogo",
  oenologistf: "Enólogo",
  technicalReferent: "Referente técnico",
  oenologyReferent: "Consultor enólogo de referencia",
  barSurSeineAgency: "Sucursal de Bar sur Seine",
  champagneAssistant: "Asistente de Champagne",
  mailSendWithSuccess:
    "Gracias por su solicitud de información. Nuestros equipos lo procesarán lo antes posible.",
  scanMe: "Escanee el código QR para descargar la aplicación en su móvil",
  lastName: "Apellido",
  firstName: "Primer nombre",
  phoneNumber: "Teléfono",
  mailAddress: "e-mail",
  postalAddress: "Dirección",
  zipCode: "Código postal",
  city: "Ciudad",
  writeHere: "Mensaje",
};

export default Sp;
