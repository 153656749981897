import { IonCard } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import chaptalisationCompute from "../../methods/chaptalisationCompute";
import { InputNumberField } from "./inputFields";

const Chaptalisation: React.FC = () => {

  const language = localStorage.getItem('language')

  const [density, setDensity] = useState<number>(0);
  const [temperature, setTemperature] = useState<number>(0);
  const [wantedDegree, setWantedDegree] = useState<number>(0);
  const [result, setResult] = useState<number>(0);

  useEffect(() => {
    if (density >= 1064 && temperature > 0 && wantedDegree > 0) {
      setResult(chaptalisationCompute(density, temperature, wantedDegree));
    }
  }, [density, temperature, wantedDegree]);
  return (
    <div>
      <IonCard style={{ textAlign: "left", padding: "5%" }}>
        <div className="inputDiv">
          <div className="inputTitle">
            <FormattedMessage id="density" />
          </div>
          <InputNumberField value={density} setValue={setDensity} />
          {density !== 0 && density < 1064 && (
            <span style={{ color: "red" }}>
              <FormattedMessage id="chaptalisationDensityError" />
            </span>
          )}
        </div>
        <div className="inputDiv">
          <div className="inputTitle">
            <FormattedMessage id="temperature" />
            <br />
            (°C) <span className="onlyIntergerMessage"><FormattedMessage id="onlyInteger" /></span>
          </div>
          <InputNumberField value={temperature} setValue={setTemperature} parseValue={(value) => parseInt(value, 10)}/>
        </div>
        <div className="inputDiv">
          <div className="inputTitle">
            <FormattedMessage id="wantedDegree" />
            (%vol.)
          </div>
          <InputNumberField value={wantedDegree} setValue={setWantedDegree}/>
        </div>
        <div style={{ textAlign: "center" }}>
          <FormattedMessage id="chaptalisationDescription" />
        </div>
      </IonCard>

      {density > 0 && temperature > 0 && wantedDegree > 0 && (
        <div>
          <div className="inputDiv">
            <div
              className="inputTitle"
              style={{ textAlign: "left", marginLeft: "5%" }}
            >
              <FormattedMessage id="results" /> :{" "}
            </div>
          </div>
          <div className="inputDiv" style={{ width: "80%", marginLeft: "10%" }}>
            <FormattedMessage id="chaptalisationResult" />
          </div>
          <span className="productValue">{result}</span> {language === 'RU-RU' ? 'kг/гл' : 'kg/hL'}
          <div className="actionDiv" style={{ color: "#C20E31" }}>
            <FormattedMessage id="chaptalisationWarning" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Chaptalisation;
