import { IonCard, IonIcon } from "@ionic/react";
import { returnUpBack } from "ionicons/icons";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import SO2Molecular from "../component/so2Molecular";

const SO2MolecularPage : React.FC = () => {
  const history = useHistory();
  return (
    <div>
      <div className='pageTitle'>SO<sub>2</sub> <FormattedMessage id='molecular'/></div>
      <IonCard
        style={{
          color: "black",
          width: "40%",
          padding: "5%",
          fontFamily: "Lato",
          fontSize: "15pt",
          fontWeight: 600,
        }}
        onClick={() => {
          history.goBack();
        }}
      > <IonIcon icon={returnUpBack} /> <FormattedMessage id="goBack" /></IonCard>
      <SO2Molecular/>
    </div>
  );
};

export default SO2MolecularPage;
