import { IonIcon } from "@ionic/react";
import { close, informationCircle } from "ionicons/icons";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import translations from '../../../modules/translations';
import FMLBloquee from "../../../types/FMLBloquee";
import MultiChoicesDisplaySeveralValues from "../multiChoicesDisplaySeveralValues";
import SimpleChoice from "../simpleChoice";
import SimpleChoiceSeveralValues from "../simpleChoiceSeveralValues";

interface Props {
  computedData: FMLBloquee | undefined;
  cepage: string;
}

interface CustomProps {
  option: Products;
  cepage: string;
}

type Product = {
  name: string;
  unit: string;
  values: (number | undefined)[];
  description: string;
};

type Products = Product[];

type Options = Products[];

const CustomDisplay: React.FC<CustomProps> = ({ option, cepage }) => {
  return (
    <div>
      {option.length === 1 ? (
        <div>
          {cepage === "chardonnay" ? (
            <div>
              <span className="productValue">{option[0].values[0]}</span>{" "}
              <span className="productUnit">
                <FormattedMessage id="to" />
              </span>{" "}
              <span className="productValue">{option[0].values[1]}</span>
              <span className="productUnit">
                <FormattedMessage id={option[0].unit} />{" "}
              </span>
            </div>
          ) : (
            <div>
              <span className="productValue">{option[0].values[0]}</span>{" "}
              <span className="productUnit">
                <FormattedMessage id={option[0].unit} />{" "}
              </span>
            </div>
          )}
          <span className="transitionWord">
            <FormattedMessage id="of" />
          </span>{" "}
          <span className="productName">{option[0].name}</span>
          <br />
          {option[0].description}
        </div>
      ) : (
        <div>
          {option.map((product, index) => (
            <div>
              {cepage === "chardonnay" ? (
                <div>
                  <span className="productValue">{product.values[0]}</span>{" "}
                  {product.values.length > 1 && (
                    <>
                      <span className="productUnit">
                        <FormattedMessage id="to" />
                      </span>{" "}
                      <span className="productValue">{product.values[1]}</span>
                    </>
                  )}
                  <span className="productUnit">
                    <FormattedMessage id={product.unit} />{" "}
                  </span>
                </div>
              ) : (
                <div>
                  <span className="productValue">{product.values[0]}</span>{" "}
                  <span className="productUnit">
                    <FormattedMessage id={product.unit} />{" "}
                  </span>
                </div>
              )}
              <span className="transitionWord">
                <FormattedMessage id="of" />
              </span>{" "}
              <span className="productName">{product.name}</span>
              <br />
              {product.description}
              <br />
              {index === 0 && <span>+</span>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const FMLBloqueeThirdStep: ({ computedData, cepage }: Props) => JSX.Element = ({
  computedData,
  cepage,
}) => {

  const language = localStorage.getItem('language');
  const traductions = translations[language as string];

  const [toggleCatalMessage, setToggleCatalMessage] = useState<boolean>(false);
  const [toggleNoirActivaMessage, setToggleNoirActivaMessage] =
    useState<boolean>(false);
  const options: Options =
    cepage === "chardonnay"
      ? [
          //Chardonnay
          [
            {
              name: "SULFOSSOL®150",
              unit: "cL",
              values: [
                computedData?.taille.firstStep.sulfossolOnly?.minVolume,
                computedData?.taille.firstStep.sulfossolOnly?.maxVolume,
              ],
              description: `(6 ${traductions["to"]} 7 ${language === 'RU-RU' ? 'сл/гл' : 'cL/hL'})`,
            },
          ],
          [
            {
              name: "SULFOSSOL®150",
              unit: "cL",
              values: [
                computedData?.taille.firstStep.sulfivendangeAndSulfossol
                  .sulfossolMinVolume,
                computedData?.taille.firstStep.sulfivendangeAndSulfossol
                  .sulfossolMaxVolume,
              ],
              description: `(1 ${traductions["to"]} 2 ${language === 'RU-RU' ? 'сл/гл' : 'cL/hL'})`,
            },
            {
              name: "SULFIVENDANGE",
              unit: "cL",
              values: [
                computedData?.taille.firstStep.sulfivendangeAndSulfossol
                  .sulfivendangeVolume,
              ],
              description: `(7 ${language === 'RU-RU' ? 'сл/гл' : 'cL/hL'})`,
            },
          ],
        ]
      : [
          //Pinot noir & Meunier
          [
            {
              name: "SULFOSSOL®150",
              unit: "cL",
              values: [computedData?.cuvee.firstStep.sulfossolOnlyVolume],
              description: `(7 ${language === 'RU-RU' ? 'сл/гл' : 'cL/hL'})`,
            },
          ],
          [
            {
              name: "SULFOSSOL®150",
              unit: "cL",
              values: [
                computedData?.cuvee.firstStep.sulfivendangeAndSulfossol
                  .sulfossolVolume,
              ],
              description: `(2 ${language === 'RU-RU' ? 'сл/гл' : 'cL/hL'})`,
            },
            {
              name: "SULFIVENDANGE",
              unit: "cL",
              values: [
                computedData?.cuvee.firstStep.sulfivendangeAndSulfossol
                  .sulfivendangeVolume,
              ],
              description: `(7 ${language === 'RU-RU' ? 'сл/гл' : 'cL/hL'})`,
            },
          ],
        ];

  const [selected, setSelected] = useState<Products>(options[0]);
  const [extended, setExtended] = useState<boolean>(false);

  return (
    <div>
      <div className="actionDiv">
        <span className="actionTitle">
          <FormattedMessage id="theTaille" />
        </span>{" "}
        -{" "}
        <span className="actionMethod">
          <FormattedMessage id="add" />
        </span>
      </div>
      <div className="actionDiv">
        {!extended ? (
          <div
            style={{
              border: "#1B1D26 solid 1px",
              borderRadius: "20px",
              padding: "3%",
            }}
            onClick={() => setExtended(true)}
          >
            <CustomDisplay option={selected} cepage={cepage} />
          </div>
        ) : (
          <div
            style={{
              border: "#1B1D26 solid 1px",
              borderRadius: "20px",
              padding: "3%",
            }}
            onClick={() => setExtended(false)}
          >
            {options.map((option, index) => (
              <div key={index} onClick={() => setSelected(option)}>
                <CustomDisplay option={option} cepage={cepage} />
                {index === 0 && (
                  <div
                    style={{
                      borderTop: "#1B1D26 solid 1px",
                      width: "50%",
                      margin: "20px 0 20px 25%",
                    }}
                  ></div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="actionDiv" style={{ position: "relative" }}>
        <IonIcon
          onClick={() => {
            setToggleCatalMessage(!toggleCatalMessage);
          }}
          size="large"
          color="warning"
          icon={informationCircle}
          style={{
            position: "absolute",
            left: "0%",
            top: "20%",
            zIndex: "1000",
          }}
        />
        <SimpleChoice
          action={{
            title: undefined,
            method: undefined,
            product: {
              name: "E CATAL® CLARIF",
              value: computedData?.taille.secondStep.catalWeight,
              description: `${
                cepage === "chardonnay" ? `(2 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})` : `(1 ${traductions["to"]} 2 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`
              }`,
              unit: "g",
            },
          }}
        />
      </div>
      {toggleCatalMessage && (
        <div
          style={{
            backgroundColor: "#1B1D26",
            color: "white",
            height: "86px",
            width: "100%",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 5%",
            position: "relative",
          }}
        >
          <IonIcon
            icon={close}
            style={{ position: "absolute", top: "5px", right: "5px" }}
            onClick={() => setToggleCatalMessage(false)}
          />
          <FormattedMessage id="FMLCatalMessage" />
        </div>
      )}
      {cepage === "pinotNoirMeunier" && (
        <>
          <div className="actionDiv" style={{ position: "relative" }}>
            <IonIcon
              onClick={() => {
                setToggleNoirActivaMessage(!toggleNoirActivaMessage);
              }}
              size="large"
              color="warning"
              icon={informationCircle}
              style={{
                position: "absolute",
                left: "0%",
                top: "20%",
                zIndex: "1000",
              }}
            />
            <SimpleChoiceSeveralValues
              action={{
                title: undefined,
                method: undefined,
                product: {
                  name: `NOIR ACTIVA + ${language === 'DE-DE' ? "Granulat" : "Granulé"}`,
                  values: [
                    computedData?.taille.fourthStep?.activa.minWeight,
                    computedData?.taille.fourthStep?.activa.maxWeight,
                  ],
                  unit: "kg",
                  description: `(50 ${traductions["to"]} 100 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
                },
              }}
            />
          </div>
          {toggleNoirActivaMessage && (
            <div
              style={{
                backgroundColor: "#1B1D26",
                color: "white",
                height: "86px",
                width: "100%",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0 5%",
                position: "relative",
              }}
            >
              <IonIcon
                icon={close}
                style={{ position: "absolute", top: "5px", right: "5px" }}
                onClick={() => setToggleNoirActivaMessage(false)}
              />
              <FormattedMessage id="FMLToDelay" />
            </div>
          )}
        </>
      )}
      <div className="actionDiv">
        <MultiChoicesDisplaySeveralValues
          choicesList={[
            {
              name: "ORIGIN® F-MAX",
              unit: "kg",
              values: [
                computedData?.taille.thirdStep.origin.minWeight,
                computedData?.taille.thirdStep.origin.maxWeight,
              ],
              description: `(50 ${traductions["to"]} 100 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
            },
            {
              name: "POLYGREEN®",
              unit: "kg",
              values: [
                computedData?.taille.thirdStep.polygreen.minWeight,
                computedData?.taille.thirdStep.polygreen.maxWeight,
              ],
              description: `(50 ${traductions["to"]} 100 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
            },
          ]}
        />
      </div>
      <p>
        <FormattedMessage id="FMLThirdStepMessage" />
      </p>
    </div>
  );
};

export default FMLBloqueeThirdStep;
