import React from "react";
import { FormattedMessage } from "react-intl";

interface Product {
  name: string;
  unit: string;
  value: number | undefined;
  description: string;
}

interface Action {
  title: string | undefined;
  method: string | undefined;
  product: Product;
}
interface Props {
  action: Action;
}

const SimpleChoice = ({ action }: Props) => {
  return (
    <div>
      {action.title && (
        <h1>
          {action.title !== "" &&<span className="actionTitle">
            <FormattedMessage id={action.title} />
            {" "} -
          </span>
          }
          <span className="actionMethod">
            {" "}
            <FormattedMessage id={action.method} /> :{" "}
          </span>
        </h1>
      )}
      <>
        <div>
          <span className="productValue">{action.product.value}</span>{" "}
          <span className="productUnit">
            <FormattedMessage id={action.product.unit} />
          </span>
          <br />
          <span className="transitionWords">
            <FormattedMessage id="of" />
          </span>{" "}
          <span className="productName">{action.product.name}</span>{" "}<br/>
          {action.product.description}
          <br />
        </div>
      </>
    </div>
  );
};

export default SimpleChoice;
