import React from "react";
import { FormattedMessage } from "react-intl";

const CharmatThirdStep: React.FC = () => {
  return (
    <div style={{ margin: "0 10% ", textAlign: "left" }}>
      <div className="inputDiv">
        <span className="productName">
          <FormattedMessage id="charmatThirdStepFirstMessage" />
        </span>
      </div>
      <div
        style={{
          border: "solid 1px #EDAE32",
          marginBottom: "10%",
          width: "30%",
        }}
      />
      <div className="inputDiv">
        <FormattedMessage id="charmatThirdStepSecondMessage" />
      </div>
      <div
        style={{
          border: "solid 1px #EDAE32",
          marginBottom: "10%",
          width: "30%",
        }}
      />
      <div className="inputDiv">
        <FormattedMessage id="charmatThirdStepThirdMessage" />
      </div>
      <div
        style={{
          border: "solid 1px #EDAE32",
          marginBottom: "10%",
          width: "30%",
        }}
      />
      <div className="inputDiv">
        <FormattedMessage id="charmatThirdStepFourthMessage" />
      </div>
    </div>
  );
};

export default CharmatThirdStep;
