import React from "react";
import { FormattedMessage } from "react-intl";
import Charmat from "../../../types/Charmat";

interface Props {
  computedData: Charmat | undefined;
  step: number;
}

const roundTo1Digit = (x: number | undefined) => x && Math.round(x * 10) / 10;

const CharmatSecondStep: React.FC<Props> = ({ computedData, step }) => {
  const language = localStorage.getItem('language');

  const alreadyPrepared = (step: number) => {
    switch (step) {
      case 2:
        return roundTo1Digit(computedData?.acclimatation.step1AlreadyPrepared);
      case 3:
        return roundTo1Digit(computedData?.acclimatation.step2AlreadyPrepared);
      case 4:
        return roundTo1Digit(computedData?.acclimatation.step3AlreadyPrepared);
    }
  };

  const basicWine = (step: number) => {
    switch (step) {
      case 2:
        return roundTo1Digit(computedData?.acclimatation.step1BasicWine);
      case 3:
        return roundTo1Digit(computedData?.acclimatation.step2BasicWine);
      case 4:
        return roundTo1Digit(computedData?.acclimatation.step3BasicWine);
    }
  };

  const water = (step: number) => {
    switch (step) {
      case 2:
        return roundTo1Digit(computedData?.acclimatation.step1Water);
      case 3:
        return roundTo1Digit(computedData?.acclimatation.step2Water);
      case 4:
        return roundTo1Digit(computedData?.acclimatation.step3Water);
    }
  };
  return (
    <div>
      <div className="inputDiv">
        <span className="actionTitle">
          <FormattedMessage id="stepCaps" /> {step - 1}{" "}
        </span>
      </div>
      <div className="inputDiv" style={{marginLeft:"10%", marginRight:"10%"}}>
        {step === 2 && (
          <FormattedMessage id="charmatSecondStepMessageFirstStep" />
        )}
        {step === 3 && (
          <FormattedMessage id="charmatSecondStepMessageSecondStep" />
        )}
        {step === 4 && (
          <FormattedMessage id="charmatSecondStepMessageThirdStep" />
        )}
        <p>
          <span className="productValue">{alreadyPrepared(step)}</span>
          <span className="productUnit">
            <FormattedMessage id="liters" />
          </span>
          <br />
          {language === 'DE-DE' && <>
            Gäransatz <br/>
          </>}
          <FormattedMessage id="alreadyPrepared" />
        </p>
      </div>
      <div>
        <span className="productValue">{basicWine(step)}</span>
        <span className="productUnit">
          <FormattedMessage id="liters" />
        </span>
        <br />
        <FormattedMessage id={`acclimatationBasicWine${step - 1 === 1 ? "12" : "14"}`} />
        <p>
          <span className="productValue">{water(step)}</span>
          <span className="productUnit">
            <FormattedMessage id="liters" />
          </span>
          <br />
          <FormattedMessage id={`water${step - 1 === 1 ? "30" : step - 1 === 2 ? "25" : "20"}`} />
        </p>
      </div>
      {step === 4 && (
        <div className="inputDiv">
          <span className="productValue">
            {roundTo1Digit(computedData?.acclimatation.step3Sugar)}
          </span>
          <span className="productUnit">
            <FormattedMessage id="kg" />
          </span>
          <br />
          <FormattedMessage id="of" /> <FormattedMessage id="sugar" />
        </div>
      )}
      <div className="inputDiv">
        <span className="productName">
          <FormattedMessage id="wait" /> {step === 2 ? "45 " : "1 "}{" "}
          {step === 2 ? (
            <FormattedMessage id="minutes" />
          ) : (
            <FormattedMessage id="hour" />
          )}
        </span>
      </div>
    </div>
  );
};

export default CharmatSecondStep;
