import { IonCard, IonIcon } from "@ionic/react";
import { mail } from "ionicons/icons";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import CharmatTemplate from "../../templates/CharmatTemplate";
import FMLBTemplate from "../../templates/FMLBTemplate";
import FMLTemplate from "../../templates/FMLTemplate";
import MacerationTemplate from "../../templates/MacerationTemplate";
import Charmat from "../../types/Charmat";
import FML from "../../types/FML";
import FMLBloquee from "../../types/FMLBloquee";
import Maceration from "../../types/Maceration";



const downloadResults : (props : Props) => void = ({computedData, sampleName, protocoleType, selectedDate, cepage , macerationType, aromaticProfile, sugar}) => {
  const element = document.createElement("a");
  let file = null;

  if (protocoleType === "FML") {
    const data = computedData as FML;
    const cep = cepage as string;
    file = FMLTemplate({data, sampleName, protocoleType, selectedDate, cep})
  }
  else if (protocoleType === "FMLB") {
    const data = computedData as FMLBloquee;
    const cep = cepage as string;
    file = FMLBTemplate({data, sampleName, protocoleType, selectedDate, cep})
  }
  else if (protocoleType === "Maceration") {
    const data = computedData as Maceration;
    const type = macerationType as string;
    file = MacerationTemplate({data, sampleName, protocoleType, selectedDate, macerationType : type})
  }
  else {
    const data = computedData as Charmat;
    const aromatic = aromaticProfile as string;
    const sug = sugar as number;
    file = CharmatTemplate({data, sampleName, protocoleType, selectedDate, aromaticProfile : aromatic , sugar : sug})
  }
  element.href = URL.createObjectURL(file);
  element.download = `${sampleName}.txt`;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
  document.body.removeChild(element);
};


interface Props {
  computedData: FML | FMLBloquee | Charmat | Maceration | undefined;
  sampleName: string;
  protocoleType: string;
  selectedDate: string;
  cepage: string | undefined;
  macerationType: string | undefined;
  aromaticProfile: string | undefined;
  sugar: number | undefined;
}

const EndOfProtocole: React.FC<Props> = ({computedData, sampleName, protocoleType, selectedDate, cepage, macerationType, aromaticProfile, sugar}) => {
  const history = useHistory();
  return (
    <div>
      <IonCard
        onClick={() => downloadResults({computedData, sampleName, protocoleType, selectedDate, cepage, macerationType, aromaticProfile, sugar})}
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10%",
          padding: "10% 0",
          fontFamily: "Lato",
          fontWeight: 800,
          fontSize: "20pt",
          color: "#1B1D26",
          verticalAlign: "center",
        }}
      >
        <span style={{width: "50%"}}><FormattedMessage id="sendResults" /></span>
        <IonIcon
          style={{ fontSize: "30pt", marginLeft: "15%" }}
          color="warning"
          icon={mail}
        />
      </IonCard>
      <IonCard
        onClick={() => history.push("/home")}
        color="dark"
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10%",
          padding: "10% 0",
          fontFamily: "Lato",
          fontWeight: 800,
          fontSize: "20pt",
          color: "white",
          verticalAlign: "center",
        }}
      >
        <FormattedMessage id="goBackToBoard" />
      </IonCard>
    </div>
  );
};

export default EndOfProtocole;
