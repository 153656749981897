import translations from "../modules/translations";
import Charmat from "../types/Charmat";

interface Props {
  data: Charmat;
  sampleName: string;
  protocoleType: string;
  selectedDate: string;
  aromaticProfile: string;
  sugar: number;
}

const roundTo1Digit = (x: number | undefined) => x && Math.round(x * 10) / 10;
const roundTo2Digit = (x: number | undefined) => x && Math.round(x * 100) / 100;

const CharmatTemplate: (props: Props) => Blob = ({
  data,
  sampleName,
  protocoleType,
  selectedDate,
  aromaticProfile,
  sugar,
}) => {
  const language = localStorage.getItem("language") as string;
  const traductions = translations[localStorage.getItem("language") as string];
  const content = new Blob(
    [
      traductions["protocoleType"],
      protocoleType,
      "\n",
      traductions["sampleTitle"],
      sampleName,
      "\n",
      traductions["beginningDate"],
      " : ",
      new Date(selectedDate).toLocaleDateString(
        localStorage.getItem("language") as string
      ),
      "\n \n",
      traductions["charmatFirstStepFirstMessage"],
      "\n",
      traductions["dissolve"],
      " :\n",
      `${roundTo1Digit(data.rehydratation.startYWeight)}`,
      traductions['kg'],
      traductions["of"],
      `START Y SP (5 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
      traductions["in"],
      `${roundTo1Digit(data.rehydratation.startYWaterVolume)}`,
      " ",
      traductions["liters"],
      " ",
      traductions["water35"],
      "\n",
      traductions["charmatFirstStepFirstBlackMessage"],
      "\n",
      traductions["dissolve"],
      " :\n",
      `${roundTo1Digit(data.rehydratation.SP7Weight)}`,
      traductions['kg'],
      traductions["of"],
      " ",
      aromaticProfile === "mineralFraisFruitBlanc"
        ? " SOEC 1971"
        : aromaticProfile === "tresFruite"
        ? "SP49"
        : aromaticProfile === "floralElegant"
        ? "SP39"
        : "SP7 ",
      " ",
      traductions["in"],
      `${roundTo1Digit(data.rehydratation.SP7WaterVolume)}`,
      " ",
      traductions["liters"],
      " ",
      traductions["water35"],
      "\n",
      traductions["charmatFirstStepSecondBlackMessage"],
      "\n",
      traductions["add"],
      "\n",
      `${roundTo1Digit(data.rehydratation.sugarWeight)}`,
      traductions['kg'],
      traductions["of"],
      " ",
      traductions["sugar"],
      "\n \n \n",
      traductions["TitleCharmatStep2"],
      "\n",
      traductions["stepCaps"],
      " 1",
      "\n",
      traductions["charmatSecondStepMessageFirstStep"],
      "\n",
      `${roundTo1Digit(data?.acclimatation.step1AlreadyPrepared)}`,
      " ",
      traductions["liters"],
      " ",
      traductions["alreadyPrepared"],
      "\n",
      `${roundTo1Digit(data?.acclimatation.step1BasicWine)}`,
      " ",
      traductions["liters"],
      " ",
      traductions["acclimatationBasicWine"],
      "\n",
      `${roundTo1Digit(data?.acclimatation.step1Water)}`,
      " ",
      traductions["liters"],
      " ",
      traductions["water30"],
      "\n",
      traductions["wait"],
      " 45 ",
      traductions["minutes"],
      "\n",
      traductions["stepCaps"],
      " 2",
      "\n",
      traductions["charmatSecondStepMessageSecondStep"],
      "\n",
      `${roundTo1Digit(data?.acclimatation.step2AlreadyPrepared)}`,
      " ",
      traductions["liters"],
      " ",
      traductions["alreadyPrepared"],
      "\n",
      `${roundTo1Digit(data?.acclimatation.step2BasicWine)}`,
      " ",
      traductions["liters"],
      " ",
      traductions["acclimatationBasicWine"],
      "\n",
      `${roundTo1Digit(data?.acclimatation.step2Water)}`,
      " ",
      traductions["liters"],
      " ",
      traductions["water30"],
      "\n",
      traductions["wait"],
      " 1 ",
      traductions["hour"],
      "\n",
      traductions["stepCaps"],
      " 3",
      "\n",
      traductions["charmatSecondStepMessageThirdStep"],
      "\n",
      `${roundTo1Digit(data?.acclimatation.step3AlreadyPrepared)}`,
      " ",
      traductions["liters"],
      " ",
      traductions["alreadyPrepared"],
      "\n",
      `${roundTo1Digit(data?.acclimatation.step3BasicWine)}`,
      " ",
      traductions["liters"],
      " ",
      traductions["acclimatationBasicWine"],
      "\n",
      `${roundTo1Digit(data?.acclimatation.step3Water)}`,
      " ",
      traductions["liters"],
      " ",
      traductions["water30"],
      "\n",
      `${roundTo1Digit(data?.acclimatation.step3Sugar)}`,
      traductions['kg'],
      traductions["sugar"],
      "\n",
      traductions["wait"],
      " 1 ",
      traductions["hour"],
      "\n \n \n",
      traductions["TitleCharmatStep3"],
      "\n",
      traductions["charmatThirdStepFirstMessage"],
      "\n",
      traductions["charmatThirdStepSecondMessage"],
      "\n",
      traductions["charmatThirdStepThirdMessage"],
      "\n",
      traductions["charmatThirdStepFourthMessage"],
      "\n \n \n",

      traductions["TitleCharmatStep4"],
      "\n",
      traductions["stepCaps"],
      " 1",
      "\n",
      traductions["charmatFourthStepMessageFirstStep"],
      "\n",
      `${roundTo1Digit(data?.feedStock.step1AlreadyPrepared)}`,
      " ",
      traductions["liters"],
      " ",
      traductions["alreadyPrepared"],
      "\n",
      `${roundTo1Digit(data?.feedStock.step1BasicWine)}`,
      " ",
      traductions["liters"],
      " ",
      traductions["feedStockBasicWine"],
      "\n",
      `${roundTo1Digit(data?.feedStock.step1Sugar)}`,
      traductions['kg'],
      traductions["sugar"],
      "\n",
      `${roundTo1Digit(data?.feedStock.step1StartY)}`,
      traductions['kg'],
      traductions["of"],
      " START Y SP ",
      traductions["or"],
      " ",
      `${roundTo1Digit(data?.feedStock.step1Charm)}`,
      traductions['kg'],
      traductions["of"],
      " CHARM AGE ",
      "\n",
      `${roundTo1Digit(data?.feedStock.step1Water)}`,
      " ",
      traductions["liters"],
      " ",
      traductions["water30"],
      "\n",
      traductions["wait"],
      " 24 ",
      traductions["hours"],
      "\n",

      traductions["stepCaps"],
      " 2",
      "\n",
      traductions["charmatSecondStepMessageSecondStep"],
      "\n",
      `${roundTo1Digit(data?.feedStock.step2AlreadyPrepared)}`,
      " ",
      traductions["liters"],
      " ",
      traductions["alreadyPrepared"],
      "\n",
      `${roundTo1Digit(data?.feedStock.step2BasicWine)}`,
      " ",
      traductions["liters"],
      " ",
      traductions["feedStockBasicWine"],
      "\n",
      `${roundTo1Digit(data?.feedStock.step2Sugar)}`,
      traductions['kg'],
      traductions["sugar"],
      "\n",
      `${roundTo1Digit(data?.feedStock.step2Water)}`,
      " ",
      traductions["liters"],
      " ",
      traductions["water30"],
      "\n",
      traductions["wait"],
      " 24 ",
      traductions["hours"],
      "\n",

      traductions["stepCaps"],
      " 3",
      "\n",
      traductions["charmatSecondStepMessageThirdStep"],
      "\n",
      `${roundTo1Digit(data?.feedStock.step3AlreadyPrepared)}`,
      " ",
      traductions["liters"],
      " ",
      traductions["alreadyPrepared"],
      "\n",
      `${roundTo1Digit(data?.feedStock.step3BasicWine)}`,
      " ",
      traductions["liters"],
      " ",
      traductions["feedStockBasicWine"],
      "\n",
      `${roundTo1Digit(data?.feedStock.step3Sugar)}`,
      traductions['kg'],
      traductions["sugar"],
      "\n",
      `${roundTo1Digit(data?.feedStock.step3Water)}`,
      " ",
      traductions["liters"],
      " ",
      traductions["water30"],
      "\n",
      traductions["optionnal"],
      " : ",
      `${roundTo1Digit(data?.feedStock.step3StartY)}`,
      " ",
      traductions["of"],
      " START Y SP",
      "\n",
      traductions["wait"],
      " 24 ",
      traductions["hours"],
      "\n",
      traductions["charmatFourthStepLastMessage"],
      "\n \n \n",
      traductions["TitleCharmatStep10"],
      "\n",
      `${roundTo1Digit(data?.interStep.sugar)}`,
      traductions['kg'],
      traductions["of"],
      " ",
      traductions["charmatSugarResultSugar"],
      "\n",
      ["mineralFraisFruitBlanc", "tresFruite"].includes(aromaticProfile)
        ? `${traductions["charmatSugarStartFreshMessage"]}\n ${roundTo1Digit(
            data?.interStep.startY
          )} ${traductions['kg']} ${traductions["of"]} START Y FRESH (20 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'}) ${
            traductions["and"]
          } ${roundTo1Digit(data?.interStep.dap)} ${traductions['kg']} ${
            traductions["of"]
          } DAP (10 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})\n`
        : ``,
      ["mineralFraisFruitBlanc", "cremeuxVarietal"].includes(aromaticProfile)
        ? `${
            traductions[`${aromaticProfile}SubliCharmMessage`]
          }\n ${roundTo1Digit(data?.interStep.subliCharm)} ${traductions['kg']} ${
            traductions["of"]
          } SUBLI CHARM (1.5 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})\n`
        : ``,
      ["floralElegant"].includes(aromaticProfile)
        ? `${
            traductions[`${aromaticProfile}SubliCharmMessage`]
          }\n ${roundTo2Digit(data?.interStep.floralSubliCharm)} ${traductions['kg']} ${
            traductions["of"]
          } SUBLI CHARM (1 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})\n`
        : ``,
      traductions[
        `${
          aromaticProfile === "cremeuxVarietal" ? "cremeuxVarietalC" : "c"
        }harmElevageMessage`
      ],
      "\n",
      `${roundTo2Digit(
        aromaticProfile === "floralElegant"
          ? data?.interStep.floralElevageCharm
          : aromaticProfile === "cremeuxVarietal"
          ? data?.interStep.creamyElevageCharm
          : data?.interStep.elevageCharm
      )}`,
      traductions['kg'],
      traductions["of"],
      " ",
      `CHARM ELEVAGE (${
        aromaticProfile === "floralElegant"
          ? "4"
          : aromaticProfile === "cremeuxVarietal"
          ? "8"
          : "5"
      }
      ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})\n`,
      ["mineralFraisFruitBlanc", "floralElegant"].includes(aromaticProfile)
        ? `${traductions["charmAgeMessage"]}\n ${roundTo2Digit(
            aromaticProfile === "floralElegant"
              ? data?.interStep.floralAgeCharm
              : data?.interStep.ageCharm
          )} ${traductions['kg']} ${traductions["of"]} CHARM AGE (
          ${aromaticProfile === "floralElegant" ? 4 : 5}
          ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})\n`
        : ``,
      ["floralElegant", "cremeuxVarietal"].includes(aromaticProfile)
        ? `${traductions["charmActivMessage"]}\n ${roundTo1Digit(
            data?.interStep.activCharm
          )} ${traductions['kg']} ${traductions["of"]} CHARM ACTIV (20 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})\n`
        : ``,
      aromaticProfile === "tresFruite"
        ? `${traductions["charmAgeMessage"]}\n ${roundTo1Digit(
            data?.interStep.redoxCharm
          )} ${traductions['kg']} ${traductions["of"]} CHARM REDOX T (3 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`
        : ``,
      "\n \n \n",
      traductions["TitleCharmatStep11"],
      "\n",
      traductions["decreaseTemperatureUnder15"],
      "\n",
      traductions["addProductsUnderPressure"],
      "\n",
      aromaticProfile === "mineralFraisFruitBlanc"
        ? `${roundTo2Digit(data?.alcoholicFerment.activCharm)} ${traductions['kg']} ${
            traductions["of"]
          } CHARM ACTIV (5 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`
        : aromaticProfile === "cremeuxVarietal"
        ? `${roundTo2Digit(data?.alcoholicFerment.phosphate)} ${traductions['kg']} ${
            traductions["of"]
          } PHOSPHATE COMPOSE (5 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`
        : `${roundTo2Digit(data?.alcoholicFerment.startY)} ${traductions['kg']} ${
            traductions["of"]
          } START Y FRESH (5 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'}) ${traductions["and"]} ${roundTo2Digit(
            data?.alcoholicFerment.dap
          )} ${traductions['kg']} ${traductions["of"]} DAP (5 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
      "\n",
      traductions["fermentRecommandedTemperature"],
      "\n",
      traductions["reductionAromaCase"],
      `${roundTo1Digit(data?.alcoholicFerment.redoxCharm)} ${traductions['kg']} ${
        traductions["of"]
      } CHARM REDOX T (2 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
      "\n",
      traductions["pressureReached"],
      "\n",
      traductions["necessaryTimeDependingEquipment"],
      "\n",
      traductions["setTemperatureTo0"],
      "\n \n \n",
      traductions["TitleCharmatStep12"],
      "\n",
      traductions["duringElevage"],
      "\n",
      traductions["firstOrder"],
      "\n",
      traductions["secondOrder"],
      "\n",
      traductions["thirdOrder"],
      "\n \n \n",
      traductions["TitleCharmatStep13"],
      "\n",
      traductions["elevageMessage"],
      "\n",
      aromaticProfile === "mineralFraisFruitBlanc"
        ? `${roundTo2Digit(data?.elevage.ageCharm)} ${traductions['kg']} ${
            traductions["of"]
          } CHARM AGE (5 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'}) ${traductions["or"]} ${roundTo2Digit(
            data?.elevage.subliCharm
          )} ${traductions['kg']} ${traductions["of"]} SUBLICHARM (1 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`
        : aromaticProfile === "tresFruite"
        ? `${roundTo2Digit(data?.elevage.ageCharm)} ${traductions['kg']} ${
            traductions["of"]
          } CHARM AGE (5 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'}) ${traductions["or"]} ${roundTo2Digit(
            data?.elevage.fruitedSubliCharm
          )} ${traductions['kg']} ${traductions["of"]} SUBLICHARM (1,5 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'}) ${
            traductions["or"]
          } ${roundTo2Digit(data?.elevage.elevageCharm)} ${traductions['kg']} ${
            traductions["of"]
          } CHARM ELEVAGE (5 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`
        : aromaticProfile === "floralElegant"
        ? `${roundTo2Digit(data?.elevage.ageCharm)} ${traductions['kg']} ${
            traductions["of"]
          } CHARM AGE (5 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'}) ${traductions["or"]} ${roundTo2Digit(
            data?.elevage.floralElevageCharm
          )} ${traductions['kg']} ${traductions["of"]} CHARM ELEVAGE (3 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'}) ${
            traductions["or"]
          } ${roundTo2Digit(data?.elevage.redoxCharm)} ${traductions['kg']} ${
            traductions["of"]
          } CHARM REDOX T (1,5 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`
        : `${roundTo2Digit(data?.elevage.creamySubliCharm)} ${traductions['kg']} ${
            traductions["of"]
          } SUBLICHARM (2 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'}) ${traductions["or"]} ${roundTo2Digit(
            data?.elevage.creamyElevageCharm
          )} ${traductions['kg']} ${traductions["of"]} CHARM ELEVAGE (10 ${language === 'RU-RU' ? 'г/гл' : 'g/hL'})`,
    ],
    {
      type: "text/plain; charset=utf-8",
    }
  );
  return content;
};

export default CharmatTemplate;
