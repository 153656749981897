import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

type Choice = {
  name: string;
  unit: string;
  value: number | (number | undefined)[] | undefined;
  description: string;
};

interface Props {
  setSelectedProduct: ((name: string) => void) | undefined;
  choicesList: Choice[];
}

const MultiChoicesDisplay: React.FC<Props> = ({
  setSelectedProduct,
  choicesList,
}) => {
  const [selected, setSelected] = useState(choicesList[0]);
  const [extended, setExtended] = useState(false);
  return (
    <>
      {selected && (
        <>
          {!extended ? (
            <div
              style={{
                border: "#1B1D26 solid 1px",
                borderRadius: "20px",
                padding: "3%",
              }}
              onClick={() => setExtended(true)}
            >
              <span className="productValue">{selected.value}</span>{" "}
              <span className="productUnit">
                <FormattedMessage id={selected.unit} />{" "}
              </span>
              <br />
              <span className="transitionWord">
                <FormattedMessage id="of" />
              </span>{" "}
              <span className="productName">{selected.name}</span>
              <br />
              {selected.description}
            </div>
          ) : (
            <div
              style={{
                border: "#1B1D26 solid 1px",
                borderRadius: "20px",
                padding: "3%",
              }}
              onClick={() => setExtended(false)}
            >
              {choicesList.map((choice) => (
                <div
                  key={choice.name}
                  onClick={() => {
                    setSelected(choice);
                    if (setSelectedProduct) setSelectedProduct(choice.name);
                  }}
                >
                  <span className="productValue">{choice.value}</span>{" "}
                  <span className="productUnit">
                    <FormattedMessage id={choice.unit} />{" "}
                  </span>
                  <br />
                  <span className="transitionWord">
                    <FormattedMessage id="of" />
                  </span>{" "}
                  <span className="productName">{choice.name}</span>
                  <br />
                  {choice.description}
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MultiChoicesDisplay;
