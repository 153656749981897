type Translation = {
  [key: string]: string;
};

const De: Translation = {
  "FR-FR": "Französisch",
  "EN-GB": "Englisch",
  "ES-ES": "Spanisch",
  "IT-IT": "Italienisch",
  "DE-DE": "Deutsch",
  "RU-RU": "Russisch",

  //Version ordinateur
  notAvailableForComputerTitle:
    "Die SOEC-App ist nicht für den PC verfügbar.",
  notAvailableForComputerContent:
    "Leider ist diese App nicht für den PC verfügbar. Um darauf zuzugreifen, scannen Sie den Code oder nutzen Sie diese URL: {soecUrl} auf Ihrem Tablet oder Smartphone. Um die App auf Ihrem Gerät zu installieren, folgen Sie den Pop-up-Anweisungen.",

  //installer la PWA
  addSOECToApp: "SOEC zu Ihren Apps hinzufügen!",
  appSOECToAppInformations:
    "Sie können unsere App zu Ihren mobilen Apps hinzufügen. Hierzu müssen Sie nur unten den Button anklicken.",
  appSOECToAppInformationsIos:
    "Zur Installation auf Ihrem iPhone klicken Sie das Icon Teilen an:",
  appSOECToAppInformationsIosFinalStep:
    "Dann auf «Zum Startbildschirm» klicken:",
  addApplication: "App hinzufügen",
  stayOnBrowser: "Im Browser bleiben",

  //Menu
  home: "Startseite",
  tanks: "Meine Tanks",
  protocoles: "Protokolle",
  calculators: "Rechner",
  contact: "Hilfe?",

  //Page d'accueil
  legalesNoticies: "Impressum",
  GTC: "AGB",
  calculatorsTools: "Entscheidungshilfetools",
  startA: "Start eines",
  protocole: "Protokolls",
  allTools: "Alle Tools",
  useCalculator: "Einen Rechner benutzen",
  handleTank: "Meine Tanks verwalten",

  //Page d'accueil sans protocole
  noProtocoleFoundfirst: "Es wurde kein laufendes Protokoll gefunden",
  noProtocoleFoundsecond:
    "Wir haben kein Protokoll auf Ihrem Gerät gefunden.",
  noProtocoleFoundthird:
    "Start Ihres ersten Protokolls durch Klick unten!",

  //Liste des protocoles disponibles
  protocols: "Protokolle",
  maceration: "Maischung",
  traditionalMethod: "Traditionelle Methode",
  feedStockMethod: "Großraumverfahren",
  martinottiMethod: "Martinotti-Methode",
  martinottiMethodDescription:
    "Die Protokolle zur traditionellen Methode sind auf die Champagnerbereitung ausgerichtet. Wenden Sie sich gerne an unsere beratenden Önologen.",
  malolacticFermentation: "Vinifikation mit BSA",
  malolacticFermentationBlocked: "Vinifikation ohne BSA",

  //Liste des calculateurs disponible
  decisionSupportToolsCaps: "ENTSCHEIDUNGSHILFETOOLS",
  calculationCorrection: "Berechnung der Korrektur des",
  overpressureSparklingWine: "Überdrucks eines Schaumweins",
  calculationFoot: "BSA-Ansatz-Berechnung",
  maloTankLowPh: "bei niedrigem pH",
  calculationOf: "Berechnung des",
  sulfurAcetaldehyde: "Schwefel - Acetaldehyd",
  calculationOfThe: "Berechnung der",

  //Propre à tous les protocoles
  selectTank: "Tank wählen / hinzufügen",
  askSelectedTank: "Welchem Tank soll das Protokoll zugeordnet werden?",
  tankVolume: "Tankvolumen:",
  tankVolumeInfo:
    "(Zum Ändern des Volumens können Sie einen Tank ändern bzw. hinzufügen.)",
  beginningDate: "Durchführungsdatum",
  askBeginningDate: "Wann wollen Sie das Protokoll starten?",
  sampleName: "Proben- bzw. Chargennummer",
  askSampleName: "Für welche Probe soll das Protokoll realisiert werden?",
  beginProtocole: "PROTOKOLL STARTEN",
  endProtocole: "Protokoll beenden",
  sendResults: "Ergebnisse herunterladen",
  goBackToBoard: "Zurück zum Dashboard",
  selectADate: "Datum auswählen",
  selectATank: "Tank auswählen",
  selectACepage: "Rebsorte auswählen",
  selectAMacerationType : "Maischungsart auswählen",
  selectAnAromaticProfile: "Aromenprofil auswählen",
  nameTheTank: "Tanknummer",
  errorMessageInput: "Das ist ein Pflichtfeld",
  errorNumberInput: "Der Wert muss größer als 0 sein",
  errorSelectTank: "Bitte Tank auswählen",
  errorDate: "Datum auswählen",
  optionnal: "Optional",

  //Protocole Macération
  //Titre et sous-titre pour chacune des étapes
  TitleMacerationStep0_classic: "Maischung",
  TitleMacerationStep1_classic: "Klassische Maischung",
  TitleMacerationStep2_classic: "Tankbefüllung",
  TitleMacerationStep3_classic: "Schritte vor der Gärung",
  TitleMacerationStep4_classic: "Alkoholische Gärung",
  TitleMacerationStep5_classic: "Alkoholische Gärung",
  TitleMacerationStep6_classic: "Maischung",
  TitleMacerationStep7_classic: "Anreicherung",
  TitleMacerationStep8_classic: "Abstich, Pressen & Gärende",
  TitleMacerationStep9_classic: "BSA",
  TitleMacerationStep10_classic: "Protokoll-Ende",

  SubtitleMacerationStep0_classic: "TRADITIONELLE METHODE",
  SubtitleMacerationStep1_classic: " ",
  SubtitleMacerationStep2_classic: "Klassische Maischung",
  SubtitleMacerationStep3_classic: "Klassische Maischung",
  SubtitleMacerationStep4_classic: "Klassische Maischung",
  SubtitleMacerationStep5_classic: "Klassische Maischung",
  SubtitleMacerationStep6_classic: "Klassische Maischung",
  SubtitleMacerationStep7_classic: "Klassische Maischung",
  SubtitleMacerationStep8_classic: "Klassische Maischung",
  SubtitleMacerationStep9_classic: "Klassische Maischung",
  SubtitleMacerationStep10_classic: "Klassische Maischung",

  TitleMacerationStep0_coldPreferm: "Maischung",
  TitleMacerationStep1_coldPreferm: "Cryomaceration vor der Gärung",
  TitleMacerationStep2_coldPreferm: "Tankbefüllung",
  TitleMacerationStep3_coldPreferm: "Enzymgabe",
  TitleMacerationStep4_coldPreferm: "Cryomaceration vor der Gärung",
  TitleMacerationStep5_coldPreferm: "Alkoholische Gärung",
  TitleMacerationStep6_coldPreferm: "Maischung",
  TitleMacerationStep7_coldPreferm: "Anreicherung",
  TitleMacerationStep8_coldPreferm: "Abstich, Pressen & Gärende",
  TitleMacerationStep9_coldPreferm: "BSA",
  TitleMacerationStep10_coldPreferm: "Protokoll-Ende",

  SubtitleMacerationStep0_coldPreferm: "TRADITIONELLE METHODE",
  SubtitleMacerationStep1_coldPreferm: " ",
  SubtitleMacerationStep2_coldPreferm: "Cryomaceration vor der Gärung",
  SubtitleMacerationStep3_coldPreferm: "Cryomaceration vor der Gärung",
  SubtitleMacerationStep4_coldPreferm: "Cryomaceration vor der Gärung",
  SubtitleMacerationStep5_coldPreferm: "Cryomaceration vor der Gärung",
  SubtitleMacerationStep6_coldPreferm: "Cryomaceration vor der Gärung",
  SubtitleMacerationStep7_coldPreferm: "Cryomaceration vor der Gärung",
  SubtitleMacerationStep8_coldPreferm: "Cryomaceration vor der Gärung",
  SubtitleMacerationStep9_coldPreferm: "Cryomaceration vor der Gärung",
  SubtitleMacerationStep10_coldPreferm: "Cryomaceration vor der Gärung",

  //Quantité de raisin
  harvestWeight: "Eingesetzte Traubenmenge",
  askHarvestWeight: "Größe Ihrer Lese in Kilogramm?",
  //Type de macération
  macerationType: "Maischungsart",
  askMacerationType: "Welche Maischungsart wollen Sie durchführen?",
  coldPreferm: "Cryo vor Gärung",
  classic: "Klassisch",

  //Etape 1
  harvestSorting: "Lesegut-Selektion",
  foulageEraflage: "Mahlen - Abbeeren",

  //Etape 2 pour préf. à froid
  decreaseTemperature: "Temperatur senken",
  toDecreaseOf1Deg: "zur Senkung um 1°C",

  //Etape 3 pour préf. à froid
  atT24: "Nach 24 Std.",

  //Etape 4 pour pref. à froid
  prefermTitle: "CRYOMACERATION VOR DER GÄRUNG",
  pigeageWithoutOxygene: "Pigeage ohne Belüftung",

  //Etape 6 pour les 2
  macerationCaps: "MAISCHUNG",
  remontagePigeage: "Umpumpen und/oder Pigeage mit Belüftung",
  decreaseVolume:
    "Umgepumptes Volumen in dem Maß senken wie Dichte sinkt",

  //Etape 7 pour les 2
  chaptalisationMessage:
    "Bei Durchführung der Anreicherung sollte die Dichte unter 1040 liegen.",

  //Etape 8 pour les 2
  devatting: "ABSTICH",
  devattingContent: "Wenn die Dichte um 1000 liegt",
  pressing: "PRESSUNG",
  pressingContent:
    "Für einen etwaigen späteren Verschnitt von Vorlauf- und Pressmost ziehen Sie Ihren Önologen zurate.",
  endOfAF: "Gärende",
  bottling: "Abziehen",

  //Etape 9 pour les 2
  malolacticFermentationCaps: "BSA",

  //FML
  TitleFMLStep0: "Vinifikation mit BSA",
  TitleFMLStep1: "Schritte vor der Gärung",
  TitleFMLStep2: "Schritte vor der Gärung",
  TitleFMLStep3: "Schritte vor der Gärung",
  TitleFMLStep4: "Alkoholische Gärung",
  TitleFMLStep5: "Alkoholische Gärung",
  TitleFMLStep6: "BSA",
  TitleFMLStep7: "Protokoll-Ende",

  SubtitleFMLStep0: "TRADITIONELLE METHODE",
  SubtitleFMLStep1: "Vinifikation mit BSA",
  SubtitleFMLStep2: "Vinifikation mit BSA",
  SubtitleFMLStep3: "Vinifikation mit BSA",
  SubtitleFMLStep4: "Vinifikation mit BSA",
  SubtitleFMLStep5: "Vinifikation mit BSA",
  SubtitleFMLStep6: "Vinifikation mit BSA",
  SubtitleFMLStep7: "Vinifikation mit BSA",

  //Type de cépage
  cepageType: "Rebsorte der Probe",
  askCepageType: "Von welcher Rebsorte stammt die Probe?",
  chardonnay: "Weiße Sorten - Blanc de Noirs",
  pinotNoirMeunier: "Rote Sorten",

  //Etape 1 pour les 2 (Sulfitage est déjà défini)
  FMLFirstStepSulfitingMessage:
    "Nach Mostablauf, Zugabe in 3 Schritten",
  coldPrefermOperations: "Behandlung Cryo vor der Gärung",
  whenNeeded: "Nach Bedarf",

  //Etape 2
  theCuvee: "DIE CUVÉE",
  FMLToDelay:
    "In der 10-fachen Menge Wasser auflösen und danach zum Most geben.",
  FMLCatalMessage:
    "Vor dem teilweisen Umpumpen in den Vorklärtank geben.",
  FMLThirdStepMessage:
    "Je nach Oxidation der Moste und Farbextraktion (Chinone).",

  //Etape 3
  theTaille: "DIE TAILLE",

  //Etape 4
  FMLYeastingWater: "Hälfte Wasser / Hälfte Most bei 37°C",
  FMLYeastingMessage:
    "Es ist entscheidend, die Rehydrierungstemperatur einzuhalten und die 40°C nicht zu überschreiten, damit die Zellen nicht absterben.",
  FMLYeastingMessagePart1:
    "20 Minuten ruhen lassen, dann durch leichtes Rühren durchmischen.",
  FMLYeastingMessagePart2:
    "Den Hefeansatz in die 10- bis 20-fache Mostmenge geben.",
  FMLYeastingMessagePart3:
    "6 bis 12 Stunden gehen lassen, dann in den Tank geben.",

  //Etape 5
  yeastNutrition: "NÄHRSTOFFVERSORGUNG DER HEFE",
  FMLYeastNutritionMessage:
    "Dieser Nährstoffzusatz ermöglicht eine sichere Vergärung und optimiert das Aromenprofil der Weine.",

  //FML Bloquée
  TitleFMLBloqueeStep0: "Vinifikation ohne BSA",
  TitleFMLBloqueeStep1: "Schritte vor der Gärung",
  TitleFMLBloqueeStep2: "Schritte vor der Gärung",
  TitleFMLBloqueeStep3: "Schritte vor der Gärung",
  TitleFMLBloqueeStep4: "Alkoholische Gärung",
  TitleFMLBloqueeStep5: "Alkoholische Gärung",
  TitleFMLBloqueeStep6: "Protokoll-Ende",

  SubtitleFMLBloqueeStep0: "TRADITIONELLE METHODE",
  SubtitleFMLBloqueeStep1: "Vinifikation ohne BSA",
  SubtitleFMLBloqueeStep2: "Vinifikation ohne BSA",
  SubtitleFMLBloqueeStep3: "Vinifikation ohne BSA",
  SubtitleFMLBloqueeStep4: "Vinifikation ohne BSA",
  SubtitleFMLBloqueeStep5: "Vinifikation ohne BSA",
  SubtitleFMLBloqueeStep6: "Vinifikation ohne BSA",

  //Charmat
  TitleCharmatStep0: "Martinotti-Methode",
  TitleCharmatStep1: "1.1 - Rehydrierung",
  TitleCharmatStep2: "1.2 - Akklimatisierung",
  TitleCharmatStep3: "1.2 - Akklimatisierung",
  TitleCharmatStep4: "1.2 - Akklimatisierung",
  TitleCharmatStep5: "1.3 - Gäransatz",
  TitleCharmatStep6: "1.3 - Gäransatz",
  TitleCharmatStep7: "1.3 - Gäransatz",
  TitleCharmatStep8: "1.3 - Gäransatz",
  TitleCharmatStep9: "Zwischenschritt",
  TitleCharmatStep10: "Zwischenschritt",
  TitleCharmatStep11: "1.4 - Schlüsseletappen der Vergärung",
  TitleCharmatStep12: "2.1 - Schlüsseletappen des Ausbaus",
  TitleCharmatStep13: "2.1 - Schlüsseletappen des Ausbaus",
  TitleCharmatStep14: "Protokoll-Ende",

  SubtitleCharmatStep0: "Großraumverfahren",
  SubtitleCharmatStep1: "Martinotti-Methode - Starter",
  SubtitleCharmatStep2: "Martinotti-Methode - Starter",
  SubtitleCharmatStep3: "Martinotti-Methode - Starter",
  SubtitleCharmatStep4: "Martinotti-Methode - Starter",
  SubtitleCharmatStep5: "Martinotti-Methode - Starter",
  SubtitleCharmatStep6: "Martinotti-Methode - Starter",
  SubtitleCharmatStep7: "Martinotti-Methode - Starter",
  SubtitleCharmatStep8: "Martinotti-Methode - Starter",
  SubtitleCharmatStep9: "Martinotti-Methode - Starter",
  SubtitleCharmatStep10: "Martinotti-Methode - Starter",
  SubtitleCharmatStep11: "Martinotti-Methode - Starter",
  SubtitleCharmatStep12: "Martinotti-Methode - Starter",
  SubtitleCharmatStep13: "Martinotti-Methode - Starter",
  SubtitleCharmatStep14: "Martinotti-Methode - Starter",

  mineralFraisFruitBlanc: "Mineralisch, frisch, helles Obst",
  tresFruite: "Sehr fruchtig",
  floralElegant: "Floral & elegant",
  cremeuxVarietal: "Cremig & sortentypisch",

  //Etape 1
  charmatFirstStepFirstMessage:
    "Bringen Sie den Grundwein zunächst auf eine Temperatur von 12 °C.",
  water35: "Wasser (35-36°C)",
  water30: "Wasser (30°C)",
  water25: "Wasser (25°C)",
  water20: "Wasser (20°C)",
  charmatFirstStepFirstBlackMessage:
    "Es ist entscheidend, die Rehydrierungstemperatur einzuhalten und die 40°C nicht zu überschreiten, damit die Zellen nicht absterben.",
  charmatFirstStepSecondBlackMessage:
    "Kräftig rühren, damit die Hefe nicht klumpt.",
  aromaticProfile: "Gewünschtes Aromenprofil",
  askAromaticProfile: "Welches Aromenprofil soll Ihr Wein haben?",

  //Etape 2
  sugar: "Zucker",
  stepCaps: "SCHRITT",
  step: "Schritt",
  charmatSecondStepMessageFirstStep:
    "Die Bestandteile unten in einem separaten Gefäß mischen.",
  charmatSecondStepMessageSecondStep:
    "Etwas Grundwein und Wasser zur Zubereitung geben.",
  charmatSecondStepMessageThirdStep:
    "Die folgenden Produkte zum Abschluss der Akklimatisierung zugeben.",
  alreadyPrepared: "schon zubereitet",
  acclimatationBasicWine12: "Grundwein (12°C)",
  acclimatationBasicWine14: "Grundwein (14°C)",
  feedStockBasicWine: "Grundwein (15-16°C)",
  feedStockBasicWine16: "Grundwein (16°C)",

  //Etape 3
  charmatThirdStepFirstMessage:
    "In den 3 Tagen der Zubereitung des Gäransatzes ist sicherzustellen, dass:",
  charmatThirdStepSecondMessage:
    "Die Dichte nie unter 1005 sinkt (bei Abnahme etwas Zucker zugeben)",
  charmatThirdStepThirdMessage:
    "Der Temperaturunterschied zwischen Akklimatisierungsmilieu und Wein 5-7°C nicht übersteigt",
  charmatThirdStepFourthMessage:
    "Die Temperatur des zum Ansatz zugegebenen Grundweins stets zwischen 16 und 18°C liegt",

  //Etape 4
  charmInfo: "Empfohlen für eine bessere Beständigkeit",
  charmatFourthStepMessageFirstStep:
    "Nach einer Stunde wird das Volumen mit Grundwein verdoppelt, die Temperatur muss bei 18-20°C bleiben.",
  charmatFourthStepMessageSecondStep:
    "Nach 24 Std. vorherigen Schritt wiederholen und Volumen verdoppeln.",
  charmatFourthStepMessageThirdStep:
    "Nach weiteren 24 Std. die Nährstoffe zugeben und den vorherigen Schritt wiederholen.",
  charmatFourthStepLastMessage:
    "Sobald die Gärung eingesetzt hat, die gesamte zubereitete Menge in den Drucktank geben.",
  startYInfo: "Nur bei hohem Alkoholgehalt",

  //Etape 5
  sugarQuantity: "Restzuckermenge",
  sugarQuantityAsk:
    "Welche Restzuckermenge wird gewünscht? (in g/L)",

  //Etape 6
  charmatSugarResultSugar: "Zucker (25 g/L + Restzucker)",
  charmatSugarStartFreshMessage:
    "Es wird empfohlen, START® Y FRESH (20 g/hL) zuzusetzen, um die Hefe mit Nährstoffen zu versorgen",
  mineralFraisFruitBlancSubliCharmMessage:
    "Zur Förderung der fruchtigen Aromen hinzugeben:",
  cremeuxVarietalSubliCharmMessage: "Zur Steigerung der Üppigkeit im Mund:",
  floralElegantSubliCharmMessage: "Zur Steigerung der Üppigkeit im Mund:",
  charmElevageMessage:
    "Zur Steigerung von Üppigkeit und Fülle und zur Verbesserung der Schaumqualität hinzugeben:",
  tresFruiteCharmElevageMessage:
    "Zur Steigerung der Üppigkeit und des fruchtigen Eindrucks sowie zur Verbesserung der Schaumqualität hinzugeben:",
  cremeuxVarietalCharmElevageMessage:
    "Zur Verbesserung von REDOX-Gleichgewicht und MP:",
  charmAgeMessage:
    "Zur Verbesserung der Lagerfähigkeit und zur Bewahrung zarter Aromen hinzugeben:",
  charmActivMessage: "Zur Versorgung der Hefe mit Nährstoffen:",
  charmRedoxMessage: "Zur Vorbeugung von Reduktion:",

  //Etape 7
  decreaseTemperatureUnder15: "Temperatur unter 15°C senken",
  addProductsUnderPressure:
    "Falls die Möglichkeit zur Zugabe von Produkten bei 2,5 bar Druck besteht, hinzugeben:",
  fermentRecommandedTemperature:
    "Empfohlene Gärtemperatur: 14-16°C",
  reductionAromaCase: "Bei Auftreten von Reduktionsnoten hinzugeben:",
  pressureReached:
    "Sobald der Druck 4,3 bis 4,6 atm erreicht (berechnet bei 20°C), Temperatur unter 2°C senken, um die Gärung zu stoppen.",
  necessaryTimeDependingEquipment:
    "Je nach Ausstattung dauert das ca. 1 bis 2 Tage.",
  setTemperatureTo0:
    "Temperatur auf 0°C einstellen und Wein filtrieren (Cross-Flow- oder Membranfiltration 1 μm).",

  //Etape 8
  duringElevage: "Während des Ausbaus muss das Rütteln:",
  firstOrder: "in den ersten 15 Tagen 2x15 Minuten pro Tag erfolgen",
  secondOrder: "in den nächsten 30 Tagen alle 3 Tage 1x20 Minuten erfolgen",
  thirdOrder: "in den letzten 15 Tagen einmal pro Woche 1x15 Minuten erfolgen",

  //Etape 9
  elevageMessage:
    "Ausbau bei einer Mindesttemperatur von 6-8°C für mindestens 2 Monate mit wahlweise (je nach Verkostungsergebnissen):",

  //Calculateur SO2 Moléculaire
  so2MolecularTitle: "Berechnung des Gehalts an molekularem SO{sub2}",
  so2MolecularSubTitle:
    "Achtung, molekulares SO{sub2} wirkt jenseits einer Konzentration von 1 mg/L keimtötend.",
  so2Amount: "Gehalt an molekularem SO{sub2}",
  pH: "pH",
  good: "Abfüllen möglich",
  risquy: "Gefährlich",
  lethal: "Letal",

  //Calculateur Soufre - Acétaldéhyde
  soufreAcetaldehydeTitle: "Schwefel - Acetaldehyd",
  soufreAcetaldehydeSubtitle: "Häufig für die Martinotti-Methode verwendet",
  so2Molecular: "molekulares SO{sub2}",
  molecular: "molekular",
  totalEthanal: "Acetaldehyd gesamt",
  freeSO2: "freies SO{sub2}",
  totalSO2: "Gesamt-SO{sub2}",
  residualSugar: "Restzucker",
  wantedFreeSO2: "Zielwert freies SO{sub2}",
  freeEthanal: "freies Acetaldehyd",
  freeCombiningSO2: "freies So{sub2}, das Verbindungen eingeht",
  SO2ToAdd: "Zuzugebendes SO{sub2}",
  finalTotalSO2: "Endwert des Gesamt-SO{sub2}",

  //Calculateur Chaptalisation
  chaptalization: "Anreicherung",
  density: "Dichte",
  wantedDegree: "gewünschter Grad",
  chaptalisationDescription:
    "Die Erfahrung aus der Beobachtung zahlreicher Lesen zeigt, dass es zur Erzielung eines Endgrads von 11% vol wünschenswert ist, 11% vol bei Chardonnay und 11,2% vol bei Pinot Noir und Pinot Meunier anzusteuern.",
  results: "Ergebnisse",
  chaptalisationDensityError:
    "Geben Sie eine Dichte höher oder gleich 1064 ein.",
  chaptalisationResult:
    "Zuzugebende Kristallzuckermenge, um den gewünschten Alkoholgrad in % vol zu erhalten:",
  chaptalisationWarningTitle:
    "Bei Durchführung der Anreicherung sollte die Dichte unter 1040 liegen.",
  chaptalisationWarningContent:
    "Impressum zur Anreicherung.",

  //Calculateur Pied de Cuve Malo
  maloTankTitle: "Milchsäurebakterien-Ansatz bei niedrigem pH",
  cuveMaloTitle: "Vorgehensweise bei der Zubereitung eines Milchsäurebakterien-Ansatzes bei Most",
  cuveMaloContent:
    "Nach einer von der technischen Abteilung der CIVC entwickelten und in der Fachzeitschrift „Vigneron Champenois“ Nr. 6 - Juni 2009 veröffentlichten Vorgehensweise",
  totalWineToSeed: "Gesamtmenge des zu beimpfenden Weins",
  inHectoliter: "In Hektolitern (HL)",
  globaldata: "Gesamtdaten",
  rehydratation: "Rehydrierung",
  reactivationEnvironment: "Milieu für die Reaktivierung",
  feedStock: "Gäransatz",
  reactivationEnvironmentVolume: "Menge des Milieus für die Reaktivierung:",
  feedStockVolume: "Menge des Gäransatzes:",
  bacteries: "Eingesetzte Bakterien: ",
  activator: "Eingesetzter Aktivator: ",
  dryYeast: "Eingesetzte Trockenhefen: ",
  globalDataFeedStockMessage:
    "Die für den Ansatz verwendeten Moste sind vorgeklärte, nicht angereicherte Moste, vorzugsweise Pressmoste, die mit max. 4 g/hL (also 2,7 cL/hL SULFOSSOL® 150) geschwefelt wurden.",
  maloTankMiddleMessage:
    "Der Ansatz und das Milieu für die Reaktivierung sind gleichzeitig zuzubereiten.",
  delay: "Rühren Sie",
  litersOfWater: "Liter Wasser (25°C)",
  rehydratationMessage:
    "Kräftig rühren, um Klumpenbildung zu verhindern. 15 bis 20 Minuten ruhen lassen, dann die Zubereitung durch erneutes Rühren durchmischen und anschließend das Milieu für die Reaktivierung herstellen.",
  preparedBefore: "(zuvor hergestellt)",
  rehydratedBacteries: "rehydrierte Bakterien",
  settlingMust: "vorgeklärter & wenig geschwefelter Most (halbe Dosage)",
  ofWaterAt25: "Wasser mit einer Temperatur von 25°C",
  sprinklingDryYeast:
    "Trockenhefe SOEC® 1971 ohne Rehydrierung hineinrieseln",
  inTotal: "Gesamtmenge",
  reactivationEnvironmentMessage:
    "Darauf achten, während der gesamten Reaktivierung eine Temperatur von 25°C beizubehalten und sobald das Milieu für die Reaktivierung fertig ist (Äpfelsäure < 0,5 g/L), dessen Menge mit gärendem Most aus dem Gäransatz verdoppeln – ergibt:",
  duration: "Dauer",
  mustWaterMixing:
    "einer Mischung aus Most u. warmem Wasser an, um eine Temperatur von 35 °C zu erhalten.",
  feedStockFirstMessagePart1:
    "(Darauf achten, die vorstehenden Temperaturen einzuhalten und 40°C nicht zu überschreiten, da die aktiven Zellen ansonsten zerstört werden). Kräftig rühren, um Klumpenbildung zu verhindern.",
  feedStockFirstMessagePart2:
    "20 bis 30 Minuten ruhen lassen, dann die Zubereitung durch erneutes Rühren durchmischen und anschließend den Gäransatz herstellen.",
  rehydratedYeasts: "rehydrierte Hefe",
  must: "Most",
  feedStockVolumeComplement:
    "Zufuhr des Milieus für die Reaktivierung zum Gäransatz nach 3 Tagen",
  feedStockTotalVolume: "Gesamtmenge des Gäransatzes: ",
  feedStockSecondMessage:
    "Die Temperatur des Gäransatzes während der Gärung bei 25 °C halten, während des BSA dann 20 °C. Die Menge der bei der Beimpfung vorhandenen Äpfelsäure bestimmen. Dann wird die Kontrolle nach 6 bzw. 7 Tagen u. dann alle 2 Tage durchgeführt.",
  feedStockFinalMessagePart1:
    "Die Beimpfung der Gebinde erfolgt in einem Verhältnis von 3% durch Zufuhr von oben in die Tanks und ohne Rühren, sobald 2/3 der im Gäransatz vorhandenen Äpfelsäure aufgebraucht sind.",
  feedStockFinalMessagePart2:
    "Die Zufuhr zum Wein kann während oder nach der Gärung erfolgen, mit oder ohne vorheriges Abziehen der zu beimpfenden Gebinde.",
  feedStockFinalMessagePart3:
    "Um von der thermischen Trägheit der Gebinde zu profitieren, empfiehlt es sich, die Temperatursteuerung bei 1005 - 1010 abzuschalten.",
  feedStockFinalMessagePart4:
    "Anschließend die Temperatur zwischen 18°C und 20°C halten. Die erste Kontrolle erfolgt frühestens 15 Tage nach der Beimpfung.",
  maloTankFinalMessage:
    "Diese Werte gelten als Hinweis und ersetzen in keinem Fall die Meinung Ihres beratenden Önologen.",

  //Calculateur Correction de la surpression d'un vin effervescent
  sparklinWineOverpressureTitle:
    "Korrektur des Überdrucks eines Schaumweins",
  sparklingWineOverpressureMessage:
    "Berechnen Sie die Korrektur des Überdrucks eines Schaumweins bei einer bestimmten Temperatur",
  overpressure: "Überdruck",
  temperature: "Temperatur",

  //Gestion de Cuve
  managementTanks: "Tankmanagement",
  volume: "Volumen",
  currentProtocol: "Laufendes Protokoll",
  startProtocol: "Protokoll starten",
  continueProtocol: "Protokoll fortführen",
  stopProtocol: "Protokoll stoppen",
  startNewProtocol: "Neues Protokoll starten",
  addATank: "Tank hinzufügen",
  noTankFound: "Es wurde kein Tank gefunden",
  textNoTankFound:
    "Wir haben keinen Tank auf Ihrem Gerät gefunden. Unten klicken, um einen hinzuzufügen!",
  addingTank: "Hinzufügen eines Tanks",
  nameTank: "Tanknummer",
  volumeTank: "Tankvolumen:",
  addTank: "Den Tank hinzufügen",
  dontAddTank: "Hinzufügen rückgängig machen",
  confirmationModalDeleteTank: "Wollen Sie den Tank wirklich löschen?",
  subTitleConfirmationModalDeleteTank:
    "Mit Löschen des Tanks wird das zugehörige Protokoll gelöscht",
  confirmationModalDeleteProtocol:
    "Wollen Sie das Protokoll wirklich stoppen?",
  confirmationModalNewProtocol:
    "Wollen Sie wirklich ein neues Protokoll starten?",
  confirmationModalDeleteProtocolInProgress:
    "Wollen Sie das laufende Protokoll wirklich löschen?",
  protocoleType: "Protokoll-Art: ",
  sampleTitle: "Bezeichnung der Probe: ",
  date: "Datum",
  tank: "Tank",
  sampleNameOnly: "Probennummer",
  FMLstep1: "Pressung",
  FMLstep2: "Cuvée",
  FMLstep3: "Taille",
  FMLstep4: "Alkoholische Gärung",
  FMLstep5: "Hefezusatz",
  FMLstep6: "Nährstoffversorgung der Hefe",
  FMLstep7: "Berechnung des Gäransatzes",
  Macerationstep1: "Selektion des Leseguts - Mahlen - Abbeeren",
  Macerationstep2: "Maischegärung",
  Macerationstep3: "Enzymzufuhr",
  Macerationstep4: "Cryomaceration vor der Gärung",
  Macerationstep5: "Alkoholische Gärung",
  Macerationstep6: "Maischung",
  Macerationstep7: "Anreicherung",
  Macerationstep8: "Abstich - Pressen",
  Macerationstep9: "BSA",
  Charmatstep1: "1.1 - Rehydrierung",
  Charmatstep2: "1.2 - Akklimatisierung/Schritt 1",
  Charmatstep3: "1.2 - Akklimatisierung/Schritt 2",
  Charmatstep4: "1.2 - Akklimatisierung/Schritt 3",
  Charmatstep5: "1.3 - Gäransatz",
  Charmatstep6: "1.3 - Gäransatz/Schritt 1",
  Charmatstep7: "1.3 - Gäransatz/Schritt 2",
  Charmatstep8: "1.3 - Gäransatz/Schritt 3",
  Charmatstep9: "Zwischenschritt",
  Charmatstep10: "Zwischenschritt",
  Charmatstep11: "Zwischenschritt",
  Charmatstep12: "1.4 - Schlüsseletappen der Vergärung",
  Charmatstep13: "2.1 - Schlüsseletappen des Ausbaus",
  Charmatstep14: "2.1 - Schlüsseletappen des Ausbaus",
  protocolCompleted: "Protokoll beendet",
  continue: "Fortsetzen",
  seeResults: "Ergebnisse ansehen",
  startnewProtocol: "Neues Protokoll starten",
  runningProtocole: "Laufende Protokolle",

  goToNextStep: "Nächster Schritt",
  goBack: "Zurück",
  none: "Keine",
  by: "Durch: ",
  of: " ",
  in: "in",
  to: "bis",
  and: "und",
  at: "bei",
  or: "oder",
  yes: "Ja",
  no: "Nein",
  between: "zwischen",
  add: "Hinzufügen",
  perDay: "pro Tag",
  wait: "Warten Sie",
  minutes: "Minuten",
  hour: "Stunde",
  hours: "Stunden",
  days: "Tage",
  liters: "Liter",
  inHectoliters: "in Hektolitern (hL)",
  g: "g",
  kg: "kg",
  cL: "cL",
  L: "L",
  dissolve: "Auflösen",
  yeasting: "HEFEZUSATZ",
  SULFITING: "SCHWEFELUNG",
  sulfiting: "Schwefelung",
  nutrition: "NÄHRSTOFFZUFUHR",
  tannin: "TANNINEINSATZ",
  enzyme: "ENZYMGABE",
  anthocyaninsStabilization: "STABILISIERUNG DER ANTHOCYANE",
  addInTank: "Direkt in den Tank geben",
  mustClarification: "Mostklärung",

  FMLInterStep:
    "Zwischenschritt durchführen, bevor das Protokoll fortgeführt wird",

  //Mentions Légales
  editor: "HERAUSGEBER",
  websiteEditBy: "Diese App wird herausgegeben von",
  headOffice: "Sitz",
  tel: "Tel.",
  fax: "Fax",
  sharedCapital: "Gesellschaftskapital",
  siretNumber: "SIRET-Nr.",
  numero: "USt-",
  TVA: "IdNr.",
  directorOfPublication: "Verantwortlicher für den Inhalt",
  host: "HOST",
  reproductiveRights: "URHEBERRECHTE",
  reproductiveRightsContent:
    "Die Informationen auf dieser Website sind öffentlich. Die Wiedergabe der Informationen dieser Website ist mit Ausnahme der Bilder gestattet, sofern deren Quelle genannt und ein Link zu der oder den Bezugsseiten eingerichtet wird. Die Informationen dieser Website dürfen keinesfalls für gewerbliche oder Werbezwecke verwendet werden.",
  personnalData: "DATENSCHUTZ",
  personnalDataContent:
    "Falls die auf dieser Website angegebenen Daten personenbezogen sind, müssen die Nutzer sie gemäß den geltenden Bestimmungen und Empfehlungen der französischen Datenschutzbehörde CNIL verwenden. Die Informationen dürfen ausschließlich zu persönlichen, beruflichen oder Verbandszwecken verwendet werden, wobei jede Verbreitung oder Nutzung zu gewerblichen oder Werbezwecken ausgeschlossen ist.",

  //Formulaire de contact
  titleContact: "Kontakt",
  errorMessageContact: "Nachricht oder E-Mail-Adresse fehlt.",
  errorSendMail:
    "Fehler beim Senden Ihrer Nachricht. Versuchen Sie es später erneut.",
  send: "Senden",
  chaptalisationWarning:
    "Die zuzugebende Kristallzuckermenge dient als Hinweis. Die Anreicherung unterliegt Regelungen; beachten Sie die geltenden gesetzlichen Bestimmungen.",
  close: "Schließen",
  inCaseOfProblem: "Bei Problemen: ",
  laboSecretary: "Sekretär Labor",
  reimsAgency: "Niederlassung Reims",
  oenologist: "Önologe",
  oenologistf: "Önologe",
  technicalReferent: "Technischer Ansprechpartner",
  oenologyReferent: "Önologischer Ansprechpartner",
  barSurSeineAgency: "Niederlassung Bar sur Seine",
  champagneAssistant: "Assistentin für die Champagne",
  mailSendWithSuccess:
    "Wir bedanken uns für Ihre Informationsanfrage. Unsere Teams werden diese umgehend bearbeiten.",
  scanMe: "Scannen Sie den QR-Code, um die Anwendung auf Ihr Mobiltelefon herunterzuladen",
};

export default De;
