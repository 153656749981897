import { IonCard, IonIcon } from "@ionic/react";
import { arrowForwardCircle, returnUpBack } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import Tank from "../../types/Tank";
import FMLZeroStep from "../component/FML/FMLZeroStep";
import {
  computeFMLBloqueeChardonnay,
  computeFMLBloqueePinotNoir,
} from "../../methods/compute";
import FMLFisrtStep from "../component/FML/FMLFirstStep";
import FMLBloqueeSecondStep from "../component/FMLBloquee/FMLBloqueeSecondStep";
//import FMLMustClarification from "../component/FML/FMLMustClarification";
import FMLFourthStep from "../component/FML/FMLFourthStep";
import FMLFifthStep from "../component/FML/FMLFifthStep";
import EndOfProtocole from "../component/endOfProtocole";
import FMLBloquee from "../../types/FMLBloquee";
import Protocole from "../../types/Protocole";
import FMLBloqueeThirdStep from "../component/FMLBloquee/FMLBloqueeThirdStep";
import translations from "../../modules/translations";

const compareWith = (o1: Tank, o2: Tank) => {
  return o1 && o2 ? o1.id === o2.id : o1 === o2;
};
interface Props {
  location: Location;
}

interface Location {
  state: State;
}

interface State {
  protocolId: string | undefined;
}

const ProtocoleFMLBloquee: React.FC<Props> = (props) => {
  const [protocolId, setProtocolId] = useState<string | undefined>(
    props.location?.state?.protocolId
  );
  const protocoleSelected: Protocole | undefined = protocolId
    ? Object.keys(localStorage)
        .filter((key) => key === "protocol_" + protocolId)
        .map((key) => JSON.parse(localStorage[key]))[0]
    : undefined;
  const [step, setStep] = useState<number>(
    protocoleSelected ? protocoleSelected.step : 0
  );
  const savedStep: number = step;
  const tanks: Tank[] = Object.keys(localStorage)
    .filter((key) => key.startsWith("tank_"))
    .map((key) => JSON.parse(localStorage[key]));
  const tankSelectedInProtocol =
    protocoleSelected && protocoleSelected.tankId
      ? tanks.filter((tank) => tank.id === protocoleSelected?.tankId)[0]
      : undefined;
  const [tankSelected, setTankSelected] = useState<Tank | undefined>(
    tankSelectedInProtocol
  );
  const [selectedDate, setSelectedDate] = useState<string>(
    protocoleSelected ? protocoleSelected.implementationDate : ""
  );
  const [sampleName, setSampleName] = useState<string>(
    protocoleSelected ? protocoleSelected.name : ""
  );
  const [cepage, setCepage] = useState<string>(
    protocoleSelected ? protocoleSelected.name : "chardonnay"
  );
  const [computedData, setComputedData] = useState<FMLBloquee | undefined>(
    protocoleSelected && tankSelected && cepage
      ? cepage === "chardonnay"
        ? computeFMLBloqueeChardonnay(tankSelected.volume)
        : computeFMLBloqueePinotNoir(tankSelected.volume)
      : undefined
  );

  const mainRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (mainRef && mainRef.current) {
      mainRef.current.scrollIntoView();
    }
  }, [step]);

  const [errorTank, setErrorTank] = useState<boolean>(false);
  const [errorDate, setErrorDate] = useState<boolean>(false);
  const history = useHistory();
  return (
    <div ref={mainRef}>
      <p>
        <span className="titleProtocols">
          <FormattedMessage id={`TitleFMLBloqueeStep${step}`} /> <br />
        </span>
        <span className="subTitleProtocols">
          <FormattedMessage id={`SubtitleFMLBloqueeStep${step}`} />
        </span>
      </p>
      <IonCard
        style={{
          color: "black",
          width: "40%",
          padding: "5%",
          fontFamily: "Lato",
          fontSize: "15pt",
          fontWeight: 600,
        }}
        onClick={() => {
          if (step === 0) {
            history.goBack();
          } else {
            setStep(step - 1);
          }
        }}
      >
        <IonIcon icon={returnUpBack} /> <FormattedMessage id="goBack" />
      </IonCard>
      <div>
        {step === 0 && (
          <FMLZeroStep
            tankSelected={tankSelected}
            tanks={tanks}
            setTankSelected={setTankSelected}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            sampleName={sampleName}
            setSampleName={setSampleName}
            cepage={cepage}
            setCepage={setCepage}
            compareWith={compareWith}
            errorTank={errorTank}
            setErrorTank={setErrorTank}
            errorDate={errorDate}
            setErrorDate={setErrorDate}
          />
        )}
        {step === 1 && <FMLFisrtStep />}
        {step === 2 && (
          <FMLBloqueeSecondStep computedData={computedData} cepage={cepage} />
        )}
        {step === 3 && (
          <FMLBloqueeThirdStep computedData={computedData} cepage={cepage} />
        )}
        {/* {step === 4 && <FMLMustClarification />} */}
        {step === 4 && (
          <FMLFourthStep computedData={computedData} FMLBloquee={true} />
        )}
        {step === 5 && <FMLFifthStep computedData={computedData} />}
        {step === 6 && (
          <EndOfProtocole
            computedData={computedData}
            sampleName={sampleName}
            protocoleType={"FMLB"}
            selectedDate={selectedDate}
            cepage={cepage}
            macerationType={undefined}
            aromaticProfile={undefined}
            sugar={undefined}
          />
        )}
        {step !== 6 && (
          <div>
            {step === 0 ? (
              <IonCard
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#1B1D26",
                  fontFamily: "Lato",
                  fontSize: "11pt",
                  fontWeight: 100,
                  padding: "5% 0",
                }}
                onClick={() => {
                  if (!tankSelected) {
                    setErrorTank(true);
                  } else if (!selectedDate) {
                    setErrorDate(true);
                  } else {
                    if (tankSelected && cepage) {
                      cepage === "chardonnay"
                        ? setComputedData(
                            computeFMLBloqueeChardonnay(tankSelected?.volume)
                          )
                        : setComputedData(
                            computeFMLBloqueePinotNoir(tankSelected?.volume)
                          );
                      const saveStep = step + 1;
                      setStep(step + 1);
                      let id = protocolId;
                      if (!id) {
                        id =
                          Math.random().toString(36).substring(2, 15) +
                          Math.random().toString(36).substring(2, 15);
                      }
                      setProtocolId(id);
                      if (savedStep === 0) {
                        localStorage.setItem(
                          "protocol_" + id,
                          JSON.stringify({
                            id: id,
                            modifiedAt: new Date(),
                            tankId: tankSelected.id,
                            name: sampleName,
                            type: "FMLBloquee",
                            step: saveStep,
                            status: "En cours",
                            implementationDate: selectedDate,
                            cepage: cepage,
                          })
                        );
                        const protocoles = tankSelected.protocoles.concat(id);
                        localStorage.setItem(
                          "tank_" + tankSelected.id,
                          JSON.stringify({
                            ...tankSelected,
                            protocoles: protocoles,
                          })
                        );
                      } else {
                        if (
                          protocoleSelected?.tankId !== tankSelected.id ||
                          protocoleSelected?.cepage !== cepage ||
                          protocoleSelected?.implementationDate !==
                            selectedDate ||
                          protocoleSelected?.name !== sampleName
                        ) {
                          //supp protocolId in tank
                          if (tankSelectedInProtocol) {
                            localStorage.setItem(
                              "tank_" + tankSelectedInProtocol.id,
                              JSON.stringify({
                                ...tankSelectedInProtocol,
                                protocoles: tankSelectedInProtocol.protocoles.filter(
                                  (protocolId) => protocolId !== id
                                ),
                              })
                            );
                          }

                          //add protocolId in tankSelected
                          const protocoles = tankSelected.protocoles.concat(id);
                          localStorage.setItem(
                            "tank_" + tankSelected.id,
                            JSON.stringify({
                              ...tankSelected,
                              protocoles: protocoles,
                            })
                          );

                          localStorage.setItem(
                            "protocol_" + id,
                            JSON.stringify({
                              id: id,
                              modifiedAt: new Date(),
                              tankId: tankSelected.id,
                              name: sampleName,
                              type: "FMLBloquee",
                              step: saveStep,
                              status: "En cours",
                              implementationDate: selectedDate,
                              cepage: cepage,
                            })
                          );
                        }
                      }
                    }
                  }
                }}
              >
                <FormattedMessage id="beginProtocole" />
              </IonCard>
            ) : (
              <IonCard
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#1B1D26",
                  fontFamily: "Lato",
                  fontSize: "15pt",
                  fontWeight: 600,
                  padding: "5% 0",
                }}
                onClick={() => {
                  const saveStep = step + 1;
                  setStep(step + 1);
                  if (saveStep > savedStep) {
                    localStorage.setItem(
                      "protocol_" + protocolId,
                      JSON.stringify({
                        ...protocoleSelected,
                        modifiedAt: new Date(),
                        status: saveStep >= 6 ? "Terminé" : "En cours",
                        step: saveStep,
                      })
                    );
                  }
                }}
              >
                {step === 5 ? (
                  <FormattedMessage id="endProtocole" />
                ) : (
                  <FormattedMessage id="goToNextStep" />
                )}
                <span style={{ paddingLeft: "25%" }}>
                  <IonIcon
                    style={{ fontSize: "30pt" }}
                    icon={arrowForwardCircle}
                    color="warning"
                  />
                </span>
              </IonCard>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProtocoleFMLBloquee;
