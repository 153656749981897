import React from "react";
import { FormattedMessage } from "react-intl";

const FMLFisrtStep: React.FC = () => {
  return (
    <div>
      <div className="actionDiv">
        <p className="actionTitle">
          <FormattedMessage id="pressing" />
        </p>
      </div>
      <div className="actionDiv">
        <p>
          <span style={{ fontWeight: 600 }}>
            <FormattedMessage id="sulfiting" />
          </span>{" "}
          <FormattedMessage id="FMLFirstStepSulfitingMessage" />
        </p>
      </div>
      <div className="actionDiv">
        <p>
          <span style={{ fontWeight: 600 }}>
            <FormattedMessage id="coldPrefermOperations" />
          </span><br />
          <FormattedMessage id="whenNeeded" />
        </p>
      </div>
    </div>
  );
};

export default FMLFisrtStep;
