import { IonCard, IonIcon } from "@ionic/react";
import { caretDownOutline, caretUpOutline } from "ionicons/icons";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

interface Result {
  reactivationVolume: number;
  feedStockVolume: number;
  bacteries: number;
  activator: number;
  rehydratationActivatorG: number;
  rehydratationActivatorL: number;
  rehydratationBacteriesG: number;
  rehydratationBacteriesL: number;
  rehydratedBacteries: number;
  settlingMust: number;
  aqueousEnvironment: number;
  sprinklingDryYeast: number;
  totalReactivation: number;
  totalDoubleReactivation: number;
  delayerTank: number;
  mixedTank: number;
  mustTank: number;
  totalTank: number;
  dryYeast: number;
}

const computeResult: (totalWineToSeed: number) => Result = (
  totalWineToSeed
) => {
  const reactivationVolume = totalWineToSeed * 0.2;
  const feedStockVolume = totalWineToSeed * 3;
  const bacteries = totalWineToSeed * 0.8;
  const activator = reactivationVolume * 5;
  const rehydratationActivatorG = activator;
  const rehydratationActivatorL = (activator * 10) / 1000;
  const rehydratationBacteriesG = bacteries;
  const rehydratationBacteriesL = (activator * 10) / 100;
  const rehydratedBacteries = (activator * 10) / 1000;
  const settlingMust = reactivationVolume / 2;
  const aqueousEnvironment = settlingMust - rehydratedBacteries;
  const sprinklingDryYeast = 0.5 * reactivationVolume;
  const totalReactivation =
    rehydratedBacteries + settlingMust + aqueousEnvironment;
  const totalDoubleReactivation = totalReactivation * 2;
  const delayerTank = totalWineToSeed * 3 * 0.2;
  const mixedTank = (10 * delayerTank - 3) / 1000;
  const mustTank = totalWineToSeed * 3 - mixedTank - totalDoubleReactivation;
  const totalTank = mixedTank + mustTank;
  const dryYeast = sprinklingDryYeast + delayerTank;

  return {
    reactivationVolume: Math.round(reactivationVolume * 10) / 10,
    feedStockVolume: Math.round(feedStockVolume * 10) / 10,
    bacteries: Math.round(bacteries * 10) / 10,
    activator: Math.round(activator * 10) / 10,
    rehydratationActivatorG: Math.round(rehydratationActivatorG * 10) / 10,
    rehydratationActivatorL: Math.round(rehydratationActivatorL * 10) / 10,
    rehydratationBacteriesG: Math.round(rehydratationBacteriesG * 10) / 10,
    rehydratationBacteriesL: Math.round(rehydratationBacteriesL * 10) / 10,
    rehydratedBacteries: Math.round(rehydratedBacteries * 10) / 10,
    settlingMust: Math.round(settlingMust * 10) / 10,
    aqueousEnvironment: Math.round(aqueousEnvironment * 10) / 10,
    sprinklingDryYeast: Math.round(sprinklingDryYeast * 10) / 10,
    totalReactivation: Math.round(totalReactivation * 10) / 10,
    totalDoubleReactivation: Math.round(totalDoubleReactivation * 10) / 10,
    delayerTank: Math.round(delayerTank * 10) / 10,
    mixedTank: Math.round(mixedTank * 10) / 10,
    mustTank: Math.round(mustTank * 10) / 10,
    totalTank: Math.round(totalTank * 10) / 10,
    dryYeast: Math.round(dryYeast * 10) / 10,
  };
};
interface Props {
  wineVolume: number | undefined;
}

const MaloTankResult: ({ wineVolume }: Props) => JSX.Element = ({
  wineVolume,
}) => {
  const language = localStorage.getItem('language');

  const result = wineVolume ? computeResult(wineVolume) : undefined;
  const [globalDataToggle, setGlobalDataToggle] = useState<boolean>(false);
  const [rehydratationToggle, setRehydratationToggle] =
    useState<boolean>(false);
  const [reactivationEnvironmentToggle, setReactivationEnvironmentToggle] =
    useState<boolean>(false);
  const [feedStockToggle, setFeedStockToggle] = useState<boolean>(false);

  const untoggleTabs = () => {
    setGlobalDataToggle(false);
    setRehydratationToggle(false);
    setReactivationEnvironmentToggle(false);
    setFeedStockToggle(false);
  };

  return (
    <div>
      {result && (
        <div>
          <IonCard style={{ color: "black" }}>
            <div
              onClick={() => {
                untoggleTabs();
                setGlobalDataToggle(!globalDataToggle);
              }}
              style={{ padding: "5%" }}
            >
              {" "}
              <FormattedMessage id="globaldata" /> <br />
              <br />
              <IonIcon
                icon={globalDataToggle ? caretUpOutline : caretDownOutline}
              />
            </div>
            {globalDataToggle && (
              <div>
                <div style={{ padding: "5%" }}>
                  <p>
                    <FormattedMessage id="reactivationEnvironmentVolume" />
                    <br />
                    <span className="productValue">
                      {result.reactivationVolume}
                    </span>
                    <span className="productUnit">
                      <FormattedMessage id="liters" />
                    </span>
                    <br />
                  </p>
                  <p>
                    <FormattedMessage id="feedStockVolume" />
                    <br />
                    <span className="productValue">
                      {result.feedStockVolume}
                    </span>
                    <span className="productUnit">
                      <FormattedMessage id="liters" />
                    </span>
                    <br />
                  </p>
                  <p>
                    <FormattedMessage id="bacteries" />
                    <br />
                    <span className="productValue">{result.bacteries}</span>
                    <span className="productUnit">
                      <FormattedMessage id="g" />
                    </span>
                    <br />
                  </p>
                  <p>
                    <FormattedMessage id="activator" />
                    <br />
                    <span className="productValue">{result.activator}</span>
                    <span className="productUnit">
                      <FormattedMessage id="g" /> START® ML 5
                    </span>
                    <br />
                  </p>
                  <p>
                    <FormattedMessage id="dryYeast" />
                    <br />
                    <span className="productValue">{result.dryYeast}</span>
                    <span className="productUnit">
                      <FormattedMessage id="g" /> SOEC® 1971
                    </span>
                    <br />
                  </p>
                </div>
                <div
                  style={{
                    color: "white",
                    backgroundColor: "#1B1D26",
                    width: "100%",
                    padding: "10%",
                  }}
                >
                  <FormattedMessage id="globalDataFeedStockMessage" />
                </div>
              </div>
            )}
          </IonCard>
          <IonCard style={{ color: "black" }}>
            <div
              onClick={() => {
                untoggleTabs();
                setRehydratationToggle(!rehydratationToggle);
              }}
              style={{ padding: "5%" }}
            >
              {" "}
              <FormattedMessage id="rehydratation" /> <br />
              <FormattedMessage id="duration" /> 15 <FormattedMessage id="to" />{" "}
              20 <FormattedMessage id="minutes" />
              <br />
              <IonIcon
                icon={rehydratationToggle ? caretUpOutline : caretDownOutline}
              />
            </div>
            {rehydratationToggle && (
              <div>
                <div style={{ padding: "5%" }}>
                  <p>
                    <FormattedMessage id="delay" />
                    <br />{" "}
                    <span className="productValue">
                      {result.rehydratationActivatorG}
                    </span>
                    <span className="productUnit">
                      <FormattedMessage id="g" />
                    </span>{" "}
                    <br />
                    <FormattedMessage id="of" /> START® ML 5{" "}
                    <FormattedMessage id="in" /> <br />
                    <span className="productValue">
                      {result.rehydratationActivatorL}
                    </span>{" "}
                    <FormattedMessage id="litersOfWater" />
                  </p>
                </div>
                <div style={{ padding: "5%" }}>
                  <p>
                    <FormattedMessage id="delay" />
                    <br />{" "}
                    <span className="productValue">
                      {result.rehydratationBacteriesG}
                    </span>
                    <span className="productUnit">
                      <FormattedMessage id="g" />
                    </span>{" "}
                    <br />
                    <FormattedMessage id="of" /> Vitilactic® BL01{" "}
                    <FormattedMessage id="in" /> <br />
                    <span className="productValue">
                      {result.rehydratationBacteriesL}
                    </span>{" "}
                    <FormattedMessage id="liters" />
                    <br />
                    {language === 'DE-DE' && <>
                      Bakterienansatz <br />
                    </>}
                    <FormattedMessage id="preparedBefore" />
                  </p>
                </div>
                <div
                  style={{
                    color: "white",
                    backgroundColor: "#1B1D26",
                    width: "100%",
                    padding: "10%",
                  }}
                >
                  <FormattedMessage id="rehydratationMessage" />
                </div>
              </div>
            )}
          </IonCard>
          <div className="actionDiv">
            <FormattedMessage id="maloTankMiddleMessage" />
          </div>
          <IonCard style={{ color: "black" }}>
            <div
              style={{ padding: "5%" }}
              onClick={() => {
                untoggleTabs();
                setReactivationEnvironmentToggle(
                  !reactivationEnvironmentToggle
                );
              }}
            >
              {" "}
              <FormattedMessage id="reactivationEnvironment" /> <br />
              <FormattedMessage id="duration" /> 1 <FormattedMessage id="to" />{" "}
              2 <FormattedMessage id="days" />
              <br />
              <IonIcon
                icon={
                  reactivationEnvironmentToggle
                    ? caretUpOutline
                    : caretDownOutline
                }
              />
            </div>
            {reactivationEnvironmentToggle && (
              <div>
                <div style={{ padding: "5%" }}>
                  <p>
                    <span className="productValue">
                      {result.rehydratedBacteries}
                    </span>
                    <span className="productUnit">
                      <FormattedMessage id="liters" />
                    </span>
                    <br />
                    <FormattedMessage id="of" />{" "}
                    <FormattedMessage id="rehydratedBacteries" />
                    <br />
                  </p>
                  <p>
                    <span className="productValue">{result.settlingMust}</span>
                    <span className="productUnit">
                      <FormattedMessage id="liters" />
                    </span>
                    <br />
                    <FormattedMessage id="of" />{" "}
                    <FormattedMessage id="settlingMust" />
                    <br />
                  </p>
                  {/* <p>
                    <span className="productValue">
                      {result.aqueousEnvironment}
                    </span>
                    <span className="productUnit">
                      <FormattedMessage id="liters" />
                    </span>
                    <br />
                    <FormattedMessage id="of" />{" "}
                    <FormattedMessage id="aqueousEnvironment" />
                    <br />
                  </p> */}
                  <p>
                    <span className="productValue">
                      {result.sprinklingDryYeast}
                    </span>
                    <span className="productUnit">
                      <FormattedMessage id="g" />
                    </span>
                    <br />
                    <FormattedMessage id="of" />{" "}
                    <FormattedMessage id="sprinklingDryYeast" />
                    <br />
                  </p>
                  <p>
                    <span className="productValue">
                      {result.totalReactivation}
                    </span>
                    <span className="productUnit">
                      <FormattedMessage id="liters" />
                    </span>
                    <br />
                    <span>
                      <FormattedMessage id="inTotal" />{" "}
                    </span>
                    <br />
                  </p>
                </div>
                <div
                  style={{
                    color: "white",
                    backgroundColor: "#1B1D26",
                    width: "100%",
                    padding: "10%",
                  }}
                >
                  <p>
                    <FormattedMessage id="reactivationEnvironmentMessage" />
                  </p>
                  <span className="productValue">
                    {result.totalDoubleReactivation}
                  </span>{" "}
                  <span className="productUnit">
                    <FormattedMessage id="liters" />
                  </span>
                  <br />
                  <FormattedMessage id="duration" /> : 2{" "}
                  <FormattedMessage id="days" />
                </div>
              </div>
            )}
          </IonCard>
          <IonCard style={{ color: "black" }}>
            <div
              style={{ padding: "5%" }}
              onClick={() => {
                untoggleTabs();
                setFeedStockToggle(!feedStockToggle);
              }}
            >
              {" "}
              <FormattedMessage id="feedStock" /> <br />
              <FormattedMessage id="duration" /> 3{" "}
              <FormattedMessage id="days" />
              <br />
              <IonIcon
                icon={feedStockToggle ? caretUpOutline : caretDownOutline}
              />
            </div>
            {feedStockToggle && (
              <div>
                <div style={{ padding: "5%" }}>
                  <p>
                    <FormattedMessage id="delay" />
                    <br />
                    <span className="productValue">
                      {result.delayerTank}
                    </span>{" "}
                    <span className="productUnit">
                      <FormattedMessage id="g" />
                    </span>
                    <br />
                    <FormattedMessage id="of" /> SOEC® 1971{" "}
                    <FormattedMessage id="in" />
                    <br />
                    <span className="productValue">{result.mixedTank}</span>
                    <span className="productUnit">
                      <FormattedMessage id="liters" />
                    </span>
                    <br />
                    <FormattedMessage id="mustWaterMixing" />
                  </p>
                </div>
                <div
                  style={{
                    color: "white",
                    backgroundColor: "#1B1D26",
                    width: "100%",
                    padding: "10%",
                  }}
                >
                  <p>
                    <FormattedMessage id="feedStockFirstMessagePart1" />
                  </p>
                  <p>
                    <FormattedMessage id="feedStockFirstMessagePart2" />
                  </p>
                </div>
                <div style={{ padding: "5%" }}>
                  <p>
                    <span className="productValue">{result.mixedTank}</span>{" "}
                    <span className="productUnit">
                      <FormattedMessage id="liters" />
                    </span>
                    <br />
                    <FormattedMessage id="of" />{" "}
                    <FormattedMessage id="rehydratedYeasts" />
                  </p>
                  <p>
                    <span className="productValue">{result.mustTank}</span>{" "}
                    <span className="productUnit">
                      <FormattedMessage id="liters" />
                    </span>
                    <br />
                    <FormattedMessage id="of" /> <FormattedMessage id="must" />
                  </p>

                  {/* <p>
                    <span className="productValue">{result.totalTank}</span>{" "}
                    <span className="productUnit">
                    <FormattedMessage id="liters" />
                    </span>
                    <br />
                    <FormattedMessage id="inTotal" />
                  </p> */}
                </div>
                <div
                  style={{
                    color: "white",
                    backgroundColor: "#1B1D26",
                    width: "100%",
                    padding: "10%",
                  }}
                >
                  <p>
                    <FormattedMessage id="feedStockVolumeComplement" />
                  </p>
                </div>
                <div style={{ padding: " 10% 5%" }}>
                  <p>
                    <FormattedMessage id="feedStockTotalVolume" />
                    <br />
                    <span className="productValue">
                      {result.feedStockVolume}
                    </span>{" "}
                    <span className="productUnit">
                      <FormattedMessage id="liters" />
                    </span>
                  </p>
                </div>
                <div
                  style={{
                    color: "white",
                    backgroundColor: "#1B1D26",
                    width: "100%",
                    padding: "10%",
                  }}
                >
                  <p>
                    <FormattedMessage id="feedStockSecondMessage" />
                  </p>
                </div>
                <div style={{ padding: "10%" }}>
                  <p>
                    <FormattedMessage id="feedStockFinalMessagePart1" />
                  </p>
                  <p>
                    <FormattedMessage id="feedStockFinalMessagePart2" />
                  </p>
                  <p>
                    <FormattedMessage id="feedStockFinalMessagePart3" />
                  </p>
                  <p>
                    <FormattedMessage id="feedStockFinalMessagePart4" />
                  </p>
                </div>
              </div>
            )}
          </IonCard>
          <p style={{ color: "red", padding: "10%" }}>
            <FormattedMessage id="maloTankFinalMessage" />
          </p>
        </div>
      )}
    </div>
  );
};

export default MaloTankResult;
