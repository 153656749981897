import { IonCard } from "@ionic/react";
import React from "react";
import { FormattedMessage } from "react-intl";
import { InputNumberField } from "../inputFields";

interface Props {
  sugar: number;
  setSugar: (x: number) => void;
}

const CharmatSugarAsk: React.FC<Props> = ({ sugar, setSugar }) => {
  return (
    <IonCard style={{ margin: "20% 5%", textAlign: "left" }}>
      <div className="inputDiv">
        <div className="inputTitle">
          <FormattedMessage id="sugarQuantity" />
        </div>
        <div className="descriptionText">
          <FormattedMessage id="sugarQuantityAsk" />
        </div>
        <InputNumberField value={sugar} setValue={setSugar} />
      </div>
    </IonCard>
  );
};

export default CharmatSugarAsk;
